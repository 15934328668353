import React, { Component } from 'react';
import API from '../../api';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import {
    CustomInput,
    Modal,
    ModalFooter,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import LoadingBar from 'react-top-loading-bar'

export default class GenerateQRCode extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.getManufacturerOption = this.getManufacturerOption.bind(this);

        this.state = {
            menuCode: constants.MENU_IMPORT_CSV,
            scope: [],
            productId: "",
            productCode: "",
            manufacturerId: "",
            certificate: "",
            qrCodeId: "",
            manufacturerFlag: "",
            madeIn: "",
            productionDate: "",
            expiredDate: "",
            manufacturer: [],
            variantInputIndexes: [],
            variant: [],
            variantId: '',
            openModalVariant: false,
            variantPointer: "",
            variantIdMulti: [],
            emptyVariant: "",
            variantIdPointer: "",
            newVariant: "",
            variantList: [],
            variantChangeIndex: 0,
            productCodes: [],
        }
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.setState({
            productId: id
        })
        this.getDetailProduct(id);
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'create');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    getDetailProduct(id) {
        API.get("product/" + id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(async (res) => {
                console.log("Detail product", res.data[0]);
                const variant = this.state.variant

                let url = 'product/getVariant/'+res.data[0].manufacturer_id;            
                if(parseInt(res.data[0].flag_productvariant, 10) === 1){
                    url = 'product/getVariant/'+res.data[0].manufacturer_id+'/'+res.data[0].product_id+'/0';
                }
                await API.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
                    .then(respVar => {
                        variant[this.state.variantChangeIndex] = respVar.data.result
                        this.setState({
                            variant: variant,
                        })
                    }).catch(err => alert(err))
                const productCodeId = id;

                this.setState({
                    productCodeIdInt: productCodeId
                });
                this.setState({
                    productCode: res.data[0].product_code,
                    manufacturerId: res.data[0].manufacturer_id,
                    certificate: res.data[0].certificate,
                    qrCodeId: res.data[0].qrcode_id,
                    manufacturerFlag: parseInt(res.data[0].flag_productvariant),
                    madeIn: res.data[0].made_in,
                    productionDate: res.data[0].production_date,
                    expiredDate: res.data[0].expired_date,
                    manufacturerName: res.data[0].name,
                    variantInputIndexes: [{ value: '' }],
                    variant: variant
                })
                this.getManufacturer();
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    async getManufacturer() {
        let manufacturer = await API.get('manufacturer?size=20&page=1', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        let manufacturerOptions = await API.get('manufacturer/options', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })

        this.setState({
            // manufacturer: manufacturer.data.item,
            manufacturer: manufacturerOptions.data,
            // manufacturerId: manufacturerOptions.data[0].manufacturer_id,
            manufacturerName: manufacturerOptions.data[0].name,
            manufacturerFlag: manufacturerOptions.data[0].flag_productvariant,
            variantInputIndexes: [{ value: '', id: 0 }]
        })

        if (this.state.manufacturerId) {
            // let manufacturerVariant = await API.get('product/getVariant/'+this.state.manufacturerId)
            let manufacturerProductCode = await API.get('product/getProductCodes/' + this.state.manufacturerId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            // if(this.state.manufacturerId === 49){
            //     this.downloadImageFromHTMLSource();
            // }
            // alert(JSON.stringify(manufacturerProductCode.data.result))
            if (
                // (manufacturerVariant.data.result.length > 0 && manufacturerVariant.data.result[0].variant) && 
                (manufacturerProductCode.data.result.length > 0 && manufacturerProductCode.data.result[0].code))
                this.setState({
                    productCodes: manufacturerProductCode.data.result,
                    productCodeId: manufacturerProductCode.data.result[0].code,
                    // variant: manufacturerVariant.data.result,
                    // variantId: manufacturerVariant.data.result[0].variant,                
                })

            // if((manufacturerVariant.data.result.length > 0 && manufacturerVariant.data.result[0].variant)){
            //     this.setState({
            //         variant: manufacturerVariant.data.result,
            //         variantId: manufacturerVariant.data.result[0].variant,
            //     })
            //     // setTimeout(()=>{
            //     //     alert(this.state.variantId)
            //     // })
            // } else
            if (manufacturerProductCode.data.result.length > 0 && manufacturerProductCode.data.result[0].code) {
                this.setState({
                    productCodes: manufacturerProductCode.data.result,
                    productCodeId: manufacturerProductCode.data.result[0].code
                })
                // setTimeout(()=>{
                //     alert(this.state.productCodeId)
                // })
            } else {
                this.setState({
                    productCodes: manufacturerProductCode.data.result,
                    // variant: manufacturerVariant.data.result
                })
                // setTimeout(()=>{
                //     alert(JSON.stringify(manufacturerProductCode.data.result[0]))
                //     // alert(manufacturerProductCode.data.result)
                // })
            }
            // alert(this.state.manufacturerId)
        }

    }

    getManufacturerOption() {
        let items = [];
        let manufacturer = this.state.manufacturer;
        console.log("List manufacturer", manufacturer)
        for (let i = 0; i < manufacturer.length; i++) {
            items.push(<option key={manufacturer[i].manufacturer_id} value={manufacturer[i].manufacturer_id} data-flag={manufacturer[i].flag_productvariant}>{manufacturer[i].name}</option>);
        }
        return items;
    }

    getVariant(index){
        let items = [];
        let variant = this.state.variant[index];
        console.log('ini variant get index', variant);
        const indexList = index;
        let variantIndex = this.state.variantInputIndexes.filter((item,index)=> index===indexList)
        console.log(this.state.variantInputIndexes)
        console.log(variantIndex);
        console.log(variant);
        let selected = false;
        if(variant==undefined){
            items.push(<option label="Empty list" disabled selected/>)            
        }
        else if(!variant.length){
            items.push(<option label="Empty list" disabled selected/>)            
        }
        else{
            // items.push(<option key={0} value={''}>Choose Option</option>)
            for(let i=0; i < variant.length; i++){
                selected = variantIndex.length ? (variantIndex[0].value===variant[i].variant ? true : false) : false
    
                items.push(<option key={variant[i].id} data-id={variant[i].id} value={variant[i].variant} selected={selected}>{variant[i].variant}</option>);
            }
        }
        
        return items;
    }

    async handleChange(event) {
        if (event.target.name === 'manufacturerId' && event.target.value != '') {

            var index = event.target.selectedIndex;
            var optionElement = event.target.childNodes[index]
            var option = optionElement.getAttribute('data-name');
            this.setState({ manufacturerName: option })

            var optionFlag = optionElement.getAttribute('data-flag');
            this.setState({ manufacturerFlag: optionFlag })

            console.log(option);
            console.log(optionFlag);
            const manId = event.target.value;

            if (manId) {
                setTimeout(() => {
                    if (parseInt(this.state.manufacturerId) === 49) {
                        // this.downloadImageFromHTMLSource();
                        this.setState({
                            fileChangeDetected: true,
                            useCustomCert: true,
                        })
                        setTimeout(() => {
                            this.setState({
                                fileChangeDetected: false,
                                certFile: null,
                                certFileLabel: null
                            })
                        }, 500)
                    } else {
                        this.setState({
                            useCustomCert: false
                        })
                    }
                }, 5)

                // setTimeout(async () => {
                // let manufacturerVariant = await API.get('product/getVariant/'+manId)
                await API.get('product/getProductCodes/' + manId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
                    .then(resp => {
                        this.setState({
                            // variant: resp.data.result,
                            // variantId: resp.data.result[0] ? manufacturerVariant.data.result[0].variant : -1,
                            productCodes: resp.data.result,
                            // productCodeId: resp.data.result[0] ? manufacturerProductCode.data.result[0].code : -1,
                        })
                    }).catch(err => console.log(err))

                // }, 5);
            }
        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    }

    handleChangeDate(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    async addVariant(e) {
        e.preventDefault()
        const parentId = this.state.variantChangeIndex == 0 ? 0 : this.state.variantInputIndexes[this.state.variantChangeIndex - 1].id
        const body = {
            "manufacturedId": this.state.manufacturerId,
            "variantName": this.state.newVariant.replace(/%/g, '%25'),
            "parentId": parentId,
            "productCodeId": this.state.productCodeIdInt
        }

        API.post('product/variant', body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(async () => {
                let url = 'product/getVariant/'+this.state.manufacturerId;            
                if(parseInt(this.state.manufacturerFlag, 10) === 1){
                    url = 'product/getVariant/'+this.state.manufacturerId+'/'+this.state.productCodeIdInt+'/0';
                }
                API.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
                    .then(res => {
                        const variant = this.state.variant
                        variant[this.state.variantChangeIndex] = res.data.result
                        this.setState({
                            variant: variant,
                            openModalVariant: !this.state.openModalVariant,
                        })
                    }).catch(error => console.log("Error Get Variant ", error))
            })
            .catch(error => console.log("Error Add Variant", error))
    }

    handleSubmit(event) {
        console.log("submit", this.state)
        // const config = {
        //     headers: {
        //         'content-type': 'multipart/form-data'
        //     }
        // }

        // let paramQRCode = {
        //     logo: this.state.logoFile
        // }
        let body = {
            manufacturerId: this.state.manufacturerId,
            madeIn: this.state.madeIn,
            code: this.state.productCode,
            variantId: this.state.variantId,
            qrCodeId: this.state.qrCodeId,
            certificate: this.state.certificate,
        }

        if (this.state.productionDate != "") {
            body.productionDate = this.state.productionDate
        }

        if (this.state.expiredDate != "") {
            body.expiredDate = this.state.expiredDate
        }

        console.log("Param product", body);
        API.put('product/' + this.state.productId, body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(response => {
                console.log("Sukses create Manufacturer");
                this.props.history.push("/product/");
            })
            .catch(error => {
                console.log("Error create Manufacturer", error);
            });
    }

    // VARIANT SKU
    setVariant(index) {
        let items = [<option key={0} value={''}>Choose Option</option>];
        let variant = this.state.variant;
        const indexList = index;
        let variantIndex = this.state.variantInputIndexes.filter((item, index) => index === indexList)
        let selected = false;
        for (let i = 0; i < variant.length; i++) {
            selected = variantIndex[0].value === variant[i].variant ? true : false

            items.push(<option key={variant[i].id} value={variant[i].variant} selected={selected}>{variant[i].variant}</option>);
        }
        return items;
    }

    handleChangeSKU(event, index) {
        const value = event.target.value;
        let newVariant = this.state.variantInputIndexes;
        newVariant[index].value = value

        this.setState({
            variantInputIndexes: newVariant,
            variantId: newVariant.map(u => u.value).join('-')
        });
    }

    PushArrayVariant() {
        let arrLength = this.state.variantInputIndexes.length;
        let arr = this.state.variantInputIndexes;
        arr.push({ value: '' })
        this.setState({
            variantInputIndexes: arr
        })
    }

    RemoveArrayVariant(index) {
        const newIndex = this.state.variantInputIndexes.splice(index, 1);
        console.log(index, this.state.variantInputIndexes, newIndex)
        this.setState({
            variantInputIndexes: this.state.variantInputIndexes
        })
    }
    changeVariant(index) {
        this.setState({
            openModalVariant: !this.state.openModalVariant,
            variantList: this.state.variant ? this.state.variant[index] : [],
            variantChangeIndex: index
        })
        console.log('ini variant list', this.state.variantList)
    }
    async editVariant(id, e){
        e.preventDefault();
        const body = {
            "variant" : this.state.variantPointer.replace(/%/g,'%25'),
        }

        await API.post('product/editVariant/'+id, body,{ headers: { Authorization: `Bearer ${localStorage.getItem('token')}`,'Content-Type': 'application/json', }})
        .then( async (resp) => {
            if(resp.data.success){
                const variant = this.state.variant;
                console.log(variant, this.state.variantChangeIndex, this.state.variantChangeIndex-1)
                const parentId = this.state.variantChangeIndex == 0 ? 0 : this.state.variantInputIndexes[this.state.variantChangeIndex-1].id

                let url = 'product/getVariant/'+this.state.manufacturerId;            
                if(parseInt(this.state.manufacturerFlag, 10) === 1){
                    url = 'product/getVariant/'+this.state.manufacturerId+'/'+this.state.productCodeIdInt+'/0';
                }
                await API.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
                .then (resp => {
                    variant[this.state.variantChangeIndex] = resp.data.result;
                    this.setState({
                        variant: variant,
                        openModalEdit: !this.state.openModalEdit,
                        variantPointer: ''
                    })
                }).catch(error => console.log("Error Get Variant ",error))

                
            }
        })
        .catch(error => console.log("Error Add Variant",error))        
    }

    async deleteVariant(id){
        let deleteVariant = await API.get('product/deleteVariant/'+id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
        if(deleteVariant.data.success){
            // alert('Success')
            let manufacturerVariant = await API.get('product/getVariant/'+this.state.manufacturerId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
            // alert(JSON.stringify(manufacturerVariant.data.result))
            this.setState({
                variant: manufacturerVariant.data.result,
                variantId: manufacturerVariant.data.result[0] ? manufacturerVariant.data.result[0].variant : -1,
                openModalConfirmation: !this.state.openModalConfirmation,
                variantIdPointer: ''
            })
            // if(parseInt(this.state.manufacturerId)===49){
            //     this.downloadImageFromHTMLSource();
            // }
        }
    }

    render() {
        return (
            <div className="content">
            <style>{this.state.css}</style>
            <LoadingBar onRef={ref => (this.LoadingBar = ref)} />
                <Modal isOpen={this.state.openModalVariant}
                    toggle={() => this.setState({ openModalVariant: !this.state.openModalVariant })}
                    modalTransition={{ timeout: 500 }}>
                    <ModalHeader>
                        <h5>Change Variant/SKU Value</h5>
                    </ModalHeader>
                    <ModalBody>
                        <div className='mb-3'>
                            Variant List of <b>{this.state.productCode ? this.state.productCode : '-'}</b>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                {
                                    this.state.variantList.length > 0
                                        ?
                                        <div className='table-modal-wrapper'>
                                            <table className='table table-striped'>
                                                {
                                                    this.state.variantList.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td className='vert-middle width-80'>
                                                                    <span>{item.variant}</span>
                                                                </td>
                                                                <td className='text-right width-10'>
                                                                    {/* <button onClick={()=>this.deleteVariant(item.id)} className='btn btn-danger'>Delete</button> */}
                                                                    <button onClick={() => this.setState({ openModalEdit: !this.state.openModalEdit, variantPointer: item.variant, variantIdPointer: item.id })} className='btn btn-info'>Edit</button>
                                                                </td>
                                                                <td className='text-right'>
                                                                    {/* <button onClick={()=>this.deleteVariant(item.id)} className='btn btn-danger'>Delete</button> */}
                                                                    <button onClick={() => this.setState({ openModalConfirmation: !this.state.openModalConfirmation, variantPointer: item.variant, variantIdPointer: item.id })} className='btn btn-danger'>Delete</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <form className='row m-0 p-0'>
                                <div className='col-md-10'>
                                    {/* {this.state.newVariant} */}
                                    <div className='my-2'>
                                        <span>Add Variant List Here</span>
                                    </div>
                                    <input className="form-control valid" value={this.state.newVariant} onChange={(e) => { this.setState({ newVariant: e.target.value }) }} />
                                </div>
                                <div className='col-md-2'>
                                    <button type="submit" className='btn btn-info button-add-variant-modal mt-3' onClick={(e) => this.addVariant(e)}>Add</button>
                                </div>
                            </form>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.openModalEdit}
                    toggle={()=>this.setState({ openModalEdit: !this.state.openModalEdit })}
                    modalTransition={{ timeout: 500 }}
                >
                    <ModalHeader>
                        <span>Edit Variant/SKU</span>
                    </ModalHeader>
                    <form>
                        <ModalBody>
                            <input className="form-control valid" value={this.state.variantPointer} onChange={(e)=>{ this.setState({ variantPointer: e.target.value }) }}/>
                        </ModalBody>
                        <ModalFooter>
                            <button type="submit" className='btn btn-primary text-white' onClick={(e)=>this.editVariant(this.state.variantIdPointer, e)}>Edit</button>
                            <button type="button" className='btn btn-info  text-white' onClick={()=>this.setState({ openModalEdit:!this.state.openModalEdit })}>Cancel</button>
                        </ModalFooter>
                    </form>
                </Modal>

                <Modal isOpen={this.state.openModalConfirmation}
                    toggle={()=>this.setState({ openModalConfirmation: !this.state.openModalConfirmation })}
                    modalTransition={{ timeout: 500 }}
                >
                    <ModalHeader>
                        <span>Delete Confirmation</span>
                    </ModalHeader>
                    <ModalBody>
                        <span>Are you sure you want to delete '<b>{this.state.variantPointer}</b>' variant?</span>
                    </ModalBody>
                    <ModalFooter>
                        <button className='btn btn-danger' onClick={()=>this.deleteVariant(this.state.variantIdPointer)}>Delete</button>
                        <button className='btn btn-info' onClick={()=>this.setState({ openModalConfirmation:!this.state.openModalConfirmation })}>Cancel</button>
                    </ModalFooter>
                </Modal>
                <div className="animated fadeIn">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="card-title">Edit Product</strong>
                                </div>
                                <div className="card-body">
                                    <div className="form-group has-success">
                                        <label for="productCode" className="control-label mb-1">ProductID</label>
                                        <input
                                            id="productCode" name="productCode" type="text"
                                            className="form-control valid"
                                            data-val="true"
                                            data-val-required="Please enter the ProductID"
                                            autocomplete="productCode" aria-required="true"
                                            aria-invalid="false" aria-describedby="productCode"
                                            value={this.state.productCode}
                                            onChange={this.handleChange}
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="productCode" data-valmsg-replace="true"></span>
                                    </div>
                                    <div class="form-group">
                                        <label for="manufacturerId" class=" form-control-label">Manufacturer</label>
                                        <select name="manufacturerId" id="manufacturerId" class="form-control" onChange={this.handleChange} value={this.state.manufacturerId}>
                                            {this.getManufacturerOption()}
                                        </select>
                                    </div>
                                    <div className="form-group has-success">
                                        <label for="madeIn" className="control-label mb-1">Made In</label>
                                        <input
                                            id="madeIn" name="madeIn" type="text"
                                            className="form-control valid"
                                            data-val="true"
                                            data-val-required="Please enter the ProductID"
                                            autocomplete="madeIn" aria-required="true"
                                            aria-invalid="false" aria-describedby="madeIn"
                                            value={this.state.madeIn}
                                            onChange={this.handleChange}
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="madeIn" data-valmsg-replace="true"></span>
                                    </div>

                                    {
                                        this.state.variantInputIndexes.length > 0
                                            ?
                                            this.state.variantInputIndexes.map((item, index) => {
                                                return (
                                                    <div className="form-group has-success">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label for="variantId" className="control-label mb-1">Variant/SKU
                                                                    {
                                                                        parseInt(this.state.manufacturerFlag, 10) === 1 ?
                                                                            index + 1
                                                                            : null
                                                                    }</label>
                                                            </div>
                                                            <div className='col-md-6 text-right'>
                                                                {
                                                                    this.state.productCodeId ?
                                                                        <a href='#' onClick={() =>
                                                                            this.changeVariant(index)
                                                                            // this.setState({ openModalVariant:!this.state.openModalVariant  })
                                                                        }>Change Variant/SKU {index + 1} Value
                                                                        </a> : null
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className={
                                                                parseInt(this.state.manufacturerFlag, 10) === 1 ?
                                                                    'col-md-10'
                                                                    : 'col-md-12'
                                                            }>
                                                                <select name="variantId" id="variantId" class="form-control" onChange={(event) => this.handleChangeSKU(event, index)}>
                                                                    {
                                                                        this.state.variant.length > 0
                                                                            ?
                                                                            this.getVariant(index)
                                                                            :
                                                                            this.state.variant.length === 0 && this.state.manufacturerId > 0
                                                                                ?
                                                                                <option label="Empty list" disabled selected />
                                                                                :
                                                                                <option label="Please select the product first" disabled selected />
                                                                    }
                                                                </select>
                                                            </div>
                                                            {
                                                                parseInt(this.state.manufacturerFlag, 10) === 1 ? (
                                                                    index === 0
                                                                        ?
                                                                        <div className='col-md-1-5 p-0' >
                                                                            <button style={{ fontSize: '17px' }} onClick={() => { this.PushArrayVariant(item) }} className='btn btn-sm btn-primary text-xs'>
                                                                                <i className='ti-plus'></i> More Item
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        <div className='col-md-1-5 text-left p-0'>
                                                                            <a onClick={() => { this.RemoveArrayVariant(index) }} className='btn text-danger bg-none'>
                                                                                <i className='ti-trash'></i>
                                                                            </a>
                                                                        </div>
                                                                ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            null
                                    }
                                    <div className="form-group has-success">
                                        <label for="productionDate" className="control-label mb-1">Production Date</label>
                                        <input
                                            id="productionDate" name="productionDate" type="date"
                                            className="form-control valid"
                                            data-val="true"
                                            data-val-required="Please enter the ProductID"
                                            autocomplete="productionDate" aria-required="true"
                                            aria-invalid="false" aria-describedby="productionDate"
                                            value={this.state.productionDate}
                                            onChange={this.handleChangeDate}
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="productionDate" data-valmsg-replace="true"></span>
                                    </div>
                                    <div className="form-group has-success">
                                        <label for="expiredDate" className="control-label mb-1">Expired Date</label>
                                        <input
                                            id="expiredDate" name="expiredDate" type="date"
                                            className="form-control valid"
                                            data-val="true"
                                            data-val-required="Please enter the ProductID"
                                            autocomplete="expiredDate" aria-required="true"
                                            aria-invalid="false" aria-describedby="expiredDate"
                                            value={this.state.expiredDate}
                                            onChange={this.handleChangeDate}
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="expiredDate" data-valmsg-replace="true"></span>
                                    </div>
                                    <button type="button" className="btn btn-info" onClick={this.handleSubmit}>Edit</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}