import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  CustomInput,
  Spinner
} from 'reactstrap';
import API from "../../api";

const init_body = {
  condition_name: '',
  condition_value: '',
  url_image: '',
  url_link: '',
}

export default class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      body: init_body,
      error: {},
      disabled: false,
      buttonLoading: false,
      popupFileLabel: "Choose File",
      popupFile: "",
    }
  }

  componentDidMount() {
    // this.getLoginData();
    console.log(this.state.showModal, this.state.error.length)

  }
  handleUploadChange(event) {
    console.log("Logo file: ", event.target.files[0]);
    // check if file is not image or compressedFile
    if (event.target.files[0].type !== 'image/jpeg' && event.target.files[0].type !== 'image/png' && event.target.files[0].type !== 'image/jpg') {
      alert('This file is not an image file. Please select another image file')
      this.setState({
        fileChangeDetected: true
      })
      setTimeout(() => {
        this.setState({
          fileChangeDetected: false,
          logoFileLabel: null,
          logoFile: null
        });
      }, 5)
    } else {
      if (event.target.files[0].size > 5120000) {
        alert('This certificate file is too big. Please select another certificate file')
        this.setState({
          fileChangeDetected: true
        })
        setTimeout(() => {
          this.setState({
            fileChangeDetected: false,
            popupFileLabel: null,
            popupFile: null
          });
        }, 5)
      } else {
        if (event.target.files[0] && event.target.files[0].name && event.target.files[0].size <= 5120000) {
          this.setState({
            popupFileLabel: event.target.files[0].name,
            popupFile: event.target.files[0]
          });
          // setTimeout(()=>{
          //     alert(JSON.stringify(this.state.popupFile))
          // }, 1000)
        } else {
          this.setState({
            popupFileLabel: null,
            popupFile: null
          });
        }
      }

    }
  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      const name = e.target.name;
      const value = e.target.value;

    }
    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }


  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '');
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    this.setState({ buttonLoading: true });
    let body = new FormData();
    body.append("condition_name", this.state.body.condition_name);
    body.append("condition_value", this.state.body.condition_value);

    if (this.state.popupFile != "") {
      body.append("popupFile", this.state.popupFile);
    }

    if (this.state.body.url_link != "") {
      body.append("url_link", this.state.body.url_link);
    }

    API.post('popup/condition/config', body, { headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          body: {},
        });
        this.setState({ buttonLoading: false });
        this.props.afterSubmit('success', resp.message)
        this.props.close()
      })
      .catch(error => {
        this.props.afterSubmit('success', error)
        this.props.close()
      })
  }



  render() {
    return (
      <Modal isOpen={this.props.open} toggle={() => this.props.close()}>
        <ModalHeader toggle={() => this.props.close()}>Add Config Popup</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">
                Condition Name
              </Label>
              <Input
                id="condition_name"
                name="condition_name"
                placeholder="Enter Condition Name"
                type="text"
                onChange={this.onChange}
                required
                invalid={this.state.error['condition_name'] ? true : false}
              />
              <FormFeedback >{this.state.error['condition_name']}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">
                Condition Value
              </Label>
              <Input
                id="condition_value"
                name="condition_value"
                placeholder="Enter Condition Value"
                type="text"
                onChange={this.onChange}
                invalid={this.state.error['condition_value'] ? true : false}
              />
              <FormFeedback >{this.state.error['condition_value']}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">
                URL Link
              </Label>
              <Input
                id="url_link"
                name="url_link"
                placeholder="Enter URL Link"
                type="text"
                onChange={this.onChange}
                invalid={this.state.error['url_link'] ? true : false}
              />
              <FormFeedback >{this.state.error['url_link']}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">
                URL Image
              </Label>
              <CustomInput
                type="file"
                id="popupFile"
                name="popupFile"
                // disabled={this.state.useCustomCert}
                label={this.state.popupFileLabel}
                onChange={e => this.handleUploadChange(e)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={() => this.onSubmit()}>
            {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
          </Button>
          <Button color="danger" onClick={() => this.props.close()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}