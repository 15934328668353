import React, { Component } from 'react';
import API from 'src/api'
import {
  Spinner,
  Input,
  Col,
  Row,
  Label,
  Button,
  FormGroup,
  FormFeedback,
  Alert,
  Badge,
} from 'reactstrap';
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import NotifToast from 'src/components/notificationModal/NotifToast';
import SourceType from './sourceType/Index';
import Channel from './channel/Index';

class index extends Component {

  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      id: this.props.match.params.id,
      menuCode: constants.MENU_LEADS_TRACKING_TYPE_PRODUCT,
      scope: [],
      buttonLoading: false,
      disabled: true,
      body: {
        id_source_type: null,
        id_leads_channel: null,
        company_name: null,
        brand_name: null,
        pic_name: null,
        phone: null,
        note: null,
        status: null,
        lead_products: [],
      },
      required: ['company_name', 'pic_name', 'phone', 'id_source_type', 'id_leads_channel', 'status'],
      error: {},
      data: [],
      editData: {},
      showTable: false,
      showModal: false,
      search: '',
      notif: {
        show: false,
        status: true,
        message: '',
        title: 'Success'
      },
      optionSource: [],
      optionChannel: [],
      optionProductType: [],
      selectedOption: [],
      total: {
        qty: 0,
        price: 0
      },
      showModalSource: false,
      showModalChannel: false,
      status: [
        // { value: '0', label: 'Deleted' },
        { value: '1', label: 'Cold' },
        { value: '2', label: 'Hot' },
        { value: '3', label: 'Super Hot' },
        { value: '4', label: 'Accepted' },
        { value: '5', label: 'Losses' },
      ],
    };
  }

  async componentDidMount() {
    await this.checkAccess();
    this.onLoad();
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  onLoad = async () => {
    try {
      const source = await API.get('product/leads/source-type', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      const detail = await API.get('leads/' + this.state.id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      const detailData = detail.data.data;

      const selected_id = detailData.lead_products.map(item => item.md_produk_lead_config?.id);
      const productType = await API.get('product/leads/config?option=true&selected_id=' + selected_id.join(','), { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

      const channel = await API.get('product/leads/channel?id_source_type=' + detailData.id_source_type, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

      this.setState({
        optionProductType: productType.data.data,
        optionSource: source.data.data,
        optionChannel: channel.data.data,
        showTable: true,
        body: {
          ...this.state.body,
          id_source_type: detailData.id_source_type,
          id_leads_channel: detailData.id_leads_channel,
          company_name: detailData.company_name,
          brand_name: detailData.brand_name,
          pic_name: detailData.pic_name,
          phone: detailData.phone,
          note: detailData.note,
          status: detailData.status,
        },
      })

    } catch (error) {

    }
  }

  onSubmit = () => {
    const body = { ...this.state.body };
    body.lead_products = [...this.state.selectedOption];
    body.status = parseInt(body.status);

    this.setState({
      buttonLoading: true,
      disabled: true,
    });

    // alert(JSON.stringify(body)); return;
    API.put('leads/' + this.state.id, body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          buttonLoading: false,
          notif: {
            show: true,
            title: 'Success',
            message: result.message,
            status: result.status
          }
        });

        setTimeout(() => {
          this.setState({
            notif: {
              show: false
            },
            disabled: false,
          })
          this.props.history.push('/leads-tracking/data-leads');
        }, 3000);
      })
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is Required'
    }
    else {
      delete error[name]
    }

    if (name == 'id_source_type') {
      const channel = await API.get('product/leads/channel?id_source_type=' + value, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      this.setState({
        optionChannel: channel.data.data,
      })
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => (value === '' || value === null) && this.state.required.includes(key));

    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  render() {
    const { selectedOption } = this.state;

    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className='d-flex justify-content-center'>
                <div className="col-xl-5 px-0">
                  <div className="card">
                    <div className="card-header">
                      <strong className="card-title align-middle">Edit Lead</strong>
                    </div>
                    <div className="card-body">
                      <Row className="row-cols-auto align-items-center mb-4">
                        <Col className='w-100'>
                          <FormGroup>
                            <Label>Company Name</Label>
                            <Input
                              placeholder='Enter Company Name'
                              name='company_name'
                              type="text"
                              value={this.state.body.company_name}
                              onChange={this.onChange}
                              invalid={this.state.error['company_name'] ? true : false}
                            />
                            <FormFeedback >{this.state.error['company_name']}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col className='w-100'>
                          <FormGroup>
                            <Label>Brand Name</Label>
                            <Input
                              placeholder='Enter Brand Name'
                              name='brand_name'
                              type="text"
                              value={this.state.body.brand_name}
                              onChange={this.onChange}
                              invalid={this.state.error['brand_name'] ? true : false}
                            />
                            <FormFeedback >{this.state.error['brand_name']}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col className='w-100'>
                          <FormGroup>
                            <Label>Whatsapp Number</Label>
                            <Input
                              placeholder='Enter Whatsapp Number'
                              name='phone'
                              type="number"
                              value={this.state.body.phone}
                              onChange={this.onChange}
                              invalid={this.state.error['phone'] ? true : false}
                            />
                            <FormFeedback >{this.state.error['phone']}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col className='w-100'>
                          <FormGroup>
                            <Label>PIC Name</Label>
                            <Input
                              placeholder='Enter PIC Name'
                              name='pic_name'
                              type="text"
                              value={this.state.body.pic_name}
                              onChange={this.onChange}
                              invalid={this.state.error['pic_name'] ? true : false}
                            />
                            <FormFeedback >{this.state.error['pic_name']}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col className='w-100'>
                          <Label className='w-100'>
                            Source
                            <a className='float-right' href='#' onClick={() => this.setState({ showModalSource: !this.state.showModalSource })}>More Source</a>
                          </Label>
                          <Input
                            name="id_source_type"
                            type="select"
                            value={this.state.body.id_source_type}
                            onChange={this.onChange}
                            required
                            invalid={this.state.error['id_source_type']}
                          >
                            {
                              <>
                                <option value={''}>Choose</option>
                                {
                                  this.state.optionSource.map(item => {
                                    return <option key={item.id} value={item.id}>{item.source_type}</option>
                                  })
                                }
                              </>
                            }
                          </Input>
                          <FormFeedback >{this.state.error['id_source_type']}</FormFeedback>
                        </Col>
                        <Col className='w-100 mt-3'>
                          <Label className='w-100'>
                            Channel
                            {
                              this.state.body.id_source_type ? <a className='float-right' href='#' onClick={() => this.setState({ showModalChannel: !this.state.showModalChannel })}>More Channel</a> : null
                            }
                          </Label>
                          <Input
                            name="id_leads_channel"
                            type="select"
                            value={this.state.body.id_leads_channel}
                            onChange={this.onChange}
                            required
                            invalid={this.state.error['id_leads_channel']}
                          >
                            {
                              this.state.body.id_source_type ?
                                <>
                                  <option value={''}>{this.state.optionChannel?.length ? 'Choose' : 'Empty'}</option>
                                  {
                                    this.state.optionChannel.map(item => {
                                      return <option key={item.id} value={item.id}>{item.channel}</option>
                                    })
                                  }
                                </>
                                : <option value={''}>Please select source first</option>
                            }
                          </Input>
                          <FormFeedback >{this.state.error['id_leads_channel']}</FormFeedback>
                        </Col>
                        <Col className='w-100 mt-2'>
                          <Label>Note</Label>
                          <Input
                            name="note"
                            placeholder="Enter Note"
                            type="textarea"
                            value={this.state.body.note}
                            onChange={this.onChange}
                            rows="2"
                            invalid={this.state.error['note'] ? true : false}
                          />
                          <FormFeedback >{this.state.error['note']}</FormFeedback>
                        </Col>
                        <Col className='w-100'>
                          <Label className='w-100'>Status</Label>
                          <Input
                            name="status"
                            type="select"
                            value={this.state.body.status}
                            onChange={this.onChange}
                            required
                            invalid={this.state.error['status']}
                          >
                            {
                              <>
                                <option value={''}>Choose</option>
                                {
                                  this.state.status.map(item => {
                                    return <option key={item.value} value={item.value}>{item.label}</option>
                                  })
                                }
                              </>
                            }
                          </Input>
                          <FormFeedback >{this.state.error['status']}</FormFeedback>
                        </Col>
                      </Row>
                      <Button className='w-100' color="primary" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                        {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NotifToast
            show={this.state.notif.show}
            close={() => this.setState({ notif: { show: false } })}
            title={this.state.notif.title}
            status={this.state.notif.status}
            message={this.state.notif.message}
          />
          <SourceType
            open={this.state.showModalSource}
            close={() => this.setState({ showModalSource: !this.state.showModalSource })}
            data={this.state.optionSource}
            afterSubmit={() => this.onLoad()}
          />
          <Channel
            open={this.state.showModalChannel}
            close={() => this.setState({ showModalChannel: !this.state.showModalChannel })}
            data={this.state.optionChannel}
            id_source_type={this.state.body.id_source_type}
            afterSubmit={async () => {
              const channel = await API.get('product/leads/channel?id_source_type=' + this.state.body.id_source_type, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
              this.setState({
                optionChannel: channel.data.data,
              })
            }}
          />
        </div>
    )
  }
}

export default index;