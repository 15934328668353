import React, { Component } from 'react';
import API from 'src/api'
import {
  Spinner,
  Input,
  Col,
  Row,
  Label,
  Button,
  FormGroup,
  FormFeedback,
  Alert,
  Badge,
} from 'reactstrap';
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import NotifToast from 'src/components/notificationModal/NotifToast';
import Select from 'react-select';
import { addCommas } from 'src/lib/helpers';
import SourceType from './sourceType/Index';
import Channel from './channel/Index';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import HistoryIndex from './_components/HistoryIndex';

class index extends Component {

  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      id: this.props.match.params.id,
      menuCode: constants.MENU_LEADS_TRACKING_TYPE_PRODUCT,
      scope: [],
      buttonLoading: false,
      disabled: true,
      body: {
        id_source_type: null,
        id_leads_channel: null,
        company_name: null,
        brand_name: null,
        pic_name: null,
        phone: null,
        note: null,
        lead_products: [],
      },
      required: ['company_name', 'brand_name', 'pic_name', 'id_source_type', 'id_leads_channel', 'status'],
      error: {},
      data: [],
      editData: {},
      showTable: false,
      showModal: false,
      search: '',
      notif: {
        show: false,
        status: true,
        message: '',
        title: 'Success'
      },
      optionSource: [],
      optionChannel: [],
      optionProductType: [],
      selectedOption: [
        {
          product_id: null,
          id_produk_lead_config: null,
          size: null,
          qty: null,
          price: null,
          history_price: []
        }
      ],
      errorSelectedOption: [],
      total: {
        qty: 0,
        price: 0
      },
      showModalSource: false,
      showModalChannel: false,
      status: [
        // { value: '0', label: 'Deleted' },
        { value: '1', label: 'Cold' },
        { value: '2', label: 'Hot' },
        { value: '3', label: 'Super Hot' },
        { value: '4', label: 'Accepted' },
        { value: '5', label: 'Losses' },
      ],
      leadHistory: [],
    };
  }

  async componentDidMount() {
    await this.checkAccess();
    this.onLoad();
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  onLoad = async () => {
    try {
      const productType = await API.get('product/leads/config?option=true', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      const source = await API.get('product/leads/source-type', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      const detail = await API.get('leads/' + this.state.id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      const detailData = detail.data.data;

      const channel = await API.get('product/leads/channel?id_source_type=' + detailData.id_source_type, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

      this.setState({
        optionProductType: productType.data.data,
        optionSource: source.data.data,
        optionChannel: channel.data.data,
        showTable: true,
        body: {
          ...this.state.body,
          id_source_type: detailData.id_source_type,
          id_leads_channel: detailData.id_leads_channel,
          company_name: detailData.company_name,
          brand_name: detailData.brand_name,
          pic_name: detailData.pic_name,
          phone: detailData.phone,
          note: detailData.note,
          status: detailData.status,
        },
        selectedOption: detailData.lead_products.map(item => {
          return {
            product_id: 4,
            id_produk_lead_config: item.id,
            size: item.size,
            qty: item.qty,
            normal_price: item.normal_price,
            price: item.price,
            subtotal: item.qty * item.price,
            history_price: [],
            label: item.md_produk_lead_config.md_tipe_produk.tipe_produk + ' - ' + item.size,
          }
        }),
        leadHistory: detailData.lead_history,
      })

      this.calculateTotal();

    } catch (error) {

    }
  }

  CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} className='py-1 option-custom'>
      <div className='px-2'>
        <div>{label}</div>
        {data.deskripsi && <small>{data.deskripsi}</small>}
      </div>
    </div>
  );


  calculateTotal = () => {
    let totalQty = 0;
    let totalPrice = 0;
    this.state.selectedOption.map(item => {
      totalQty = totalQty + parseInt(item.qty);
      totalPrice = totalPrice + (parseInt(item.price) * parseInt(item.qty));
    })

    this.setState({
      total: {
        qty: isNaN(totalQty) ? 0 : totalQty,
        price: isNaN(totalPrice) ? 0 : totalPrice
      }
    });
  }

  render() {
    const { selectedOption } = this.state;

    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-md-5">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title align-middle">Detail Lead</strong>
                  </div>
                  <div className="card-body">
                    <Row className="row-cols-auto align-items-center mb-4">
                      <Col className='w-100'>
                        <FormGroup>
                          <Label>Company Name</Label>
                          <Input
                            name='company_name'
                            type="text"
                            value={this.state.body.company_name}
                            disabled
                          />
                          <FormFeedback >{this.state.error['company_name']}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <FormGroup>
                          <Label>Brand Name</Label>
                          <Input
                            name='brand_name'
                            type="text"
                            value={this.state.body.brand_name}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <FormGroup>
                          <Label>PIC Name</Label>
                          <Input
                            name='pic_name'
                            type="text"
                            value={this.state.body.pic_name}
                            onChange={this.onChange}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <FormGroup>
                          <Label>Whatsapp Number</Label>
                          <Input
                            name='phone'
                            type="number"
                            value={this.state.body.phone}
                            onChange={this.onChange}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <Label className='w-100'>Source</Label>
                        <Input
                          name="id_source_type"
                          type="select"
                          value={this.state.body.id_source_type}
                          disabled
                        >
                          {
                            <>
                              <option value={''}>Choose</option>
                              {
                                this.state.optionSource.map(item => {
                                  return <option key={item.id} value={item.id}>{item.source_type}</option>
                                })
                              }
                            </>
                          }
                        </Input>
                        <FormFeedback >{this.state.error['id_source_type']}</FormFeedback>
                      </Col>
                      <Col className='w-100 mt-3'>
                        <Label className='w-100'>
                          Channel
                        </Label>
                        <Input
                          name="id_leads_channel"
                          type="select"
                          value={this.state.body.id_leads_channel}
                          disabled
                        >
                          {
                            this.state.body.id_source_type ?
                              <>
                                <option value={''}>{this.state.optionChannel.length ? 'Choose' : 'Empty'}</option>
                                {
                                  this.state.optionChannel.map(item => {
                                    return <option key={item.id} value={item.id}>{item.channel}</option>
                                  })
                                }
                              </>
                              : <option value={''}>Please select source first</option>
                          }
                        </Input>
                        <FormFeedback >{this.state.error['id_leads_channel']}</FormFeedback>
                      </Col>
                      <Col className='w-100 mt-2'>
                        <Label>Note</Label>
                        <Input
                          name="note"
                          placeholder="Enter Note"
                          type="textarea"
                          value={this.state.body.note}
                          disabled
                          rows="2"
                        />
                      </Col>
                      <Col className='w-100'>
                        <Label className='w-100'>Status</Label>
                        <Input
                          name="status"
                          type="select"
                          value={this.state.body.status}
                          disabled
                        >
                          {
                            <>
                              <option value={''}>Choose</option>
                              {
                                this.state.status.map(item => {
                                  return <option key={item.value} value={item.value}>{item.label}</option>
                                })
                              }
                            </>
                          }
                        </Input>
                        <FormFeedback >{this.state.error['status']}</FormFeedback>
                      </Col>
                    </Row>
                  </div>
                </div>
                <HistoryIndex 
                  data={this.state.leadHistory} 
                  optionSource={this.state.optionSource}
                  optionChannel={this.state.optionChannel}
                />
              </div>
              <div className="col-md-7">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title align-middle">Product</strong>
                  </div>
                  <div className="card-body">
                    {
                      selectedOption.map((item, index) => {
                        return (
                          <Row className=" align-items-center mb-4">
                            <Col md={8}>
                              <FormGroup>
                                <Label>Product {index + 1}</Label>
                                <Select
                                  name="product_id"
                                  value={this.state.optionProductType.find((option) => option.label === item.label)}
                                  onChange={(e) => this.onSelect(e, index)}
                                  options={this.state.optionProductType}
                                  components={{
                                    Option: this.CustomOption,
                                  }}
                                  isDisabled={true}
                                />
                                <FormFeedback >{this.state.error['product_id']}</FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col md='3'>
                              <FormGroup>
                                <Label>Size</Label>
                                <Input
                                  name='size'
                                  type="text"
                                  value={item.size}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col md='3'>
                              <FormGroup>
                                <Label>Qty</Label>
                                <Input
                                  name='qty'
                                  type="text"
                                  value={item.qty}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col md='3'>
                              <FormGroup>
                                <Label>Normal Price</Label>
                                <Input
                                  name='normal_price'
                                  type="text"
                                  value={item.normal_price}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col md='3'>
                              <FormGroup>
                                <Label>Price After Discount</Label>
                                <Input
                                  name='price'
                                  type="text"
                                  value={item.price}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col md='3'>
                              <FormGroup>
                                <Label>Sub Total</Label>
                                <Input
                                  placeholder='Auto Calculate'
                                  name='price_total'
                                  type="text"
                                  value={addCommas(item.subtotal)}
                                  onChange={(e) => this.onChangeProduct(e, index)}
                                  readOnly={true}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )
                      })
                    }
                    <Alert color="primary" className='mt-3'>
                      <div className='d-flex'>
                        <Label className='w-25'>Total Qty</Label>
                        <div className="text-dark">:  <span className='font-weight-bold'>{this.state.total.qty}</span> </div>
                      </div>
                      <div className='d-flex'>
                        <Label className='w-25'>Total Price</Label>
                        <div className="text-dark">:  <span className='font-weight-bold'>Rp. {this.state.total.price ? addCommas(this.state.total.price) : 0}</span> </div>
                      </div>
                    </Alert>
                    <Link to='/leads-tracking/data-leads' className='btn btn-danger w-100 mt-3'>Back</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NotifToast
            show={this.state.notif.show}
            close={() => this.setState({ notif: { show: false } })}
            title={this.state.notif.title}
            status={this.state.notif.status}
            message={this.state.notif.message}
          />
          <SourceType
            open={this.state.showModalSource}
            close={() => this.setState({ showModalSource: !this.state.showModalSource })}
            data={this.state.optionSource}
            afterSubmit={() => this.onLoad()}
          />
          <Channel
            open={this.state.showModalChannel}
            close={() => this.setState({ showModalChannel: !this.state.showModalChannel })}
            data={this.state.optionChannel}
            id_source_type={this.state.body.id_source_type}
            afterSubmit={async () => {
              const channel = await API.get('product/leads/channel?id_source_type=' + this.state.body.id_source_type, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
              this.setState({
                optionChannel: channel.data.data,
              })
            }}
          />
        </div>
    )
  }
}

export default index;