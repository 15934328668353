import React, { Component, useRef, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import chroma from 'chroma-js';
import Select, { StylesConfig } from 'react-select';

import API from '../../api';
import * as htmlToImage from 'html-to-image';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import {
    CustomInput,
    Alert,
    Modal,
    ModalFooter,
    ModalHeader,
    ModalBody,
    Button
} from 'reactstrap';
import LoadingBar from 'react-top-loading-bar'
import imageCompression from 'browser-image-compression';
import { isNull, times } from 'lodash';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import API_TOKEN from '../../apitoken';
import QrcodeLabelModal from './component/label/QrcodeLabelModal';
const { convert } = require("any-to-any");


export default class LabelingQRCode extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.getManufacturerOption = this.getManufacturerOption.bind(this);
        this.handleMultiChange = this.handleMultiChange.bind(this);

        this.state = {
            menuCode: constants.MENU_LABELING_QRCODE,
            modalQrcodeLabel: false,
            scope: [],
            fileChangeDetected: false,
            useCustomCert: false,
            productCode: "",
            username: "",
            manufacturerId: "",
            manufacturerName: "",
            manufacturerFlag: "",
            manufacturerCert: "",
            madeIn: "",
            productionDate: "",
            expiredDate: "",
            manufacturer: [],
            labeling: [],
            variant: [],
            variantPointer: "",
            variantId: "",
            variantIdMulti: [],
            emptyVariant: "",
            variantIdPointer: "",
            newVariant: "",
            variantList: [],
            variantChangeIndex: 0,
            productCodes: [],
            variantInputIndexes: [],
            productCodePointer: "",
            productCodeId: "",
            productCodeIdInt: "",
            emptyProductCode: "",
            productCodeIdPointer: "",
            skuLevelPointer: null,
            newProductCode: "",
            disable: false,
            disabledButton: false,
            openModalVariant: false,
            openModalProductCode: false,
            openModalConfirmation: false,
            openModalConfirmationProductCode: false,
            qrcode: "",
            formError: {},
            countRangeQrcode: 0,
            alertError: false,
            message: "",
            certFileLabel: "Choose File",
            certFile: "",
            multiValue: [],
            css: `
                @font-face {
                    font-family: GothamBook;
                    src: url('https://api.shieldtag.co/GothamBook.ttf');
                }
                #domEl p {
                    font-family: GothamBook;
                    color: #0c0c0c;
                    width: 100%;
                    position: absolute;
                    // left: 42.25%;
                    // bottom: 20.65%;
                    left: 400px;
                    bottom: 275.5px;
                    font-size: 22.5px;
                }
                @media screen 
                    and (min-device-width: 1200px) 
                    and (max-device-width: 1600px) 
                    and (-webkit-min-device-pixel-ratio: 2)
                    and (min-resolution: 192dpi) { 
                        #domEl p {
                            bottom: 267.5px;
                        }
                }
                @media screen and (max-width: 767px){
                    #domEl p {
                        bottom: 275px;
                    }
                    @supports (-webkit-touch-callout: none) {
                        #domEl p {
                            bottom: 268.5px;
                        } 
                    }
                }
                #root {
                    overflow-x: hidden;
                    overflow-y: hidden;
                }
            `
        }
    }

    componentDidMount() {
        this.checkAccess();
        this.getManufacturer();
        this.getLabelingData();
        var loginData = localStorage.getItem('loginData');
        var parseLoginData = JSON.parse(loginData)
        if (parseLoginData.data.username) {
            this.setState({
                username: parseLoginData.data.username
            })
        }
        // alert(parseLoginData.data.username)
    }

    async getManufacturerConfig(id) {
        let manufacturer = await API.get('/manufacturer/' + id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        // alert(JSON.stringify(manufacturer.data))
        this.setState({
            manufacturerFlagSingle: manufacturer.data.data[0].flag_singlecert === null ? 0 : manufacturer.data.data[0].flag_singlecert,
            manufacturerCertSingle: manufacturer.data.data[0].path_singlecert === null ? 0 : manufacturer.data.data[0].path_singlecert,
            manufacturerFlagCustom: manufacturer.data.data[0].flag_customcert === null ? 0 : manufacturer.data.data[0].flag_customcert,
            manufacturerCertCustom: manufacturer.data.data[0].path_customcert === null ? 0 : manufacturer.data.data[0].path_customcert,
            manufacturerFlagSKU: manufacturer.data.data[0].flag_sku === null ? 0 : manufacturer.data.data[0].flag_sku,
        })
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'create');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    async getManufacturer() {
        let manufacturer = await API.get('manufacturer?size=20&page=1', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        let manufacturerOptions = await API.get('manufacturer/options', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })

        this.setState({
            // manufacturer: manufacturer.data.item,
            manufacturer: manufacturerOptions.data,
            // manufacturerId: manufacturerOptions.data[0].manufacturer_id,
            manufacturerName: manufacturerOptions.data[0].name,
            manufacturerFlag: manufacturerOptions.data[0].flag_productvariant,
            variantInputIndexes: [{ value: '', id: 0 }]
        })
    }

    async getLabelingData() {

        const colourStyles = {
            control: (styles) => ({ ...styles, backgroundColor: 'white' }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                const color = chroma(data.color);
                return {
                    ...styles,
                    backgroundColor: isDisabled
                        ? undefined
                        : isSelected
                            ? data.color
                            : isFocused
                                ? color.alpha(0.1).css()
                                : undefined,
                    color: isDisabled
                        ? '#ccc'
                        : isSelected
                            ? chroma.contrast(color, 'white') > 2
                                ? 'white'
                                : 'black'
                            : data.color,
                    cursor: isDisabled ? 'not-allowed' : 'default',

                    ':active': {
                        ...styles[':active'],
                        backgroundColor: !isDisabled
                            ? isSelected
                                ? data.color
                                : color.alpha(0.3).css()
                            : undefined,
                    },
                };
            },
            multiValue: (styles, { data }) => {
                const color = chroma(data.color);
                return {
                    ...styles,
                    backgroundColor: color.alpha(0.1).css(),
                };
            },
            multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: data.color,
            }),
            multiValueRemove: (styles, { data }) => ({
                ...styles,
                color: data.color,
                ':hover': {
                    backgroundColor: data.color,
                    color: 'white',
                },
            }),
        };
        let labelingOptions = await API.get('labeling/options', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })

        this.setState({
            colourStyles: colourStyles,
            labeling: labelingOptions.data,
        })
    }

    getManufacturerOption() {
        let items = [<option key={0} value={''} disabled selected>Choose Manufacture</option>];
        let manufacturer = this.state.manufacturer;
        // console.log("List manufacturer", manufacturer)

        for (let i = 0; i < manufacturer.length; i++) {
            items.push(<option key={manufacturer[i].manufacturer_id} value={manufacturer[i].manufacturer_id} data-name={manufacturer[i].name} data-flag={manufacturer[i].flag_productvariant} data-flag-single={manufacturer[i].flag_singlecert} data-cert-single={manufacturer[i].path_singlecert}>{manufacturer[i].name}</option>);
        }
        return items;
    }

    async handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            disabledButton: false,

        });
        const formError = this.state.formError;
        formError[event.target.name] = '';

        if (event.target.value === '') {
            formError[event.target.name] = '*This field is required';
        }

        if (event.target.name === 'qrcode' && event.target.value != '') {
            this.setState({ countRangeQrcode: 0 })

            const range = event.target.value.split('-');
            if (range.length < 2) {
                formError.qrcode = '*Range must be separated by "-"';
            } else {
                const firstRange = range[0] !== '' ? convert(range[0], 36, 10) : 0;
                const secondRange = range[1] !== '' ? convert(range[1], 36, 10) : 0;
                const countRange = secondRange === firstRange ? 1 : secondRange - firstRange + 1;

                if (firstRange > secondRange) {
                    formError.qrcode = '*First range must be less than second range';
                }

                if (countRange > 500000) {
                    formError.qrcode = '*Maximum Count Range is 500.000';
                }

                this.setState({ countRangeQrcode: countRange.toLocaleString() })
            }

        }

        if (formError[event.target.name]) {
            this.setState({ disabledButton: true });
        }

        this.setState({ formError: formError });
    }

    handleChangeDate(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    handleMultiChange(option) {
        this.setState(state => {
            return {
                multiValue: option
            };
        });
        console.log(option);
    }

    async handleSubmit(event) {
        console.log('this.state.multiValue ' + this.state.multiValue);
        const MySwal = withReactContent(Swal)
        const from = this.state.qrcode.split("-")[0];
        const to = this.state.qrcode.split("-")[1];
        console.log(this.state);
        if (this.state.qrcode) {
            if(this.state.manufacturerId === "" && this.state.multiValue.length ===0){
                alert('Please choose between manufacture or labeling data')
            }else{
                var qrCodeParse = this.state.qrcode.replace(/ +/g, "").split("-")
                var idFrom = convert(qrCodeParse[0], 36, 10);
                var idTo = convert(qrCodeParse[1], 36, 10);
    
                if (parseInt(idFrom) > parseInt(idTo)) {
                    alert('id from must be smaller than id to')
                } else {
                    // alert('Correct')
                    this.setState({ disable: true });
                    this.LoadingBar.continuousStart();
                    console.log("submit", this.state.logoFile);
    
                    let body = {};
    
                    if (this.state.qrcode != "") {
                        // body[0];
                        body.qrCodeId = this.state.qrcode
                    }
    
                    if (this.state.manufacturerId != "") {
                        // body.push({"manufacturerId":this.state.manufacturerId});
                        body.manufacturerId = this.state.manufacturerId
                    }
    
                    if (this.state.multiValue.length > 0) {
                        const multivalue = this.state.multiValue.map((data) => { return data.value })
                        console.log(multivalue.toString())
                        // body.push({"label_ids":multivalue.toString()});
                        body.label_ids = multivalue.toString()
                    }
    
    
                    console.log("Param product", body);
                    // alert(JSON.stringify(body))
                    if (this.state.manufacturerId === "") {
                        await API.post('qrcode/labeling', body, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                            }
                        }
                        ).then(async (resp) => {
                            console.log(resp)
                            alert(resp.data.message)
                        })
                    } else {
    
                        if (!/\s/.test(this.state.qrcode)) {
                            this.LoadingBar.complete();
                            this.setState({
                                disable: false
                            })
                            await API.get('qrcode/checkdata/search?from=' + from + '&to=' + to + '&manufacturerID=' + this.state.manufacturerId, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem('token')}`
                                }
                            }
                            ).then(async (resp) => {
                                console.log('ini resdata ', resp.data)
                                if (resp.data?.data.length > 0) {
                                    MySwal.fire({
                                        title: <strong>Warning!!</strong>,
                                        html: <i>The QR code is not placed correctly with manufacturer input. = {resp.data.data.map((list) => (
                                            <p>
                                                {list.qrcode} - {list.manufacturer_name}
                                            </p>
                                        ))}</i>,
                                        icon: 'warning',
                                        showCancelButton: true,
                                        cancelButtonText: 'Cancel',
                                        confirmButtonText: 'Continue',
                                        showLoaderOnConfirm: true,
                                        preConfirm: async () => {
                                            return await API.post('qrcode/labeling', body, {
                                                headers: {
                                                    "Content-Type": "application/json",
                                                    Authorization: `Bearer ${localStorage.getItem('token')}`
                                                }
                                            }
                                            );
                                        },
                                        allowOutsideClick: () => !Swal.isLoading()
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            setTimeout(() => {
                                                this.setState({
                                                    variantInputIndexes: [{ value: '', id: 0 }],
                                                    variantId: '',
                                                })
                                                //this.state.variantId = '';
                                                alert("success");
                                            }, 500);
                                        }
                                    })
    
                                } else {
                                    await API.post('qrcode/labeling', body, {
                                        headers: {
                                            "Content-Type": "application/json",
                                            Authorization: `Bearer ${localStorage.getItem('token')}`
                                        }
                                    }
                                    ).then(async (resp) => {
                                        alert(resp.data.message)
                                    })
                                }
                            })
                        } else {
                            alert('QRCode ID contains whitespaces')
                            this.setState({ disable: false })
                            this.LoadingBar.complete()
                        }
                    }
                }
                
            }
        } else {
            alert('Please fill all required fields')
        }
    }

    async searchQueueByQrIdRange(val, e) {
        e.preventDefault();
        var splittedQrId = val.split(',')
        var splittedArr = [];
        for (var i = 0; i < splittedQrId.length; i++) {
            splittedArr.push(splittedQrId[i])
        }
        this.setState({ qrIdRangeSearch: splittedArr })
        let searchResults = await API.get('/searchQueue/' + JSON.stringify(splittedArr), { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        this.setState({
            qrIdSearchResults: searchResults.data
        })
        // setTimeout(()=>{
        //     alert(JSON.stringify(this.state.qrIdRangeSearch))
        // })
    }

    render() {
        return (
            <div className="content">
                <style>{this.state.css}</style>
                <LoadingBar onRef={ref => (this.LoadingBar = ref)} />
                <div className="animated fadeIn">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="card-title">Labeling QR Code</strong>
                                    {/* <p>{this.state.variantId}</p> */}
                                    <Alert
                                        color="danger"
                                        isOpen={this.state.alertError}
                                    >
                                        {this.state.message}
                                    </Alert>
                                </div>
                                <div id="domEl" style={{
                                    zIndex: -1, position: 'absolute', width: 945, height: 1336,
                                    // background:'url("https://api.shieldtag.co/Certificate_Template_Kosong-01.png")' 
                                }}
                                >
                                    <img src="https://api.shieldtag.co/Certificate_Template_Kosong-01.png" />
                                    <p>{this.state.variantId}</p>
                                    {/* <img src="{sample}" width="100"/> */}
                                </div>
                                <div id="domEl2" style={{
                                    zIndex: -1, position: 'absolute', width: 945, height: 1336,
                                    // background:'url("https://api.shieldtag.co/Certificate_Template_Kosong-01.png")' 
                                }}
                                >

                                </div>
                                <div className="card-body">
                                    <div className="form-group has-success">
                                        <label for="qrcode" className="control-label mb-1">Manufacturer</label>
                                        <div class="input-group">
                                            <select name="manufacturerId" id="manufacturerId" class="form-control" onChange={this.handleChange}>
                                                {this.getManufacturerOption()}
                                            </select>
                                        </div>
                                        <span className="help-block text-danger field-validation-valid text-xs mt-2" style={{ fontSize: '12px' }} data-valmsg-for="productCode" data-valmsg-replace="false">{this.state.formError?.manufacturerId}</span>
                                    </div>
                                    <div className="form-group has-success">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label for="productCodeId" className="control-label mb-1">Labeling</label>
                                            </div>
                                            <div className='col-md-6 text-right'>
                                                <a href='#' onClick={() => this.setState({ modalQrcodeLabel: !this.state.modalQrcodeLabel })}>More QR Code Label</a>
                                            </div>
                                        </div>
                                        {/* <Multiselect
                                            options={this.state.labeling} // Options to display in the dropdown
                                            selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                            onSelect={this.onSelect} // Function will trigger on select event
                                            onRemove={this.onRemove}
                                            displayValue="name"
                                        /> */}
                                        <Select
                                            onChange={this.handleMultiChange}
                                            options={this.state.labeling}
                                            isMulti
                                            styles={this.state.colourStyles}
                                            value={this.state.multiValue}
                                        // onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="form-group has-success">
                                        <label for="qrcode" className="control-label mb-1">QRCodeID (Base 36) *</label>
                                        <div class="input-group">
                                            <input
                                                id="qrcode" name="qrcode" type="text"
                                                className="form-control valid"
                                                data-val="true"
                                                data-val-required="Please enter the ProductID"
                                                autocomplete="qrcode" aria-required="true"
                                                aria-invalid="false" aria-describedby="qrcode"
                                                value={this.state.qrcode}
                                                onChange={this.handleChange}
                                            />
                                            <span class="input-group-text">{this.state.countRangeQrcode} QR Code</span>
                                        </div>
                                        <span className="help-block text-danger field-validation-valid text-xs mt-2" style={{ fontSize: '12px' }} data-valmsg-for="productCode" data-valmsg-replace="false">{this.state.formError?.qrcode}</span>
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-1 col-fl-9'>
                                            <button disabled={this.state.disabledButton} type="button" className="btn btn-info" onClick={this.handleSubmit}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <QrcodeLabelModal
                    open={this.state.modalQrcodeLabel}
                    close={() => this.setState({ modalQrcodeLabel: false })}
                    reOpen={() => this.setState({ modalQrcodeLabel: true })}
                    afterSubmit={() => this.getLabelingData()}
                    data={this.state.labeling}
                />
            </div>
        )
    }
}

