import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner
} from 'reactstrap';
import API from "../../api";

const init_body = {
  condition_name: '',
  condition_value: '',
  url_image: '',
  url_link: '',
}

const required = ['condition_name','condition_value']

export default class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      body: init_body,
      error: {},
      disabled: true,
      buttonLoading: false,
    }
  }

  componentDidMount() {

  }

  onChange = (e) => {
    const error = this.state.error;
    const name = e.target.name;
    const isRequired = required.includes(name)
    if (!e.target.value && isRequired) {
      error[e.target.name] = 'Field is Required'
    }
    else {      
      const value = e.target.value;
    }

    const newBody = this.props.body
    newBody[e.target.name] = e.target.value
    this.setState({
      body: newBody,
      error: error
    });
  }
  
  render() {
    return (
      <Modal isOpen={this.props.open} toggle={() => this.props.close()}>
        <ModalHeader toggle={() => this.props.close()}>Detail Config</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">
                Condition Name
              </Label>
              <Input
                id="condition_name"
                name="condition_name"
                placeholder="Enter Username"
                type="text"
                disabled={true}
                value ={this.props.body.condition_name}
                invalid={this.state.error['condition_name'] ? true : false}
              />
              <FormFeedback >{this.state.error['condition_name']}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">
                Condition Value
              </Label>
              <Input
                id="condition_value"
                name="condition_value"
                placeholder="Enter condition value"
                type="text"
                disabled={true}
                value ={this.props.body.condition_value ?? ''}
                invalid={this.state.error['condition_value'] ? true : false}
              />
              <FormFeedback >{this.state.error['condition_value']}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">
                URL Image
              </Label>
              {
                this.props.body.url_image  && this.props.body.url_image.includes("null") ?                  
                null :
                <img src={this.props.body.url_image} alt="image" style={{ width: '100%' }} /> 
              }
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">
                URL Link
              </Label>
              <Input
                id="url_link"
                name="url_link"
                placeholder="Enter URL Link"
                type="text"
                disabled={true}
                value ={this.props.body.url_link ?? ''}
                invalid={this.state.error['url_link'] ? true : false}
              />
              <FormFeedback >{this.state.error['url_link']}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">
                Status
              </Label>
              <Input
                id="status"
                name="status"
                placeholder="Enter URL Link"
                type="text"
                disabled={true}
                value ={this.props.body.status=='1' ? 'Active' : 'Non-Active'}
                invalid={this.state.error['status'] ? true : false}
              />
              <FormFeedback >{this.state.error['status']}</FormFeedback>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => this.props.close()}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
}