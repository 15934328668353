/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import API from '../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  FormText,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NotifToast from '../../components/notificationModal/NotifToast';
import GroupModal from './partials/groupModal';

const init_body = {
  name: '',
  code: '',
  role_menu_group_id: '',
  url: '',
  icon: '',
  status: 1,
  permission: [],
}

class add extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      body: init_body,
      code: '',
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      optionGroup: [],
      modalGroup: false
    }
  }

  componentDidMount() {
    // this.getLoginData();
    // this.getOptionGroup();
    // console.log(this.state.showModal, this.state.error.length)

  }

  getOptionGroup(group) {
    API.get(`role/menu/group?type=options&group=${group}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data.data;
        this.setState({
          optionGroup: result
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  setOptionGroup(data) {
    console.log(data)
    this.setState({
      optionGroup: data
    });
  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    if (e.target.name === 'permission') {
      var options = e.target.options;
      var value = [];
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      newBody[e.target.name] = value
    } else if (e.target.name === 'role_group_name') {
      this.getOptionGroup(e.target.value)
    }

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    if (e.target.name === 'name') {
      const newCode = e.target.value.toLowerCase().replaceAll(' ','-')
      newBody['code'] = newCode
      this.setState({
        code: newCode
      })
    }

    this.setState({
      body: newBody,
      error: error
    });
    // console.log(newBody)

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '');
    const result = filtered.length ? true : false

    this.setState({
      disabled: result,
    });

    return result
  }

  onSubmit() {
    this.setState({
      // buttonLoading: true,
      notif: { show: true, title: 'Processing ...' }
    });

    API.post('role/menu', this.state.body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          body: {},
          notif: {
            title: 'Success',
            message: result.message,
          }
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false }
          });
          this.props.history.push(`/menu/edit/${result.data.insertId}`);
        }, 1500);
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
          }
        });
      })
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Add Menu</strong>
                  <div className="float-right">
                    <Link to="/menu">
                      <button
                        color="info"
                        size="sm"
                        className="btn btn-outline-link"
                      >
                        <i className='fa fa-arrow-left'></i>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Name
                      </Label>
                      <Input
                        id="name"
                        name="name"
                        placeholder="Enter Name"
                        type="text"
                        onChange={this.onChange}
                        required
                        invalid={this.state.error['name'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['name']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Code
                      </Label>
                      <Input
                        id="code"
                        name="code"
                        placeholder="Auto Filled"
                        type="text"
                        onChange={this.onChange}
                        value={this.state.code}
                        readOnly = {true}
                        invalid={this.state.error['name'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['name']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label className='w-100'>
                        Group Application
                      </Label>
                      <Input
                        id="role_group_name"
                        name="role_group_name"
                        type="select"
                        onChange={this.onChange}
                        invalid={this.state.error['role_group_name'] ? true : false}
                      >
                        <option value={''}>Choose</option>
                        <option value={'dashboard_app'}>Dashboard App</option>
                        <option value={'dashboard_partner'}>Dashboard Partner</option>
                      </Input>
                      <FormFeedback >{this.state.error['role_group_name']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label className='w-100'>
                        Group Menu
                        <a href='#' onClick={() => this.setState({ groupModal: !this.state.groupModal })} className='pull-right text-secondary text-decoration-none' style={{ fontSize: '14px' }} >+ More Item</a>
                      </Label>
                      <Input
                        id="role_menu_group_id"
                        name="role_menu_group_id"
                        type="select"
                        onChange={this.onChange}
                        invalid={this.state.error['role_menu_group_id'] ? true : false}
                      >
                        {
                          this.state.optionGroup.length ? (
                            <>
                              <option value={''}>Choose</option>
                              {
                                this.state.optionGroup.map(item => {
                                  return <option key={item.id} value={item.id}>{item.name}</option>
                                })
                              }
                            </>                            
                          ) : <option value={''}>{this.state.body.role_group_name !== undefined ? 'Empty' : 'Please Select Group Application First'}</option>
                        }
                      </Input>
                      <FormFeedback >{this.state.error['role_menu_group_id']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        URL
                      </Label>
                      <Input
                        id="url"
                        name="url"
                        placeholder="Enter URL"
                        type="text"
                        onChange={this.onChange}
                        required
                        invalid={this.state.error['url'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['url']}</FormFeedback>
                      <FormText>
                        Example : /urlname
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Icon Class Name
                      </Label>
                      <Input
                        id="icon"
                        name="icon"
                        placeholder="Enter Icon Name"
                        type="text"
                        onChange={this.onChange}
                        required
                        invalid={this.state.error['icon'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['icon']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelectMulti">
                        Permission Type
                      </Label>
                      <Input
                        id="permission"
                        multiple
                        name="permission"
                        type="select"
                        onChange={this.onChange}
                      >
                        <option>create</option>
                        <option>read</option>
                        <option>update</option>
                        <option>delete</option>
                      </Input>
                    </FormGroup>
                    <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                      {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
        />
        <GroupModal
          open={this.state.groupModal}
          close={() => this.setState({ groupModal: !this.state.groupModal })}
          data={this.state.optionGroup}
          setData={(data) => this.setOptionGroup(data)}
          value={this.state.body}
          setNotif={(show, title, message) => this.setState({
            notif: {
              show: show,
              title: title,
              message: message,
            }
          })}
        />
      </div>
    )
  }
}

export default add;