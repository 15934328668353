import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Spinner,
  Input
} from 'reactstrap';
import API from "../../../api";

const init_body = {
  name: null
}

export default class GroupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      error: {},
      buttonLoading: false,
      buttonLoadingAdd: false,
      newGroup: '',
      body: init_body
    }
  }

  componentDidMount() {

  }

  onDelete(id, index) {
    this.setState({ buttonLoading: true });

    API.delete(`role/menu/group/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;

        const newList = this.props.data;

        if (result.status) {
          newList.splice(index, 1);
          this.props.setData(newList)
          this.props.setNotif(true, 'Success', 'Group menu updated successfully')
        }
        else {
          this.props.setNotif(true, 'Failed', result.message)
        }

        this.props.close()

        setTimeout(() => {
          this.props.setNotif(false)
        }, 3000)
      })
      .catch(err => {
        alert(err)
      })
  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      error: error
    });
  }

  onEdit(id, index) {
    this.setState({
      buttonLoading: true,
      // notif: { show: true, title: 'Processing ...' }
    });
    API.put(`role/menu/group/${id}`, this.state.body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;

        const newList = this.props.data;
        newList[index].name = this.state.body.name

        this.props.setData(newList)
        this.props.setNotif(true, 'Success', 'Group menu updated successfully')

        this.setState({
          buttonLoading: false
        });

        this.props.close()


        setTimeout(() => {
          this.props.setNotif(false)
        }, 1300);

      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
          }
        });
      })
  }

  onAdd() {
    this.setState({
      buttonLoadingAdd: true,
    });

    const body = {
      name: this.state.newGroup,
      role_group_name: this.props.value.role_group_name
    }
    API.post(`role/menu/group`, body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        const newList = this.props.data;
        newList.push(...[{
          id: result.data.insertId,
          name: this.state.newGroup
        }]);
        console.log(newList)
        this.props.setData(newList)
        this.props.setNotif(true, 'Success', 'Group menu updated successfully')

        this.setState({
          buttonLoadingAdd: false
        });

        this.props.close()
      })
      .catch(err => {
        this.props.setNotif(true, 'Failed', err)
      })

      setTimeout(() => {
        this.props.setNotif(false)
      }, 1300);
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()}>
          <ModalHeader>
            Group Menu
          </ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='col-md-12'>
                {
                  this.props.data.length > 0
                    ?
                    <div className='table-modal-wrapper'>
                      <table className='table'>
                        <tbody>
                          {
                            this.props.data.map((item, index) => (
                              <RowComponent
                                data={item}
                                onChange={(e) => this.onChange(e)}
                                value={this.state.body.name}
                                onEdit={() => this.onEdit(item.id, index)}
                                onDelete={() => this.onDelete(item.id, index)}
                                buttonLoading={this.state.buttonLoading}
                              />
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                    :
                    null
                }

              </div>
              <div className='col-md-10'>
                <div className='my-2'>
                  <span>Add Group Menu List Here</span>
                </div>
                <input className="form-control valid" value={this.state.newGroup} onChange={(e) => { this.setState({ newGroup: e.target.value }) }} />
              </div>
              <div className='col-md-2'>
                <button className='btn btn-info button-add-variant-modal mt-3' onClick={() => this.onAdd()}>
                {!this.state.buttonLoadingAdd ? 'Add' : <Spinner size="sm">Loading...</Spinner>}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

class RowComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editTab: false
    }
  }

  render() {
    return (
      <>
        <tr id="box">
          <td className='align-middle'>
            <span hidden={this.state.editTab}>
              {this.props.data.name}
            </span>
            <Input
              name="name"
              placeholder="sm"
              onChange={(e) => this.props.onChange(e)}
              hidden={!this.state.editTab}
              value={this.props.value ? this.props.value : this.props.data.name}
            />
          </td>
          <td className='text-right text-sm'>
            <button hidden={this.state.editTab} onClick={() => this.setState({ editTab: true })} className='btn btn-xs btn-info'>Edit</button>
            <button hidden={this.state.editTab} onClick={() => this.props.onDelete()} className='btn btn-xs btn-danger ml-2'>Delete</button>
            <button hidden={!this.state.editTab} onClick={() => this.props.onEdit()} className='btn btn-xs btn-info'>
              {!this.props.buttonLoading ? 'Save' : <Spinner size="sm">Loading...</Spinner>}
            </button>
            <button hidden={!this.state.editTab} onClick={() => this.setState({ editTab: false })} className='btn btn-xs btn-danger ml-2'>Close</button>
          </td>
        </tr>
      </>
    );
  };

}