import React, { Component, useState } from 'react';
import ReactPlayer from 'react-player';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';

// export default function ReportedForumCarousel(images) {
export default class ReportedForumDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      animating: false,
    }
  }

  next = () => {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === this.props.images.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({
      activeIndex: nextIndex
    })
  };

  previous = () => {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.props.images.length - 1 : this.state.activeIndex - 1;
    this.setState({
      activeIndex: nextIndex
    })
  };

  goToIndex = (newIndex) => {
    if (this.state.animating) return;
    this.setState({
      activeIndex: newIndex
    })
  };

  isVideo = (item) => {
    const videoFormats = ["mp4", "webm", "ogg"];
    return videoFormats.some((format) => item?.endsWith(format));
  };

  renderCurrentItem = (image) => {
    if (this.isVideo(image)) {
      return (
        <ReactPlayer
          playing={true}
          controls
          url={image}
        // width="100%" 
        // height="100%"
        />
      );
    } else {
      return <img src={image} style={{ maxWidth: '250px', maxHeight: '250px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
    }
  };


  render() {

    return (
      <Carousel
        activeIndex={this.state.activeIndex}
        next={() => this.next()}
        previous={() => this.previous()}
      >
        <CarouselIndicators
          items={this.props.images}
          activeIndex={this.state.activeIndex}
          onClickHandler={() => this.goToIndex()}
        />
        {
          this.props.images.map((item) => {

            return (
              <CarouselItem
                className='bg-secondary'
                onExiting={() => this.setState({ animating: true })}
                onExited={() => this.setState({ animating: false })}
                key={item.image}
              >
                {this.renderCurrentItem(item.image)}

                {/* <img src={item.image} alt={item.altText} style={{ maxWidth: '250px', maxHeight: '250px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} /> */}
                {/* <CarouselCaption
                captionText={item.caption}
                captionHeader={item.caption}
              /> */}
              </CarouselItem>
            )
          })
        }
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={() => this.previous()}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={() => this.next()}
        />
      </Carousel>
    );
  }
}