import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner
} from 'reactstrap';
import API from "../../../api";
import NotifToast from "../../../components/notificationModal/NotifToast";

const init_body = {
  name: '',
  status: 1
}

const required = ['name', 'status']

export default class EditPlaceToBuy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      body: init_body,
      error: {},
      notif: false,
      notifTitle: 'Success',
      notifMessage: '',
      disabled: true,
      buttonLoading: false,
    }
  }

  componentDidMount() {

  }

  onChange = (e) => {
    const error = this.state.error;
    const name = e.target.name;
    const isRequired = required.includes(name)
    if (!e.target.value && isRequired) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    const newBody = this.props.body
    newBody[e.target.name] = e.target.value
    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    this.setState({ buttonLoading: true });
    API.put('forum/place-to-buy/' + this.props.id, this.state.body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          body: init_body,
          buttonLoading: false,
          notif: true,
          notifTitle: 'Success',
          notifMessage: resp.data.message,
        });
        
        setTimeout(() => {
          this.setState({
            notif: false,
          });
        }, 3000)
        
        this.props.afterSubmit('success', resp.message)
        this.props.close()
      })
      .catch(error => {
        this.props.afterSubmit('success', error)
        this.props.close()
      })
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()}>
          <ModalHeader toggle={() => this.props.close()}>Edit User</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="exampleEmail">
                  Username
                </Label>
                <Input
                  id="name"
                  name="name"
                  placeholder="Enter Name"
                  type="text"
                  onChange={this.onChange}
                  value={this.props.body.name}
                  invalid={this.state.error['name'] ? true : false}
                />
                <FormFeedback >{this.state.error['name']}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label >
                  Status
                </Label>
                <Input
                  id="status"
                  name="status"
                  type="select"
                  onChange={this.onChange}
                  value={this.props.body.status}
                  invalid={this.state.error['status'] ? true : false}
                >
                  <option value={'1'}>{'Active'}</option>
                  <option value={'0'}>{'Non-Active'}</option>
                </Input>
                <FormFeedback >{this.state.error['username']}</FormFeedback>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled && this.state.error != ''}>
              {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
            </Button>
            <Button color="danger" onClick={() => this.props.close()}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <NotifToast
          show={this.state.notif}
          close={() => this.setState({ notif: false })}
          title={this.state.notifTitle}
          message={this.state.notifMessage}
        />
      </>
    )
  }
}