import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner
} from 'reactstrap';
import API from "../../../api";
import NotifToast from "../../../components/notificationModal/NotifToast";

const init_body = {
  name: '',
  status: 1
}

export default class AddPlaceToBuy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      body: init_body,
      notif: false,
      notifTitle: 'Success',
      notifMessage: '',
      error: {},
      disabled: true,
      buttonLoading: false
    }
  }

  componentDidMount() {
    console.log(this.state.showModal, this.state.error.length)

  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '');
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    this.setState({ buttonLoading: true });

    let body = this.state.body;
    body.password_default = body.password;

    API.post('forum/place-to-buy', this.state.body, { headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then((resp) => {
        this.setState({
          body: init_body,
          buttonLoading: false,
          notif: true,
          notifTitle: 'Success',
          notifMessage: resp.data.message,
        });
        
        setTimeout(() => {
          this.setState({
            notif: false,
          });
        }, 3000)
        
        this.props.afterSubmit()
        this.props.close()
      })
      .catch(error => {
        // this.setState({ 
        //   buttonLoading: false,
        //   notif: true,
        //   notifTitle: 'Fail',
        //   notifMessage: error,
        // });
  
        // setTimeout(() => {
        //   this.setState({
        //     notif: false,
        //   });
        // }, 3000)
        
        this.props.afterSubmit()
        this.props.close()
      })
  }

  render() {
    return (
      <>
      <Modal isOpen={this.props.open} toggle={() => this.props.close()}>
        <ModalHeader toggle={() => this.props.close()}>Add Place to Buy</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">
                Name
              </Label>
              <Input
                id="name"
                name="name"
                placeholder="Enter Name"
                type="text"
                onChange={this.onChange}
                required
                invalid={this.state.error['name'] ? true : false}
              />
              <FormFeedback >{this.state.error['name']}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label >
                Status
              </Label>
              <Input
                id="status"
                name="status"
                type="select"
                onChange={this.onChange}
                invalid={this.state.error['role_id'] ? true : false}
              >
                <option value={'1'}>{'Active'}</option>
                <option value={'0'}>{'Non-Active'}</option>
              </Input>
              <FormFeedback >{this.state.error['status']}</FormFeedback>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
            {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
          </Button>
          <Button color="danger" onClick={() => this.props.close()}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <NotifToast
          show={this.state.notif}
          close={() => this.setState({ notif: false})}
          title={this.state.notifTitle}
          message={this.state.notifMessage}
        />
      </>
    )
  }
}