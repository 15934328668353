import React, { Component } from 'react';
import API from '../../api';
import {
    NavLink,
    Button, 
    Modal, ModalHeader, ModalBody, ModalFooter,
    Alert
} from 'reactstrap';

export default class GenerateQRCode extends Component{
    constructor(props){
        super(props);
        this.toggleModalCertificate = this.toggleModalCertificate.bind(this);

        this.state = {
            productCode: "",
            manufacturerId: "",
            madeIn: "",
            productionDate: "",
            expiredDate: "",
            manufacturerName: "",
            certificate: "",
            qrCode: "",
            isModalCertificateOpen: false,
            openedCertificateImg: ""
        }
    }

    componentDidMount(){
        let id = this.props.match.params.id;
        this.getDetailProduct(id);
    }

    getDetailProduct(id){
        API.get("product/"+id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
            .then(res => {
                console.log("Detail product", res.data[0]);
                this.setState({
                    productId: res.data[0].product_id,
                    productCode: res.data[0].product_code,
                    manufacturerId: res.data[0].manufacturer_id,
                    madeIn: res.data[0].made_in,
                    productionDate: res.data[0].production_date,
                    expiredDate: res.data[0].expired_date,
                    manufacturerName: res.data[0].name,
                    certificate: res.data[0].certificate,
                    qrCode: res.data[0].qrcode
                })
                var stringCert = res.data[0].certificate.toString();
                var splitStringCert = stringCert.split("/").pop();
                console.log(splitStringCert)
                API.get('getBase64/'+splitStringCert, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
                .then(res => {
                    if(res.status === 200){
                        this.setState({
                            certificate: res.data
                        })
                    }
                    // console.log(res.data);
                })
                .catch(err => {
                    console.log("Error", err)
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    toggleModalCertificate(certificate){
        console.log("certificate type", typeof certificate)
        this.setState({
            isModalCertificateOpen: !this.state.isModalCertificateOpen
        })

        if (typeof certificate == "string"){
            this.setState({
                openedCertificateImg: certificate
            })
        } else {
            this.setState({
                openedCertificateImg: ""
            })
        }
    }

    render() {
        return(
            <div className="content">
                <div className="animated fadeIn">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="card-title">Detail Product</strong>
                                </div>
                                <div className="card-body">
                                    <div className="form-group has-success">
                                        <label for="productCode" className="control-label mb-1">ProductID</label>
                                        <input 
                                            id="productCode" name="productCode" type="text" 
                                            className="form-control valid" 
                                            data-val="true" 
                                            data-val-required="Please enter the ProductID" 
                                            autocomplete="productCode" aria-required="true" 
                                            aria-invalid="false" aria-describedby="productCode"
                                            value={this.state.productCode}
                                            onChange={this.handleChange}
                                            disabled
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="productCode" data-valmsg-replace="true"></span>
                                    </div>
                                    <div class="form-group">
                                        <label for="manufacturerId" class=" form-control-label">Manufacturer</label>
                                        <input 
                                            id="manufacturerId" name="manufacturerId" type="text" 
                                            className="form-control valid" 
                                            data-val="true" 
                                            data-val-required="Please enter the ProductID" 
                                            autocomplete="manufacturerId" aria-required="true" 
                                            aria-invalid="false" aria-describedby="manufacturerId"
                                            value={this.state.manufacturerName}
                                            onChange={this.handleChange}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group has-success">
                                        <label for="madeIn" className="control-label mb-1">Made In</label>
                                        <input 
                                            id="madeIn" name="madeIn" type="text" 
                                            className="form-control valid" 
                                            data-val="true" 
                                            data-val-required="Please enter the ProductID" 
                                            autocomplete="madeIn" aria-required="true" 
                                            aria-invalid="false" aria-describedby="madeIn"
                                            value={this.state.madeIn}
                                            onChange={this.handleChange}
                                            disabled
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="madeIn" data-valmsg-replace="true"></span>
                                    </div>
                                    <div className="form-group has-success">
                                        <label for="productionDate" className="control-label mb-1">Production Date</label>
                                        <input 
                                            id="productionDate" name="productionDate" type="date" 
                                            className="form-control valid" 
                                            data-val="true" 
                                            data-val-required="Please enter the ProductID" 
                                            autocomplete="productionDate" aria-required="true" 
                                            aria-invalid="false" aria-describedby="productionDate"
                                            value={this.state.productionDate}
                                            onChange={this.handleChangeDate}
                                            disabled
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="productionDate" data-valmsg-replace="true"></span>
                                    </div>
                                    <div className="form-group has-success">
                                        <label for="expiredDate" className="control-label mb-1">Expired Date</label>
                                        <input 
                                            id="expiredDate" name="expiredDate" type="date" 
                                            className="form-control valid" 
                                            data-val="true" 
                                            data-val-required="Please enter the ProductID" 
                                            autocomplete="expiredDate" aria-required="true" 
                                            aria-invalid="false" aria-describedby="expiredDate"
                                            value={this.state.expiredDate}
                                            onChange={this.handleChangeDate}
                                            disabled
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="expiredDate" data-valmsg-replace="true"></span>
                                    </div>
                                    <div className="form-group has-success">
                                        <label for="value" className="control-label mb-1">Certificate</label>
                                        <div>
                                            <img src={this.state.certificate} alt={this.state.productId} style={{maxWidth: "150px"}}/>
                                        </div><br/>
                                        <button type="button" className="btn btn-sm btn-info" onClick={this.toggleModalCertificate.bind(this, this.state.certificate)}>
                                            View Certificate
                                        </button>
                                    </div>
                                    <div className="form-group has-success">
                                        <label for="value" className="control-label mb-1">QRCode</label>
                                        <div>
                                            <img src={this.state.qrCode} alt={this.state.productId} style={{maxWidth: "150px"}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal isOpen={this.state.isModalCertificateOpen} toggle={this.toggleModalCertificate}>
                            <ModalHeader toggle={this.toggleModalCertificate}>Certificate
                            </ModalHeader>
                            <ModalBody style={{textAlign: "center"}}>
                                <p hidden={this.state.openedCertificateImg != ""}>No certificate found</p>
                                <img src={this.state.openedCertificateImg} ></img>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" onClick={this.toggleModalCertificate}>Close</Button>
                            </ModalFooter>
                        </Modal>


                    </div>
                </div>
            </div>
        )
    }
}