import React, { Component } from 'react';
import API from '../../api'
import LoadingBar from 'react-top-loading-bar'
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'

export default class ReplaceQRCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuCode: constants.MENU_REPLACE_QRCODE,
            scope: [],
            value: '5HEA-5HEZ',
            startValue: '',
            endValue: '',
            customUrl: '',
            customBackground: '#FFFFFF',
            customColor: '#000000',
            showCustomBackground: false,
            showCustomColor: false
        }
    }
    handleCustomBackgroundClick = () => {
        this.setState({ showCustomBackground: !this.state.showCustomBackground })
    };

    handleCustomBackgroundClose = () => {
        this.setState({ showCustomBackground: false })
    };

    handleCustomCustomClick = () => {
        this.setState({ showCustomColor: !this.state.showCustomColor })
    };

    handleCustomCustomClose = () => {
        this.setState({ showCustomColor: false })
    };


    componentDidMount() {
        this.checkAccess();
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'create');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    changeCustomBackground = (color) => {
        this.setState({ customBackground: color.hex });
    };

    changeCustomColor = (color) => {
        this.setState({ customColor: color.hex });
    };

    handleSubmit(e) {
        e.preventDefault();
        this.LoadingBar.continuousStart();

        let customUrl = '-';
        if (this.state.customUrl !== '') customUrl = this.state.customUrl;
        let customColor = '-';
        if (this.state.customColor !== '') customColor = encodeURIComponent(this.state.customColor);
        let customBackground = '-';
        if (this.state.customBackground !== '') customBackground = encodeURIComponent(this.state.customBackground);
        if (customBackground === 'transparent') customBackground = encodeURIComponent('#00000000');
        console.log('ini state', this.state)
        console.log('ini customBackground', customBackground)
        console.log('ini customColor', customColor)
        console.log('qrcode/replace/' + this.state.startValue + '-' + this.state.endValue + '?customBackground=' + customBackground + '&customColor=' + customColor + '&customUrl=' + customUrl)
        // API.get('qrcode/replace/'+this.state.value, {timeout:0})
        API.get('qrcode/replace/' + this.state.startValue + '-' + this.state.endValue + '?customBackground=' + customBackground + '&customColor=' + customColor + '&customUrl=' + customUrl, { timeout: 0, headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(response => {
                this.LoadingBar.complete();
                alert('Success')
                // this.props.history.push("/");
            }).catch((error) => {
                alert(error)
            })
        // alert('test')
    }

    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '20px',
                    borderRadius: '2px',
                    backgroundColor: `${this.state.customBackground}`,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                    marginTop: '40%',
                    marginLeft: '-270%'
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        return (
            <div className='container'>
                <div className='content'>
                    <LoadingBar onRef={ref => (this.LoadingBar = ref)} />
                    <div className='card'>
                        <div className='card-header'>
                            <strong className='card-title'>Replace QR Code</strong>
                        </div>
                        <div className='card-body'>
                            <form onSubmit={(e) => this.handleSubmit(e)}>
                                <div className='form-group'>
                                    <p className='mb-2' style={{ color: 'black' }}>Enter Base36 QR Code Value Here</p>
                                    <div className='row mb-2'>
                                        <div className='col-md-4'>
                                            {/* <p>{this.state.startValue}</p> */}
                                            <input required type='text' value={this.state.startValue} onChange={(e) => this.setState({ startValue: e.target.value })} className='form-control' placeholder='Enter Start Value' />
                                        </div>
                                        <div className='col-md-4'>
                                            {/* <p>{this.state.endValue}</p> */}
                                            <input required type='text' value={this.state.endValue} onChange={(e) => this.setState({ endValue: e.target.value })} className='form-control' placeholder='Enter End Value' />
                                        </div>
                                        <div className='col-md-4'>
                                            {/* <p>{this.state.endValue}</p> */}
                                            <input type='text' value={this.state.customUrl} onChange={(e) => this.setState({ customUrl: e.target.value })} className='form-control' placeholder='Custom Url' />
                                        </div>
                                        <div className='col-md-3'>
                                            <label className='mb-2 w-100'>Background ({this.state.customBackground})</label>
                                            <a className='text-dark btn btn-sm btn-outline-danger' onClick={this.handleCustomBackgroundClick}>Pick Color</a>
                                            {this.state.showCustomBackground ? <div style={popover}>
                                                <div style={cover} onClick={this.handleCustomBackgroundClose} />
                                                <SketchPicker
                                                    color={this.state.customBackground}
                                                    width='300px'
                                                    onChangeComplete={this.changeCustomBackground}
                                                />
                                            </div> : null}
                                        </div>
                                        <div className='col-md-1'>

                                            <div style={styles.swatch}>
                                                <div style={styles.color} />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='mb-2 w-100'>Color ({this.state.customColor})</label>
                                            <a className='text-dark btn btn-sm btn-outline-danger' onClick={this.handleCustomCustomClick}>Pick Color</a>
                                            {this.state.showCustomColor ? <div style={popover}>
                                                <div style={cover} onClick={this.handleCustomCustomClose} />
                                                <SketchPicker
                                                    color={this.state.customColor}
                                                    width='300px'
                                                    onChangeComplete={this.changeCustomColor}
                                                />
                                            </div> : null}
                                        </div>
                                    </div>
                                    <button type='submit' className='btn btn-primary'>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}