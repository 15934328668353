import React, { Component } from 'react';
import API from '../../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default class ConfigAdd extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      loading: false,
      optionProductType: [],
      body: {},
    }
  }

  componentDidMount() {
    //Load Tipe Produk
    API.get('affiliate/product-type?option=true', { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          optionProductType: resp.data.data
        })
      })
    
    API.get(`affiliate/config/${this.state.id}`, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          body: resp.data.data
        })
      })
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className='d-flex justify-content-center'>
              <div className="col-xl-5 px-0">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Edit Commission Config Affiliate</strong>
                  </div>
                  <div className="card-body">
                    <Form>
                      <FormGroup>
                        <Label className='w-100'>Product Type</Label>
                        <Input
                          name="tipe_produk_id"
                          type="select"
                          value={this.state.body.tipe_produk_id}
                          readOnly
                        >
                          {
                            <>
                              <option value={''}>Choose</option>
                              {
                                this.state.optionProductType.map(item => {
                                  return <option key={item.value} value={item.value}>{item.label}</option>
                                })
                              }
                            </>
                          }
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label>Commission</Label>
                        <InputGroup>
                          <Input
                            name="commission"
                            placeholder="Enter Commission"
                            type="text"
                            value={this.state.body.commission}
                            readOnly
                          />
                        </InputGroup>
                      </FormGroup>
                      <Link to={'/affiliate/config'}>
                        <Button className='w-100' color="primary" >
                          Back
                        </Button>
                      </Link>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}