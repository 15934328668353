import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Alert,
  Nav,
  NavItem,
} from 'reactstrap';
import API from "../../../api";
import { NavLink } from "react-bootstrap";
import { formatDay } from "../../../lib/helpers";

export default class CommentForumReportedDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
      activeTab: 'comment'
    }
  }

  switchTab(tab) {
    this.setState({
      activeTab: tab
    })
  }

  submitHidden() {
    this.setState({
      buttonLoading: true
    })

    API.put(`/forum/comment/reported-banned/${this.props.data.id}`, {}, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          buttonLoading: false
        })
        this.props.close();
        this.props.refresh();
      })
      .catch(err => {
        this.setState({
          buttonLoading: false
        })
        console.log(err)
      })
  }

  submitCloseReport() {
    this.setState({
      buttonLoading: true
    })

    API.put(`/forum/comment/reported-close/${this.props.data.id}`, {}, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          buttonLoading: false
        })
        this.props.close();
        this.props.refresh();
      })
      .catch(err => {
        this.setState({
          buttonLoading: false
        })
        console.log(err)
      })
  }

  render() {
    return (
      <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="md">
        <ModalHeader toggle={() => this.props.close()}>Detail Reported Comment</ModalHeader>
        <ModalBody>
          <Nav className="mb-4" tabs>
            <NavItem>
              <NavLink
                className={this.state.activeTab === 'comment' ? 'active font-weight-bold' : 'text-secondary'}
                onClick={() => this.switchTab('comment')}
              >
                Comment
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab === 'report' ? 'active font-weight-bold' : 'text-secondary'}
                onClick={() => this.switchTab('report')}
              >
                User Report
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className={this.state.activeTab === 'forum' ? 'active font-weight-bold' : 'text-secondary'}
                onClick={() => this.switchTab('forum')}
              >
                Forum
              </NavLink>
            </NavItem>             */}
          </Nav>
          {
            this.props.dataLoading ?
              <div className="">
                <div class="skeleton skeleton-text" style={{ width: '25%' }}></div>
                <div class="skeleton skeleton-body"></div>
                <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
              </div>
              :
              <>
                <Form className={this.state.activeTab === 'comment' ? 'show' : 'd-none'}>
                  <div className="card-forum">
                    <div className="card-forum-header">
                      <div className="font-weight-bold">
                        {this.props.data.created_by?.fullname}
                      </div>
                      <div className="text-secondary font-xs">
                      {this.props.data.created_by?.username}
                      </div>
                      <div className="text-secondary small">
                        {formatDay(this.props.data.created_at)} WIB
                      </div>
                    </div>
                    <div className="card-forum-body">
                      <div className="content-forum mt-4">
                        <h6 className="mt-4 mb-2">Comment</h6>
                        <div className="row bg-light p-2 m-0 rounded">
                          <div className="font-sm">
                            {this.props.data.comment}
                            </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  {
                    this.props.data.is_banned ?
                      <Alert color="danger p-2">
                        This Comment is already hidden for all users
                      </Alert>
                      : null
                  }
                </Form>
                <div className={this.state.activeTab === 'report' ? 'show' : 'd-none'}>
                  {
                    this.props.data.reports?.length > 0 ?
                      this.props.data.reports.map((item, index) => {
                        return (
                          <div className={`row bg-light p-2 m-0 rounded mb-2`} >
                            <div className="font-weight-bold font-sm">
                              {item.created_user?.fullname}
                            </div>
                            <div className="text-secondary font-xs">
                            {formatDay(item.created_at)} WIB
                            </div>
                            <div className="font-sm">
                              {item.description}
                            </div>
                          </div>
                        )
                      }) : null
                  }
                </div>
              </>
          }
        </ModalBody>
        <ModalFooter>          
          {
            !this.props.data.is_banned && this.props.data.reports?.filter(item => item.status === 1).length > 0 ?
              <>
                <Button color="success" className="float-left" onClick={() => this.submitHidden()}>Hidden From All</Button>
                <Button color="warning" onClick={() => this.submitCloseReport()}>Close Report</Button>
              </>
              : <Button color="danger" onClick={() => this.props.close()}>Close</Button>
          }
        </ModalFooter>
      </Modal>
    )
  }
}