import React, { Component } from "react";
import {
  Modal, ModalHeader, ModalBody,
  Input,
  Row,
  Col,
  FormGroup,
  Label
} from 'reactstrap';
import { addCommas } from "src/lib/helpers";

export default class HistoryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
    }
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="xl">
          <ModalHeader>Detail History</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-4">
                <Row className="row-cols-auto align-items-center mb-4">
                  <Col className='w-100'>
                    <FormGroup>
                      <Label>Company Name</Label>
                      <Input
                        name='company_name'
                        type="text"
                        value={this.props.data.company_name}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className='w-100'>
                    <FormGroup>
                      <Label>Brand Name</Label>
                      <Input
                        name='brand_name'
                        type="text"
                        value={this.props.data.brand_name}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className='w-100'>
                    <FormGroup>
                      <Label>PIC Name</Label>
                      <Input
                        name='pic_name'
                        type="text"
                        value={this.props.data.pic_name}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className='w-100'>
                    <Label className='w-100'>Source</Label>
                    <Input
                      name="id_source_type"
                      type="select"
                      value={this.props.data.id_source_type}
                      disabled
                    >
                      {
                        <>
                          <option value={''}>Choose</option>
                          {
                            this.props.optionSource?.map(item => {
                              return <option key={item.id} value={item.id}>{item.source_type}</option>
                            })
                          }
                        </>
                      }
                    </Input>
                  </Col>
                  <Col className='w-100 mt-3'>
                    <Label className='w-100'>Channel</Label>                      
                    <Input
                      name="id_leads_channel"
                      type="select"
                      value={this.props.data.id_leads_channel}
                      disabled
                    >
                      {
                        this.props.data.id_source_type ?
                          <>
                            <option value={''}>{this.props.optionChannel?.length ? 'Choose' : 'Empty'}</option>
                            {
                              this.props.optionChannel?.map(item => {
                                return <option key={item.id} value={item.id}>{item.channel}</option>
                              })
                            }
                          </>
                          : <option value={''}>Please select source first</option>
                      }
                    </Input>
                  </Col>
                  <Col className='w-100 mt-2'>
                    <Label>Note</Label>
                    <Input
                      name="note"
                      placeholder="Enter Note"
                      type="textarea"
                      value={this.props.data.note}
                      disabled
                      rows="2"
                    />
                  </Col>
                  <Col className='w-100'>
                    <Label className='w-100'>Status</Label>
                    <Input
                      name="status"
                      type="select"
                      value={this.props.data.status}
                      disabled
                    >
                      {
                        <>
                          <option value={''}>Choose</option>
                          {
                            this.props.status?.map(item => {
                              return <option key={item.value} value={item.value}>{item.label}</option>
                            })
                          }
                        </>
                      }
                    </Input>
                  </Col>
                </Row>
              </div>
              <div className="col-md-8">
                {
                  this.props.data?.lead_history_products?.map((item, index) => {
                    return (
                      <Row className=" align-items-center mb-4 border-bottom">
                        <Col md={8}>
                          <FormGroup>
                            <Label>Product {index + 1}</Label>
                            <Input
                              name='size'
                              type="text"
                              value={item.md_produk_lead_config?.md_tipe_produk?.tipe_produk + ' - ' + item.size}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col md='4'>
                          <FormGroup>
                            <Label>Size</Label>
                            <Input
                              name='size'
                              type="text"
                              value={item.size}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col md='3'>
                          <FormGroup>
                            <Label>Qty</Label>
                            <Input
                              name='qty'
                              type="text"
                              value={item.qty}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col md='3'>
                          <FormGroup>
                            <Label>Normal Price</Label>
                            <Input
                              name='normal_price'
                              type="text"
                              value={item.normal_price}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col md='3'>
                          <FormGroup>
                            <Label>Price after Discount</Label>
                            <Input
                              name='price'
                              type="text"
                              value={item.price}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col md='3'>
                          <FormGroup>
                            <Label>Sub Total</Label>
                            <Input
                              name='subtotal'
                              type="text"
                              value={addCommas(item.price * item.qty)}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )
                  })
                }
              </div>
            </div>

          </ModalBody>
        </Modal>
      </>
    )
  }
}