import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Alert,
  Nav,
  NavItem,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import API from "../../api";
import ReportedForumCarousel from "./partials/ReportedForumCarousel";
import { NavLink } from "react-bootstrap";
import moment from "moment";

export default class ReportedReasonDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
      activeTab: 'detail'
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="md">
        <ModalHeader toggle={() => this.props.close()}>Detail Reason Report</ModalHeader>
        <ModalBody>
          {
            this.props.dataLoading ?
              <div className="">
                <div class="skeleton skeleton-text" style={{ width: '25%' }}></div>
                <div class="skeleton skeleton-body"></div>
                <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
              </div>
              :
              <>
                <Form>
                  <FormGroup>
                    <Label>
                      Name
                    </Label>
                    <Input
                      type="textarea"
                      disabled={true}
                      value={this.props.data.reason}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Status
                    </Label>
                    <Input
                      type="text"
                      disabled={true}
                      value={this.props.data.status ? 'Active' : 'Deleted'}
                    />
                  </FormGroup>
                </Form>
              </>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => this.props.close()}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
}