import React, { Component, useState } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
  NavLink,
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Alert,
  CustomInput,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  Badge
} from 'reactstrap';
import AddUser from '../../components/user/AddUser';
import EditUser from '../../components/user/EditUser';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import DetailUser from '../../components/user/DetailUser';
import ConfirmModal from '../../components/notificationModal/ConfirmModal';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_MANAGE_USER,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'users_id',
          sortable: true
        },
        {
          name: 'Username',
          selector: 'username',
          sortable: true
        },
        {
          name: 'Email',
          selector: 'email',
          sortable: true
        },
        {
          name: 'Status',
          selector: 'status',
          sortable: true,
          cell: user =>
            <label>
              {user.status ? 
              <Badge
                color="success"
                pill
              >
                Active
              </Badge> 
              : 
              <Badge
                color="danger"
                pill
              >
                Non-Active
              </Badge> 
              }
            </label>
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: user =>
            <div>
              {/* <a href={`user/detail/${user.users_id}`} className="mr-2"><li className="text-dark fa fa-eye"></li></a> */}
              {
                this.state.scope.includes('read') ?
                  <button className="text-dark btn btn-sm btn-outline-link" onClick={() => this.showModalUser('detail', user.users_id)}><li className="fa fa-eye"></li></button>
                : null
              }
              {
                this.state.scope.includes('update') ?
                  <button className="text-primary btn btn-sm btn-outline-link" onClick={() => this.showModalUser('edit', user.users_id)}><li className="fa fa-edit"></li></button>
                : null
              }
              {
                this.state.scope.includes('delete') ? 
                  <button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(user.users_id)}><li className="fa fa-trash"></li></button>
                : null              
              }
            </div>
        }
      ],
      id: null,
      data: [],
      editData: {},
      editDataId: null,
      roleOption: [],
      showTable: false,
      showConfirm: false,
      showModal: false,
      showModalEdit: false,
      showModalDetail: false,
      disableButtonModal: false,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 5,
      paginationTotalRows: 0,
      paginationPage: 1
    };
    // this.modalUser = this.modalUser.bind(this);
  }

  componentDidMount() {
    this.checkAccess();
    this.getListRole();
    this.getListUser();
  }

  async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
    console.log(access)
    if (access) {
        let scope = [];
        access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

        this.setState({
            scope: scope
        })
        return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getListRole() {
    API.get(`role?option=${true}`, {headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then(res => {
        const result = res.data;
        this.setState({
          roleOption: result.data,
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  getListUser() {
    API.get('userApp?size=' + this.state.paginationPerPage + '&page=' + this.state.paginationPage, {headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(this.state.data)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changeEditData(body) {
    this.setState({ editData: body });
  }

  showModalUser = (type, id) => {
    if (type === 'edit') {
      this.setState({
        showModalEdit: !this.state.showModalEdit,
        editDataId: id
      });
      const data = this.state.data.filter((item) => item.users_id == id)
      if (data.length) {
        const newData = data[0]
        this.setState({
          editData: {
            username: newData.username,
            email: newData.email,
            role_id: newData.role_id,
          }
        });
      }
    }
    else if (type === 'add') {
      this.setState({ showModal: !this.state.showModal });
    }
    else if (type === 'detail') {
      this.setState({
        showModalDetail: !this.state.showModalDetail,
      });

      const data = this.state.data.filter((item) => item.users_id == id)
      if (data.length) {
        const newData = data[0]
        this.setState({
          editData: {
            username: newData.username,
            email: newData.email,
            role_id: newData.role_id,
          }
        });
      }
    }
  }

  afterSubmit(status, message) {
    this.getListUser()

    this.setState({
      alert: {
        show: true,
        status: status,
        message: message
      }
    });

    setTimeout(() => {
      this.setState({
        alert: {
          show: false,
          status: '',
          message: ''
        }
      });
    }, 3000)
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getListUser();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getListUser();
    })
  }

  showConfirmDelete = (id) => {
    this.setState({ 
      showConfirm: !this.state.showConfirm,
      id: id,
    });
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                {
                  this.state.alert.show ?
                    <Alert
                      color="success"
                      isOpen={this.state.alertSuccess}
                    >
                      successfully
                    </Alert>
                    : null
                }

                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Manage Users {this.state.showModal}</strong>
                    {/* <NavLink href="/user/add" > */}
                    {
                      this.state.scope.includes('create') ?
                        <div className="float-right">
                          <Button
                            color="info"
                            size="sm"
                            onClick={() => this.showModalUser('add',)}
                          >
                            Add User
                          </Button>
                        </div>
                        : null
                    }
                    {/* </NavLink> */}
                  </div>
                  <div className="card-body">
                    {
                      !this.state.showTable ? <>Loading ...</> :
                        <DataTable
                          noHeader={true}
                          columns={this.state.columns}
                          data={this.state.data}
                          pagination={true}
                          paginationServer={true}
                          paginationPerPage={5}
                          paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                          paginationTotalRows={this.state.paginationTotalRows}
                          onChangePage={page => this.changePage(page)}
                          onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                        />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddUser 
            open={this.state.showModal} 
            close={() => this.showModalUser('add')} 
            afterSubmit={() => this.afterSubmit()} 
            roleOption={this.state.roleOption}/>
          <EditUser
            open={this.state.showModalEdit}
            close={() => this.showModalUser('edit', this.state.editDataId)}
            afterSubmit={() => this.afterSubmit()}
            body={this.state.editData}
            id={this.state.editDataId}
            roleOption={this.state.roleOption}
          />
          <DetailUser
            open={this.state.showModalDetail}
            close={() => this.showModalUser('detail', this.state.editDataId)}
            afterSubmit={() => this.afterSubmit()}
            body={this.state.editData}
            id={this.state.editDataId}
            roleOption={this.state.roleOption}
          />
          <ConfirmModal 
            open={this.state.showConfirm} 
            close={() => this.showConfirmDelete()} 
            id={this.state.id} 
            url={`userApp/${this.state.id}`}
          />
        </div>
    )
  }
}

export default index;