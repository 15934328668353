/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import API from 'src/api'
import {
  Form,
  Label,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NotifToast from 'src/components/notificationModal/NotifToast';
import { addCommas, formatDay } from 'src/lib/helpers';

export default class UsersEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      body: {
        point: '',
        point_price: '',
        start_date: '',
        end_date: '',
        status: 1,
      },
      required: ['point', 'point_price', 'start_date', 'end_date', 'status'],
    }
  }

  componentDidMount() {
    //Load Data
    API.get('scn/point-conversion/' + this.state.id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const data = resp.data.data;
        data.point_price = addCommas(data.point_price);
        data.start_date = data.start_date.substring(0, 10);
        data.end_date = data.end_date.substring(0, 10);

        ['created_at', 'created_by', 'updated_at', 'updated_by'].forEach(prop => delete data[prop]);

        this.setState({
          body: data
        })
      })
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is Required'
    }
    else {
      delete error[name]
    }

    if (name === 'point_price') {
      newBody[name] = addCommas(value.replace(/\./g, ''))
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    this.setState({
      notif: {
        ...this.state.notif,
        show: true,
        title: 'Processing...',
      },
      buttonLoading: true,
    });
    let body = this.state.body;
    body.point_price = body.point_price.replace(/\./g, '');

    // alert(JSON.stringify(this.state.body)); return;
    API.put('scn/point-conversion/' + this.state.id, body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          notif: {
            title: 'Success',
            status: result.status,
            message: result.message,
          }
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false },
            buttonLoading: false,
          });
        }, 1500);
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
          }
        });
      })
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className='d-flex justify-content-center'>
              <div className="col-xl-5 px-0">
                <div className="card">
                  <div className="card-header vertical-middle">
                    <strong className="card-title vertical-middle-p">Detail Point Conversion</strong>
                    <Link to="/scn/point-conversion" className="float-right">
                      <button
                        color="info"
                        size="sm"
                        className="btn btn-outline-link"
                      >
                        <i className='fa fa-arrow-left'></i>
                      </button>
                    </Link>
                  </div>
                  <div className="card-body">
                    <Form>
                      <div className='d-flex g-5'>
                        <Label className='w-50'>Point</Label>
                        <div className="text-dark">:  <span className='font-weight-bold'>{this.state.body?.point}</span> </div>
                      </div>
                      <div className='d-flex g-5'>
                        <Label className='w-50'>Point Price</Label>
                        <div className="text-dark">:  <span className='font-weight-bold'>Rp. {this.state.body?.point_price}</span> </div>
                      </div>
                      <div className='d-flex g-5'>
                        <Label className='w-50'>Withdrawal Min. Point</Label>
                        <div className="text-dark">:  <span className='font-weight-bold'>{this.state.body?.point_withdrawal_min}</span> </div>
                      </div>
                      <div className='d-flex g-5'>
                        <Label className='w-50'>Start Date</Label>
                        <div className="text-dark">:  <span className='font-weight-bold'>{formatDay(this.state.body?.start_date, false)}</span> </div>
                      </div>
                      <div className='d-flex g-5'>
                        <Label className='w-50'>End Date</Label>
                        <div className="text-dark">:  <span className='font-weight-bold'>{formatDay(this.state.body?.end_date, false)}</span> </div>
                      </div>
                      <div className='d-flex g-5'>
                        <Label className='w-50'>Status</Label>
                        <div className="text-dark">:  <span className='font-weight-bold'>{this.state.body.status ? 'Active' : 'Non Active'}</span> </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
          status={this.state.notif.status}
        />
      </div>
    )
  }
}