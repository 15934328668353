import React, { Component, useState } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
    NavLink,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Alert
} from 'reactstrap';
import LoadingBar from 'react-top-loading-bar';
import _ from 'lodash'

class ListProductSelected extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
        this.toggleModalCertificate = this.toggleModalCertificate.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            columns: [
                {
                    name: 'Id',
                    selector: 'product_id',
                    sortable: true
                },
                {
                    name: 'ProductID',
                    selector: 'product_code',
                    sortable: true
                },
                {
                    name: 'Manucaturer',
                    selector: 'name',
                    sortable: true
                },
                {
                    name: 'Pin',
                    selector: 'pin',
                    sortable: true,
                },
                {
                    name: 'Made In',
                    selector: 'made_in',
                    sortable: true
                },
                {
                    name: 'Certificate & QRCode',
                    selector: 'certificate',
                    sortable: true,
                    cell: product =>
                        <div>
                            <button type="button" className="btn btn-sm btn-info" onClick={this.toggleModalCertificate.bind(this, product.certificate, "certificate")}>
                                Certificate
                            </button><br />
                            <button type="button" className="btn btn-sm btn-info" onClick={this.toggleModalCertificate.bind(this, product.qrcode, "qrcode")}>
                                QRCode
                            </button>
                        </div>
                },
                {
                    name: 'Action',
                    selector: 'action',
                    sortable: false,
                    cell: product =>
                        <div className="row">
                            <button type="button" className="btn btn-sm btn-outline-link" onClick={this.linkDetail.bind(this, product.product_id)}>
                                <li className="fa fa-eye"></li>
                            </button>
                            <button type="button" className="btn btn-sm btn-info" onClick={this.linkEditValue.bind(this, product.product_id)}>
                                Edit Value
                            </button>
                            <button type="button" className="btn btn-sm btn-danger" onClick={this.toggle.bind(this, product.product_id)}>
                                <li className="fa fa-trash"></li>
                            </button>
                        </div>
                }
            ],
            data: [],
            isModalDeleteOpen: false,
            alertSuccess: false,
            deleteProductId: "",
            paginationPerPage: 5,
            paginationTotalRows: 0,
            paginationPage: 1,
            isModalCertificateOpen: false,
            openedCertificateImg: "",
            printed: [],
            from: 0,
            to: 0
        };
    }

    componentDidMount() {
        this.getListProduct();
    }

    getListProduct() {
        API.get('product', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                console.log("List product", res.data);
                this.setState({
                    data: res.data.item,
                    paginationTotalRows: res.data.totalItem
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    linkEditValue(id) {
        this.props.history.push("/product/edit/" + id);
    }

    toggle(id) {
        console.log("Id delete", typeof id)
        this.setState({
            isModalDeleteOpen: !this.state.isModalDeleteOpen,
        })

        if (typeof id == "number") {
            this.setState({
                deleteProductId: id
            })
        }
    }

    toggleModalCertificate(certificate, type) {
        console.log("certificate type", typeof certificate)
        this.setState({
            isModalCertificateOpen: !this.state.isModalCertificateOpen,
            modalType: type
        })

        if (typeof certificate == "string") {
            this.setState({
                openedCertificateImg: certificate
            })
        } else {
            this.setState({
                openedCertificateImg: ""
            })
        }
    }

    deleteProduct() {
        API.delete('product/' + this.state.deleteProductId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                console.log("product deleted");
                this.getListProduct();
                this.toggle();
                this.toggleAlertSuccess();
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    toggleAlertSuccess() {
        this.setState({
            alertSuccess: !this.state.alertSuccess
        })

        setTimeout(() => {
            this.setState({
                alertSuccess: !this.state.alertSuccess
            })
        }, 2000)
    }

    linkDetail(id) {
        this.props.history.push("/product/" + id);
    }

    changePage(page) {
        this.setState({
            paginationPage: page
        }, () => {
            this.getListProduct();
        })
    }

    changeRowsPerPage(rowsPerPage) {
        this.setState({
            paginationPerPage: rowsPerPage
        }, () => {
            this.getListProduct();
        })
    }

    handleChange(value) {
        // You can use setState or dispatch with something like Redux so we can use the retrieved data
        this.setState({
            printed: value.selectedRows
        })
    };

    printProduct() {

        let printed = []

        for (let i = 0; i < this.state.printed.length; i++) {
            let temp = {}
            temp.product_id = this.state.printed[i].product_id
            temp.qrcode = this.state.printed[i].qrcode
            temp.token = this.state.printed[i].token
            temp.pin = this.state.printed[i].pin
            printed.push(temp)
        }

        console.log("this printed: ", printed)

        let body = {
            printed: printed
        }
        console.log("param", body)
        API.post('print/product', body, { headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` }})
            .then(response => {
                console.log("Sukses create Printed");
                this.LoadingBar.complete()

                setTimeout(() => {
                    window.location.href = "/product";
                }, 500);
            })
            .catch(error => {
                console.log("Error create Manufacturer", error);
            });
    }

    // handleChange(event) {
    //     this.setState({[event.target.name]: event.target.value});
    // }

    async printProductFromTo() {

        const { from, to } = this.state

        var printing = _.map(this.state.data, function (product) {
            if (product.product_id >= from && product.product_id <= to) return product;
        });

        // Remove undefines from the array
        printing = _.without(printing, undefined)

        console.log(printing)
        let printed = []

        for (let i = 0; i < printing.length; i++) {
            let temp = {}
            temp.product_id = printing[i].product_id
            temp.qrcode = printing[i].qrcode
            temp.token = printing[i].token
            temp.pin = printing[i].pin
            printed.push(temp)
        }

        console.log("this printed: ", printed)

        let body = {
            printed: printed
        }
        console.log("param", body)
        API.post('print/product', body, { headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` }})
            .then(response => {
                console.log("Sukses create Printed");
                this.LoadingBar.complete()

                setTimeout(() => {
                    window.location.href = "/product";
                }, 500);
            })
            .catch(error => {
                console.log("Error create Manufacturer", error);
            });
    }

    render() {

        console.log("data: ", this.state.from)

        return (
            <div className="content">
                <LoadingBar onRef={ref => (this.LoadingBar = ref)} />
                <div className="animated fadeIn">
                    <div className="row">

                        <div className="col-md-12">
                            <Alert
                                color="success"
                                isOpen={this.state.alertSuccess}
                            >
                                Product deleted successfully
                            </Alert>
                            <div className="card">
                                <div className="card-header">
                                    <strong className="card-title">Data Table</strong>
                                    <NavLink href="/product/add" className="float-right">
                                        <button type="button" className="btn btn-info">Add ProductID</button>
                                    </NavLink>
                                    {/* <NavLink className="float-right">
                                        <div className="form-row">
                                            <div className="col">
                                                <input 
                                                    id="from" name="from" type="number" 
                                                    className="form-control valid" 
                                                    data-val="true" 
                                                    data-val-required="from" 
                                                    autocomplete="from" aria-required="true" 
                                                    aria-invalid="false" aria-describedby="from"
                                                    value={this.state.from}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="col">
                                                <input 
                                                    id="to" name="to" type="text" 
                                                    className="form-control valid" 
                                                    data-val="true" 
                                                    data-val-required="Please enter the ProductID" 
                                                    autocomplete="to" aria-required="true" 
                                                    aria-invalid="false" aria-describedby="to"
                                                    value={this.state.to}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="col">
                                                <button type="button" onClick={()=>this.printProductFromTo()} className="btn btn-danger">Export Qr</button>
                                            </div>
                                        </div>
                                    </NavLink> */}
                                    {
                                        this.state.printed.length > 0 &&
                                        <NavLink className="float-right" style={{ marginLeft: 40 }}>
                                            <button type="button" onClick={() => this.printProduct()} className="btn btn-danger">Export Qr</button>
                                        </NavLink>
                                    }
                                </div>
                                <div className="card-body">
                                    <DataTable
                                        title="List ProductID"
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        selectableRows
                                        hover
                                        // entries={5}
                                        paginationPerPage={100}
                                        paginationRowsPerPageOptions={[100, 200, 300]}
                                        pagesAmount={4}
                                        pagination={true}
                                        onSelectedRowsChange={value => this.handleChange(value)}
                                    // onChangePage={}
                                    // onChangeRowsPerPage={}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.isModalDeleteOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Are you sure?</ModalHeader>
                    <ModalFooter>
                        <Button color="info" onClick={this.deleteProduct}>Delete</Button>{' '}
                        <Button color="danger" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.isModalCertificateOpen} toggle={this.toggleModalCertificate}>
                    <ModalHeader toggle={this.toggleModalCertificate}>
                        {this.state.modalType == "certificate" ? "Certificate" : "QR Code"}
                    </ModalHeader>
                    <ModalBody style={{ textAlign: "center" }}>
                        <p hidden={this.state.openedCertificateImg != ""}>No certificate found</p>
                        <img src={this.state.openedCertificateImg} ></img>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.toggleModalCertificate}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default ListProductSelected;