import React, { useState } from "react";
import { useEffect } from 'react';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from '../api';


const AxiosErrorHandler = ({ children }) => {

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use((request) => {
      return request;
    });

    const responseInterceptor = axios.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      if (error.response?.status) {
        let title = 'Error!';
        let icon = 'error';

        // eslint-disable-next-line default-case
        switch (error.response.status) {
          case 403:
            title = 'Forbidden!';
            icon = 'warning';
            break;
        }
        // alert(error.response.status)
        const MySwal = withReactContent(Swal)
        MySwal.fire({
          title: title,
          text: error.response.data?.message,
          icon: icon,
          confirmButtonText: 'OK, Understand'
        })
        .then((result) => {
          if (result.isConfirmed) {
            if(error.response.status === 401){
              localStorage.clear();
              window.location.href = '/login';
            } else {
              MySwal.close();
            }       
          }
        })
      }
      return error.response;
    });

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return children;
};


export default AxiosErrorHandler;