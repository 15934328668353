/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import API from '../../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  FormText,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NotifToast from '../../../components/notificationModal/NotifToast';

const init_body = {
  point: '',
  max_count: '',
  type: '',
  status: 1,
}

class add extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      body: init_body,
      code: '',
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      optionGroup: [],
      modalGroup: false
    }
  }

  componentDidMount() {
    // this.getLoginData();
    // this.getOptionGroup();
    // console.log(this.state.showModal, this.state.error.length)

  }


  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    if (e.target.name === 'name') {
      const newCode = e.target.value.toLowerCase().replaceAll(' ','-')
      newBody['code'] = newCode
      this.setState({
        code: newCode
      })
    }

    this.setState({
      body: newBody,
      error: error
    });
    // console.log(newBody)

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '');
    const result = filtered.length ? true : false

    this.setState({
      disabled: result,
    });

    return result
  }

  onSubmit() {
    this.setState({
      // buttonLoading: true,
      notif: { show: true, title: 'Processing ...' }
    });
    let config = 'scn/config/point/referral';
    if (this.state.type === 'referrer') {
      config = 'scn/config/point/referrer';
    }
    this.state.body.point = parseInt(this.state.body.point)
    this.state.body.max_count = parseInt(this.state.body.max_count)
    this.state.body.status = 1

    API.post(config, this.state.body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          body: {},
          notif: {
            status: true,
            title: 'Success',
            message: result.message,
          }
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false }
          });
          this.props.history.push(`/scn/config/referral`);
        }, 1500);
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            status: false,
            title: 'Failed',
            message: err,
          }
        });
      })
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Add Config</strong>
                  <div className="float-right">
                    <Link to="/menu">
                      <button
                        color="info"
                        size="sm"
                        className="btn btn-outline-link"
                      >
                        <i className='fa fa-arrow-left'></i>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label >
                        Type
                      </Label>
                      <Input
                        id="type"
                        name="type"
                        type="select"
                        onChange={this.onChange}
                      >
                        <option value={''}>Choose</option>
                        <option value={'referral'}>Referral</option>
                        <option value={'referrer'}>Referrer</option>
                      </Input>
                      <FormFeedback >{this.state.error['type']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Bonus Point
                      </Label>
                      <Input
                        id="point"
                        name="point"
                        placeholder="Enter point"
                        type="number"
                        onChange={this.onChange}
                        required
                        invalid={this.state.error['point'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['point']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Max Mission Work
                      </Label>
                      <Input
                        id="max_count"
                        name="max_count"
                        placeholder="Enter Max Mission Work"
                        type="number"
                        onChange={this.onChange}
                        required
                        invalid={this.state.error['max_count'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['max_count']}</FormFeedback>
                    </FormGroup>
                    <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                      {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
        />
      </div>
    )
  }
}

export default add;