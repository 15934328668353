import React, { Component } from 'react';
import API from '../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from 'reactstrap';
import MappingRole from './mappingRole';
import { Link } from 'react-router-dom';
import NotifToast from '../../components/notificationModal/NotifToast';

const init_body = {
  name: '',
  group_name: '',
}

export default class editRole extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      body: init_body,
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      showManageRoles: false,
      group: 'dashboard_app'
    }
  }

  componentDidMount() {
    this.getRole()
  }

  getRole() {
    API.get(`role/${this.state.id}`,{headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          body: {
            name: result.data.name,
            group_name: result.data.group_name
          },
        });
      })
      .catch(err => {
        alert(err)
      })
      .finally(() => {
        this.setState({ showManageRoles: true, group: this.state.body.group_name });
      })
  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '');
    const result = filtered.length ? true : false

    this.setState({
      disabled: result,
    });

    return result
  }

  onSubmit() {
    this.setState({
      disabled: true,
      notif: { show: true, title: 'Processing ...' }
    });
    API.put(`role/${this.state.id}`, this.state.body, { headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          body: {},
          notif: {
            title: 'Success',
            message: result.message,
          }
        });

        setTimeout(() => {
          this.setState({
            disabled: false,
            notif: { show: false }
          });
          this.props.history.push(`/role/edit/${result.data.insertId}`);
        }, 2500);
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
          }
        });
      })
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Edit Roles</strong>
                  {/* <NavLink href="/user/add" > */}
                  <div className="float-right">
                    <Link to="/role">
                      <button
                        color="info"
                        size="sm"
                        className="btn btn-outline-link"
                      >
                        <i className='fa fa-arrow-left'></i>
                      </button>
                    </Link>

                  </div>
                  {/* </NavLink> */}
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Name
                      </Label>
                      <Input
                        id="name"
                        name="name"
                        placeholder="Enter Name"
                        type="text"
                        onChange={this.onChange}
                        value={this.state.body.name}
                        invalid={this.state.error['name'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['name']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label >
                        Group Name
                      </Label>
                      <Input
                        id="group_name"
                        name="group_name"
                        type="select"
                        onChange={this.onChange}
                        value={this.state.body.group_name}
                        invalid={this.state.error['group_name'] ? true : false}
                        disabled={true}
                      >
                        <option value={''}>Choose</option>
                        <option value={'dashboard_app'}>Dashboard App</option>
                        <option value={'dashboard_partner'}>Dashboard Partner</option>
                      </Input>
                      <FormFeedback >{this.state.error['group_name']}</FormFeedback>
                    </FormGroup>
                    <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                      {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          {
            this.state.showManageRoles && <MappingRole id={this.state.id} group={this.state.group}/>
          }          
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
        />
      </div>
    )
  }
}