/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import API from '../../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  CustomInput,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NotifToast from '../../../components/notificationModal/NotifToast';
import { Image } from 'react-bootstrap';

export default class UsersDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      optionManufactur: [],
      optionRole: [],
      body: {},
    }
  }

  componentDidMount() {
    //Load Data
    API.get('affiliate/users/' + this.state.id, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          body: resp.data.data
        })
      })

    //Load Manufactur
    API.get('manufacturer?option=true', { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          optionManufactur: resp.data
        })
      })

    //Load Role
    API.get('role?option=true&group=dashboard_partner', { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          optionRole: resp.data.data
        })
      })
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className='d-flex justify-content-center'>
              <div className="col-xl-5 px-0">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Detail Users Affiliate</strong>
                  </div>
                  <div className="card-body">
                    <Form>
                      <FormGroup>
                        <Label className='w-100'>Manufactur</Label>
                        <Input
                          name="manufacturer_id"
                          type="select"
                          value={this.state.body.manufacturer_id}
                        >
                          {
                            <>
                              <option value={''}>Choose</option>
                              {
                                this.state.optionManufactur.map(item => {
                                  return <option key={item.value} value={item.value}>{item.label}</option>
                                })
                              }
                            </>
                          }
                        </Input>
                      </FormGroup>
                      <div className='d-xl-flex'>
                        <div className='col-xl-6 pl-0'>
                          <FormGroup>
                            <Label>Fullname</Label>
                            <Input
                              name="fullname"
                              placeholder="Enter Fullname"
                              type="text"
                              value={this.state.body.fullname}
                            />
                          </FormGroup>
                        </div>
                        <div className='col-xl-6 pl-0'>
                          <FormGroup>
                            <Label>Email</Label>
                            <Input
                              name="email"
                              placeholder="Enter Email"
                              type="text"
                              value={this.state.body.email}
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className='d-xl-flex'>
                        <div className='col-xl-6 pl-0'>
                          <FormGroup className='d-'>
                            <Label>KTP</Label>
                            <Image src={this.state.body.ktp_image} alt='ktp' width={300} />
                          </FormGroup>
                        </div>
                        <div className='col-xl-6 pl-0'>
                          <FormGroup className='d-grid'>
                            <Label>NPWP Image</Label>
                            {
                              this.state.body.npwp_image ? <Image src={this.state.body.npwp_image} alt='npwp' width={300} /> : null
                            }
                          </FormGroup>
                        </div>
                      </div>
                      <FormGroup>
                        <Label>NPWP Number</Label>
                        <Input
                          name="npwp_number"
                          placeholder="Enter NPWP Number"
                          type="text"
                          value={this.state.body.npwp_number}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Username</Label>
                        <Input
                          name="username"
                          placeholder="Enter Username"
                          type="text"
                          value={this.state.body.username}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className='w-100'>Role Akses</Label>
                        <Input
                          name="role_id"
                          type="select"
                          value={this.state.body.role_id}
                        >
                          {
                            <>
                              <option value={''}>Choose</option>
                              {
                                this.state.optionRole.map(item => {
                                  return <option key={item.id} value={item.id}>{item.name}</option>
                                })
                              }
                            </>
                          }
                        </Input>
                      </FormGroup>
                      <Link to={'/affiliate/users'}>
                        <Button className='w-100' color="primary" >
                          Back
                        </Button>
                      </Link>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}