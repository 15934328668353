import React, { Component, useState } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import {
    NavLink,
    Button, 
    Modal, ModalHeader, ModalBody, ModalFooter,
    Alert
} from 'reactstrap';
import LoadingBar from 'react-top-loading-bar';
import Moment from 'moment';
import moment from 'moment-timezone';
import _ from 'lodash'

class ListProduct extends Component {
      
    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
        this.toggleModalCertificate = this.toggleModalCertificate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.convertTZ = this.convertTZ.bind(this);
        
        var dayjs = require('dayjs')
        
        this.state = {
            menuCode: constants.MENU_LIST_CITY_QR_LOCATION,
            scope: [],
            columns: [
                {
                    name: 'Location ID',
                    selector: 'location_id',
                    sortable: true
                },
                {
                    name: 'Manufacturer ID',
                    selector: 'manufacturer_id',
                    sortable: true,
                        cell: location =>
                        <div style={{ }}>
                            {
                                location.manufacturer_id !== null
                                ?
                                <li style={{ listStyleType:'none' }}>
                                    <a href={'/manufacturer/'+location.manufacturer_id} data-toggle="tooltip" title={location.manufacturer_name}>
                                        {location.manufacturer_name}
                                    </a>
                                </li>
                                :
                                <li style={{ listStyleType:'none' }}>-</li>
                            }
                        </div>
                },
                {
                    name: 'Product Code',
                    selector: 'product_code',
                    sortable: true,
                        cell: location =>
                        <div style={{ }}>
                            {
                                location.product_code !== null
                                ?
                                <li style={{ listStyleType:'none' }}>
                                    <a href={"/product/"+location.product_id} target="_blank" data-toggle="tooltip" title={location.product_code}>
                                        {location.product_code}
                                    </a>
                                </li>
                                :
                                <li style={{ listStyleType:'none' }}>-</li>
                            }
                        </div>
                },
                {
                    name: 'QRCode ID Base36',
                    selector: 'qrcode_id36',
                    sortable: true,
                    cell: location =>
                        <div style={{ }}>
                            {
                                location.qrcode_id36
                                ?
                                    location.qrcode_id36.length === 1
                                    ?
                                    <li style={{ listStyleType:'none' }}>
                                        <a href={"/qrcode/"+parseInt(location.qrcode_id36, 36)} target="_blank" data-toggle="tooltip" title={location.qrcode_id36}>
                                            00000{location.qrcode_id36}
                                        </a>
                                    </li>
                                    :
                                    location.qrcode_id36.length === 2
                                    ?
                                    <li style={{ listStyleType:'none' }}>
                                        <a href={"/qrcode/"+parseInt(location.qrcode_id36, 36)} target="_blank" data-toggle="tooltip" title={location.qrcode_id36}>
                                            0000{location.qrcode_id36}
                                        </a>
                                    </li>
                                    :
                                    location.qrcode_id36.length === 3
                                    ?
                                    <li style={{ listStyleType:'none' }}>
                                        <a href={"/qrcode/"+parseInt(location.qrcode_id36, 36)} target="_blank" data-toggle="tooltip" title={location.qrcode_id36}>
                                            000{location.qrcode_id36}
                                        </a>
                                    </li>
                                    :
                                    location.qrcode_id36.length === 4
                                    ?
                                    <li style={{ listStyleType:'none' }}>
                                        <a href={"/qrcode/"+parseInt(location.qrcode_id36, 36)} target="_blank" data-toggle="tooltip" title={location.qrcode_id36}>
                                            00{location.qrcode_id36}
                                        </a>
                                    </li>
                                    :
                                    location.qrcode_id36.length === 5
                                    ?
                                    <li style={{ listStyleType:'none' }}>
                                        <a href={"/qrcode/"+parseInt(location.qrcode_id36, 36)} target="_blank" data-toggle="tooltip" title={location.qrcode_id36}>
                                            0{location.qrcode_id36}
                                        </a>
                                    </li>
                                    :
                                    <li style={{ listStyleType:'none' }}>
                                        <a href={"/qrcode/"+parseInt(location.qrcode_id36, 36)} target="_blank" data-toggle="tooltip" title={location.qrcode_id36}>
                                            {location.qrcode_id36}
                                        </a>
                                    </li>
                                :
                                <li style={{ listStyleType:'none' }}>-</li>
                            }
                        </div>
                },
                {
                    name: 'Latitude',
                    selector: 'latitude',
                    sortable: true
                },
                {
                    name: 'Longitude',
                    selector: 'longitude',
                    sortable: true
                },
                {
                    name: 'City Name',
                    selector: 'city_name',
                    sortable: true
                },
                {
                    name: 'Status',
                    selector: 'status',
                    sortable: true,
                },
                {
                    name: 'Created At',
                    selector: 'created_at',
                    sortable: true,
                    cell: location =>
                        <div style={{ width:300 }}>
                            {/* <li style={{ listStyleType:'none' }}>{new Date(location.created_at_location).toISOString('dd-mm-yyyy')}</li> */}
                            {/* <marquee> */}
                                <li style={{ listStyleType:'none' }}>{moment(location.created_at_location).format('DD-MM-YYYY HH:mm')} WIB</li>
                            {/* </marquee> */}
                        </div>
                },
                // {
                //     name: 'Action',
                //     selector: 'action',
                //     sortable: false,
                //     cell: product => 
                //         <div className="row">
                //             <div className="col-md-6">
                //                 <button type="button" className="btn btn-sm btn-outline-link" onClick={this.linkDetail.bind(this, product.product_id)}>
                //                     <li className="fa fa-eye"></li>
                //                 </button>
                //             </div>
                //             <div className="col-md-6">
                //                 <button type="button" className="btn btn-sm btn-danger" onClick={this.toggle.bind(this, product.product_id)}>
                //                     <li className="fa fa-trash"></li>
                //                 </button>
                //             </div>
                //             <button type="button" className="btn btn-sm btn-info" onClick={this.linkEditValue.bind(this, product.product_id)}>
                //                 Edit Value
                //             </button>
                //         </div>
                // }
            ],
            data: [],
            isModalDeleteOpen: false,
            alertSuccess: false,
            deleteProductId: "",
            paginationPerPage: 50,
            paginationTotalRows: 0,
            paginationPage: 1,
            isModalCertificateOpen: false,
            openedCertificateImg: "",
            printed: [],
            from : 0,
            to : 0,
            loadingData: true
        };
    }

    convertTZ(date, tzString) {
        return new Date(date);   
    }

    componentDidMount(){
        this.checkAccess();
        this.getListProduct();
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    getListProduct(){
        API.get('location',{
            params: {
                size: this.state.paginationPerPage,
                page: this.state.paginationPage
            },
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
            .then(res => {
                console.log("List product", res.data);
                this.setState({
                    data: res.data.item,
                    paginationTotalRows: res.data.totalItem,
                    loadingData: false
                })
                for(var i = 0; i < this.state.data.length; i++){

                }
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    linkEditValue(id){
        this.props.history.push("/product/edit/"+id);
    }

    convertToDate(date){
        var newDate = new Date(date).toString();
        return 'AAA';
    }

    toggle(id){
        console.log("Id delete", typeof id)
        this.setState({
            isModalDeleteOpen: !this.state.isModalDeleteOpen,
        })

        if (typeof id == "number"){
            this.setState({
                deleteProductId: id
            })
        }
    }

    toggleModalCertificate(certificate, type){
        console.log("certificate type", typeof certificate)
        this.setState({
            isModalCertificateOpen: !this.state.isModalCertificateOpen,
            modalType: type
        })

        if (typeof certificate == "string"){
            this.setState({
                openedCertificateImg: certificate
            })
        } else {
            this.setState({
                openedCertificateImg: ""
            })
        }
    }

    deleteProduct(){
        API.delete('product/'+this.state.deleteProductId)
            .then(res => {
                console.log("product deleted");
                this.getListProduct();
                this.toggle();
                this.toggleAlertSuccess();
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    toggleAlertSuccess(){
        this.setState({
            alertSuccess: !this.state.alertSuccess
        })

        setTimeout(() => {
            this.setState({
                alertSuccess: !this.state.alertSuccess
            })
         }, 2000)
    }

    linkDetail(id){
        this.props.history.push("/product/"+id);
    }

    changePage(page){
        console.log("page", page)
        this.setState({
            paginationPage: page
        }, () => {
            this.getListProduct();
        })
    }

    changeRowsPerPage(rowsPerPage){
        this.setState({
            paginationPerPage: rowsPerPage
        }, () => {
            this.getListProduct();
        })
    }

    //  handleChange (value) {
    //     // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //     this.setState({
    //         printed : value.selectedRows
    //     })
    //   };

    printProduct(){

        let printed = []
        
        for(let i=0;i<this.state.printed.length;i++){
            let temp = {}
            temp.product_id = this.state.printed[i].product_id
            temp.qrcode  = this.state.printed[i].qrcode
            temp.token = this.state.printed[i].token
            temp.pin = this.state.printed[i].pin
            printed.push(temp)
        }

        console.log("this printed: ", printed)

        let body = {
            printed: printed
        }
        console.log("param", body)
        API.post('print/product', body, { headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` }})
                .then(response => {
                    console.log("Sukses create Printed");
                    this.LoadingBar.complete()
                   
                    setTimeout(() => {
                         window.location.href="/product";
                      }, 500);
                })
                .catch(error => {
                    console.log("Error create Manufacturer",error);
                });
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    async printProductFromTo(){
        this.LoadingBar.continuousStart()
        const {from, to} = this.state

        var printing = _.map(this.state.data, function(product) {
            if (product.product_id >= from && product.product_id<=to) return product;
        });

        // Remove undefines from the array
        printing = _.without(printing, undefined)
        
        console.log(printing)
        let printed = []
        
        for(let i=0;i<printing.length;i++){
            let temp = {}
            temp.product_id = printing[i].product_id
            temp.qrcode  = printing[i].qrcode
            temp.token = printing[i].token
            temp.pin = printing[i].pin
            printed.push(temp)
        }

        console.log("this printed: ", printed)

        let body = {
            printed: printed
        }
        console.log("param", body)
        API.post('print/product', body, { headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` }})
                .then(response => {
                    console.log("Sukses create Printed");
                    this.LoadingBar.complete()
                   
                    setTimeout(() => {
                         window.location.href="/product";
                      }, 500);
                })
                .catch(error => {
                    console.log("Error create Manufacturer",error);
                });
    }

    render(){

        console.log("data: ", this.state.from)

        return(
            <div className="content">
                <style>
                    {
                    `
                    @media screen
                    and (min-device-width: 1200px) 
                    and (max-device-width: 1600px) 
                    and (-webkit-min-device-pixel-ratio: 2)
                    and (min-resolution: 192dpi) {
                        .right-panel{
                            width:100%;
                        }
                    }

                    @media screen
                    and (max-width: 1366px){
                        .right-panel{
                            width:100%;
                        }
                    }
                    `
                    }
                </style>
                 <LoadingBar onRef={ref => (this.LoadingBar = ref)} />
                <div className="animated fadeIn">
                    <div className="row">

                        <div className="col-md-12">
                            <Alert 
                                color="success"
                                isOpen={this.state.alertSuccess}
                            >
                                Product deleted successfully
                            </Alert>
                            <div className="card">
                                <div className="card-header">
                                    <strong className="card-title">Data Table</strong>
                                    {/* <NavLink href="/product/add" className="float-right">
                                        <button type="button" className="btn btn-info">Add ProductID</button>
                                    </NavLink> */}
                                    {/* <NavLink className="float-right">
                                        <div className="form-group row">
                                            <label for="From" class="col-form-label">From</label>
                                            <div className="col">
                                                <input 
                                                    id="from" name="from" type="number" 
                                                    className="form-control valid" 
                                                    data-val="true" 
                                                    data-val-required="from" 
                                                    autocomplete="from" aria-required="true" 
                                                    aria-invalid="false" aria-describedby="from"
                                                    value={this.state.from}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <label for="From" class="col-form-label">To</label>
                                            <div className="col">
                                                <input 
                                                    id="to" name="to" type="text" 
                                                    className="form-control valid" 
                                                    data-val="true" 
                                                    data-val-required="Please enter the ProductID" 
                                                    autocomplete="to" aria-required="true" 
                                                    aria-invalid="false" aria-describedby="to"
                                                    value={this.state.to}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="col">
                                                <button type="button" onClick={()=>this.printProductFromTo()} className="btn btn-danger">Export Qr</button>
                                            </div>
                                        </div>
                                    </NavLink> */}
                                    {
                                        this.state.printed.length>0 &&
                                        <NavLink className="float-right" style={{marginLeft:40}}>
                                            <button type="button" onClick={()=>this.printProduct()} className="btn btn-danger">Export Qr</button>
                                        </NavLink>
                                    }
                                </div>
                                <div className="card-body">
                                    <DataTable
                                        title="List City QR Location"
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        // selectableRows 
                                        hover
                                        // entries={5}
                                        paginationPerPage={this.state.paginationPerPage}
                                        paginationRowsPerPageOptions={[50, 100, 200, 300]}
                                        paginationTotalRows={this.state.paginationTotalRows}
                                        pagination={true}
                                        onSelectedRowsChange={value =>this.handleChange(value)}
                                        paginationServer={true}
                                        onChangePage={data => this.changePage(data)}
                                        onChangeRowsPerPage={data => this.changeRowsPerPage(data)}
                                        progressPending={this.state.loadingData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.isModalDeleteOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Are you sure?</ModalHeader>
                    <ModalFooter>
                        <Button color="info" onClick={this.deleteProduct}>Delete</Button>{' '}
                        <Button color="danger" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.isModalCertificateOpen} toggle={this.toggleModalCertificate}>
                    <ModalHeader toggle={this.toggleModalCertificate}>
                        {this.state.modalType == "certificate" ? "Certificate" : "QR Code"}
                    </ModalHeader>
                    <ModalBody style={{textAlign: "center"}}>
                        <p hidden={this.state.openedCertificateImg != ""}>No certificate found</p>
                        <img src={this.state.openedCertificateImg} ></img>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.toggleModalCertificate}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default ListProduct;