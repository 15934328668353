import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
  Button,
  Spinner,
  Badge,
  InputGroup,
  Input,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import ReportedForumDetail from './ReportedForumDetail';
import ReportedReasonDetail from './ReportedReasonDetail';
import ConfirmModal from '../../components/notificationModal/ConfirmModal';
import ReportedReasonEdit from './ReportedReasonEdit';
import ReportedReasonAdd from './ReportedReasonAdd';

export default class ReportedReasonIndex extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_REPORTED_FORUM,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          sortable: true,
          maxWidth: '5%'
        },
        {
          name: 'Reason',
          selector: 'reason',
          sortable: true
        },
        {
          name: 'Status',
          sortable: true,
          cell: data =>
            <label>
              {data.status === 1 ?
                <Badge
                  color="success"
                  pill
                >
                  Active
                </Badge>
                :
                <Badge
                  color="danger"
                  pill
                >
                  Deleted
                </Badge>
              }
            </label>
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: data =>
            <>
              <div>
                <button type='button' className="text-dark btn btn-sm btn-outline-link" onClick={() => this.showDetail(data.id)} ><li className="fa fa-eye"></li></button>
              </div>
              {
                this.state.scope.includes('read') && data.reason !== 'Lainnya' ?
                  <button className="text-primary btn btn-sm btn-outline-link" onClick={() => this.showEdit(data.id)}><li className="fa fa-edit"></li></button>
                  : null
              }
              {
                this.state.scope.includes('read') && data.reason !== 'Lainnya' ?
                  <button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(data.id)}><li className="fa fa-trash"></li></button>
                  : null
              }
            </>
        }
      ],
      loaderPage: true,
      data: [],
      selectedId: {},
      detailData: {},
      detailDataLoading: false,
      showTable: false,
      showModalConfirm: false,
      showModalDetail: false,
      showModalEdit: false,
      showModalAdd: false,
      search: '',
      status: '',
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.setState({
      loaderPage: false
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      }, () => {
        this.getList();
      })

      // alert(JSON.stringify(scope))
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    const search = this.state.search;
    console.log(search);
    API.get('forum/reason-report?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      // + '&status=' + this.state.status
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    this.setState({
      search: e.target.value
    }, () => {
      this.getList()
    })
  }

  onfilter(status) {
    this.setState({
      status: status
    }, () => {
      this.getList()
    })
  }

  getDetailData(reasonReportId) {
    API.get('forum/reason-report/' + reasonReportId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          detailData: result.data,
          detailDataLoading: false
        })

      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  showDetail(reasonReportId) {
    this.setState({
      detailDataLoading: true,
      showModalDetail: true
    })

    this.getDetailData(reasonReportId)
  }

  showEdit = (reasonReportId) => {
    this.setState({
      showModalEdit: !this.state.showModalEdit,
      selectedId: reasonReportId
    });
    this.getDetailData(reasonReportId)
  }

  showAdd() {
    this.setState({
      showModalAdd: true
    })
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModalConfirm: !this.state.showModalConfirm,
      selectedId: id,
    });
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">
                      <Link to="/forum/reported">
                        <button
                          color="info"
                          size="sm"
                          className="btn btn-outline-link"
                        >
                          <i className='fa fa-arrow-left'></i>
                        </button>
                      </Link>
                      <span className='align-middle'>
                        Reason Report
                      </span>
                    </strong>
                    {
                      this.state.scope.includes('read') ?
                        <div className="float-right">
                          <Button
                            color="info"
                            size="sm"
                            onClick={() => this.showAdd()}
                          >
                            Add Reason Report
                          </Button>
                        </div>
                        : null
                    }
                  </div>
                  <div className="card-body">
                    <InputGroup>
                      <Input
                        placeholder='Search by Reason'
                        onChange={this.onChange}
                      />
                    </InputGroup>
                    <div style={{ maxWidth: window.innerWidth - 83 }}>
                      {
                        // !this.state.showTable ? <>Loading ...</> :
                        <DataTable
                          noHeader={true}
                          columns={this.state.columns}
                          data={this.state.data}
                          pagination={true}
                          paginationServer={true}
                          paginationPerPage={10}
                          paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                          paginationTotalRows={this.state.paginationTotalRows}
                          onChangePage={page => this.changePage(page)}
                          onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                        />
                      }
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReportedReasonDetail
            open={this.state.showModalDetail}
            close={() => this.setState({ showModalDetail: false })}
            data={this.state.detailData}
            dataLoading={this.state.detailDataLoading}
            refresh={() => this.getList()}
          />
          <ReportedReasonEdit
            open={this.state.showModalEdit}
            close={() => this.setState({ showModalEdit: false })}
            data={this.state.detailData}
            dataLoading={this.state.detailDataLoading}
            refresh={() => this.getList()}
          />
          <ReportedReasonAdd
            open={this.state.showModalAdd}
            close={() => this.setState({ showModalAdd: false })}
            refresh={() => this.getList()}
          />
          <ConfirmModal
            open={this.state.showModalConfirm}
            close={() => this.showConfirmDelete()}
            afterSubmit={() => this.getList()}
            id={this.state.selectedId}
            url={`forum/reason-report/${this.state.selectedId}`}
          />
        </div>

    )
  }
}