import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from 'src/api'
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import {
  CustomInput,
  NavLink,
  Button,
  Modal, ModalHeader, ModalFooter,
  Alert,
} from 'reactstrap';

class ListManufacturer extends Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.deleteManufacturer = this.deleteManufacturer.bind(this);

    this.state = {
      menuCode: constants.MENU_LIST_MANUFACTURER,
      flag_show: 'all',
      scope: [],
      columns: [
        {
          name: 'Id',
          selector: 'manufacturer_id',
          sortable: true,
          maxWidth: '30px'
        },
        {
          name: 'Name',
          selector: 'name',
          sortable: true
        },
        {
          name: 'Logo',
          selector: 'logo',
          sortable: true,
          cell: manufacturer => <img src={API.defaults.baseURL === 'http://localhost:3030/v2' ? manufacturer.logo.replace('https', 'http') : manufacturer.logo} alt={manufacturer.name} style={{ maxWidth: '100px' }} />,
        },
        {
          name: 'Website Links',
          selector: 'website_links',
          sortable: true,
          cell: manufacturer => <a href={manufacturer.website_links} target="_blank">{manufacturer.website_links}</a>,
        },
        {
          name: 'Email Notification',
          selector: 'action',
          sortable: false,
          maxWidth: '60px',
          cell: manufacturer =>
            <div>
              <div class="form-check form-switch">
                <input class="form-check-input form-switch" type="checkbox" value={manufacturer.flag_suspiciousemail} checked={manufacturer.flag_suspiciousemail === 1} role="switch" id="flexSwitchCheckDefault" onClick={this.updateManufacturerSusEmailFlag.bind(this, manufacturer.manufacturer_id, manufacturer.flag_suspiciousemail === 0 ? true : false)} />
              </div>
            </div>
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: manufacturer =>
            <div>
              <button type="button" className="btn btn-sm btn-outline-link" onClick={this.linkDetail.bind(this, manufacturer.manufacturer_id)}>
                <li className="fa fa-eye"></li>
              </button>&nbsp;
              {
                this.state.scope.includes('update') ?
                  <button type="button" className="btn btn-sm btn-outline-link text-info" onClick={this.linkEdit.bind(this, manufacturer.manufacturer_id)}>
                    <li className="fa fa-pencil"></li>
                  </button>
                  : null
              }&nbsp;
              {

                this.state.scope.includes('delete') ?
                  <button type="button" className="btn btn-sm btn-outline-link text-danger" onClick={this.toggle.bind(this, manufacturer.manufacturer_id)}>
                    <li className="fa fa-trash"></li>
                  </button>
                  : null
              }
            </div>
        },
      ],
      attributeValues: [{ name: 'SKU', value: "sku" }, { name: 'Production Date', value: "production_date" }, { name: 'Warranty', value: "warranty" }],
      data: [],
      isModalDeleteOpen: false,
      alertSuccess: false,
      alertFlagSuccess: false,
      alertError: false,
      deleteManufacturer: "",
      search: "",
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
    };
  }

  async componentDidMount() {
    await this.checkAccess();
    this.getListManufacturer();
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }
    this.props.history.push(`/unauthorized`);
  }

  getListManufacturer() {
    console.log('this.state.flag_show ', this.state.flag_show)
    API.get('manufacturer?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      + '&flag_show=' + this.state.flag_show
      + '&type=guest'
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        console.log("List Manufacturer", res.data);
        this.setState({
          data: res.data.item,
          paginationTotalRows: res.data.totalItem
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  linkEdit(id) {
    this.props.history.push("/manufacturer/edit/" + id + "?type=guest");
  }

  linkDetail(id) {
    this.props.history.push("/manufacturer/" + id);
  }

  toggle(id) {
    // console.log("Id delete", typeof id)
    this.setState({
      isModalDeleteOpen: !this.state.isModalDeleteOpen,
    })

    if (typeof id == "number") {
      this.setState({
        deleteManufacturer: id
      })
    }
  }

  updateManufacturerSusEmailFlag(data, id) {
    API.put('manufacturer/suspiciousemail/' + data + '/' + id, this.state.body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        // console.log("manufacturer deleted");
        this.getListManufacturer();
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  deleteManufacturer() {
    API.delete('manufacturer/' + this.state.deleteManufacturer, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        console.log("manufacturer deleted");
        this.getListManufacturer();
        this.toggle();
        this.toggleAlertSuccess();
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  toggleAlertSuccess() {
    this.setState({
      alertSuccess: !this.state.alertSuccess
    })

    setTimeout(() => {
      this.setState({
        alertSuccess: !this.state.alertSuccess
      })
    }, 2000)
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getListManufacturer();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getListManufacturer();
    })
  }

  searchManufacturer(e) {
    if (e.target.id === 'flag_show') {
      this.state.flag_show = e.target.value
      this.setState({ flag_show: e.target.value })
      setTimeout(() => {
        this.getListManufacturer();
      }, 100);
    } else {
      e.preventDefault();
      this.getListManufacturer();
    }
  }


  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <Alert
                color="success"
                isOpen={this.state.alertSuccess}
              >
                Manufacturer deleted successfully
              </Alert>
              <Alert
                color="success"
                isOpen={this.state.alertFlagSuccess}
                className='page-alert'
              >
                Manufacturer/certificate config saved successfully
              </Alert>
              <div className="card">
                {
                  this.state.scope.includes('read') ?
                    <div className="card-header vertical-middle">
                      <strong className="card-title vertical-middle-p">Data Manufacturer Guest</strong>
                      <div className=''>
                        <div className='row'>
                          <div className='col-md-6'>
                            <form style={{ display: 'initial' }} onSubmit={(e) => { this.searchManufacturer(e) }}>
                              <input onChange={(e) => { this.setState({ search: e.target.value }) }} value={this.state.search} className='form-control offset-md-4 offset-7' placeholder='Search by Manufacturer Name' style={{ maxWidth: 250, display: 'inline-flex' }} />
                            </form>
                          </div>
                          <div className='col-md-4 d-flex align-items-center gap-2'>
                            <div className='w-100 text-right'>Show Brand</div>
                            <select name="flag_show" id="flag_show" class="form-control" onChange={(e) => { this.searchManufacturer(e) }}>

                              <option value="all"> All </option>
                              <option value="1"> Show </option>
                              <option value="0"> Hide </option>
                            </select>
                          </div>
                          <div className='col-md-2 d-flex align-items-center '>
                            {
                              this.state.scope.includes('read') ?
                                <NavLink href={"/manufacturer/add?type=guest"} className="float-right">
                                  <button type="button" className="btn btn-sm btn-info">Add Manufacturer</button>
                                </NavLink>
                                : null
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    : null
                }



                <div className="card-body">
                  <DataTable
                    title="List Manufacturer"
                    columns={this.state.columns}
                    data={this.state.data}
                    pagination={true}
                    paginationServer={true}
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    paginationTotalRows={this.state.paginationTotalRows}
                    onChangePage={page => this.changePage(page)}
                    onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.isModalDeleteOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Are you sure?</ModalHeader>
          <ModalFooter>
            <Button color="info" onClick={this.deleteManufacturer}>Delete</Button>{' '}
            <Button color="danger" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default ListManufacturer;