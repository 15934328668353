import React, { Component } from 'react';
import API from '../../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NotifToast from '../../../components/notificationModal/NotifToast';

const init_body = {
  point: '',
  max_point: '',
  status: 1,
}

export default class detail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      type: this.props.match.params.type,
      body: init_body,
      error: {},
      optionGroup: [],
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    let config = 'scn/config/point/referral';
    if (this.state.type === 'referrer') {
      config = 'scn/config/point/referrer';
    }
    API.get(config+`/${this.state.id}`,{headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          body: {
            point: result.data.point,
            max_count: result.data.max_count,
            status: result.data.status,
          },
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  onChange = (e) => {

  }


  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Detail Config</strong>
                  {/* <NavLink href="/user/add" > */}
                  <div className="float-right">
                    <Link to="/scn/config/referral">
                      <button
                        color="info"
                        size="sm"
                        className="btn btn-outline-link"
                      >
                        <i className='fa fa-arrow-left'></i>
                      </button>
                    </Link>

                  </div>
                  {/* </NavLink> */}
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Bonus Point
                      </Label>
                      <Input
                        id="point"
                        name="point"
                        disabled={true}
                        type="text"
                        onChange={this.onChange}
                        value={this.state.body.point}
                        invalid={this.state.error['point'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['point']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Max Mission Work
                      </Label>
                      <Input
                        id="max_count"
                        name="max_count"
                        disabled={true}
                        type="text"
                        onChange={this.onChange}
                        value={this.state.body.point}
                        invalid={this.state.error['max_count'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['max_count']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label >
                        Status
                      </Label>
                      <Input
                        id="status"
                        name="status"
                        type="select"
                        disabled={true}
                        onChange={this.onChange}
                        value={this.state.body.status}
                      >
                        <option value={''}>Choose</option>
                        <option value={'1'}>ACTIVE</option>
                        <option value={'0'}>NON-ACTIVE</option>
                      </Input>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}