import React, { Component } from 'react';
import API from '../api'

export default class UserDetailToken extends Component{
    constructor(props){
        super(props);
        this.state = {
            username:''
        }
    }

    componentDidMount(){
        var usernameArr = [];
        API.get('/user/getUserDetailById/'+this.props.userId, {
            Authorization : 'Bearer '+localStorage.getItem('token')
        })
        .then(response=>{
            if(response){
                // alert(JSON.stringify(response.data[0]))
                this.setState({
                    username: response.data[0].username,
                    fullname: response.data[0].fullname
                })
            }
        })
    }

    render(){
        return (<span>{this.props.showUsername ? this.state.username : this.props.showFullname ? this.state.fullname : '-'}</span>)
    }
}