import React, { Component, useState } from 'react';
import API from '../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NotifToast from '../../components/notificationModal/NotifToast';

const init_body = {
  role_id_reff: null,
  name: '',
  group_name: '',
}

class addRole extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      body: init_body,
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      query: new URLSearchParams(this.props.location.search),
      role_name_referrence: null
    }
  }

  componentDidMount() {
    const newBody = this.state.body;
    newBody['group_name'] = this.state.query.get('group');

    if (this.state.query.get('role_id_reff')) {
      this.loadRefference();
      newBody['role_id_reff'] = this.state.query.get('role_id_reff');
    }

    this.setState({
      body: newBody
    });
  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '');
    const result = filtered.length ? true : false

    this.setState({
      disabled: result,
    });

    return result
  }

  onSubmit() {
    this.setState({
      // buttonLoading: true,
      notif: { show: true, title: 'Processing ...' }
    });

    // alert(JSON.stringify(this.state.body)); return;
    API.post('role', this.state.body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          body: {},
          notif: {
            title: 'Success',
            message: result.message,
          }
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false }
          });
          if(result.data?.insertId){
            this.props.history.push(`/role/edit/${result.data.insertId}`);
          }
        }, 1500);
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
          }
        });
      })
  }

  loadRefference() {
    API.get('role/' + this.state.query.get('role_id_reff'), { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          role_name_referrence: result.data.name
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className='d-flex justify-content-center'>
              <div className="col-xl-5 px-0">
                <div className="card">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <strong className="card-title mb-0">{this.state.query.get('role_id_reff') ? 'Duplicate' : 'Add'} Roles</strong>
                    {/* <NavLink href="/user/add" > */}
                    <div className="float-right">
                      <Link to="/role">
                        <button
                          color="info"
                          size="sm"
                          className="btn btn-outline-link"
                        >
                          <i className='fa fa-arrow-left'></i>
                        </button>
                      </Link>

                    </div>
                    {/* </NavLink> */}
                  </div>
                  <div className="card-body">
                    <Form>
                      <FormGroup>
                        <Label for="exampleEmail">
                          Role Name Refference
                        </Label>
                        <Input
                          type="text"
                          value={this.state.role_name_referrence}
                          disabled
                        />
                        <FormFeedback >{this.state.error['name']}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="exampleEmail">
                          Name
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          placeholder="Enter Name"
                          type="text"
                          onChange={this.onChange}
                          required
                          invalid={this.state.error['name'] ? true : false}
                        />
                        <FormFeedback >{this.state.error['name']}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label >
                          Group Name
                        </Label>
                        <Input
                          id="group_name"
                          name="group_name"
                          type="select"
                          onChange={this.onChange}
                          invalid={this.state.error['group_name'] ? true : false}
                          value={this.state.body.group_name}
                        >
                          <option value={''}>Choose</option>
                          <option value={'dashboard_app'}>Dashboard App</option>
                          <option value={'dashboard_partner'}>Dashboard Partner</option>
                        </Input>
                        <FormFeedback >{this.state.error['group_name']}</FormFeedback>
                      </FormGroup>
                      <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                        {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NotifToast
            show={this.state.notif.show}
            close={() => this.setState({ notif: { show: false } })}
            title={this.state.notif.title}
            message={this.state.notif.message}
          />
        </div>
      </div>
    )
  }
}

export default addRole;