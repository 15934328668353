import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../../api'
import {
  Button,
  Spinner,
  Badge,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import constants from '../../../lib/constants';
import { _hasAccess } from '../../../middleware/accessMiddleware'
import ConfirmModal from '../../../components/notificationModal/ConfirmModal';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_SCN_CONFIG_POINT,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          sortable: true
        },
        {
          name: 'Bonus Point',
          selector: 'point',
          sortable: true
        },
        {
          name: 'Max Mission Work',
          selector: 'max_count',
          sortable: true
        },
        {
          name: 'Status',
          selector: 'status',
          sortable: false,
          cell: data =>
            <div>
              {data.status ?
                <Badge
                  color="success"
                  pill
                >
                  Active
                </Badge>
                :
                <Badge
                  color="danger"
                  pill
                >
                  Non-Active
                </Badge>
              }
            </div>
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: data =>
            <div>
              <Link to={`/scn/config/referral/${data.id}/${data.flag}`}>
                <button className="text-dark btn btn-sm btn-outline-link" ><li className="fa fa-eye"></li></button>
              </Link>
              {
                this.state.scope.includes('update') ?
                  <Link to={`/scn/config/referral/edit/${data.id}/${data.flag}`}>
                    <button className="text-primary btn btn-sm btn-outline-link" ><li className="fa fa-edit"></li></button>
                  </Link>
                  : null
              }
              {/* {
                this.state.scope.includes('delete') ?
                  <button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(data.id)}><li className="fa fa-trash"></li></button>
                  : null
              } */}
            </div>
        }
      ],
      loaderPage: true,
      data: [],
      editData: {},
      id: null,
      showTable: false,
      showModal: false,
      showModalEdit: false,
      disableButtonModal: false,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 5,
      paginationTotalRows: 0,
      paginationPage: 1,
      group: 'table_referral',
    };
    // this.modalUser = this.modalUser.bind(this);
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
    this.setState({
      loaderPage: false
    })
  }


  changeType(type) {
    this.setState({
      group: type,
    }, () => {
      this.getList();
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    console.log(access)
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    let config = 'scn/config/point/referral';
    if (this.state.group === 'table_referrer') {
      config = 'scn/config/point/referrer';
    }
    API.get(config+'?size=' 
      + this.state.paginationPerPage 
      + '&page=' + this.state.paginationPage
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changeEditData(body) {
    this.setState({ editData: body });
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModal: !this.state.showModal,
      id: id,
    });
  }

  afterSubmit(status, message) {
    this.getList()

    this.setState({
      alert: {
        show: true,
        status: status,
        message: message
      }
    });

    setTimeout(() => {
      this.setState({
        alert: {
          show: false,
          status: '',
          message: ''
        }
      });
    }, 3000)
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-header card-tabs">
                    <strong className="card-title">Manage Config Bonus Point</strong>
                    <div className="float-right">
                      {
                        this.state.scope.includes('create') ?
                          <Link to="/scn/config/referral/add">
                            <Button
                              color="info"
                              size="sm"
                            >
                              Add Config
                            </Button>
                          </Link>
                          : null
                      }
                    </div>
                    <Nav tabs className='mt-3'>
                      <NavItem>
                        <NavLink
                          active={this.state.group === 'table_referral' ? true : false}
                          className='fs-13'
                          onClick={() => this.changeType('table_referral')}
                        >
                          Referral
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          active={this.state.group === 'table_referrer' ? true : false}
                          className='fs-13'
                          onClick={() => this.changeType('table_referrer')}
                        >
                          Referrer
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <div className="card-body">
                    {
                      // !this.state.showTable ? <>Loading ...</> :
                      <DataTable
                        noHeader={true}
                        columns={this.state.columns}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            open={this.state.showModal}
            close={() => this.showConfirmDelete()}
            id={this.state.id}
            url={`role/menu/${this.state.id}`}
          />
        </div>
    )
  }
}

export default index;