import React, { Component } from 'react';
import API from '../../api';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import moment from 'moment-timezone';
import {
    Modal,
    ModalFooter,
    ModalHeader,
    ModalBody
}
    from 'reactstrap';
import PreviewImage from 'src/components/elements/image/PreviewImage';

export default class SendNotif extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuCode: constants.MENU_LIST_DONE_QUEUE_JOBS,
            scope: [],
            offset: 0,
            queueList: [],
            certList: [],
            hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            minutes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59],
            selectedHour: null,
            selectedMinute: null,
            selectedDate: null,
            searchQr: null,
            searchSku: null,
            isFilterByDate: false
        }
    }

    async handleChange(event) {
        // console.log(event.target.name)
        // this.setState({ [event.target.name]: event.target.value });
    }

    async checkSplittedRange(range) {
        var qrCodeParse = range.split("-")
        var idFrom = qrCodeParse[0];
        var idTo = qrCodeParse[1];
        // alert(idFrom)
        // alert(idTo)
        // alert(API.defaults.baseURL)
        let certUrl = await API.get('/product/queue/' + range, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        if (certUrl.data) {
            this.setState({
                openCheckCert: true,
                certList: certUrl.data,
                checkCertURL: certUrl.data[0].certificate
            })
        }
        // alert(API.defaults.baseURL+''+certUrl.data[0].certificate)
    }
    async resetData() {
        this.checkAccess();
        let queueList = await API.get('/getQueueListComplete/' + this.state.offset, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        // alert(JSON.stringify(queueList.data))
        if (queueList.data.length > 0) {
            this.setState({
                queueList: queueList.data
            })
        }
    }
    async componentDidMount() {
        this.checkAccess();
        let queueList = await API.get('/getQueueListComplete/' + this.state.offset, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        // alert(JSON.stringify(queueList.data))
        if (queueList.data.length > 0) {
            this.setState({
                queueList: queueList.data
            })
        }
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    async increaseOffset() {
        this.state.offset = this.state.offset + 10;
        let queueList;
        if (this.state.isFilterByDate) {
            queueList = await API.get('/getQueueListCompleteByDate/' + this.state.selectedDate + ' ' + this.state.selectedHour + ':' + this.state.selectedMinute + '/' + this.state.offset, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        } else {
            queueList = await API.get('/getQueueListComplete/' + this.state.offset, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        }
        // alert(JSON.stringify(queueList.data))
        if (queueList.data.length > 0) {
            this.setState({
                queueList: queueList.data
            })
        } else {
            alert('This is the last data')
        }
        // alert(this.state.offset);
    }

    async decreaseOffset() {
        if (this.state.offset > 0) {
            this.state.offset = this.state.offset - 10;
            let queueList;
            if (this.state.isFilterByDate) {
                queueList = await API.get('/getQueueListCompleteByDate/' + this.state.selectedDate + ' ' + this.state.selectedHour + ':' + this.state.selectedMinute + '/' + this.state.offset, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            } else {
                queueList = await API.get('/getQueueListComplete/' + this.state.offset, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            }
            // alert(JSON.stringify(queueList.data))
            if (queueList.data.length > 0) {
                this.setState({
                    queueList: queueList.data
                })
            } else {
                alert('This is the last data')
            }
            // alert(this.state.offset);
        }
    }

    async submitDate() {
        console.log('ini state', this.state)
        if (this.state.selectedDate && this.state.selectedHour && this.state.selectedMinute) {
            // alert(this.state.selectedHour.length)
            if (this.state.selectedMinute.length === 1) {
                this.state.selectedMinute = '0' + this.state.selectedMinute
            }
            if (this.state.selectedHour.length === 1) {
                this.state.selectedHour = '0' + this.state.selectedHour
            }
            this.state.offset = 0;
            let result = await API.get('/getQueueListCompleteByDate/' + this.state.selectedDate + ' ' + this.state.selectedHour + ':' + this.state.selectedMinute + '/' + this.state.offset, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            // alert(JSON.stringify(result.data))
            this.setState({
                queueList: result.data,
                isFilterByDate: true
            })
            // console.log(this.state.selectedDate+' '+this.state.selectedHour+':'+this.state.selectedMinute)
        } else if (!this.state.selectedDate && !this.state.selectedHour && !this.state.selectedMinute) {
            if (this.state.searchQr || this.state.searchSku) {
                if (this.state.searchQr === null || this.state.searchQr === '') {
                    this.state.searchQr = '0';
                }
                if (this.state.searchSku === null || this.state.searchSku ==='') {
                    this.state.searchSku = '0';
                }
                this.state.offset = 0;
                let result = await API.get('/getQueueListCompleteByDate/0/' + this.state.offset+'/' + this.state.searchSku+'/' + this.state.searchQr, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
                // alert(JSON.stringify(result.data))
                this.setState({
                    queueList: result.data,
                    isFilterByDate: true
                })
            } else {
                alert('Please fill all date sku or qr code fields')
            }
        }
        else {
            alert('Please fill all date time fields')
        }
    }

    render() {
        return (
            <div className='container-fluid'>
                <PreviewImage show={this.state.openCheckCert} image={this.state.checkCertURL} onClose={() => this.setState({ openCheckCert: false })} />
                <div className='content'>
                    <div className='card'>
                        <div className='card-header'>
                            <strong>List Done Queue Jobs</strong>
                        </div>
                        <div className='card-body'>
                            <div className='col-md-3 pull-right form-group inline-flex'>
                                <input className='form-control' type="date" onChange={(e) => this.setState({ selectedDate: e.target.value })} />
                                <button onClick={() => { this.submitDate() }} className='btn btn-info ml-2'>Submit</button>&nbsp;
                                <button onClick={() => { this.resetData() }} className='btn btn-danger ml-2'>Reset</button>
                            </div>
                            <div className='col-fl-10 ml-2 pull-right form-group'>
                                <div class='dropdown'>
                                    {
                                        this.state.selectedMinute
                                            ?
                                            <button class="btn btn-secondary dropdown-toggle selected" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {this.state.selectedMinute}m
                                            </button>
                                            :
                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Select Minutes
                                            </button>
                                    }
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {
                                            this.state.minutes.map((item, index) => {
                                                return (
                                                    <a onClick={() => this.setState({ selectedMinute: item.toString() })} class="dropdown-item" href="#">{item}m</a>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-fl-15 pull-right form-group'>
                                <div class="dropdown">
                                    {
                                        this.state.selectedHour
                                            ?
                                            <button class="btn btn-secondary dropdown-toggle selected" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {this.state.selectedHour}H
                                            </button>
                                            :
                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Select Hours
                                            </button>
                                    }
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {
                                            this.state.hours.map((item, index) => {
                                                return (
                                                    <a onClick={() => this.setState({ selectedHour: item.toString() })} class="dropdown-item" href="#">{item}H</a>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-fl-15 pull-right form-group'>&nbsp;</div>
                            <div className='col-fl-15 pull-right form-group'>
                               <button className='btn btn-success'>Search</button>
                            </div> */}
                            <div className='col-fl-15 pull-right form-group'>&nbsp;</div>
                            <div className='col-fl-15 pull-right form-group'>
                                <input
                                    id="searchSku" name="searchSku" type="text"
                                    className="form-control valid"
                                    data-val="true"
                                    data-val-required="searchSku"
                                    autocomplete="searchSku" aria-required="true"
                                    aria-invalid="false" aria-describedby="searchSku"
                                    placeholder="Search by SKU..."
                                    value={this.state.searchSku}
                                    onChange={(e) => this.setState({ searchSku: e.target.value })}
                                // style={{ textTransform: 'uppercase' }}
                                />
                            </div>
                            <div className='col-fl-15 pull-right form-group'>&nbsp;</div>
                            <div className='col-fl-15 pull-right form-group'>
                                <input
                                    id="searchQr" name="searchQr" type="text"
                                    className="form-control valid"
                                    data-val="true"
                                    data-val-required="searchQr"
                                    autocomplete="searchQr" aria-required="true"
                                    aria-invalid="false" aria-describedby="searchQr"
                                    placeholder="Search by QRCode..."
                                    value={this.state.searchQr}
                                    onChange={(e) => this.setState({ searchQr: e.target.value })}
                                // style={{ textTransform: 'uppercase' }}
                                />
                            </div>
                            <table class="table fixed-height-list-queue-table">
                                <thead>
                                    <tr>
                                        <th scope="col">Job UID</th>
                                        <th scope="col">QR ID Range</th>
                                        <th scope="col">Manufacturer</th>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">SKU</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Created At</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.queueList.length > 0
                                            ?
                                            this.state.queueList.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{item.jobs_uid}</td>
                                                        <td>{item.qrid_range}</td>
                                                        <td>{item.name ? item.name : '-'}</td>
                                                        <td>{item.product_name ? item.product_name : '-'}</td>
                                                        <td>{item.sku ? item.sku : '-'}</td>
                                                        <td>{item.status}</td>
                                                        <td>{moment(item.created_at).format('MMMM D YYYY HH:mm:ss')}</td>
                                                        <td>{item.username ? item.username : '-'}</td>
                                                        <td><button className='btn btn-info' onClick={() => { this.checkSplittedRange(item.jobs_uid) }}>View Cert</button></td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            null
                                    }
                                </tbody>
                            </table>
                            <div className='pull-right'>
                                <button className='btn btn-info mr-2' onClick={() => this.decreaseOffset()}>Prev</button>
                                <button className='btn btn-info' onClick={() => this.increaseOffset()}>Next</button>
                            </div>
                        </div>
                        {/* <div className='card-footer'>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}