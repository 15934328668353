import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner
} from 'reactstrap';
import API from "../../api";

const init_body = {
  username: '',
  email: '',
  password: '',
  role_id: '',
}

const required = ['username','email','role_id']

export default class DetailUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      body: init_body,
      error: {},
      disabled: true,
      buttonLoading: false,
    }
  }

  componentDidMount() {

  }

  onChange = (e) => {
    const error = this.state.error;
    const name = e.target.name;
    const isRequired = required.includes(name)
    if (!e.target.value && isRequired) {
      error[e.target.name] = 'Field is Required'
    }
    else {      
      const value = e.target.value;
      if (name == 'password' || name == 'repassword') {
        const type = name == 'password' ? 1 : 2;
        const invalidPassword = this.checkPassword(value, type);
        if (invalidPassword) {
          error[e.target.name] = invalidPassword;
        }
        else {
          delete error[e.target.name]
        }
      }
      else {
        delete error[e.target.name]
      }
    }

    const newBody = this.props.body
    newBody[e.target.name] = e.target.value
    this.setState({
      body: newBody,
      error: error
    });
  }
  
  render() {
    return (
      <Modal isOpen={this.props.open} toggle={() => this.props.close()}>
        <ModalHeader toggle={() => this.props.close()}>Edit User</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">
                Username
              </Label>
              <Input
                id="username"
                name="username"
                placeholder="Enter Username"
                type="text"
                disabled={true}
                value ={this.props.body.username}
                invalid={this.state.error['username'] ? true : false}
              />
              <FormFeedback >{this.state.error['username']}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">
                Email
              </Label>
              <Input
                id="email"
                name="email"
                placeholder="Enter Email"
                type="email"
                disabled={true}
                value ={this.props.body.email ?? ''}
                invalid={this.state.error['email'] ? true : false}
              />
              <FormFeedback >{this.state.error['username']}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label >
                Role Akses
              </Label>
              <Input
                id="role"
                name="role_id"
                type="select"
                disabled={true}
                onChange={this.onChange}
                value ={this.props.body.role_id}
                invalid={this.state.error['role_id'] ? true : false}
              >
                <option value={''}>Choose</option>
                {
                  this.props.roleOption.map( item => {
                    return <option value={item.id}>{item.name}</option>
                  })
                }
              </Input>
              <FormFeedback >{this.state.error['username']}</FormFeedback>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => this.props.close()}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
}