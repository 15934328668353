import React, { Component, useRef } from 'react';

import API from '../../api';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import * as htmlToImage from 'html-to-image';
import {
    CustomInput,
    Alert,
    Modal,
    ModalFooter,
    ModalHeader,
    ModalBody,
    Button
} from 'reactstrap';
import LoadingBar from 'react-top-loading-bar'
import imageCompression from 'browser-image-compression';
import { times } from 'lodash';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const { convert } = require("any-to-any");

export default class GenerateQRCode extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.testParseQrCodeId = this.testParseQrCodeId.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.getManufacturerOption = this.getManufacturerOption.bind(this);
        this.handleUploadChange = this.handleUploadChange.bind(this);
        this.downloadImageFromHTMLSource = this.downloadImageFromHTMLSource.bind(this);
        this.dataURLtoFile = this.dataURLtoFile.bind(this)


        this.state = {
            menuCode: constants.MENU_IMPORT_CERTIFICATE,
            scope: [],
            fileChangeDetected: false,
            useCustomCert: false,
            productCode: "",
            username: "",
            manufacturerId: "",
            manufacturerName: "",
            manufacturerFlag: "",
            madeIn: "",
            productionDate: "",
            expiredDate: "",
            manufacturer: [],
            variant: [],
            variantPointer: "",
            variantId: "",
            variantIdMulti: [],
            emptyVariant: "",
            variantIdPointer: "",
            newVariant: "",
            variantList: [],
            variantChangeIndex: 0,
            productCodes: [],
            variantInputIndexes: [],
            productCodePointer: "",
            productCodeId: "",
            productCodeIdInt: "",
            emptyProductCode: "",
            productCodeIdPointer: "",
            skuLevelPointer: null,
            newProductCode: "",
            disable: true,
            openModalVariant: false,
            openModalProductCode: false,
            openModalConfirmation: false,
            openModalConfirmationProductCode: false,
            qrcode: "",
            alertError: false,
            message: "",
            certFileLabel: "Choose File",
            certFile: "",
            formError: {},
            countRangeQrcode: 0,
            disabledButton: false,
            css: `
                @font-face {
                    font-family: GothamBook;
                    src: url('https://api.shieldtag.co/GothamBook.ttf');
                }
                #domEl p {
                    font-family: GothamBook;
                    color: #0c0c0c;
                    width: 100%;
                    position: absolute;
                    // left: 42.25%;
                    // bottom: 20.65%;
                    left: 400px;
                    bottom: 275.5px;
                    font-size: 22.5px;
                }
                @media screen 
                    and (min-device-width: 1200px) 
                    and (max-device-width: 1600px) 
                    and (-webkit-min-device-pixel-ratio: 2)
                    and (min-resolution: 192dpi) { 
                        #domEl p {
                            bottom: 267.5px;
                        }
                }
                @media screen and (max-width: 767px){
                    #domEl p {
                        bottom: 275px;
                    }
                    @supports (-webkit-touch-callout: none) {
                        #domEl p {
                            bottom: 268.5px;
                        } 
                    }
                }
                #root {
                    overflow-x: hidden;
                    overflow-y: hidden;
                }
            `
        }
    }

    componentDidMount() {
        this.checkAccess();
        this.getManufacturer();
        var loginData = localStorage.getItem('loginData');
        var parseLoginData = JSON.parse(loginData)
        if (parseLoginData.data.username) {
            this.setState({
                username: parseLoginData.data.username
            })
        }
        // alert(parseLoginData.data.username)
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'create');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    async downloadImageFromHTMLSource() {
        this.setState({
            disable: true,
            // fileChangeDetected: true,
            certFileLabel: 'Processing... Please wait...',
            useCustomCert: true
        })
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        const dataUrl = await htmlToImage.toPng(document.getElementById('domEl'), { pixelRatio: 1, quality: 0.5 });
        // download image
        // const link = document.createElement('a');
        // link.download = "html-to-img.png";
        let img = document.createElement('img');
        img.src = dataUrl;

        const image = await new Promise((resolve) => {
            img.onload = () => {
                htmlToImage.toPng(document.getElementById('domEl'), { pixelRatio: 1, quality: 0.5 }).then((urlData) => {
                    resolve(urlData)
                })
            }
        })
        // link.href = dataUrl;
        var file = this.dataURLtoFile(image, 'test.png');
        // var file = imageCompression.getFilefromDataUrl(dataUrl, 'testt.png')
        // alert(file)
        var compressedFile = await imageCompression(file, options)
        const fileCompressed = new File(
            [compressedFile],
            'compressed-cert-' + new Date().getTime() + '.png',
            {
                type: compressedFile.type,
                lastModified: new Date().getTime()
            }
        )
        this.setState({
            certFileLabel: 'test-' + new Date().getTime() + '.png',
            certFile: fileCompressed,
            disable: false,
            // fileChangeDetected: false
            // certFileLabel: 'Done'
        });
        // alert(fileCompressed)
    }

    async getManufacturer() {
        let manufacturer = await API.get('manufacturer?size=20&page=1', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        let manufacturerOptions = await API.get('manufacturer/options', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })

        this.setState({
            // manufacturer: manufacturer.data.item,
            manufacturer: manufacturerOptions.data,
            // manufacturerId: manufacturerOptions.data[0].manufacturer_id,
            manufacturerName: manufacturerOptions.data[0].name,
            manufacturerFlag: manufacturerOptions.data[0].flag_productvariant,
            variantInputIndexes: [{ value: '', id: 0 }]
        })

        if (this.state.manufacturerId) {
            // let manufacturerVariant = await API.get('product/getVariant/'+this.state.manufacturerId)
            let manufacturerProductCode = await API.get('product/getProductCodes/' + this.state.manufacturerId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            // if(this.state.manufacturerId === 49){
            //     this.downloadImageFromHTMLSource();
            // }
            // alert(JSON.stringify(manufacturerProductCode.data.result))
            if (
                // (manufacturerVariant.data.result.length > 0 && manufacturerVariant.data.result[0].variant) && 
                (manufacturerProductCode.data.result.length > 0 && manufacturerProductCode.data.result[0].code))
                this.setState({
                    productCodes: manufacturerProductCode.data.result,
                    productCodeId: manufacturerProductCode.data.result[0].code,
                    // variant: manufacturerVariant.data.result,
                    // variantId: manufacturerVariant.data.result[0].variant,                
                })

            // if((manufacturerVariant.data.result.length > 0 && manufacturerVariant.data.result[0].variant)){
            //     this.setState({
            //         variant: manufacturerVariant.data.result,
            //         variantId: manufacturerVariant.data.result[0].variant,
            //     })
            //     // setTimeout(()=>{
            //     //     alert(this.state.variantId)
            //     // })
            // } else
            if (manufacturerProductCode.data.result.length > 0 && manufacturerProductCode.data.result[0].code) {
                this.setState({
                    productCodes: manufacturerProductCode.data.result,
                    productCodeId: manufacturerProductCode.data.result[0].code
                })
                // setTimeout(()=>{
                //     alert(this.state.productCodeId)
                // })
            } else {
                this.setState({
                    productCodes: manufacturerProductCode.data.result,
                    // variant: manufacturerVariant.data.result
                })
                // setTimeout(()=>{
                //     alert(JSON.stringify(manufacturerProductCode.data.result[0]))
                //     // alert(manufacturerProductCode.data.result)
                // })
            }
            // alert(this.state.manufacturerId)
        }
    }

    getManufacturerOption() {
        let items = [<option key={0} value={''} >Choose Manufacture</option>];
        let manufacturer = this.state.manufacturer;
        // console.log("List manufacturer", manufacturer)

        for (let i = 0; i < manufacturer.length; i++) {
            items.push(<option key={manufacturer[i].manufacturer_id} value={manufacturer[i].manufacturer_id} data-name={manufacturer[i].name} data-flag={manufacturer[i].flag_productvariant}>{manufacturer[i].name}</option>);
        }
        return items;
    }

    getVariant(index) {
        let items = [];
        let variant = this.state.variant[index];
        const indexList = index;
        let variantIndex = this.state.variantInputIndexes.filter((item, index) => index === indexList)
        let selected = false;
        if (variant == undefined) {
            items.push(<option label="Empty list" disabled selected />)
        }
        else if (!variant.length) {
            items.push(<option label="Empty list" disabled selected />)
        }
        else {
            items.push(<option key={0} value={''}>Choose Option</option>)
            for (let i = 0; i < variant.length; i++) {
                selected = variantIndex.length ? (variantIndex[0].value === variant[i].variant ? true : false) : false

                items.push(<option key={variant[i].id} data-id={variant[i].id} value={variant[i].variant} selected={selected}>{variant[i].variant}</option>);
            }
        }

        return items;
    }

    getProductCodes() {
        let items = [<option key={0} value={''}>Choose Product</option>];
        let productCodes = this.state.productCodes;
        // console.log("List product codes", productCodes)
        for (let i = 0; i < productCodes.length; i++) {
            items.push(<option key={productCodes[i].id} data-id={productCodes[i].id} value={productCodes[i].code}>{productCodes[i].code}</option>);
        }
        return items;
    }

    handleUploadChange(event) {
        console.log("Logo file: ", event.target.files[0]);
        // check if file is not image or compressedFile
        if(event.target.files[0].type !== 'application/zip' && event.target.files[0].type !== 'application/rar' && event.target.files[0].type !== 'image/jpeg' && event.target.files[0].type !== 'image/png' && event.target.files[0].type !== 'image/jpg'){
            alert('This file is not an image or zip file. Please select another image or zip file')
            this.setState({
                fileChangeDetected: true
            })
            setTimeout(() => {
                this.setState({
                    fileChangeDetected: false,
                    logoFileLabel: null,
                    logoFile: null
                });
            }, 5)
        }else{
            if (event.target.files[0].size > 5120000) {
                alert('This certificate file is too big. Please select another certificate file')
                this.setState({
                    fileChangeDetected: true
                })
                setTimeout(() => {
                    this.setState({
                        fileChangeDetected: false,
                        certFileLabel: null,
                        certFile: null
                    });
                }, 5)
            } else {
                if (event.target.files[0] && event.target.files[0].name && event.target.files[0].size <= 5120000) {
                    this.setState({
                        certFileLabel: event.target.files[0].name,
                        certFile: event.target.files[0]
                    });
                    // setTimeout(()=>{
                    //     alert(JSON.stringify(this.state.certFile))
                    // }, 1000)
                } else {
                    this.setState({
                        certFileLabel: null,
                        certFile: null
                    });
                }
            }

        }
    }

    async handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
        const formError = this.state.formError;

        if (event.target.name === 'manufacturerId' && event.target.value != '') {

            var index = event.target.selectedIndex;
            var optionElement = event.target.childNodes[index]
            var option = optionElement.getAttribute('data-name');
            this.setState({ manufacturerName: option })

            var optionFlag = optionElement.getAttribute('data-flag');
            this.setState({ manufacturerFlag: optionFlag })

            console.log(option);
            console.log(optionFlag);
            const manId = event.target.value;

            if (manId) {
                setTimeout(() => {
                    if (parseInt(this.state.manufacturerId) > 0) {
                        console.log('ini manufacturer masuk sini')
                        // this.downloadImageFromHTMLSource();
                        this.setState({
                            fileChangeDetected: true,
                            useCustomCert: true,
                        })
                        setTimeout(() => {
                            this.setState({
                                fileChangeDetected: false,
                                certFile: null,
                                certFileLabel: null
                            })
                        }, 500)
                    } else {
                        this.setState({
                            useCustomCert: false
                        })
                    }
                }, 5)

                // setTimeout(async () => {
                // let manufacturerVariant = await API.get('product/getVariant/'+manId)
                await API.get('product/getProductCodes/' + manId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
                    .then(resp => {
                        this.setState({
                            // variant: resp.data.result,
                            // variantId: resp.data.result[0] ? manufacturerVariant.data.result[0].variant : -1,
                            productCodes: resp.data.result,
                            // productCodeId: resp.data.result[0] ? manufacturerProductCode.data.result[0].code : -1,
                        })
                    }).catch(err => console.log(err))

                // }, 5);
            }
        }
        else if (event.target.name === 'productCodeId') {
            const index = event.target.selectedIndex;
            const optionElement = event.target.childNodes[index]
            const productCodeId = optionElement.getAttribute('data-id');

            this.setState({
                [event.target.name]: event.target.value,
                productCodeIdInt: productCodeId
            });

            const manId = this.state.manufacturerId
            console.log('ini state', this.state)
            let url = 'product/getVariant/' + manId;
            console.log(this.state.manufacturerName)
            console.log(this.state.manufacturerFlag)
            if (parseInt(this.state.manufacturerFlag, 10) === 1) {
                url = 'product/getVariant/' + manId + '/' + productCodeId + '/0';
            }
            console.log(url)
            await API.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
                .then(resp => {
                    let variantNew = [];
                    variantNew.push(resp.data.result)
                    console.log(variantNew)

                    this.setState({
                        variant: variantNew,
                        variantInputIndexes: [{ value: '', id: 0 }],
                        // useCustomCert: resp.data.permanent_certificate,
                    })

                }).catch(err => console.log(err))



        }
        else if (event.target.name === 'variantId') {
            // const productId = event.target.value;
            // const manId = this.state.manufacturerId
            // await API.get('product/getVariant/'+manId+'/'+productId)
            // .then(resp => {

            // }).catch(err => console.log(err))
        } else if (event.target.name === 'qrcode' && event.target.value != '') {
            this.setState({ countRangeQrcode: 0 })

            const range = event.target.value.split('-');
            if (range.length < 2) {
                formError.qrcode = '*Range must be separated by "-"';
            } else if(/\s/.test(event.target.value)){
                formError.qrcode = '*QRCode ID contains whitespaces';
            }            
            else {
                const firstRange = range[0] !== '' ? convert(range[0], 36, 10) : 0;
                const secondRange = range[1] !== '' ? convert(range[1], 36, 10) : 0;
                const countRange = secondRange === firstRange ? 1 : secondRange - firstRange + 1;

                if (firstRange > secondRange) {
                    formError.qrcode = '*First range must be less than second range';
                } else if (countRange > 10000) {
                    formError.qrcode = '*Maximum Count Range is 10000';
                }
                else {
                    formError.qrcode ='';
                }

                this.setState({ countRangeQrcode: countRange.toLocaleString() })
            }
            if(formError.qrcode == ''){
                this.setState({disable: false})
            } else {
                this.setState({disable: true})
            }

            this.setState({ formError: formError });

            this.setState({
                [event.target.name]: event.target.value
            });
        }
        else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    }

    handleChangeDate(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    async addVariant(e) {
        e.preventDefault()
        const parentId = this.state.variantChangeIndex == 0 ? 0 : this.state.variantInputIndexes[this.state.variantChangeIndex - 1].id
        const body = {
            "manufacturedId": this.state.manufacturerId,
            "variantName": this.state.newVariant.replace(/%/g, '%25'),
            "parentId": parentId,
            "productCodeId": this.state.productCodeIdInt
        }

        API.post('product/variant', body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(async () => {
                API.get('product/getVariant/' + this.state.manufacturerId + '/' + this.state.productCodeIdInt + '/' + parentId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
                    .then(res => {
                        const variant = this.state.variant
                        variant[this.state.variantChangeIndex] = res.data.result
                        this.setState({
                            variant: variant,
                            openModalVariant: !this.state.openModalVariant,
                        })
                    }).catch(error => console.log("Error Get Variant ", error))
            })
            .catch(error => console.log("Error Add Variant", error))
    }

    async addProductCode(e) {
        e.preventDefault();
        var variant = this.state.newProductCode.replace(/%/g, '%25')
        let addProductCode = await API.get('product/addProductCode/' + this.state.manufacturerId + '/' + variant, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        if (addProductCode.data.success) {
            // alert('Success')
            let manufacturerProductCode = await API.get('product/getProductCodes/' + this.state.manufacturerId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            // alert(JSON.stringify(manufacturerVariant.data.result))
            this.setState({
                productCodes: manufacturerProductCode.data.result,
                productCodeId: manufacturerProductCode.data.result[0] ? manufacturerProductCode.data.result[0].code : this.state.productCodeId ? this.state.productCodeId : -1,
                newProductCode: ''
                // variantId: manufacturerVariant.data.result[0].id
            })
            // setTimeout(()=>{
            //     alert(this.state.productCodeId)
            // }, 100)
        }
    }

    async editVariant(id, e) {
        e.preventDefault();
        const body = {
            "variant": this.state.variantPointer.replace(/%/g, '%25'),
        }

        await API.post('product/editVariant/' + id, body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/json', } })
            .then(async (resp) => {
                if (resp.data.success) {
                    const variant = this.state.variant;
                    console.log(variant, this.state.variantChangeIndex, this.state.variantChangeIndex - 1)
                    const parentId = this.state.variantChangeIndex == 0 ? 0 : this.state.variantInputIndexes[this.state.variantChangeIndex - 1].id
                    await API.get('product/getVariant/' + this.state.manufacturerId + '/' + this.state.productCodeIdInt + '/' + parentId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
                        .then(resp => {
                            variant[this.state.variantChangeIndex] = resp.data.result;
                            this.setState({
                                variant: variant,
                                openModalEdit: !this.state.openModalEdit,
                                variantPointer: ''
                            })
                        }).catch(error => console.log("Error Get Variant ", error))


                }
            })
            .catch(error => console.log("Error Add Variant", error))
    }

    async editProductCode(id, e) {
        e.preventDefault();
        var variant = this.state.productCodePointer.replace(/%/g, '%25')
        let editVariant = await API.get('product/editProductCode/' + id + '/' + variant, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        // alert(JSON.stringify(editVariant.data.success))
        if (editVariant.data.success) {
            // alert('Success')
            let manufacturerProductCode = await API.get('product/getProductCodes/' + this.state.manufacturerId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            // alert(JSON.stringify(manufacturerVariant.data.result))
            this.setState({
                productCodes: manufacturerProductCode.data.result,
                productCodeId: manufacturerProductCode.data.result[0] && !this.state.productCodeId ? manufacturerProductCode.data.result[0].code : this.state.productCodeId ? this.state.productCodeId : -1,
                openModalEditProductCode: !this.state.openModalEditProductCode,
                productCodePointer: ''
            })
        }
    }

    async deleteVariant(id) {
        let deleteVariant = await API.get('product/deleteVariant/' + id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        if (deleteVariant.data.success) {
            // alert('Success')
            let manufacturerVariant = await API.get('product/getVariant/' + this.state.manufacturerId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            // alert(JSON.stringify(manufacturerVariant.data.result))
            this.setState({
                variant: manufacturerVariant.data.result,
                variantId: manufacturerVariant.data.result[0] ? manufacturerVariant.data.result[0].variant : -1,
                openModalConfirmation: !this.state.openModalConfirmation,
                variantIdPointer: ''
            })
            // if(parseInt(this.state.manufacturerId)===49){
            //     this.downloadImageFromHTMLSource();
            // }
        }
    }

    async deleteProductCode(id) {
        let deleteVariant = await API.get('product/deleteProductCode/' + id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        if (deleteVariant.data.success) {
            // alert('Success')
            let manufacturerProductCode = await API.get('product/getProductCodes/' + this.state.manufacturerId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            // alert(JSON.stringify(manufacturerVariant.data.result))
            this.setState({
                productCodes: manufacturerProductCode.data.result,
                productCodeId: manufacturerProductCode.data.result[0] && !this.state.productCodeId ? manufacturerProductCode.data.result[0].code : this.state.productCodeId ? this.state.productCodeId : -1,
                openModalConfirmationProductCode: !this.state.openModalConfirmationProductCode,
                productCodeIdPointer: ''
            })
        }
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    testParseQrCodeId(event) {
        var qrCodeParse = this.state.qrcode.split("-")
        var idFrom = convert(qrCodeParse[0], 36, 10);
        var idTo = convert(qrCodeParse[1], 36, 10);
        if (idFrom > idTo) {
            alert('Error')
        } else {
            alert('Correct')
        }
    }

    async handleSubmit(event) {
        console.log(this.state.useCustomCert);
        const MySwal = withReactContent(Swal)
        const from = this.state.qrcode.split("-")[0];
        const to = this.state.qrcode.split("-")[1];
        if (this.state.useCustomCert) {
            console.log('masuk sini sih');
            console.log(this.state.qrcode);
            if (this.state.qrcode) {
                var qrCodeParse = this.state.qrcode.replace(/ +/g, "").split("-")
                var idFrom = convert(qrCodeParse[0], 36, 10);
                var idTo = convert(qrCodeParse[1], 36, 10);
                // alert(idFrom+' idFrom')
                // alert(idTo+' idTo')
                if (parseInt(idFrom) > parseInt(idTo)) {
                    alert('id from must be smaller than id to')
                } else {
                    // alert('Correct')
                    this.setState({ disable: true });
                    this.LoadingBar.continuousStart();
                    console.log("submit", this.state.logoFile);

                    let body = new FormData();
                    body.append("manufacturerId", this.state.manufacturerId);
                    body.append("productId", this.state.productCodeIdInt);
                    body.append("madeIn", this.state.madeIn);
                    body.append("code", this.state.productCodeId);
                    // body.append("timeout", 300000); //5 minutes

                    // let body = {
                    //     manufacturerId: this.state.manufacturerId,
                    //     madeIn: this.state.madeIn,
                    //     code: this.state.productCode,
                    // }

                    if (this.state.productionDate != "") {
                        body.append("productionDate", this.state.productionDate);
                        // body.productionDate = this.state.productionDate
                    }

                    if (this.state.variantId != "") {
                        body.append("variant", this.state.variantId);
                        // alert(this.state.variantId)
                        // body.productionDate = this.state.productionDate
                    } else {
                        // alert(this.state.emptyVariant)
                        body.append("variant", "");
                    }

                    if (this.state.expiredDate != "") {
                        body.append("expiredDate", this.state.expiredDate);
                        body.expiredDate = this.state.expiredDate
                    }

                    if (this.state.qrcode != "") {
                        body.append("qrCodeId", this.state.qrcode);
                        // body.qrCodeId = this.state.qrcode
                    }

                    if (this.state.certFile != "") {
                        body.append("certFile", this.state.certFile);
                    }

                    if (this.state.username != "") {
                        body.append("username", this.state.username);
                    }

                    console.log("Param product", this.state);
                    // alert(JSON.stringify(body))
                    if (!/\s/.test(this.state.qrcode)) {
                        alert('Added to queue...')
                        this.LoadingBar.complete();
                        this.setState({
                            disable: false
                        })
                        await API.get('product/checkdata/search?from=' + from + '&to=' + to, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                            }
                        }
                        ).then(async (resp) => {
                            console.log('ini resdata ',resp.data)
                            if (resp.data.data.length > 0) {
                                MySwal.fire({
                                    title: <strong>Warning!!</strong>,
                                    html: <i>Product Already Exist = {resp.data.data.map((list) => (
                                        <p>
                                          {list.product_code} - {list.batch_number}
                                        </p>
                                      ))}</i>,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    cancelButtonText: 'Cancel',
                                    confirmButtonText: 'Continue',
                                    showLoaderOnConfirm: true,
                                    preConfirm: async () => {
                                        return await API.post('productQueueMultiple', body, {
                                            headers: {
                                                "Content-Type": "multipart/form-data",
                                                Authorization: `Bearer ${localStorage.getItem('token')}`
                                            }
                                        }
                                        );
                                    },
                                    allowOutsideClick: () => !Swal.isLoading()
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        setTimeout(() => {
                                           alert("success");
                                        }, 500);
                                    }
                                })

                            } else {
                                await API.post('productQueueMultiple', body, {
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                        Authorization: `Bearer ${localStorage.getItem('token')}`
                                    }
                                }
                                );
                            }
                        })
                        // API.post('product', body)
                        // .then(response => {
                        //     console.log("Sukses create Manufacturer");
                        //     if (response.data.error){
                        //         this.LoadingBar.complete()
                        //         this.setState({
                        //             disable: false,
                        //             alertError: true,
                        //             message: response.data.message
                        //         })
                        //     } else {
                        //         // alert(JSON.stringify(response))
                        //         this.LoadingBar.complete()
                        //         this.setState({disable:false})
                        //         this.props.history.push("/product/");
                        //         // alert(JSON.stringify(response.data))
                        //         // var thisVar = this;
                        //         // setTimeout(
                        //         //     function(){
                        //         //         thisVar.props.history.push("/product/");
                        //         //     }, 5000
                        //         // )
                        //         // setTimeout(
                        //         //     function(){
                        //         //         alert('Succesfully added/replaced products. Please wait about some minutes until all products showed again')
                        //         //     }, 1000
                        //         // )
                        //         // setTimeout(
                        //         //     function(){
                        //         //         // window.location.reload();
                        //         //         thisVar.props.history.push("/product/");
                        //         //     }, 1000
                        //         // )
                        //     }
                        //     // setTimeout(() => {
                        //     //     this.LoadingBar.complete()
                        //     //     this.setState({disable:false})
                        //     //      this.props.history.push("/product/");
                        //     //   }, 2500);
                        // })
                        // .catch(error => {
                        //     console.log("Error create Manufacturer",error);
                        // });
                    } else {
                        alert('QRCode ID contains whitespaces')
                        this.setState({ disable: false })
                        this.LoadingBar.complete()
                    }
                }
            } else {
                alert('Please fill all required fields')
            }
        } else {
            if (this.state.qrcode && this.state.certFile && this.state.certFileLabel) {
                var qrCodeParse = this.state.qrcode.replace(/ +/g, "").split("-")
                var idFrom = convert(qrCodeParse[0], 36, 10);
                var idTo = convert(qrCodeParse[1], 36, 10);
                // alert(idFrom+' idFrom')
                // alert(idTo+' idTo')
                if (parseInt(idFrom) > parseInt(idTo)) {
                    alert('id from must be smaller than id to')
                } else {
                    // alert('Correct')
                    this.setState({ disable: true });
                    this.LoadingBar.continuousStart();
                    console.log("submit", this.state.logoFile);

                    let body = new FormData();
                    body.append("manufacturerId", this.state.manufacturerId);
                    body.append("madeIn", this.state.madeIn);
                    body.append("code", this.state.productCodeId);
                    // body.append("timeout", 300000); //5 minutes

                    // let body = {
                    //     manufacturerId: this.state.manufacturerId,
                    //     madeIn: this.state.madeIn,
                    //     code: this.state.productCode,
                    // }

                    if (this.state.productionDate != "") {
                        body.append("productionDate", this.state.productionDate);
                        // body.productionDate = this.state.productionDate
                    }

                    if (this.state.variantId != "" && this.state.variantId != '-1') {
                        body.append("variant", this.state.variantId);
                        // alert(this.state.variantId)
                        // body.productionDate = this.state.productionDate
                    } else {
                        // alert(this.state.emptyVariant)
                        body.append("variant", "");
                    }

                    if (this.state.expiredDate != "") {
                        body.append("expiredDate", this.state.expiredDate);
                        body.expiredDate = this.state.expiredDate
                    }

                    if (this.state.qrcode != "") {
                        body.append("qrCodeId", this.state.qrcode);
                        // body.qrCodeId = this.state.qrcode
                    }

                    if (this.state.certFile != "") {
                        body.append("certFile", this.state.certFile);
                    }

                    if (this.state.username != "") {
                        body.append("username", this.state.username);
                    }

                    console.log("Param product", this.state);
                    // alert(JSON.stringify(body))
                    if (!/\s/.test(this.state.qrcode)) {
                        alert('Added to queue...')
                        this.LoadingBar.complete();
                        this.setState({
                            disable: false
                        })

                        await API.get('product/checkdata/search?from=' + from + '&to=' + to, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                            }
                        }
                        ).then(async (resp) => {
                            console.log('ini resdata ',resp.data)
                            if (resp.data.data.length > 0) {
                                MySwal.fire({
                                    title: <strong>Warning!!</strong>,
                                    html: <i>Product Already Exist = {resp.data.data.map((list) => (
                                        <p>
                                          {list.product_code} - {list.batch_number}
                                        </p>
                                      ))}</i>,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    cancelButtonText: 'Cancel',
                                    confirmButtonText: 'Continue',
                                    showLoaderOnConfirm: true,
                                    preConfirm: async () => {
                                        return await API.post('productQueueMultiple', body, {
                                            headers: {
                                                "Content-Type": "multipart/form-data",
                                                Authorization: `Bearer ${localStorage.getItem('token')}`
                                            }
                                        }
                                        );
                                    },
                                    allowOutsideClick: () => !Swal.isLoading()
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        setTimeout(() => {
                                           alert("success");
                                        }, 500);
                                    }
                                })

                            } else {
                                await API.post('productQueueMultiple', body, {
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                        Authorization: `Bearer ${localStorage.getItem('token')}`
                                    }
                                }
                                );
                            }
                        })
                        // API.post('product', body)
                        // .then(response => {
                        //     console.log("Sukses create Manufacturer");
                        //     if (response.data.error){
                        //         this.LoadingBar.complete()
                        //         this.setState({
                        //             disable: false,
                        //             alertError: true,
                        //             message: response.data.message
                        //         })
                        //     } else {
                        //         // alert(JSON.stringify(response))
                        //         this.LoadingBar.complete()
                        //         this.setState({disable:false})
                        //         this.props.history.push("/product/");
                        //         // alert(JSON.stringify(response.data))
                        //         // var thisVar = this;
                        //         // setTimeout(
                        //         //     function(){
                        //         //         thisVar.props.history.push("/product/");
                        //         //     }, 5000
                        //         // )
                        //         // setTimeout(
                        //         //     function(){
                        //         //         alert('Succesfully added/replaced products. Please wait about some minutes until all products showed again')
                        //         //     }, 1000
                        //         // )
                        //         // setTimeout(
                        //         //     function(){
                        //         //         // window.location.reload();
                        //         //         thisVar.props.history.push("/product/");
                        //         //     }, 1000
                        //         // )
                        //     }
                        //     // setTimeout(() => {
                        //     //     this.LoadingBar.complete()
                        //     //     this.setState({disable:false})
                        //     //      this.props.history.push("/product/");
                        //     //   }, 2500);
                        // })
                        // .catch(error => {
                        //     console.log("Error create Manufacturer",error);
                        // });
                    } else {
                        alert('QRCode ID contains whitespaces')
                        this.setState({ disable: false })
                        this.LoadingBar.complete()
                    }
                }
            } else {
                alert('Please fill all required fields')
            }
        }
    }

    async searchQueueByQrIdRange(val, e) {
        e.preventDefault();
        var splittedQrId = val.split(',')
        var splittedArr = [];
        for (var i = 0; i < splittedQrId.length; i++) {
            splittedArr.push(splittedQrId[i])
        }
        this.setState({ qrIdRangeSearch: splittedArr })
        let searchResults = await API.get('/searchQueue/' + JSON.stringify(splittedArr), { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        this.setState({
            qrIdSearchResults: searchResults.data
        })
        // setTimeout(()=>{
        //     alert(JSON.stringify(this.state.qrIdRangeSearch))
        // })
    }

    async handleChangeSKU(event, index) {
        const indexOpt = event.target.selectedIndex;
        const optionElement = event.target.childNodes[indexOpt]
        const variantId = optionElement.getAttribute('data-id');

        const value = event.target.value;
        let newVariant = this.state.variantInputIndexes;
        newVariant[index].value = value
        newVariant[index].id = variantId
        // const newIndex = index+2;

        await API.get('product/getVariant/' + this.state.manufacturerId + '/' + this.state.productCodeIdInt + '/' + variantId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(resp => {
                const data = resp.data.result;
                let variantNew = this.state.variant;
                variantNew[index + 1] = data

                this.setState({
                    variant: variantNew,
                })

            }).catch(err => console.log(err))


        // this.state.variantInputIndexes.map(item => {
        //     console.log(item)
        // })

        this.setState({
            variantInputIndexes: newVariant,
            variantId: newVariant.map(u => u.value).join('-')
        });

        console.log(newVariant)
    }

    PushArrayVariant() {
        let arrLength = this.state.variantInputIndexes.length;
        let arr = this.state.variantInputIndexes;
        if (this.state.variantInputIndexes[arrLength - 1].value == '') {
            alert('Please Select SKU Before')
        } else {
            arr.push({ value: '' })
            this.setState({
                variantInputIndexes: arr
            })
        }
    }

    RemoveArrayVariant(index) {
        // var array = [...this.state.variantInputIndexes];
        // var index = array.indexOf(index)
        const newIndex = this.state.variantInputIndexes.splice(index, 99);
        console.log(this.state.variantInputIndexes)

        this.setState({
            variantInputIndexes: this.state.variantInputIndexes
        })
    }

    changeVariant(index) {
        this.setState({
            openModalVariant: !this.state.openModalVariant,
            variantList: this.state.variant ? this.state.variant[index] : [],
            variantChangeIndex: index
        })
    }

    render() {
        return (
            <div className="content">
                <style>{this.state.css}</style>
                <LoadingBar onRef={ref => (this.LoadingBar = ref)} />
                <Modal isOpen={this.state.openModalVariant}
                    toggle={() => this.setState({ openModalVariant: !this.state.openModalVariant })}
                    modalTransition={{ timeout: 500 }}>
                    <ModalHeader>
                        <h5>Change Variant/SKU Value</h5>
                    </ModalHeader>
                    <ModalBody>
                        <div className='mb-3'>
                            Variant List of <b>{this.state.productCodeId ? this.state.productCodeId : '-'}</b>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                {
                                    this.state.variantList.length > 0
                                        ?
                                        <div className='table-modal-wrapper'>
                                            <table className='table table-striped'>
                                                {
                                                    this.state.variantList.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td className='vert-middle width-80'>
                                                                    <span>{item.variant}</span>
                                                                </td>
                                                                <td className='text-right width-10'>
                                                                    {/* <button onClick={()=>this.deleteVariant(item.id)} className='btn btn-danger'>Delete</button> */}
                                                                    <button onClick={() => this.setState({ openModalEdit: !this.state.openModalEdit, variantPointer: item.variant, variantIdPointer: item.id })} className='btn btn-info'>Edit</button>
                                                                </td>
                                                                <td className='text-right'>
                                                                    {/* <button onClick={()=>this.deleteVariant(item.id)} className='btn btn-danger'>Delete</button> */}
                                                                    <button onClick={() => this.setState({ openModalConfirmation: !this.state.openModalConfirmation, variantPointer: item.variant, variantIdPointer: item.id })} className='btn btn-danger'>Delete</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <form className='row m-0 p-0'>
                                <div className='col-md-10'>
                                    {/* {this.state.newVariant} */}
                                    <div className='my-2'>
                                        <span>Add Variant List Here</span>
                                    </div>
                                    <input className="form-control valid" value={this.state.newVariant} onChange={(e) => { this.setState({ newVariant: e.target.value }) }} />
                                </div>
                                <div className='col-md-2'>
                                    <button type="submit" className='btn btn-info button-add-variant-modal mt-3' onClick={(e) => this.addVariant(e)}>Add</button>
                                </div>
                            </form>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.openModalProductCode}
                    toggle={() => this.setState({ openModalProductCode: !this.state.openModalProductCode })}
                    modalTransition={{ timeout: 500 }}>
                    <ModalHeader>
                        <h5>Change Variant/SKU Value</h5>
                    </ModalHeader>
                    <ModalBody>
                        <div className='mb-3'>
                            ProductID List of <b>{this.state.manufacturerName ? this.state.manufacturerName : '-'}</b>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                {
                                    this.state.productCodes.length > 0
                                        ?
                                        <div className='table-modal-wrapper'>
                                            <table className='table table-striped'>
                                                {
                                                    this.state.productCodes.map((item, index) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td className='vert-middle width-80'>
                                                                    <span >{item.code}</span>
                                                                </td>
                                                                <td className='text-right width-10'>
                                                                    {/* <button onClick={()=>this.deleteVariant(item.id)} className='btn btn-danger'>Delete</button> */}
                                                                    <button onClick={() => this.setState({ openModalEditProductCode: !this.state.openModalEditProductCode, productCodePointer: item.code, productCodeIdPointer: item.id })} className='btn btn-info'>Edit</button>
                                                                </td>
                                                                <td className='text-right'>
                                                                    {/* <button onClick={()=>this.deleteVariant(item.id)} className='btn btn-danger'>Delete</button> */}
                                                                    <button onClick={() => this.setState({ openModalConfirmationProductCode: !this.state.openModalConfirmationProductCode, productCodePointer: item.code, productCodeIdPointer: item.id })} className='btn btn-danger'>Delete</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <form className='row col-md-12'>
                                <div className='col-md-10'>
                                    {/* {this.state.newVariant} */}
                                    <div className='my-2'>
                                        <span>Add Product Code List Here</span>
                                    </div>
                                    <input className="form-control valid" value={this.state.newProductCode} onChange={(e) => { this.setState({ newProductCode: e.target.value }) }} />
                                </div>
                                <div className='col-md-2'>
                                    <button type="submit" className='btn btn-info button-add-variant-modal mt-3' onClick={(e) => this.addProductCode(e)}>Add</button>
                                </div>
                            </form>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.openModalConfirmation}
                    toggle={() => this.setState({ openModalConfirmation: !this.state.openModalConfirmation })}
                    modalTransition={{ timeout: 500 }}
                >
                    <ModalHeader>
                        <span>Delete Confirmation</span>
                    </ModalHeader>
                    <ModalBody>
                        <span>Are you sure you want to delete '<b>{this.state.variantPointer}</b>' variant?</span>
                    </ModalBody>
                    <ModalFooter>
                        <button className='btn btn-danger' onClick={() => this.deleteVariant(this.state.variantIdPointer)}>Delete</button>
                        <button className='btn btn-info' onClick={() => this.setState({ openModalConfirmation: !this.state.openModalConfirmation })}>Cancel</button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.openModalConfirmationProductCode}
                    toggle={() => this.setState({ openModalConfirmationProductCode: !this.state.openModalConfirmationProductCode })}
                    modalTransition={{ timeout: 500 }}
                >
                    <ModalHeader>
                        <span>Delete Confirmation</span>
                    </ModalHeader>
                    <ModalBody>
                        <span>Are you sure you want to delete '<b>{this.state.productCodePointer}</b>' variant?</span>
                    </ModalBody>
                    <ModalFooter>
                        <button className='btn btn-danger' onClick={() => this.deleteProductCode(this.state.productCodeIdPointer)}>Delete</button>
                        <button className='btn btn-info' onClick={() => this.setState({ openModalConfirmationProductCode: !this.state.openModalConfirmationProductCode })}>Cancel</button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.openModalEdit}
                    toggle={() => this.setState({ openModalEdit: !this.state.openModalEdit })}
                    modalTransition={{ timeout: 500 }}
                >
                    <ModalHeader>
                        <span>Edit Variant/SKU</span>
                    </ModalHeader>
                    <form>
                        <ModalBody>
                            <input className="form-control valid" value={this.state.variantPointer} onChange={(e) => { this.setState({ variantPointer: e.target.value }) }} />
                        </ModalBody>
                        <ModalFooter>
                            <button type="submit" className='btn btn-primary text-white' onClick={(e) => this.editVariant(this.state.variantIdPointer, e)}>Edit</button>
                            <button type="button" className='btn btn-info  text-white' onClick={() => this.setState({ openModalEdit: !this.state.openModalEdit })}>Cancel</button>
                        </ModalFooter>
                    </form>
                </Modal>
                <Modal isOpen={this.state.openModalEditProductCode}
                    toggle={() => this.setState({ openModalEditProductCode: !this.state.openModalEditProductCode })}
                    modalTransition={{ timeout: 500 }}
                >
                    <ModalHeader>
                        <span>Edit Variant/SKU</span>
                    </ModalHeader>
                    <form>
                        <ModalBody>
                            <input className="form-control valid" value={this.state.productCodePointer} onChange={(e) => { this.setState({ productCodePointer: e.target.value }) }} />
                        </ModalBody>
                        <ModalFooter>
                            <button type="submit" className='btn btn-primary text-white' onClick={(e) => this.editProductCode(this.state.productCodeIdPointer, e)}>Edit</button>
                            <button type="button" className='btn btn-info text-white' onClick={() => this.setState({ openModalEditProductCode: !this.state.openModalEditProductCode })}>Cancel</button>
                        </ModalFooter>
                    </form>
                </Modal>
                <Modal isOpen={this.state.openCheckStatusQueueModal}
                    toggle={() => this.setState({ openCheckStatusQueueModal: !this.state.openCheckStatusQueueModal, qrIdSearchResults: [] })}
                    modalTransition={{ timeout: 500 }}
                >
                    <ModalHeader>
                        <span>Check Status Queue</span>
                    </ModalHeader>
                    <ModalBody>
                        <form onSubmit={(e) => this.searchQueueByQrIdRange(this.state.rawQrIdRangeValue, e)}>
                            <div className='form-group'>
                                <label from="qrid-range">Search by QRCodeID (Base 36)</label>
                                <input onChange={(e) => { this.setState({ rawQrIdRangeValue: e.target.value }) }} name="qrid-range" className='form-control' type='text' />
                            </div>
                            <div className='form-group'>
                                <button className='btn btn-info' type="button" onClick={(e) => this.searchQueueByQrIdRange(this.state.rawQrIdRangeValue, e)}>Search Queue</button>
                            </div>
                        </form>
                        <div>
                            {
                                this.state.qrIdSearchResults && this.state.qrIdSearchResults.length > 0
                                    ?
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Job UID</th>
                                                <th scope="col">QR ID Range</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.qrIdSearchResults.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{item.jobs_uid}</td>
                                                            <td>{item.qrid_range}</td>
                                                            <td>{item.status}</td>
                                                        </tr>
                                                        // <div>
                                                        //     <span>{item.qrid_range}</span>
                                                        //     <span>{item.status}</span>
                                                        // </div>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    // <p>{JSON.stringify(this.state.qrIdSearchResults)}</p>
                                    :
                                    null
                            }
                            <p></p>
                        </div>
                    </ModalBody>
                </Modal>
                <div className="animated fadeIn">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="card-title">Add product certificate by QRcode id</strong>
                                    {/* <p>{this.state.variantId}</p> */}
                                    <Alert
                                        color="danger"
                                        isOpen={this.state.alertError}
                                    >
                                        {this.state.message}
                                    </Alert>
                                </div>
                                <div id="domEl" style={{
                                    zIndex: -1, position: 'absolute', width: 945, height: 1336,
                                    // background:'url("https://api.shieldtag.co/Certificate_Template_Kosong-01.png")' 
                                }}
                                >
                                    <img src="https://api.shieldtag.co/Certificate_Template_Kosong-01.png" />
                                    <p>{this.state.variantId}</p>
                                    {/* <img src="{sample}" width="100"/> */}
                                </div>
                                <div id="domEl2" style={{
                                    zIndex: -1, position: 'absolute', width: 945, height: 1336,
                                    // background:'url("https://api.shieldtag.co/Certificate_Template_Kosong-01.png")' 
                                }}
                                >
                                    {/* <img src="https://api.shieldtag.co/Certificate_Template_Kosong-01.png"/>
                                    <p>{this.state.variantId}</p> */}
                                    {/* <img src="{sample}" width="100"/> */}
                                </div>
                                <div className="card-body">
                                    <div className="form-group has-success">
                                        <label for="qrcode" className="control-label mb-1">QRCodeID (Base 36)</label>
                                        <div class="input-group">
                                            <input
                                                id="qrcode" name="qrcode" type="text"
                                                className="form-control valid"
                                                data-val="true"
                                                data-val-required="Please enter the ProductID"
                                                autocomplete="qrcode" aria-required="true"
                                                aria-invalid="false" aria-describedby="qrcode"
                                                value={this.state.qrcode}
                                                onChange={this.handleChange}
                                            />
                                            <span class="input-group-text">{this.state.countRangeQrcode} QR Code</span>
                                        </div>
                                        <span className="help-block text-danger field-validation-valid text-xs mt-2" style={{ fontSize: '12px' }} data-valmsg-for="productCode" data-valmsg-replace="false">{this.state.formError?.qrcode}</span>
                                    </div>
                                    {/* <div className="form-group has-success">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label for="variantId" className="control-label mb-1">Variant/SKU 1</label>
                                            </div>
                                            <div className='col-md-6 text-right'>
                                                <a href='#' onClick={() => this.setState({ openModalVariant:!this.state.openModalVariant })}>Change Variant/SKU 1 Value</a>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-10-5'>
                                                <select name="variantId" id="variantId" class="form-control" onChange={this.handleChange}>
                                                    {
                                                        this.state.variant.length > 0
                                                        ?
                                                        this.getVariant()
                                                        :
                                                        this.state.variant.length === 0 && this.state.productCodeId!=''
                                                        ?
                                                        <option label="Empty list" disabled selected/>
                                                        :
                                                        <option label="Please select the product first" disabled selected/>
                                                    }
                                                </select>
                                            </div>
                                            <div className='col-md-1-5 p-0'>
                                                <button onClick={()=>{ this.testPushArray() }} className='btn btn-primary'>
                                                    + Add More SKU
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="form-group has-success d-none">
                                        <label for="madeIn" className="control-label mb-1">Made In</label>
                                        <input
                                            id="madeIn" name="madeIn" type="text"
                                            className="form-control valid"
                                            data-val="true"
                                            data-val-required="Please enter the ProductID"
                                            autocomplete="madeIn" aria-required="true"
                                            aria-invalid="false" aria-describedby="madeIn"
                                            value={this.state.madeIn}
                                            onChange={this.handleChange}
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="madeIn" data-valmsg-replace="true"></span>
                                    </div>
                                    <div className="form-group has-success d-none">
                                        <label for="productionDate" className="control-label mb-1">Production Date</label>
                                        <input
                                            id="productionDate" name="productionDate" type="date"
                                            className="form-control valid"
                                            data-val="true"
                                            data-val-required="Please enter the ProductID"
                                            autocomplete="productionDate" aria-required="true"
                                            aria-invalid="false" aria-describedby="productionDate"
                                            value={this.state.productionDate}
                                            onChange={this.handleChangeDate}
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="productionDate" data-valmsg-replace="true"></span>
                                    </div>
                                    <div className="form-group has-success d-none">
                                        <label for="expiredDate" className="control-label mb-1">Expired Date</label>
                                        <input
                                            id="expiredDate" name="expiredDate" type="date"
                                            className="form-control valid"
                                            data-val="true"
                                            data-val-required="Please enter the ProductID"
                                            autocomplete="expiredDate" aria-required="true"
                                            aria-invalid="false" aria-describedby="expiredDate"
                                            value={this.state.expiredDate}
                                            onChange={this.handleChangeDate}
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="expiredDate" data-valmsg-replace="true"></span>
                                    </div>
                                    <div className="form-group has-success">
                                        <label for="logoFile" className="control-label mb-1">Certificate Image</label>
                                        {
                                            this.state.fileChangeDetected
                                                ?
                                                null
                                                :
                                                <CustomInput
                                                    type="file"
                                                    id="certFile"
                                                    name="certFile"
                                                    // disabled={this.state.useCustomCert}
                                                    label={this.state.certFileLabel}
                                                    onChange={e => this.handleUploadChange(e)}
                                                />
                                        }
                                        <small>* import jpg/png format for single certificate and zip file for multiple certificate</small>
                                        <span className="help-block field-validation-valid" data-valmsg-for="certFile" data-valmsg-replace="true"></span>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-1 col-fl-9'>
                                            <button disabled={this.state.disable} type="button" className="btn btn-info" onClick={this.handleSubmit}>Add To Queue</button>
                                        </div>
                                        <div className='col-md-3 col- d-none'>
                                            <button type="button" className="btn btn-info" onClick={() => this.setState({ openCheckStatusQueueModal: !this.state.openCheckStatusQueueModal })}>Check Queue Job Status</button>
                                        </div>
                                        <button type="button" className="btn btn-info d-none" onClick={() => this.downloadImageFromHTMLSource()}>Test Download Image From HTML</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}