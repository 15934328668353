import React, { Component } from 'react';
import API from '../../api'

export default class GenerateQRCode extends Component{
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            data: {}
        }
    }

    componentDidMount(){
        let id = this.props.match.params.id;
        this.getValueQRCode(id);
    }

    getValueQRCode(id){
        API.get("qrcode/"+id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
            .then(res => {
                console.log("Detail QRCode", res.data[0]);
                this.setState({
                    data: res.data[0]
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event){
        let paramQRCode = {
            value: this.state.value,
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        API.put('qrcode/'+this.state.data.id, paramQRCode)
                .then(response => {
                    console.log("Sukses generate qrcode");
                    this.props.history.push("/");
                })
                .catch(error => {
                    console.log("Error generate qrcode",error);
                });
    }

    render() {
        return(
            <div className="content">
                <div className="animated fadeIn">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="card-title">Detail QR Code</strong>
                                </div>
                                <div className="card-body">
                                    <div className="form-group has-success">
                                        <label for="qr-code-id" className="control-label mb-1">Id</label>
                                        <input 
                                            id="qr-code-id" name="qr-code-id" type="text" 
                                            className="form-control valid" 
                                            data-val="true" 
                                            data-val-required="Please enter the the value of QR Code" 
                                            autocomplete="qr-code-id" aria-required="true" 
                                            aria-invalid="false" aria-describedby="qr-code-id"
                                            value={this.state.data.id}
                                            onChange={this.handleChange}
                                            disabled
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="qr-code-id" data-valmsg-replace="true"></span>
                                    </div>
                                    <div className="form-group has-success">
                                        <label for="token" className="control-label mb-1">Token</label>
                                        <input 
                                            id="token" name="token" type="text" 
                                            className="form-control valid" 
                                            data-val="true" 
                                            data-val-required="Please enter the the value of QR Code" 
                                            autocomplete="token" aria-required="true" 
                                            aria-invalid="false" aria-describedby="token"
                                            value={this.state.data.token}
                                            onChange={this.handleChange}
                                            disabled
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="token" data-valmsg-replace="true"></span>
                                    </div>
                                    <div className="form-group has-success">
                                        <label for="value" className="control-label mb-1">QR Code</label>
                                        <div>
                                            <img src={this.state.data.qrcode} alt={this.state.data.token}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}