import React, { Component } from 'react';
import API from 'src/api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  FormText
} from 'reactstrap';
import NotifToast from 'src/components/notificationModal/NotifToast';

export default class ScanWebQuestionAdd extends Component {

  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      id: query.get('id'),
      error: {},
      disabled: true,
      loading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      optionManufactur: [],
      body: {
        manufacturer_id: query.get('id'),
        question: '',
        name: '',
        placeholder: '',
        type: '',
        is_required: 0,
      },
      required: ['manufacturer_id', 'question', 'name', 'placeholder', 'type'],
    }
  }

  componentDidMount() {
    //Load Manufactur
    API.get('manufacturer?option=true', { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          optionManufactur: resp.data
        })
      })
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is Required'
    }
    else {
      delete error[name]
    }

    if (name === 'is_required') {
      newBody[name] = e.target.checked ? 1 : 0
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    // alert(JSON.stringify(this.state.body)); return;
    this.setState({ loading: true });
    API.post('config/scanweb/question', this.state.body, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({ loading: false });
        if (resp.status) {
          const result = resp.data;
          this.setState({
            body: {},
            notif: {
              title: result.status ? 'Success' : 'Failed',
              message: result.message,
              status: result.status,
            }
          });

          setTimeout(() => {
            this.setState({
              notif: { show: false }
            });
            if (result.status) {
              this.props.history.push(`/config/scan-web?id=${this.state.id}`);
            }
          }, 1500);
        }
      });
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className='d-flex justify-content-center'>
              <div className="col-xl-5 px-0">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Add Question</strong>
                  </div>
                  <div className="card-body">
                    <Form>
                      <FormGroup>
                        <Label className='w-100'>Manufacturer</Label>
                        <Input
                          name="manufacturer_id"
                          type="select"
                          onChange={this.onChange}
                          invalid={this.state.error['manufacturer_id']}
                          value={this.state.body.manufacturer_id}
                          disabled
                        >
                          {
                            <>
                              <option value={null}>All Manufacturer</option>
                              {
                                this.state.optionManufactur.map(item => {
                                  return <option key={item.value} value={item.value}>{item.label}</option>
                                })
                              }
                            </>
                          }
                        </Input>
                        <FormFeedback >{this.state.error['manufacturer_id']}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>Question<i className='text-danger'>*</i></Label>
                        <Input
                          name="question"
                          placeholder="Enter Question"
                          type="text"
                          onChange={(e) => {
                            this.onChange(e);
                            this.setState({ body: {...this.state.body, name:e.target.value.toLowerCase()?.replace(' ','_')} });
                          }}
                          required
                          invalid={this.state.error['question']}
                        />
                        <FormFeedback >{this.state.error['question']}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>Name<i className='text-danger'>*</i></Label>
                        <Input
                          name="name"
                          placeholder="Enter Name"
                          type="text"
                          onChange={this.onChange}
                          value={this.state.body.name}
                          required
                          invalid={this.state.error['name']}
                        />
                        <FormFeedback >{this.state.error['name']}</FormFeedback>
                        <FormText>
                        Gunakan name untuk 3 pertanyaan terkait hal berikut : <br/>Nama Lengkap: nama_lengkap <br/>Nomor Handphone: nomor_handphone <br/>Email: email <br/>Selain 3 diatas, dapat diisi tanpa menggunakan nilai baku.
                        </FormText>
                      </FormGroup>
                      <FormGroup>
                        <Label>Placeholder<i className='text-danger'>*</i></Label>
                        <Input
                          name="placeholder"
                          placeholder="Enter Placeholder"
                          type="text"
                          onChange={this.onChange}
                          required
                          invalid={this.state.error['placeholder']}
                        />
                        <FormFeedback >{this.state.error['placeholder']}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label className='w-100'>Type<i className='text-danger'>*</i></Label>
                        <Input
                          name="type"
                          type="select"
                          onChange={this.onChange}
                          invalid={this.state.error['type']}
                        >
                          <option value={''}>Choose</option>
                          <option value={'text'}>Text</option>
                          <option value={'number'}>Number</option>
                        </Input>
                        <FormFeedback >{this.state.error['type']}</FormFeedback>
                      </FormGroup>
                      <FormGroup check>
                        <Input type="checkbox" name='is_required' onChange={this.onChange} />
                        <Label check>Required</Label>
                      </FormGroup>
                      <Button className='w-100 mt-4' color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                        {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
          status={this.state.notif.status}
        />
      </div>
    )
  }
}