import React, { Component } from 'react';
import API from '../../api';
import {
    CustomInput, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import { capitalize } from 'src/lib/helpers';

export default class GenerateQRCode extends Component {
    constructor(props) {
        super(props);
        this.handleUploadChange = this.handleUploadChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.query = new URLSearchParams(this.props.location.search);

        this.state = {
            menuCode: constants.MENU_ADD_MANUFACTURER,
            scope: [],
            name: "",
            logoFileLabel: "Choose File",
            logoFile: "",
            webSiteLinks: "",
            manufacturerTags: [],
            manufacturerTagSelected: [],
            tagForms: [],
            flag_show: 1,
            positions: 1,
            manufacturer_type: this.query.get("type") ?? 'partner',
            email_address: ''
        }
    }

    componentDidMount() {
        this.checkAccess();
        this.getManufacturerTag();
    }

    getManufacturerTag() {
        API.get('/manufacturer/tag', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res && res.data.status) {
                    this.setState({
                        manufacturerTags: res.data.data
                    })
                }
            })
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'create');
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    handleUploadChange(event) {
        console.log("Logo file: ", event.target.files[0]);

        this.setState({
            logoFileLabel: event.target.files[0].name,
            logoFile: event.target.files[0]
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    async handleSubmit(event) {
        let body = new FormData();
        body.append("logo", this.state.logoFile);
        body.append("name", this.state.name)
        body.append("email_address", this.state.email_address)
        body.append("flag_show", this.state.flag_show)
        body.append("positions", this.state.positions)
        body.append("website_links", this.state.webSiteLinks)
        body.append("manufacturer_tag", this.state.manufacturerTagSelected.toString().replaceAll(",", "#"))
        body.append("type", this.state.manufacturer_type)

        // console.log("Param manufacturer", body);
        // return;
        API.post('manufacturer', body, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                const type = this.query.get("type");
                if (response.status == 200) {
                    this.props.history.push("/manufacturer/" + (type ?? ''));
                }
            })
            .catch(error => {
                console.log("Error create Manufacturer", error);
            });
    }

    selectTag(index, value) {
        let tagList = this.state.manufacturerTagSelected;
        tagList[index] = value;
        this.setState({
            manufacturerTagSelected: tagList
        })
        // setTimeout(()=>{
        //     alert(JSON.stringify(this.state.manufacturerTagSelected))
        // }, 500)
    }

    addTagForms() {
        let tagForms = this.state.tagForms;
        let manufacturerTagSelected = this.state.manufacturerTagSelected;
        if (tagForms.length === 0 && manufacturerTagSelected[0] || tagForms.length > 0 && manufacturerTagSelected[tagForms.length]) {
            if (tagForms.length > 0) {
                tagForms.push(tagForms[tagForms.length - 1] + 1);
            } else {
                tagForms.push(tagForms.length + 1);
            }
            this.setState({
                tagForms: tagForms
            })
        } else {
            alert('Please select tag value first')
        }
    }

    removeTagForm(index) {
        // alert(index)
        let tagForms = this.state.tagForms;
        let manufacturerTagSelected = this.state.manufacturerTagSelected;
        delete tagForms[index - 1]
        delete manufacturerTagSelected[index]
        tagForms = tagForms.filter(elements => {
            return (elements != null && elements !== undefined && elements !== "");
        });
        manufacturerTagSelected = manufacturerTagSelected.filter(elements => {
            return (elements != null && elements !== undefined && elements !== "");
        });
        this.setState({
            tagForms: tagForms,
            manufacturerTagSelected: manufacturerTagSelected
        })
        // setTimeout(()=>{
        //     alert(JSON.stringify(this.state.tagForms))
        //     alert(JSON.stringify(this.state.manufacturerTagSelected))
        // }, 500)
    }

    toggleTagList() {
        this.getManufacturerTag();
        this.setState({
            showTagList: !this.state.showTagList,
        })
    }

    addTag() {
        let name = document.querySelector("input[name=manufacturer_tag_name]").value;
        API.post('/manufacturer/tag', JSON.stringify({ name: name, created_by: JSON.parse(localStorage.getItem('loginData')).users_id }), { headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res) {
                    // alert(JSON.stringify(res.data))
                    document.querySelector("input[name=manufacturer_tag_name]").value = '';
                    this.getManufacturerTag();
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 400) {
                    // alert(err.response.status)
                    alert(err.response.data.message)
                }
            })
    }

    editTag(id) {
        API.put('/manufacturer/tag/' + id, JSON.stringify({ name: this.state.editTagName }), { headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res) {
                    this.setState({
                        editTagId: '',
                        editTagName: ''
                    })
                    this.getManufacturerTag();
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 400) {
                    // alert(err.response.status)
                    alert(err.response.data.message)
                }
            })
    }

    editTagWithSelectedTag(id) {
        API.put('/manufacturer/tag/' + id, JSON.stringify({ name: this.state.editTagName }), { headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res) {
                    this.setState({
                        manufacturerTagSelected: [this.state.editTagName],
                        editTagId: '',
                        editTagName: ''
                    })
                    this.getManufacturerTag();
                }
            })
    }

    deleteTag(id) {
        API.delete('/manufacturer/tag/' + id, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res) {
                    this.getManufacturerTag();
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 400) {
                    // alert(err.response.status)
                    alert(err.response.data.message)
                }
            })
    }

    render() {
        return (
            <div className="content">
                <div className="animated fadeIn">
                    <div className="row">
                        <div className='d-flex justify-content-center'>
                            <div className="col-xl-6 px-0">
                                <Modal isOpen={this.state.showTagList} toggle={() => { this.toggleTagList() }} >
                                    <ModalHeader>Select Tags</ModalHeader>
                                    <ModalBody>
                                        <table className='w-100'>
                                            <tr>
                                                <th>Name</th>
                                                <th className='w-50 text-center'>Actions</th>
                                            </tr>
                                            {
                                                this.state.manufacturerTags.map((item, index) => {
                                                    return (
                                                        <tr className='mb-3' key={index}>
                                                            {
                                                                item.id === this.state.editTagId
                                                                    ?
                                                                    <td>
                                                                        <form onSubmit={(e) => { e.preventDefault(); if (item.name === this.state.manufacturerTagSelected[0]) { this.editTagWithSelectedTag(item.id); } else { this.editTag(item.id); } }}>
                                                                            <input value={this.state.editTagName} onChange={(e) => { this.setState({ editTagName: e.target.value }) }} className="form-control" />
                                                                        </form>
                                                                    </td>
                                                                    :
                                                                    <td>{item.name}</td>
                                                            }
                                                            <td className='text-center'>
                                                                <button onClick={() => { this.setState({ manufacturerTagSelected: [item.name] }); this.toggleTagList(); }} className='btn btn-success mr-1'>Select</button>
                                                                {
                                                                    item.id === this.state.editTagId
                                                                        ?
                                                                        <button onClick={() => { if (item.name === this.state.manufacturerTagSelected[0]) { this.editTagWithSelectedTag(item.id); } else { this.editTag(item.id); } }} className='btn btn-success mr-1'>Edit</button>
                                                                        :
                                                                        <button onClick={() => { this.setState({ editTagId: item.id, editTagName: item.name }) }} className='btn btn-success mr-1'>Edit</button>
                                                                }
                                                                <button onClick={() => { this.deleteTag(item.id) }} className='btn btn-success'>Delete</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </table>
                                    </ModalBody>
                                    <ModalFooter>
                                        <form style={{ position: 'relative' }} className='form-inline col-12' onSubmit={(e) => { e.preventDefault(); this.addTag(); }}>
                                            <input style={{ width: '88%' }} name="manufacturer_tag_name" className='form-control' />
                                            <button type='submit' className='btn btn-primary' style={{ position: 'absolute', right: 0 }}>Add</button>
                                        </form>
                                    </ModalFooter>
                                </Modal>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <strong className="card-title">Create Manufacturer {this.query.get("type") ? capitalize(this.query.get("type")) : ''}</strong>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group has-success">
                                                <label for="name" className="control-label mb-1">Manufacturer Name</label>
                                                <input
                                                    id="name" name="name" type="text"
                                                    className="form-control valid"
                                                    data-val="true"
                                                    data-val-required="Please enter the the value of QR Code"
                                                    autocomplete="name" aria-required="true"
                                                    aria-invalid="false" aria-describedby="name"
                                                    value={this.state.name}
                                                    onChange={this.handleChange}
                                                    placeholder='Enter Manufacturer Name'
                                                />
                                                <span className="help-block field-validation-valid" data-valmsg-for="name" data-valmsg-replace="true"></span>
                                            </div>
                                            <div className="form-group has-success">
                                                <label for="name" className="control-label mb-1">Email</label>
                                                <input
                                                    id="email_address" name="email_address" type="email"
                                                    className="form-control valid"
                                                    data-val="true"
                                                    autocomplete="email_address" aria-required="true"
                                                    aria-invalid="false" aria-describedby="email_address"
                                                    value={this.state.email_address}
                                                    onChange={this.handleChange}
                                                    placeholder='Enter Manufacturer Email'
                                                />
                                                <span className="help-block field-validation-valid" data-valmsg-for="name" data-valmsg-replace="true"></span>
                                            </div>
                                            <div className='mb-3'>
                                                <label for="name" className="control-label mb-1">Type Manufacturer</label>
                                                <div className='d-flex gap-5'>
                                                    <FormGroup check>
                                                        <Input
                                                            name="manufacturer_type"
                                                            type="radio"
                                                            value={this.state.manufacturer_type}
                                                            checked={this.state.manufacturer_type === 'guest'}
                                                            onChange={() => {
                                                                this.setState({
                                                                    manufacturer_type: 'guest'
                                                                })
                                                            }}
                                                        />
                                                        <Label check>Guest Partner</Label>
                                                    </FormGroup>
                                                    <FormGroup check>
                                                        <Input
                                                            name="manufacturer_type"
                                                            type="radio"
                                                            value={this.state.manufacturer_type}
                                                            checked={this.state.manufacturer_type === 'partner'}
                                                            onChange={() => {
                                                                this.setState({
                                                                    manufacturer_type: 'partner'
                                                                })
                                                            }}
                                                        />
                                                        <Label check>Our Partner</Label>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className={`${this.state.manufacturer_type === 'partner' ? 'show' : 'hide'}`}>
                                                <div className="form-group has-success">
                                                    <label for="webSiteLinks" className="control-label mb-1">Manufacturer Website</label>
                                                    <input
                                                        id="webSiteLinks" name="webSiteLinks" type="text"
                                                        className="form-control valid"
                                                        data-val="true"
                                                        data-val-required="Please enter the the value of Manufacturer Website"
                                                        autocomplete="webSiteLinks" aria-required="true"
                                                        aria-invalid="false" aria-describedby="webSiteLinks"
                                                        value={this.state.webSiteLinks}
                                                        onChange={this.handleChange}
                                                        placeholder='Enter Manufacturer Website'
                                                    />
                                                    <span className="help-block field-validation-valid" data-valmsg-for="name" data-valmsg-replace="true"></span>
                                                </div>
                                                <div className="form-group has-success">
                                                    <label for="manufacturer_tag" className="control-label mb-1 d-flex justify-content-between w-100">
                                                        Manufacturer Tag
                                                        <button className='btn btn-xs btn-primary' onClick={() => { this.toggleTagList() }}>
                                                            <i className='fa fa-list' /> Manage Tag
                                                        </button>
                                                    </label>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <select className='form-control' name="manufacturer_tag" onChange={(e) => { this.selectTag(0, e.target.value) }}>
                                                                <option disabled selected>Choose Tag</option>
                                                                {
                                                                    this.state.manufacturerTags.map((item, index) => {
                                                                        return (
                                                                            item.name === this.state.manufacturerTagSelected[0]
                                                                                ?
                                                                                <option key={index} selected value={item.name}>{item.name}</option>
                                                                                :
                                                                                <option key={index} value={item.name}>{item.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {
                                                            this.state.tagForms.length > 0
                                                                ?
                                                                this.state.tagForms.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <div className='col-10 mt-4' key={index}>
                                                                                <select className='form-control' value={this.state.manufacturerTagSelected[index + 1]} onChange={(e) => { this.selectTag(index + 1, e.target.value) }}>
                                                                                    <option disabled selected>Choose Tag</option>
                                                                                    {
                                                                                        this.state.manufacturerTags.map((item, index) => {
                                                                                            return (
                                                                                                <option key={index} value={item.name}>{item.name}</option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                            <div className='col-2 mt-4 text-right'>
                                                                                <button className='btn btn-danger' onClick={() => { this.removeTagForm(index + 1) }}>
                                                                                    <i className='fa fa-minus' /> Remove Tag {item}
                                                                                </button>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group has-success">
                                                    <label for="logoFile" className="control-label mb-1">Manufacturer Logo</label>
                                                    <CustomInput
                                                        type="file"
                                                        id="logoFile"
                                                        name="logoFile"
                                                        label={this.state.logoFileLabel}
                                                        onChange={e => this.handleUploadChange(e)}
                                                    />
                                                    <span className="help-block field-validation-valid" data-valmsg-for="logoFile" data-valmsg-replace="true"></span>
                                                </div>
                                                <div className='form-group  has-success'>
                                                    <label>Show Brand</label>
                                                    <div>
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input form-switch form-switch" type="checkbox" value={this.state.flag_show} checked={this.state.flag_show === 1} role="switch" id="flexSwitchCheckDefault" onClick={() => { this.setState({ flag_show: this.state.flag_show === 1 ? 0 : 1 }) }} />
                                                            <label class="label-inline">{this.state.flag_show === 1 ? 'show' : 'hide'}</label>
                                                        </div>
                                                    </div>
                                                    {/* <input className='form-control' type='number' value={this.state.statusEdit} onChange={(e)=>{ this.setState({ password:e.target.value }) }}/> */}
                                                </div>
                                                <div className="form-group has-success">
                                                    <label for="positions" className="control-label mb-1">Positions</label>
                                                    <input
                                                        id="positions" name="positions" type="number"
                                                        className="form-control valid"
                                                        data-val="true"
                                                        data-val-required="Please enter the the value"
                                                        autocomplete="positions" aria-required="true"
                                                        aria-invalid="false" aria-describedby="positions"
                                                        value={this.state.positions}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="help-block field-validation-valid" data-valmsg-for="name" data-valmsg-replace="true"></span>
                                                </div>
                                            </div>
                                            <button type="button" className="btn btn-primary w-100 mt-3" onClick={this.handleSubmit}>Save</button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}