import React, { Component } from 'react';
import moment from 'moment';

export default class Footer extends Component {
    render() {
        const backoffice_branch = process.env.REACT_APP_BACKOFFICE_GIT_BRANCH
        const backoffice_commit_hash = process.env.REACT_APP_BACKOFFICE_GIT_SHA
        const api_branch = process.env.REACT_APP_API_GIT_BRANCH
        const api_commit_hash = process.env.REACT_APP_API_GIT_SHA
        const isExist = (backoffice_branch !== undefined && backoffice_commit_hash !== undefined
            && api_branch !== undefined && api_commit_hash !== undefined) ? true : false;

        return (
            <div className="bg-white footer">
                <div className='pt-2'>
                    Copyright &copy; 2020-{moment(new Date()).format('YYYY')} Shieldtag
                </div>
                {isExist ? (
                    <div className="">
                        <div className="">
                            <br></br>
                            backoffice branch <strong>{backoffice_branch}</strong>, last commit <strong>{backoffice_commit_hash}</strong><br></br>
                            api branch <strong>{api_branch}</strong>, last commit <strong>{api_commit_hash}</strong>
                        </div>
                    </div>
                ) : ""}
            </div>
        )
    }
}