import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from 'src/api'
import {
  Spinner,
  Badge,
  InputGroup,
  Input,
  Button
} from 'reactstrap';
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { addCommas, formatDate } from 'src/lib/helpers';
import ListLeadsAdd from './_component/ListLeadsAdd';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      query: new URLSearchParams(this.props.location.search),
      menuCode: constants.MENU_LEADS_TRACKING_DATA_LEADS,
      scope: [],
      data: [],
      showTable: false,
      showModalDelete: false,
      showModalAdd: false,
      search: '',
      searchStatus: null,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      totalWaiting: 0,
      selectedDataLoading: true,
      selectedData: {},
      status: [
        { value: '0', label: 'Reject', color: 'secondary' },
        { value: '1', label: 'Approved', color: 'success' },
        { value: '2', label: 'Pending', color: 'warning' },
      ],
      filter: {
        search: '',
        status: ''
      }
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.updateFilter();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.updateFilter();
    }
  }

  updateFilter() {
    const query = new URLSearchParams(this.props.location.search);
    this.setState({
      filter: {
        ...this.state.filter,
        status: query.get('status') ?? ''
      }
    }, () => {
      this.getList();
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status === 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    const search = this.state.search;
    API.get('offering-letters?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      + '&status=' + this.state.filter.status
      + (this.state.searchStatus ? '&status=' + this.state.searchStatus : '')
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true,
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    if (e.target.value.length > 3 || e.target.value === '') {
      this.setState({
        search: e.target.value
      }, () => {
        this.getList()
      })
    }
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Offering Letter</strong>
                    <div className="float-right">
                      <button className='btn btn-sm btn-info' onClick={() => this.setState({ showModalAdd: true })}>
                        <i className='fa fa-plus-square mr-1'></i> Add Offering Letter
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className='d-flex'>
                      <InputGroup className='w-auto'>
                        <Input
                          placeholder='Cari Nomor/Hal/Kepada'
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      <Link to={'/leads-tracking/offering-letter' + `?${this.state.filter.status == 2 ? '' : 'status=2'}`}>
                        <Button
                          onClick={() => {  
                            this.setState({ filter: { ...this.state.filter, status: this.state.filter.status == 2 ? '' : 2 } }, () => { this.getList() })
                          }}
                          size='md'
                          className='ml-2'
                          outline={this.state.filter.status == 2 ? false : true}
                          color={this.state.filter.status == 2 ? 'success' : 'secondary'}
                        >
                          <i className='fa fa-refresh mr-2'></i>
                          Pending <Badge color='danger'></Badge>
                        </Button>
                      </Link>
                    </div>
                    {
                      <DataTable
                        columns={[
                          {
                            name: 'ID',
                            selector: 'id',
                            maxWidth: '5%'
                          },
                          {
                            name: 'Date',
                            cell: data => formatDate(data.tgl_surat, false)
                          },
                          {
                            name: 'Letter Number',
                            selector: 'nomor_surat',
                          },
                          {
                            name: 'Hal',
                            selector: 'hal',
                          },
                          {
                            name: 'Kepada',
                            selector: 'kepada',
                          },
                          {
                            name: 'Company Name',
                            cell: data => data.leads ? data.leads.company_name : ''
                          },
                          {
                            name: 'Brand Name',
                            cell: data => data.leads ? data.leads.brand_name : ''
                          },
                          {
                            name: 'Price',
                            cell: data => addCommas(data.leads?.price_total)
                          },
                          {
                            name: 'Status',
                            cell: data =>
                              <label>
                                <Badge
                                  color={this.state.status.filter(item => item.value == data.status)[0].color}
                                  pill
                                >
                                  {this.state.status.filter(item => item.value == data.status)[0].label}
                                </Badge>
                              </label>
                          },
                          {
                            name: 'Action',
                            selector: 'action',
                            cell: data =>
                              <>
                                <div>
                                  <Link to={`/leads-tracking/offering-letter/${data.id}`}>
                                    <button type='button' className="text-dark btn btn-sm btn-outline-link"  ><li className="fa fa-eye"></li></button>
                                  </Link>
                                  {
                                    this.state.scope.includes('update') && data.status == 2 ?
                                      <Link to={`/leads-tracking/offering-letter/edit/${data.id}`}>
                                        <button className="text-primary btn btn-sm btn-outline-link" ><li className="fa fa-edit"></li></button>
                                      </Link>
                                      : null
                                  }
                                </div>
                              </>
                          }
                        ]}
                        noHeader={true}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ListLeadsAdd
            open={this.state.showModalAdd}
            close={() => this.setState({ showModalAdd: false })}
          />
        </div>
    )
  }
}

export default index;