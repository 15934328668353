import React, { Component, useRef } from 'react';
import API from '../../api';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import * as htmlToImage from 'html-to-image';
import {
    CustomInput,
    Alert,
    Modal,
    ModalFooter,
    ModalHeader,
    ModalBody,
    Button
} from 'reactstrap';
import LoadingBar from 'react-top-loading-bar'
import imageCompression from 'browser-image-compression';
import { times } from 'lodash';
import { ThemeConsumer } from 'styled-components';
// import ManufacturerName from './components/ManufacturerName';
import moment from 'moment';
const { convert } = require("any-to-any");

export default class ListDistributor extends Component{
    constructor(props){
        super(props);

        this.state = {
            menuCode: constants.MENU_SUSPICIOUS_EMAIL,
            scope: [],
            distributors: [],
            manufacturer: [],
            pageList:1,
            items:10,
            totalPages: 1,
            manufacturerId:'',
            manufacturerIdCreate:'',
            isInternal:"false",
            manufacturerIdEdit:'',
            usernameEdit:'',
            statusEdit:'',
            onEnterPressed: false,
            modalEdit: false,
            allManufacturers: [],
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount(){
        this.checkAccess();
        this.getManufacturer();
        this.getManufacturerEdit();
        this.getInitDistributorList();
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    async handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        setTimeout(()=>{
           this.getInitDistributorList();
        }, 500)
    }

    getInitDistributorList(){
        this.setState({
            loading: true,
            distributors: []
        })
        console.log('/email/suspicious?items='+this.state.items+'&manId='+this.state.manufacturerId+'&page='+this.state.pageList+'&pagination=true')
        API.get('/email/suspicious?items='+this.state.items+'&manId='+this.state.manufacturerId+'&page='+this.state.pageList+'&pagination=true', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
        .then((res)=>{
            if(res.data.data.item.length > 0){
                this.setState({
                    distributors: res.data.data.item,
                    totalPages: res.data.data.totalPage,
                    loading: false
                })
            } else {
                this.setState({
                    distributors: res.data.data.item,
                    totalPages: res.data.data.totalPage,
                    loading: false
                })
                if(this.state.pageList > this.state.totalPages){
                    if(this.state.totalPages > 0){
                        this.setState({
                            pageList: this.state.totalPages
                        })
                        setTimeout(()=>{
                            this.getInitDistributorList();
                        }, 50)
                    }
                }
            }
        })
    }

    increaseOffset(){
        if(this.state.pageList < this.state.totalPages){
            this.setState({
                pageList: parseInt(this.state.pageList)+1,
                distributors: []
            })
            setTimeout(()=>{
                this.getInitDistributorList();
            }, 100)
        } else {
            alert('You have reached last page')
        }
    }

    decreaseOffset(){
        if(this.state.pageList > 1){
            this.setState({
                pageList: parseInt(this.state.pageList)-1,
                distributors: []
            })
            setTimeout(()=>{
                this.getInitDistributorList();
            }, 100)
        } else {
            alert('You have reached first page')
        }
    }

    async getManufacturer(type = 'partner'){
        await API.get('manufacturer/options?type='+type, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
        .then(res => {
            this.setState({
                manufacturer: res.data,
            })
        })
        .catch(err => alert(err))
        
    }

    async getManufacturerEdit(){
        await API.get('manufacturer/options', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
        .then(res => {
            this.setState({
                allManufacturers: res.data
            })
        })
        .catch(err => alert(err))
        
    }

    async getManDetails(id){
        let man = await API.get('manufacturer/'+id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }});
        let td = [<td>aaa</td>];
        return td;
    }

    getManufacturerOption(){
        let items = [
            <option key={0} value={''} selected disabled>Choose Manufacture</option>,
            <option  value="internal" data-name="internal">Internal</option>
        ];
        let manufacturer = this.state.manufacturer;
        // console.log("List manufacturer", manufacturer)
        for(let i=0; i < manufacturer.length; i++){
            if(manufacturer[i].flag_suspiciousemail){
                items.push(<option key={manufacturer[i].manufacturer_id} value={manufacturer[i].manufacturer_id} data-name={manufacturer[i].name}>{manufacturer[i].name}</option>);
            }
        }
        return items;
    }

    updateDistributor(e){
        e.preventDefault();
        API.put('distributor/'+this.state.distIdEdit,
            JSON.stringify({
                name: this.state.usernameEdit,
                manufacturer_id: this.state.manufacturerIdEdit,
                status: this.state.statusEdit,
                updated_by: 1
            }), 
            {
                headers: { 
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}` 
                }
            }
        )
        .then(res=>{
            if(res.data.status){
                alert('Success Update Distributor')
                this.setState({
                    modalEdit: !this.state.modalEdit, 
                    distIdEdit:'', 
                    manufacturerIdEdit:'', 
                    usernameEdit:'', 
                    statusEdit:''
                })
                this.getInitDistributorList();
            } else {
                alert('Failed Update Distributor')
                this.setState({
                    modalEdit: !this.state.modalEdit, 
                    distIdEdit:'', 
                    manufacturerIdEdit:'', 
                    usernameEdit:'', 
                    statusEdit:''
                })
            }
        })
    }

    setDropTimeoutEnterEvent(){
        setTimeout(()=>{ this.setState({ onEnterPressed:!this.state.onEnterPressed }) }, 100)
    }

    addEmail(){
        API.post('/email/suspicious?email='+this.state.email+ '&manId='+this.state.manufacturerIdCreate+'&isInternal='+this.state.isInternal, {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res=>{
            if(res.data.status){
                this.setState({
                    modalCreate: !this.state.modalCreate,
                    email:'',
                    manufacturerIdCreate:'',
                    isInternal:'false'
                })
                this.getInitDistributorList();
                alert('Email has been succesfully added')
            }
        }).catch(err=>{
            if(err.response){
                if(err.response.status === 400){
                    alert(err.response.data.message)
                }
            }
        })
    }

    editEmail(){
        API.put('/email/suspicious?id='+this.state.idEdit+'&email='+this.state.email+'&manId='+this.state.manufacturerIdEdit, {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res=>{
            if(res.data.status){
                this.setState({
                    modalEdit: !this.state.modalEdit,
                    email:'',
                    manufacturerIdEdit:'',
                    idEdit:'',
                    isInternal:'false'
                })
                this.getInitDistributorList();
                alert('Email has been succesfully updated')
            }
        }).catch(err=>{
            if(err.response){
                if(err.response.status === 400){
                    alert(err.response.data.message)
                }
            }
        })
    }

    deleteEmail(){
        API.delete('/email/suspicious?id='+this.state.idEdit, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res=>{
            if(res.data.status){
                this.setState({
                    modalDelete: !this.state.modalDelete,
                    idEdit:'',
                    email:'',
                    manufacturerDelete:''
                })
                this.getInitDistributorList();
                alert('Email has been succesfully deleted')
            }
        }).catch(err=>{
            if(err.response){
                if(err.response.status === 400){
                    alert(err.response.data.message)
                }
            }
        })
    }
    
    render(){
        return(
            <div className='content'>
                <Modal isOpen={this.state.modalCreate}
                    toggle={()=>this.setState({ modalCreate: !this.state.modalCreate, email:'', manufacturerIdCreate:'' })}
                    // modalTransition={{ timeout: 500 }}
                >
                    <ModalHeader>
                        <h5>Add Email Address</h5>
                    </ModalHeader>
                    <ModalBody>
                        <form onSubmit={(e)=> { e.preventDefault(); }}>
                            <div className='form-group'>
                                <label>Email Type</label>
                                <select className='form-control' onChange={(e)=>{ 
                                    this.setState({ isInternal:e.target.value }) 
                                    if(e.target.value === 'guest' || e.target.value === 'false'){
                                        const type = e.target.value === 'guest' ? 'guest' : 'partner';
                                        this.getManufacturer(type);
                                    }
                                }}>
                                    <option value="false" selected>Manufacturer</option>
                                    <option value="guest">Manufacturer Guest</option>
                                    <option value="true">Internal Shieldtag</option>
                                </select>
                            </div>
                            {
                                this.state.isInternal==='false' || this.state.isInternal==='guest' ? 
                                <div className='form-group'>
                                    <label>Select Manufacturer</label>
                                    <select className='form-control' onChange={(e)=>{ this.setState({ manufacturerIdCreate:e.target.value }) }}>
                                        <option disabled selected>Choose Manufacturer</option>
                                        {
                                            this.state.manufacturer.length > 0
                                            ?
                                            this.state.manufacturer.map((item, index)=>{
                                                return(
                                                    item.flag_suspiciousemail
                                                    ?
                                                    <option value={item.manufacturer_id}>{item.name}</option>
                                                    :
                                                    null    
                                                )
                                            })
                                            :
                                            <option>-</option>
                                        }
                                    </select>
                                </div>
                                : null
                            }                            
                            <div className='form-group'>
                                <label>Email</label>
                                <input className='form-control' type='email' value={this.state.email} onChange={(e)=>{ this.setState({ email:e.target.value }) }}/>
                            </div>
                            <button onClick={()=>{ this.addEmail() }} className='btn btn-primary' type='submit'>Add</button>
                        </form>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modalEdit}
                    toggle={()=>this.setState({ modalEdit: !this.state.modalEdit, email:'', manufacturerIdEdit:'', idEdit:'' })}
                    // modalTransition={{ timeout: 500 }}
                >
                    <ModalHeader>
                        <h5>Edit Email Address</h5>
                    </ModalHeader>
                    <ModalBody>
                        <form onSubmit={(e)=> { e.preventDefault(); }}>
                            <div className='form-group'>
                                <label>Select Manufacturer</label>
                                <select disabled className='form-control' onChange={(e)=>{ this.setState({ manufacturerIdCreate:e.target.value }) }}>
                                    <option  value="internal" data-name="internal">Internal</option>
                                    {
                                        this.state.allManufacturers.length > 0
                                        ?
                                        this.state.allManufacturers.map((item, index)=>{
                                            return(
                                                item.manufacturer_id === this.state.manufacturerIdEdit
                                                ?
                                                <option selected value={item.manufacturer_id}>{item.name}</option>
                                                :
                                                <option value={item.manufacturer_id}>{item.name}</option>
                                            )
                                        })
                                        :
                                        <option>-</option>
                                    }
                                </select>
                            </div>
                            <div className='form-group'>
                                <label>Email</label>
                                <input className='form-control' type='email' value={this.state.email} onChange={(e)=>{ this.setState({ email:e.target.value }) }}/>
                            </div>
                            <button onClick={()=>{ this.editEmail() }} className='btn btn-primary' type='submit'>Edit</button>
                        </form>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modalDelete}
                    toggle={()=>this.setState({ modalDelete: !this.state.modalDelete, idEdit:'', email:'', manufacturerDelete:'' })}
                    style={{ maxWidth:'50%' }}
                    // modalTransition={{ timeout: 500 }}
                >
                    <ModalHeader>
                        <h5>Delete Email Address</h5>
                    </ModalHeader>
                    <ModalBody>
                        <p className='color-black'>Are you sure you want to delete <b>{this.state.email}</b> email from <b>{this.state.manufacturerDelete}</b> manufacturer email suspicious list?</p>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={()=>{ this.deleteEmail() }} className='btn btn-danger mr-2' type='submit'>Delete</button>
                        <button onClick={()=>{ this.setState({ modalDelete: !this.state.modalDelete, idEdit:'', email:'', manufacturerDelete:'' }) }} className='btn btn-secondary'>Cancel</button>
                    </ModalFooter>
                </Modal>
                <div className='card'>
                    <div className='card-header vertical-middle'>
                        <strong className='vertical-middle-p'>List Email Recipients</strong>
                        {
                            this.state.scope.includes('create') ? 
                            <button onClick={()=>{ this.setState({ modalCreate:!this.state.modalCreate }) }} className='pull-right btn btn-primary'>+ Add Email</button>
                            : null
                        }                    
                    </div>
                    <div className='card-body' style={{ height: this.state.distributors.length === 10 ? 560 : null, minHeight:560 }}>
                        <div class="form-group">
                            <label for="manufacturerId" class=" form-control-label">Manufacturer</label>
                            <select name="manufacturerId" id="manufacturerId" class="form-control" onChange={this.handleChange}>                                
                                {this.getManufacturerOption()}
                            </select>
                        </div>
                        {/* <p>{this.state.pageList}</p> */}
                        {/* fixed-height-list-queue-table */}
                        <table class="table vert-middle-table">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Manufacturer</th>
                                    <th scope="col">Manufacturer Type</th>
                                    {/* <th scope="col">Status</th> */}
                                    <th scope="col">Created At</th>
                                    <th scope="col">Created By</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.distributors.length > 0
                                    ?
                                    this.state.distributors.map((item, index)=>{
                                        return(
                                            <tr>
                                                <td>{item.id ? item.id : '-'}</td>
                                                <td>{item.email ? item.email : '-'}</td>
                                                {
                                                    item.man_name
                                                    ?
                                                    <td>{item.man_name}</td>
                                                    // <ManufacturerName manId={item.manufacturer_id}/>
                                                    :
                                                    <td>-</td>
                                                }
                                                {/* <td>{item.status === 1 ? 'Active' : 'Inactive'}</td> */}
                                                <td>{item.man_type ?? '-'}</td>
                                                <td>{item.created_at ? moment(item.created_at).format('MMMM D YYYY HH:mm:ss') : '-'}</td>
                                                <td className='px-3'>{item.username ? item.username : '-'}</td>
                                                <td>
                                                    <div>
                                                        {
                                                            this.state.scope.includes('update') ? 
                                                            <button type="button" title="Edit" className="btn btn-sm btn-outline-link" onClick={()=>{ this.setState({ modalEdit:!this.state.modalEdit, manufacturerIdEdit:item.manufacturer_id, email:item.email, idEdit:item.id }) }}>
                                                                <li className="fa fa-edit"></li>
                                                            </button> 
                                                            : null
                                                        }
                                                        {
                                                            this.state.scope.includes('delete') ? 
                                                            <button type="button" title="Delete" className="btn btn-sm btn-outline-link" onClick={()=>{ this.setState({ modalDelete:!this.state.modalDelete, idEdit:item.id, email:item.email, manufacturerDelete:item.man_name }) }}>
                                                                <li className="fa fa-trash"></li>
                                                            </button>  
                                                            : null
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    !this.state.loading
                                    ?
                                    <td colSpan={7} className="text-center">
                                        <span>No Email Data Found</span>
                                    </td>
                                    :
                                    <td colSpan={7} className="text-center">
                                        <span>Loading...</span>
                                    </td>
                                }
                            </tbody>
                        </table>
                        {/* <div className='pull-right'>
                            <button className='btn btn-info mr-2' onClick={()=>this.decreaseOffset()}>Prev</button>
                            <button className='btn btn-info' onClick={()=>this.increaseOffset()}>Next</button>
                        </div> */}
                    </div>
                    <div className='card-footer'>
                        <div className='row m-0 pt-2 pagination' style={{  }}>
                            <div className='col-md-4 col-3 offset-md-4 offset-6 text-right mb-2'>
                                <div className='row'>
                                    <div className='col-md-6 vertical-middle px-0 text-center'>
                                        <p className='vertical-middle-p'>Items per page</p>
                                    </div>
                                    <div className='col-md-4'>
                                        <select className='form-control' onChange={(e)=> { this.state.items = e.target.value; this.getInitDistributorList(); }}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 col-3 text-right mb-2'>
                                <div className='row'>
                                    <div className='col-md-1 vertical-middle px-0 text-center p-relative'>
                                        <button className='btn mr-2 button-center-div' onClick={()=>{ this.decreaseOffset() }}>
                                            <i className='fa fa-chevron-left'/>
                                        </button>
                                    </div>
                                    <div className='col-md-3 vertical-middle px-0 text-center'>
                                        <p className='vertical-middle-p'>Showing</p>
                                    </div>
                                    <div className='col-md-3 col-md-3-custom'>
                                        <form onSubmit={(e)=>{ e.preventDefault(); this.setState({ onEnterPressed: true }); this.setDropTimeoutEnterEvent();  this.getInitDistributorList(); this.pageInput.blur(); }}>
                                            <input onBlur={()=> { setTimeout(()=>{ if(!this.state.onEnterPressed){ this.getInitDistributorList(); } }, 50) }} ref={(input) => { this.pageInput = input; }}  className='form-control text-center pt-1' value={this.state.pageList} onChange={(e)=>{ this.setState({ pageList: e.target.value }) }}/>
                                        </form>
                                    </div>
                                    <div className='col-md-3 vertical-middle px-0 text-center'>
                                        <p className='vertical-middle-p'>of {this.state.totalPages === 0 ? '' : this.state.totalPages} pages</p>
                                    </div>
                                    <div className='col-md-1 vertical-middle px-0 text-center p-relative'>
                                        <button className='btn ml-2 button-center-div' onClick={()=>{ this.increaseOffset() }}>
                                            <i className='fa fa-chevron-right'/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}