import moment from "moment";
import React, { Component } from "react";
import API from "../../api";
import constants from "../../lib/constants";
import { _hasAccess } from "../../middleware/accessMiddleware";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Badge } from "reactstrap";
const MySwal = withReactContent(Swal);

export default class LatestQRCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuCode: constants.MENU_LATEST_SCAN_QRCODE,
      scope: [],
      data: [],
      page: 1,
      totalPages: 0,
      startDate: moment("2021-01-01").format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      onEnterPressed: false,
      sortBy: "desc",
      items: 10,
      search: "",
      suspectedFilterMode: 0,
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getLatestData();
  }
  openDetailProduct(qrcode) {
    console.log("ini qrcode", qrcode);
    const newPageUrl = "https://partner.shieldtag.co/code/" + qrcode;
    window.open(newPageUrl, "_blank");
  }
  openDetailReason(reason) {
    MySwal.fire({
      title: <strong>Detail Reason</strong>,
      html: (
        <ol>
          {reason.split(",").map((list) => (
            <li>
              <span>{list}</span>
            </li>
          ))}
        </ol>
      ),
      // icon: 'success',
      showCancelButton: false,
      cancelButtonText: "Close",
      confirmButtonText: "Ok",
      showLoaderOnConfirm: true,
      customClass: {
        content: "text-left",
        htmlContainer: "text-left",
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, "read");
    console.log(access);
    if (access) {
      let scope = [];
      access.scope.map((item) =>
        item.status == 1 ? scope.push(...[item.name]) : null
      );

      this.setState({
        scope: scope,
      });
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  async getLatestData() {
    let token = localStorage.getItem("token");
    // alert(token);
    API.get(
      "/qrcode/latestscan/" +
        this.state.page +
        "/" +
        this.state.items +
        "/" +
        this.state.startDate +
        "/" +
        this.state.endDate +
        "/" +
        this.state.sortBy +
        "/" +
        this.state.suspectedFilterMode +
        "?search=" +
        this.state.search,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        if (res.data.status) {
          res.data.result.map((item) => {
            const color = item.color?.split(",");
            let i = 0;
            const listItems = item.label
              ?.split(",")
              .map((data, index) => (
                <Badge
                  style={{ backgroundColor: color[index], marginLeft: "5px" }}
                >
                  {data}
                </Badge>
              ));
            item.color = color;
            item.label = listItems;
          });
          console.log(JSON.stringify(res.data.result));
          if (res.data.result.length > 0) {
            if (this.state.page <= res.data.totalPages) {
              this.setState({
                data: res.data.result,
                totalPages: res.data.totalPages,
              });
            } else {
              this.state.page = 1;
              this.getLatestData();
            }
          } else {
            this.setState({
              data: res.data.result,
              totalPages: res.data.totalPages,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async goToNextPage() {
    if (this.state.page < this.state.totalPages) {
      let token = localStorage.getItem("token");
      // alert(token);
      this.state.page = parseInt(this.state.page) + 1;
      API.get(
        "/qrcode/latestscan/" +
          this.state.page +
          "/" +
          this.state.items +
          "/" +
          this.state.startDate +
          "/" +
          this.state.endDate +
          "/" +
          this.state.sortBy +
          "/" +
          this.state.suspectedFilterMode +
          "?search=" +
          this.state.search,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((res) => {
          if (res.data.status) {
            res.data.result.map((item) => {
              const color = item.color?.split(",");
              let i = 0;
              const listItems = item.label
                ?.split(",")
                .map((data, index) => (
                  <Badge
                    style={{ backgroundColor: color[index], marginLeft: "5px" }}
                  >
                    {data}
                  </Badge>
                ));
              item.color = color;
              item.label = listItems;
            });
            // alert(JSON.stringify(res.data.result))
            this.setState({
              data: res.data.result,
              totalPages: res.data.totalPages,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("You have reached last page");
    }
  }

  async goToPrevPage() {
    if (this.state.page > 1) {
      let token = localStorage.getItem("token");
      // alert(token);
      this.state.page = parseInt(this.state.page) - 1;
      API.get(
        "/qrcode/latestscan/" +
          this.state.page +
          "/" +
          this.state.items +
          "/" +
          this.state.startDate +
          "/" +
          this.state.endDate +
          "/" +
          this.state.sortBy +
          "/" +
          this.state.suspectedFilterMode +
          "?search=" +
          this.state.search,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((res) => {
          if (res.data.status) {
            res.data.result.map((item) => {
              const color = item.color?.split(",");
              let i = 0;
              const listItems = item.label
                ?.split(",")
                .map((data, index) => (
                  <Badge
                    style={{ backgroundColor: color[index], marginLeft: "5px" }}
                  >
                    {data}
                  </Badge>
                ));
              item.color = color;
              item.label = listItems;
            });
            // alert(JSON.stringify(res.data.result))
            this.setState({
              data: res.data.result,
              totalPages: res.data.totalPages,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("You have reached first page");
    }
  }

  setDropTimeoutEnterEvent() {
    setTimeout(() => {
      this.setState({ onEnterPressed: !this.state.onEnterPressed });
    }, 100);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="content">
          {/* <LoadingBar onRef={ref => (this.LoadingBar = ref)} /> */}
          <div className="card">
            <div className="card-header">
              <div className="d-flex flex-column">
                <strong className="card-title vertical-middle-p mb-4">
                  Suspected QR Code List
                </strong>
                <div className="d-flex flex-row gap-4">
                  <div className="d-flex flex-row gap-2">
                    <div className="d-flex align-items-center">
                      <label>Options</label>
                    </div>
                    <div>
                      <select
                        className="form-control valid"
                        onChange={(e) => {
                          this.setState({
                            suspectedFilterMode: e.target.value,
                          });
                          setTimeout(() => {
                            this.getLatestData();
                          }, 100);
                        }}
                      >
                        <option value={1}>Suspected</option>
                        <option value={2}>Suspicious</option>
                        <option value={3}>Checked</option>
                        <option value={0} selected>
                          Show All
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-row gap-2">
                    <div className="d-flex align-items-center">
                      <label>QRCode</label>
                    </div>
                    <div>
                      <input
                        id="search"
                        name="search"
                        type="text"
                        className="form-control valid"
                        data-val="true"
                        data-val-required="search"
                        autocomplete="search"
                        aria-required="true"
                        aria-invalid="false"
                        aria-describedby="search"
                        placeholder="Search by qrcode36"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ search: e.target.value });
                        }}
                        // style={{ textTransform: 'uppercase' }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row gap-2">
                    <div className="d-flex align-items-center">
                      <label>Start Date</label>
                    </div>
                    <div>
                      <input
                        type="date"
                        ref={(input) => {
                          this.startDateInput = input;
                        }}
                        className="form-control text-center pt-1"
                        value={this.state.startDate}
                        onChange={(e) => {
                          this.setState({ startDate: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row gap-2">
                    <div className="d-flex align-items-center">
                      <label>End Date</label>
                    </div>
                    <div>
                      <input
                        type="date"
                        ref={(input) => {
                          this.endDateInput = input;
                        }}
                        className="form-control text-center pt-1"
                        value={this.state.endDate}
                        onChange={(e) => {
                          this.setState({ endDate: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        if (this.state.sortBy === "desc") {
                          this.setState({ sortBy: "asc" });
                          setTimeout(() => {
                            this.getLatestData();
                          }, 50);
                        } else {
                          this.setState({ sortBy: "desc" });
                          setTimeout(() => {
                            this.getLatestData();
                          }, 50);
                        }
                      }}
                      title="Sort By"
                    >
                      {
                        this.state.sortBy === "desc" ? (
                          // <i className='fa fa-arrow-down'></i>
                          <p className="m-0 text-white">desc</p>
                        ) : (
                          <p className="m-0 text-white">asc</p>
                        )
                        // <i className='fa fa-arrow-up'></i>
                      }
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        this.state.page = 1;
                        setTimeout(() => {
                          this.getLatestData();
                        }, 50);
                      }}
                    >
                      {/* <i className='fa fa-filter'></i> */}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div
                class="table-responsive"
                style={{ minHeight: 420, position: "relative" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th>QRCode ID</th>
                      <th>Product Name</th>
                      <th>Flag</th>
                      <th width="8%">Reason</th>
                      <th width="20%">Label</th>
                      <th>Scan Count</th>
                      <th>Manufacturer</th>
                      <th>First Scan Date At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data && this.state.data.length > 0
                      ? this.state.data.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                {item.qrcode_id36.length === 1
                                  ? "00000" + item.qrcode_id36
                                  : item.qrcode_id36.length === 2
                                  ? "0000" + item.qrcode_id36
                                  : item.qrcode_id36.length === 3
                                  ? "000" + item.qrcode_id36
                                  : item.qrcode_id36.length === 4
                                  ? "00" + item.qrcode_id36
                                  : item.qrcode_id36.length === 5
                                  ? "0" + item.qrcode_id36
                                  : item.qrcode_id36}
                              </td>
                              <td>{item.product_code}</td>
                              <td>
                                {item.suspected !== 1 &&
                                item.under_monitoring !== 1
                                  ? "-"
                                  : ""}
                                <span className="badge badge-danger badge-pill">
                                  {item.suspected === 1 ? "Suspected" : ""}
                                </span>{" "}
                                <span className="badge badge-secondary badge-pill">
                                  {item.under_monitoring === 1
                                    ? "Suspicious"
                                    : ""}
                                </span>
                              </td>
                              <td>
                                {item.reason !== null && item.reason !== "-" ? (
                                  <button
                                    type="button"
                                    title="Detail Reason"
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={this.openDetailReason.bind(
                                      this,
                                      item.reason
                                    )}
                                  >
                                    Detail
                                  </button>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>{item.label}</td>
                              <td>{item.total_scan} times</td>
                              <td>{item.manufacturer_name}</td>
                              <td>
                                {moment(item.first_date_scan).format(
                                  "DD-MM-YYYY HH:mm"
                                )}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  title="Check Product Detail"
                                  className="btn btn-sm btn-outline-success"
                                  onClick={this.openDetailProduct.bind(
                                    this,
                                    item.qrcode_id36
                                  )}
                                >
                                  Detail
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <div className="row m-0 pt-2 pagination" style={{}}>
                <div className="col-md-4 col-3 offset-md-4 offset-6 text-right mb-2">
                  <div className="row">
                    <div className="col-md-6 vertical-middle px-0 text-center">
                      <p className="vertical-middle-p">Items per page</p>
                    </div>
                    <div className="col-md-4">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.state.items = e.target.value;
                          this.getLatestData();
                        }}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-3 text-right mb-2">
                  <div className="row">
                    <div className="col-md-1 vertical-middle px-0 text-center p-relative">
                      <button
                        className="btn mr-2 button-center-div"
                        onClick={() => {
                          this.goToPrevPage();
                        }}
                      >
                        <i className="fa fa-chevron-left" />
                      </button>
                    </div>
                    <div className="col-md-3 vertical-middle px-0 text-center">
                      <p className="vertical-middle-p">Showing</p>
                    </div>
                    <div className="col-md-3 col-md-3-custom">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.setState({ onEnterPressed: true });
                          this.setDropTimeoutEnterEvent();
                          this.getLatestData();
                          this.pageInput.blur();
                        }}
                      >
                        <input
                          onBlur={() => {
                            setTimeout(() => {
                              if (!this.state.onEnterPressed) {
                                this.getLatestData();
                              }
                            }, 50);
                          }}
                          ref={(input) => {
                            this.pageInput = input;
                          }}
                          className="form-control text-center pt-1"
                          value={this.state.page}
                          onChange={(e) => {
                            this.setState({ page: e.target.value });
                          }}
                        />
                      </form>
                    </div>
                    <div className="col-md-3 vertical-middle px-0 text-center">
                      <p className="vertical-middle-p">
                        of{" "}
                        {this.state.totalPages === 0
                          ? ""
                          : this.state.totalPages}{" "}
                        pages
                      </p>
                    </div>
                    <div className="col-md-1 vertical-middle px-0 text-center p-relative">
                      <button
                        className="btn ml-2 button-center-div"
                        onClick={() => {
                          this.goToNextPage();
                        }}
                      >
                        <i className="fa fa-chevron-right" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
