import React, { Component } from "react";
import { ChromePicker } from "react-color";
import {
  Badge,
} from 'reactstrap';



export default class ColorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      color: this.props.color
    }
  }

  onChange = (e) => {
    const value = e.hex;
    this.setState({ color: value })
    this.props.setColor(value)
  }
    

  render() {
    return (
      <div>    
          <div>
            {this.props.title}
          </div>
          <Badge className='mr-2 d-flex justify-content-center align-items-center' style={{ cursor: 'pointer', background: this.props.color, width: '152px', height: '40px' }} onClick={() => this.setState({ show: !this.state.show })}>
            {this.props.color}
          </Badge>
          {
            this.state.show && !this.props.disabled ?
              <div style={{ position: 'absolute', zIndex: '2', }}>
                <div style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px', }} onClick={() => this.setState({ show: false })} />
                <ChromePicker color={this.props.color} onChange={(c) => this.onChange(c)} />
              </div>
              : null
          }
      </div>
    )
  }
}