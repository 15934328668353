/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { ChromePicker, SketchPicker } from 'react-color';
import API from '../../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  Badge
} from 'reactstrap';
import { Link } from 'react-router-dom';
import AddProductCode from '../code/addProductCode';
import AddProductVariant from '../variant/addProductVariant';
import ColorPicker from '../../../components/elements/form/ColorPicker';


class add extends Component {

  constructor(props) {
    super(props);

    const init_body = {
      manufacturer_id: this.props.match.params.id,
      product_code: '',
      product_code_id: null,
      batch_number: '',
      product_variant_ids: null,
      min_redeem: '',
      color_primary_code: '#3450A2',
      color_secondary_code: '#305ECC',
      status: 1,
    }

    this.state = {
      id: this.props.match.params.id,
      showModal: this.props.open,
      body: init_body,
      code: '',
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      optionProductCode: [],
      modalProductCode: false,
      optionProductVariant: [],
      modalProductVariant: false,
      variantInputIndexes: [{ value: '', id: 0 }],
      changeVariantIndex: 0,
      multipleVariant: false,
      manufacturer: {},
    }
  }

  componentDidMount() {
    if (!this.state.id) this.props.history.push('/product/manufacturer');
    this.getOptionProductCode();
    this.getManufacturer();
  }

  getManufacturer() {
    API.get(`manufacturer/${this.state.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data.data;
        if (result.length > 0) {
          this.setState({
            manufacturer: result[0],
            multipleVariant: result[0].flag_productvariant === 1 ? true : false
          });
        }
      })
      .catch(err => {
        alert(err)
      })
  }

  getOptionProductCode() {
    API.get(`product/getProductCodes/${this.state.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data.result;
        this.setState({
          optionProductCode: result
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  getOptionProductVariant(id, index = 0) {
    let url = `product/getVariant/${this.state.body.manufacturer_id}/${this.state.body.product_code_id}/${id}`;
    if (index === 0) url = `product/getVariant/${this.state.body.manufacturer_id}/${this.state.body.product_code_id}/0`;

    API.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data.result;
        let optionNew = this.state.optionProductVariant;
        optionNew[index] = result

        this.setState({
          optionProductVariant: optionNew
        });
      })
      .catch(err => {
        alert(err)
      })

    console.log(id, index, this.state.optionProductVariant)
  }

  onChange = async (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    if (e.target.name === 'product_code') {
      newBody['product_code_id'] = this.state.optionProductCode[e.target.selectedIndex - 1].id
      newBody['product_code'] = this.state.optionProductCode[e.target.selectedIndex - 1].code

      this.setState({ 
        body: {
          ...this.state.body,
          product_code_id: newBody['product_code_id'],
          product_code: newBody['product_code']
        }
      }, () => this.getOptionProductVariant(e.target.value, 0));
    }

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '');
    const result = filtered.length ? true : false

    this.setState({
      disabled: result,
    });
    console.log(this.state.body, result);
    return result
  }

  onSubmit() {
    this.setState({
      // buttonLoading: true,
      notif: { show: true, title: 'Processing ...' }
    });
    API.post('product/batchnumber/config', this.state.body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          body: {},
          notif: {
            title: 'Success',
            message: result.message,
          }
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false }
          });
          this.props.history.push(`/product/batchnumber/config/${result.data.id}`);
        }, 1500);
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
          }
        });
      })
  }

  handleChangeSKU(event, index) {
    const indexOpt = event.target.selectedIndex;
    const optionElement = event.target.childNodes[indexOpt]
    const variantId = optionElement.getAttribute('data-id');
    this.getOptionProductVariant(variantId, index + 1);

    const value = event.target.value;
    let newVariant = this.state.variantInputIndexes;
    newVariant[index].value = value
    newVariant[index].id = variantId

    const batchnumber = newVariant.map((item) => {
      return item.value
    })

    const productVariantIds = newVariant.map((item) => {
      return item.id
    })

    this.setState({
      variantInputIndexes: newVariant,
      body: {
        ...this.state.body,
        batch_number: batchnumber.join('-'),
        product_variant_ids: productVariantIds
      }
    }, () => {
      this.onValidation()
    });

  }

  PushArrayVariant() {
    let arrLength = this.state.variantInputIndexes.length;
    let arr = this.state.variantInputIndexes;
    if (this.state.variantInputIndexes[arrLength - 1].value === '') {
      alert('Please Select SKU Before ' + arrLength);
    } else {
      arr.push({ value: '' })
      this.setState({
        variantInputIndexes: arr
      })
    }
  }

  RemoveArrayVariant(index) {
    this.state.variantInputIndexes.splice(index, 99);
    this.setState({
      variantInputIndexes: this.state.variantInputIndexes
    })

    const batchnumber = this.state.variantInputIndexes.map((item) => {
      return item.value
    })

    const productVariantIds = this.state.variantInputIndexes.map((item) => {
      return item.id
    })

    this.setState({
      body: {
        ...this.state.body,
        batch_number: batchnumber.join('-'),
        product_variant_id: productVariantIds.join('-')
      }
    });
  }

  changeVariant(index) {
    this.setState({
      modalProductVariant: !this.state.modalProductVariant,
      changeVariantIndex: index
    })
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <Link to={`/product/batchnumber/config?id=${this.state.manufacturer.manufacturer_id}`}>
                    <button
                      color="info"
                      size="sm"
                      className="btn btn-outline-link"
                    >
                      <i className='fa fa-arrow-left'></i>
                    </button>
                  </Link>
                  <strong className="card-title align-middle">Add SKU Config</strong>
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label className='w-100'>
                        Manufacturer
                      </Label>
                      <Input
                        id="manufacturer_id"
                        name="manufacturer_id"
                        type="select"
                        onChange={this.onChange}
                        disabled={true}
                      >
                        {
                          this.state.optionProductCode.length ? (
                            <>
                              <option value={this.state.manufacturer.manufacturer_id}>{this.state.manufacturer.name}</option>
                              {
                                this.state.optionProductCode.map(item => {
                                  return <option key={item.id} value={item.id}>{item.code}</option>
                                })
                              }
                            </>) : null
                        }
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label className='w-100'>
                        Product Code
                        <button type='button' onClick={() => this.setState({ modalProductCode: !this.state.modalProductCode })} className='pull-right text-primary btn btn-outline-none' style={{ fontSize: '14px' }} >+ More Item</button>
                      </Label>
                      <Input
                        id="product_code"
                        name="product_code"
                        type="select"
                        onChange={this.onChange}
                        invalid={this.state.error['product_code'] ? true : false}
                      >
                        {
                          this.state.optionProductCode.length ? (
                            <>
                              <option value={''}>Choose</option>
                              {
                                this.state.optionProductCode.map(item => {
                                  return <option key={item.id} value={item.id}>{item.code}</option>
                                })
                              }
                            </>) : null
                        }
                      </Input>
                      <FormFeedback >{this.state.error['product_code']}</FormFeedback>
                    </FormGroup>
                    {
                      this.state.variantInputIndexes.length > 0
                        ?
                        this.state.variantInputIndexes.map((item, index) => {
                          return (
                            <div key={index} className="form-group has-success">
                              <div className='row'>
                                <div className='col-md-6'>
                                  <label className="control-label mb-1">Variant/SKU {this.state.multipleVariant ? ` ${index + 1}` : ' '}</label>
                                </div>
                                <div className='col-md-6 text-right'>
                                  {
                                    this.state.body.product_code && (
                                      <button type='button' onClick={() => this.changeVariant(index)} className='pull-right text-primary btn btn-outline-none' style={{ fontSize: '14px' }} >+ Change Variant/SKU {index + 1}</button>
                                    )
                                  }
                                </div>
                              </div>
                              <div className='row'>
                                <div className={this.state.multipleVariant && this.state.body.product_code ? 'col-md-10' : 'col-md-12'}>
                                  <select name="variant" class="form-control" onChange={(event) => this.handleChangeSKU(event, index)}>
                                    {
                                      this.state.optionProductVariant.length > 0 ?
                                        <>
                                          <option value={''}>Choose</option>
                                          {
                                            this.state.optionProductVariant[index].map(item => {
                                              return <option key={item.id} data-id={item.id} value={item.variant} >{item.variant}</option>
                                            })
                                          }
                                        </>
                                        : (
                                          this.state.body.product_code ? <option label="Empty list" value={''} disabled selected /> : <option value={''} label="Please select the product first" disabled selected />
                                        )
                                    }
                                  </select>
                                </div>
                                {
                                  this.state.multipleVariant && this.state.body.product_code && (
                                    index === 0
                                      ?
                                      <div className='col-md-1-5 p-0' >
                                        <button type='button' style={{ fontSize: '17px' }} onClick={() => { this.PushArrayVariant(item) }} className='btn btn-sm btn-primary text-xs'>
                                          <i className='ti-plus'></i> More Item
                                        </button>
                                      </div>
                                      :
                                      <div className='col-md-1-5 text-left p-0'>
                                        <a onClick={() => { this.RemoveArrayVariant(index) }} className='btn text-danger bg-none'>
                                          <i className='ti-trash'></i>
                                        </a>
                                      </div>
                                  )
                                }
                              </div>
                            </div>
                          )
                        })
                        :
                        null
                    }
                    <FormGroup>
                      <Label>
                        Batch Number
                      </Label>
                      <Input
                        id="batch_number"
                        name="batch_number"
                        placeholder="Auto Filled"
                        type="text"
                        value={this.state.body.batch_number}
                        readOnly={true}
                        invalid={this.state.error['batch_number'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['batch_number']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Min. Redeem
                      </Label>
                      <Input
                        id="min_redeem"
                        name="min_redeem"
                        placeholder="Minimal Redeem"
                        type="text"
                        value={this.state.body.min_redeem}
                        onChange={this.onChange}
                        invalid={this.state.error['min_redeem'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['min_redeem']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Color Theme
                      </Label>
                      <div className='d-flex'>
                        <ColorPicker title="Primary" color={this.state.body.color_primary_code} setColor={(c) => this.setState({ body: { ...this.state.body, color_primary_code: c } })} />
                        <ColorPicker title="Secondary" color={this.state.body.color_secondary_code} setColor={(c) => this.setState({ body: { ...this.state.body, color_secondary_code: c } })} />
                      </div>
                    </FormGroup>
                    <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                      {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddProductCode
          open={this.state.modalProductCode}
          close={() => this.setState({ modalProductCode: !this.state.modalProductCode })}
          afterSubmit={() => this.getOptionProductCode()}
          data={this.state.optionProductCode}
          manufacturer_id={this.state.id}
        />
        <AddProductVariant
          open={this.state.modalProductVariant}
          close={() => this.setState({ modalProductVariant: !this.state.modalProductVariant })}
          afterSubmit={() =>
            this.getOptionProductVariant(
              this.state.changeVariantIndex === 0 ? 0 : this.state.variantInputIndexes[this.state.changeVariantIndex - 1]?.id,
              this.state.changeVariantIndex
            )
          }
          data={this.state.optionProductVariant.length > 0 ? this.state.optionProductVariant[this.state.changeVariantIndex] : []}
          manufacturer_id={this.state.id}
          product_code_id={this.state.body.product_code_id}
          variant_index={this.state.changeVariantIndex}
          variant_input_indexes={this.state.variantInputIndexes}
        />
      </div>
    )
  }
}

export default add;