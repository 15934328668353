import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Badge,
  Input,
  FormFeedback,
  Alert
} from 'reactstrap';
import { formatDate } from "src/lib/helpers";
import API from 'src/api'
import ConfirmModalSweet from "src/components/notificationModal/ConfrimModalSweet";

export default class LeadsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
      modalReason: false,
      disabledReject: true,
      notif: {
        show: false,
        status: true,
        message: ''
      },
      previewImage: false,
      showDetail: false,
      body: {
        status: null,
        note: ''
      },
      confirmModalShow: false,
      confirmModal: {
        show: false,
        title: '',
        subtitle: '',
        confirmText: '',
        confirmClass: ''
      },

    }
  }

  async onSubmit() {
    this.setState({ 
      buttonLoading: true
    })

    if (this.state.body.status == 2 && this.state.body.note == '') {
      this.setState({
        error: {
          note: 'Reason is required'
        },
        buttonLoading: false
      })
      return false;
    }
    // alert(JSON.stringify(this.state.body)); return;
    API.put('/scn/mission-work/approval/' + this.props.data.id, { status: this.state.body.status, note: this.state.body.note }, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          buttonLoading: false,
          notif: {
            show: true,
            status: true,
            message: res.data.message
          }
        });

        this.props.close()
        this.props.refresh();

        setTimeout(() => {
          this.setState({
            notif: {
              show: false,
              status: true,
              message: ''
            },
            error: {},
          })
        }, 3000);
      })
      .catch(err => {
        this.setState({
          buttonLoading: false
        })
        this.props.close()
        console.log(err);
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        body: {
          status: this.props.data.status,
          note: this.props.data.note
        }
      });
    }
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="lg">
          <ModalHeader toggle={() => this.props.close()}>Detail Evidence of Mission</ModalHeader>
          <ModalBody>
            {
              this.props.dataLoading ?
                <div className="">
                  <div className="skeleton skeleton-text" style={{ width: '25%' }}></div>
                  <div className="skeleton skeleton-body"></div>
                  <div className="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                  <div className="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                </div>
                :
                <>
                  <Form>
                    <div className="card-forum">
                      <div className="card-forum-body">
                        <div className="content-forum">
                          <label className="small text-primary font-weight-bold">Data Member</label>
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Full Name</label>
                                <div className="text-dark font-weight-bold"> {this.props.data?.member?.fullname} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Email</label>
                                <div className="text-dark font-weight-bold"> {this.props.data?.member?.email} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Number Phone</label>
                                <div className="text-dark font-weight-bold"> {this.props.data?.member?.phone} </div>
                              </div>
                            </div>
                          </div>
                          {
                            this.props.data.member_referrer && (
                              <>
                                <label className="small text-primary font-weight-bold mt-4">Referral Mission to</label>
                                <div className="row bg-light p-2 m-0 rounded">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label className="small text-secondary mb-0">Name</label>
                                      <div className="text-dark font-weight-bold"> {this.props.data.member_referrer?.fullname ?? '-'} </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label className="small text-secondary mb-0">Email</label>
                                      <div className="text-dark font-weight-bold"> {this.props.data.member_referrer?.email ?? '-'} </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label className="small text-secondary mb-0">Phone</label>
                                      <div className="text-dark font-weight-bold"> {this.props.data.member_referrer?.phone ?? '-'} </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          }

                          <label className="small text-primary font-weight-bold mt-4">Detail Evidence of Mission</label>
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-md-5">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Title</label>
                                <div className="text-dark font-weight-bold"> {this.props.data?.mission?.title} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Periode</label>
                                <div className="text-dark font-weight-bold"> {formatDate(this.props?.data?.mission?.start_date, false)} - {formatDate(this.props.data?.mission?.end_date, false)} </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Point</label>
                                <div className="text-dark font-weight-bold d-flex justify-content-between">
                                  <div className="text-secondary">
                                    <div className="small "><small>Mission</small></div>
                                    <div>{this.props.data.point_mission}</div>
                                  </div>
                                  <div className="text-secondary">
                                    <div className="small text-secondary"><small>Bonus</small></div>
                                    <div>{this.props.data.mission_work_referral_id ? this.props.data.point_referrer : this.props.data.point_referral}</div>
                                  </div>
                                  <div>
                                    <div className="small text-secondary"><small>Total</small></div>
                                    <div>{this.props.data.total_point}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="small text-secondary">Detail of Mission</label>
                                {
                                  this.state.showDetail && (
                                    <div className="text-dark font-weight-bold"> {this.props.data?.mission?.detail} </div>
                                  )
                                }
                                <div role="button" className="small text-primary" onClick={() => this.setState({ showDetail: !this.state.showDetail })}>
                                  {this.state.showDetail ? 'sembunyikan...' : 'tampilkan...'}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row bg-light p-2 m-0 rounded mt-2">

                          </div>
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-md-8">
                              <div className="form-group">
                                <label className="small text-secondary">Content links</label>
                                <div>
                                  {
                                    this.props.data.content_link?.map((item, index) => {
                                      return (
                                        <a href={item} target="_blank" key={index}> {item} <i class="ml-2 fa fa-external-link"></i></a>
                                      )
                                    })
                                  }
                                </div>
                                <div className="text-dark font-weight-bold"> {this.props.data?.tujuan_penggunaan?.tujuan_penggunaan} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary">Status</label>
                                <div className="text-dark font-weight-bold">
                                  <Badge
                                    className="badge-c"
                                    color={this.props.data.status == 0 ? 'warning' : (this.props.data?.status == 1 ? 'success' : 'danger')}
                                    pill
                                  >
                                    {this.props.data.status_name}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="form-group">
                                <label className="small text-secondary">Images</label>
                                <div className="text-dark font-weight-bold d-flex gap-2">
                                  {
                                    this.props.data.image?.map((item, index) => {
                                      return (
                                        <img
                                          key={index}
                                          src={item}
                                          width={135}
                                          onClick={() => {
                                            this.setState({
                                              previewImage: item,
                                            });
                                            this.props.close()
                                          }}
                                          alt=""
                                          className="img-fluid pe-auto"
                                          role="button"
                                        />
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            {
                              this.props.data.status > 0 && (
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="small text-secondary">{this.props.data?.status == 1 ? 'Approved' : 'Rejected'} By</label>
                                    <div className="text-dark font-weight-bold">
                                      {this.props.data.updater?.username ?? '-'}
                                    </div>
                                  </div>
                                </div>
                              )
                            }

                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="small text-secondary mb-2">Notes</label>
                                <div className="text-dark font-weight-bold pr-3">
                                  <Input
                                    name="note"
                                    type="textarea"
                                    placeholder="Enter Note"
                                    rows="2"
                                    value={this.state.body.note}
                                    disabled={this.props.data.status > 0}
                                    onChange={(e) => this.setState({ body: { ...this.state.body, note: e.target.value } })}
                                    invalid={this.state.error['note']}
                                  />
                                  <FormFeedback>{this.state.error.note}</FormFeedback>
                                </div>
                              </div>
                            </div>
                          </div>
                          {
                            this.props.data.mission_work_referral_id ?
                              <Alert color="danger p-2">
                                This mission is done by referrals
                              </Alert>
                              : null
                          }
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
            }
          </ModalBody>
          {
            this.props.data.status == 0 && (
              <ModalFooter>
                <Button color="success" onClick={() => {
                  this.setState({ body: { ...this.state.body, status: 1 } }, () => {
                    this.setState({
                      confirmModalShow: true,
                      confirmModal: {
                        title: 'Approve Mission',
                        subtitle: 'Are you sure to approve this mission?',
                        confirmText: 'Approve',
                        confirmClass: 'bg-success'
                      }
                    })
                  })
                }}>Success</Button>
                <Button color="danger" onClick={() => {
                  this.setState({ body: { ...this.state.body, status: 2 } }, () => {
                    this.setState({ 
                      confirmModalShow: true,
                      confirmModal: {
                        title: 'Failed Mission',
                        subtitle: 'Are you sure to failed this mission?',
                        confirmText: 'Failed',
                        confirmClass: 'bg-danger'
                      } 
                    })
                  })
                }}>Failed</Button>
              </ModalFooter>
            )
          }
        </Modal>
        <Modal isOpen={this.state.previewImage} toggle={() => { this.setState({ previewImage: false }); this.props.reOpen(); }} size="lg">
          <i className="w-full h3  fa fa-times-circle position-absolute p-2 top-0 end-0" role="button" onClick={() => { this.setState({ previewImage: false }); this.props.reOpen(); }}></i>
          <img src={this.state.previewImage} alt="" className="img-fluid" />
        </Modal>
        <ConfirmModalSweet
          show={this.state.confirmModalShow}
          title={this.state.confirmModal.title}
          subtitle={this.state.confirmModal.subtitle}
          confirmText={this.state.confirmModal.confirmText}
          onConfirm={() => { this.setState({ confirmModalShow: false }); this.onSubmit(); }}
          onCancel={() => { this.setState({ confirmModalShow: false }) }}
          confirmClass={this.state.confirmModal.confirmClass}
        />
      </>

    )
  }
}