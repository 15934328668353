import NotifToast from "src/components/notificationModal/NotifToast";
import React, { Component } from "react";
import {
  Button,
  Spinner,
  Input,
  Label,
  FormGroup,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import API from "src/api";

const init_body = {
  banner_title: '',
  banner_subtitle: '',
  banner_terms: '',
  banner_finish_title: '',
  banner_finish_subtitle: '',
  banner_finish_terms: '',
  notif_headings: '',
  notif_contents: '',
  notif_contents_detail: '',
}

export default class BannerVoucherNotif extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      showModalCustomText: false,
      error: {},
      buttonLoading: false,
      body: init_body,
      activeTab: 'banner',
      notif: {
        show: false,
        status: true,
        title: 'Success',
        message: 'Batch Number Config updated successfully'
      },
      disabled: true,
    }
  }

  async componentDidMount() {
    //LOAD DATA
    await API.get(`config/redeem-voucher/${this.props.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          id: result.data.id,
          body: {
            banner_title: result.data.banner_title,
            banner_subtitle: result.data.banner_subtitle,
            banner_terms: result.data.banner_terms,
            banner_finish_title: result.data.banner_finish_title,
            banner_finish_subtitle: result.data.banner_finish_subtitle,
            banner_finish_terms: result.data.banner_finish_terms,
            notif_headings: result.data.notif_headings,
            notif_contents: result.data.notif_contents,
            notif_contents_detail: result.data.notif_contents_detail,
            status: result.data.status,
          }
        })
      })
      .catch(err => {
        // alert(err)
      })
  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      error: error
    }, () => {
      this.onValidation()
    });
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '');
    const result = filtered.length ? true : false

    this.setState({
      disabled: result,
    });
    console.log(this.state.body, result);
    return result
  }

  async onSubmit() {
    this.setState({
      notif: { show: true, title: 'Processing ...' }
    });
    const body = this.state.body;
    body.manufacturer_id = this.props.manufacturer_id;
    body.product_batchnumber_config_id = this.props.id;

    // alert(JSON.stringify(body)); return;

    let url = `config/redeem-voucher`;
    let method = 'post';
    if (this.state.id) {
      url = `config/redeem-voucher/${this.state.id}`;
      method = 'put';
    }

    await API[method](url, body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          notif: {
            title: 'Success',
            message: result.message,
            status: true,
          }
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false }
          });
        }, 1500);
      })
      .catch(err => {
        this.setState({
          notif: {
            title: 'Failed',
            message: err,
            status: false,
          }
        });
      })
  }

  handleDragStart = (event, value) => {
    event.dataTransfer.setData('text/plain', value);
  }

  render() {
    return (
      <>
        <div className="card">
          <div className="card-header">
            <strong className="card-title align-middle">Redeem Voucher</strong>
          </div>
          <div className="card-body">
            <Nav tabs className='mb-4'>
              <NavItem>
                <NavLink
                  active={this.state.activeTab === 'banner' ? true : false}
                  className='fs-13'
                  onClick={() =>
                    this.setState({
                      activeTab: 'banner',
                    })
                  }
                >
                  Banner
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.activeTab === 'notif' ? true : false}
                  className='fs-13'
                  onClick={() =>
                    this.setState({
                      activeTab: 'notif',
                    })
                  }
                >
                  Push Notif
                </NavLink>
              </NavItem>
            </Nav>
            {
              this.state.activeTab === 'banner' ? (
                <>
                  <div className="mb-2">
                    <div className="text-secondary text-md font-weight-normal  mb-2">list of custom texts that can be used, by clicking and dragging on the text to be added:</div>
                    <div className="d-flex gap-2">
                      <button className='btn btn-sm btn-outline-secondary' draggable="true" onDragStart={(e) => this.handleDragStart(e, '[sisa_scan]')} >Sisa Scan</button>
                      <button className='btn btn-sm btn-outline-secondary' draggable="true" onDragStart={(e) => this.handleDragStart(e, '[nama_produk]')} >Nama Produk</button>
                      <button className='btn btn-sm btn-outline-secondary' draggable="true" onDragStart={(e) => this.handleDragStart(e, '[sku]')} >SKU</button>
                    </div>
                  </div>
                  <div className="text-secondary text-sm">Not Finished</div>
                  <div className="row">
                    <FormGroup className="col-md-6">
                      <Label>Title</Label>
                      <Input
                        name="banner_title"
                        placeholder="Enter Title"
                        type="text"
                        value={this.state.body.banner_title}
                        onChange={this.onChange}
                        invalid={this.state.error['banner_title'] ? true : false}
                        required
                      />
                      <FormFeedback >{this.state.error['banner_title']}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <Label>Subtitle</Label>
                      <Input
                        name="banner_subtitle"
                        placeholder="Enter Subtitle"
                        type="text"
                        value={this.state.body.banner_subtitle}
                        onChange={this.onChange}
                        invalid={this.state.error['banner_subtitle'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['banner_subtitle']}</FormFeedback>
                    </FormGroup>
                  </div>
                  <FormGroup>
                    <Label>Terms</Label>
                    <Input
                      name="banner_terms"
                      placeholder="Enter Terms & Conditions Info"
                      type="text"
                      value={this.state.body.banner_terms}
                      onChange={this.onChange}
                      invalid={this.state.error['banner_terms'] ? true : false}
                    />
                    <FormFeedback >{this.state.error['banner_terms']}</FormFeedback>
                  </FormGroup>
                  <div className="text-secondary text-sm">Finished</div>
                  <div className="row">
                    <FormGroup className="col-md-6">
                      <Label>Title</Label>
                      <Input
                        name="banner_finish_title"
                        placeholder="Enter Finish Title"
                        type="text"
                        value={this.state.body.banner_finish_title}
                        onChange={this.onChange}
                        invalid={this.state.error['banner_finish_title'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['banner_finish_title']}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <Label>Subtitle</Label>
                      <Input
                        name="banner_finish_subtitle"
                        placeholder="Enter Finish Subtitle"
                        type="text"
                        value={this.state.body.banner_finish_subtitle}
                        onChange={this.onChange}
                        invalid={this.state.error['banner_finish_subtitle'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['banner_finish_subtitle']}</FormFeedback>
                    </FormGroup>
                  </div>
                  <FormGroup>
                    <Label>Terms</Label>
                    <Input
                      name="banner_finish_terms"
                      placeholder="Enter Finish Terms & Conditions Info"
                      type="text"
                      value={this.state.body.banner_finish_terms}
                      onChange={this.onChange}
                      invalid={this.state.error['banner_finish_terms'] ? true : false}
                    />
                    <FormFeedback >{this.state.error['banner_finish_terms']}</FormFeedback>
                  </FormGroup>
                </>
              ) : (
                <>
                  <div className="mb-2">
                    <div className="text-secondary text-md font-weight-normal  mb-2">list of custom texts that can be used, by clicking and dragging on the text to be added:</div>
                    <div className="d-flex gap-2">
                      <button className='btn btn-sm btn-outline-secondary' draggable="true" onDragStart={(e) => this.handleDragStart(e, '[kode_voucher]')} >Kode Voucher</button>
                      <button className='btn btn-sm btn-outline-secondary' draggable="true" onDragStart={(e) => this.handleDragStart(e, '[nama_lengkap]')} >Nama Lengkap</button>
                    </div>
                  </div>
                  <FormGroup>
                    <Label>Headings</Label>
                    <Input
                      name="notif_headings"
                      placeholder="Enter Heading"
                      type="text"
                      value={this.state.body.notif_headings}
                      onChange={this.onChange}
                      invalid={this.state.error['notif_headings'] ? true : false}
                    />
                    <FormFeedback >{this.state.error['notif_headings']}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>Content</Label>
                    <Input
                      name="notif_contents"
                      placeholder="Enter Content"
                      type="textarea"
                      value={this.state.body.notif_contents}
                      onChange={this.onChange}
                      invalid={this.state.error['notif_contents'] ? true : false}
                    />
                    <FormFeedback >{this.state.error['notif_contents']}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>Content Details</Label>
                    <Input
                      name="notif_contents_detail"
                      placeholder="Enter Content Details"
                      type="textarea"
                      value={this.state.body.notif_contents_detail}
                      onChange={this.onChange}
                      invalid={this.state.error['notif_contents_detail'] ? true : false}
                    />
                    <FormFeedback >{this.state.error['notif_contents_detail']}</FormFeedback>
                  </FormGroup>
                </>
              )
            }
            <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
              {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
            </Button>
          </div>
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { ...this.state.notif, show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
        />
      </>
    )
  }
}