import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import {
  Badge,
} from 'reactstrap';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import { formatDate } from 'src/lib/helpers';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showModalDetail: false,
      selectedData: {},
      status: [
        { value: '0', label: 'Reject', color: 'secondary' },
        { value: '1', label: 'Approved', color: 'success' },
        { value: '2', label: 'Pending', color: 'info' },
      ]
    };
  }

  render() {
    return (
      <>
        <div className="card">
          <div className="card-header">
            <strong className="card-title">History Offering Letter</strong>
          </div>
          <div className="card-body">
            {
              <DataTable
                noHeader
                columns={[
                  {
                    name: 'Date',
                    cell: data => formatDate(data.created_at) + ' WIB'
                  },
                  {
                    name: 'Username',
                    cell: data => data.created_users?.username
                  },
                  {
                    name: 'Status',
                    cell: data =>
                      <label>
                        <Badge
                          color={this.state.status.filter(item => item.value == data.status)[0].color}
                          pill
                        >
                          {this.state.status.filter(item => item.value == data.status)[0].label}
                        </Badge>
                      </label>
                  },
                ]}
                data={this.props.data}
                paginationPerPage={5}
                pagination
              />
            }
          </div>
        </div>
      </>
    )
  }
}

export default index;