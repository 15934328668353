import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  Modal, ModalHeader, ModalBody,
  Spinner,
  Input,
  InputGroup
} from 'reactstrap';
import API from "src/api";
import NotifToast from "src/components/notificationModal/NotifToast";
import { formatDate } from "src/lib/helpers";

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      notif: false,
      notifTitle: 'Success',
      notifMessage: '',
      error: {},
      buttonLoading: false,
      buttonLoadingAdd: false,
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      search: '',
      data: [],
    }
  }

  componentDidMount() {
    this.getList();
  }

  getList() {
    API.get('leads?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      + '&offering_letter=0'
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    if (e.target.value.length > 3 || e.target.value === '') {
      this.setState({
        search: e.target.value
      }, () => {
        this.getList()
      })
    }
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="xl">
          <ModalHeader>List Leads</ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='d-flex'>
                <InputGroup className='w-auto'>
                  <Input
                    placeholder='Search by Company Name'
                    onChange={this.onChange}
                  />
                </InputGroup>
              </div>
              <DataTable
                columns={[
                  {
                    name: 'ID',
                    selector: 'id',
                    maxWidth: '5%'
                  },
                  {
                    name: 'Date',
                    cell: data => formatDate(data.created_at) + ' WIB'
                  },
                  {
                    name: 'Company Name',
                    selector: 'company_name',
                    minWidth: "25%"
                  },
                  {
                    name: 'Brand Name',
                    selector: 'brand_name',
                  },
                  {
                    name: 'PIC Name',
                    selector: 'pic_name',
                  },
                  {
                    name: 'Action',
                    selector: 'action',
                    cell: data =>
                      <>
                        <div>
                          <Link to={`/leads-tracking/offering-letter/add/${data.id}`}>
                            <button type='button' className="btn btn-sm btn-outline-primary"><li className="fa fa-refresh mr-1"></li>Proses</button>
                          </Link>
                        </div>
                      </>
                  }
                ]}
                noHeader={true}
                data={this.state.data}
                pagination={true}
                paginationServer={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                paginationTotalRows={this.state.paginationTotalRows}
                onChangePage={page => this.changePage(page)}
                onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
              />
            </div>
          </ModalBody>
        </Modal>
        <NotifToast
          show={this.state.notif}
          close={() => this.setState({ notif: false })}
          title={this.state.notifTitle}
          message={this.state.notifMessage}
        />
      </>
    )
  }
}

class RowComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editTab: false,
      value: this.props.data.source_type
    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value
    })

    this.props.onChange(e)
  }

  render() {
    return (
      <>
        <tr id="box">
          <td className={`align-middle ${this.state.editTab ? 'pl-0' : null}`}>
            <span hidden={this.state.editTab}>
              {this.props.data.source_type}
            </span>
            <Input
              name="name"
              placeholder="sm"
              onChange={(e) => this.onChange(e)}
              hidden={!this.state.editTab}
              value={this.state.value}
            />
          </td>
          <td className='text-right text-sm'>
            <button hidden={this.state.editTab} onClick={() => this.setState({ editTab: true })} className='btn btn-xs btn-info'>Edit</button>
            <button hidden={this.state.editTab} onClick={() => this.props.onDelete()} className='btn btn-xs btn-danger ml-2'>Delete</button>
            <button hidden={!this.state.editTab} onClick={() => this.props.onEdit()} className='btn btn-xs btn-success'>
              {!this.props.buttonLoading ? 'Save' : <Spinner size="sm">Loading...</Spinner>}
            </button>
            <button hidden={!this.state.editTab} onClick={() => this.setState({ editTab: false })} className='btn btn-xs btn-warning ml-2'>Cancel</button>
          </td>
        </tr>
      </>
    );
  };

}