import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody,
  Spinner,
  Input
} from 'reactstrap';
import API from "src/api";
import NotifToast from "src/components/notificationModal/NotifToast";

const init_body = {
  name: ''
}

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      notif: false,
      notifTitle: 'Success',
      notifMessage: '',
      error: {},
      buttonLoading: false,
      buttonLoadingAdd: false,
      body: init_body
    }
  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      error: error
    });
  }

  onDelete(id, index) {
    this.setState({ buttonLoading: true });

    API.delete(`product/leads/source-type/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          body: init_body,
          buttonLoading: false,
          notif: true,
          notifTitle: 'Success',
          notifMessage: 'Success Delete Source Type',
        });

        setTimeout(() => {
          this.setState({
            notif: false,
          });
        }, 3000)

        this.props.afterSubmit()
        this.props.close()
      })
      .catch(err => {
        alert(err)
      })
  }

  onEdit(id, index) {
    this.setState({
      buttonLoading: true,
      // notif: { show: true, title: 'Processing ...' }
    });

    API.put(`product/leads/source-type/${id}`,{source_type:this.state.body.name}, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          body: init_body,
          buttonLoading: false,
          notif: true,
          notifTitle: 'Success',
          notifMessage: 'Success Edit Source Type',
        });

        setTimeout(() => {
          this.setState({
            notif: false,
          });
        }, 3000)

        this.props.afterSubmit()
        this.props.close()

      })
      .catch(err => {
        this.props.afterSubmit()
        this.props.close()
      })
  }

  onAdd() {
    this.setState({
      buttonLoadingAdd: true,
    });

    API.post(`product/leads/source-type`,{source_type:this.state.body.name}, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          body: {},
          buttonLoadingAdd: false,
          notif: true,
          notifTitle: 'Success',
          notifMessage: 'Success Add Source Type',
        });

        setTimeout(() => {
          this.setState({
            notif: false,
          });
        }, 3000)

        this.props.afterSubmit()
        this.props.close()
      })
      .catch(err => {
        this.props.afterSubmit()
        this.props.close()
      })
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()}>
          <ModalHeader>More Source Type</ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='col-md-12'>
                {
                  this.props.data.length > 0
                    ?
                    <div className='table-modal-wrapper'>
                      <table className='table'>
                        <tbody>
                          {
                            this.props.data.map((item, index) => (
                              <RowComponent
                                key={index}
                                data={item}
                                onChange={(e) => this.onChange(e)}
                                value={this.state.body.name}
                                onEdit={() => this.onEdit(item.id, index)}
                                onDelete={() => this.onDelete(item.id, index)}
                                buttonLoading={this.state.buttonLoading}
                                disabled={false}
                              />
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                    :
                    null
                }

              </div>
              <div className='col-md-10'>
                <div className='my-2'>
                  <span>Add Source Type</span>
                </div>
                <Input
                  id="name"
                  name="name"
                  placeholder="Enter Name"
                  className="form-control valid"
                  type="text"
                  onChange={this.onChange}
                  required
                  invalid={this.state.error['name'] ? true : false}
                />
              </div>
              <div className='col-md-2'>
                <Button color="info" onClick={() => this.onAdd()} disabled={this.state.buttonLoadingAdd} className="button-add-variant-modal mt-3">
                  {!this.state.buttonLoadingAdd ? 'Add' : <Spinner size="sm">Loading...</Spinner>}
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <NotifToast
          show={this.state.notif}
          close={() => this.setState({ notif: false })}
          title={this.state.notifTitle}
          message={this.state.notifMessage}
        />
      </>
    )
  }
}

class RowComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editTab: false,
      value: this.props.data.source_type
    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value
    })

    this.props.onChange(e)
  }

  render() {
    return (
      <>
        <tr id="box">
          <td className={`align-middle ${this.state.editTab ? 'pl-0' : null}`}>
            <span hidden={this.state.editTab}>
              {this.props.data.source_type}
            </span>
            <Input
              name="name"
              placeholder="sm"
              onChange={(e) => this.onChange(e)}
              hidden={!this.state.editTab}
              value={this.state.value}
            />
          </td>
          <td className='text-right text-sm'>
            <button hidden={this.state.editTab} onClick={() => this.setState({ editTab: true })} className='btn btn-xs btn-info'>Edit</button>
            <button hidden={this.state.editTab} onClick={() => this.props.onDelete()} className='btn btn-xs btn-danger ml-2'>Delete</button>
            <button hidden={!this.state.editTab} onClick={() => this.props.onEdit()} className='btn btn-xs btn-success'>
              {!this.props.buttonLoading ? 'Save' : <Spinner size="sm">Loading...</Spinner>}
            </button>
            <button hidden={!this.state.editTab} onClick={() => this.setState({ editTab: false })} className='btn btn-xs btn-warning ml-2'>Cancel</button>
          </td>
        </tr>
      </>
    );
  };

}