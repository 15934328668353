import React, { Component } from 'react';
import API from '../../api';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import {
    CustomInput
} from 'reactstrap';
import LoadingBar from 'react-top-loading-bar'

export default class GenerateQRCode extends Component{
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUploadChange = this.handleUploadChange.bind(this);
        this.getManufacturerOption = this.getManufacturerOption.bind(this);

        this.state = {
            menuCode: constants.MENU_IMPORT_CSV,
            scope: [],
            manufacturerId: "",
            manufacturer: [],
            csvFileLable: "",
            csvFile: "",
            disable: false,
            error: false,
            errorMsg: false,
        }
    }

    componentDidMount(){
        this.checkAccess();
        this.getManufacturer();
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'create');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    handleUploadChange(event) {
        console.log("Logo file: ",event.target.files[0]);
        
        this.setState({
            csvFileLable: event.target.files[0].name,
            csvFile: event.target.files[0]
        });
    }

    async getManufacturer(){
        let manufacturer = await API.get('manufacturer?size=20&page=1', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }});
        this.setState({
            manufacturer: manufacturer.data.item,
            manufacturerId: manufacturer.data.item[0].manufacturer_id
        })
    }

    getManufacturerOption(){
        let items = [];
        let manufacturer = this.state.manufacturer;
        console.log("List manufacturer", manufacturer)
        for(let i=0; i < manufacturer.length; i++){
            items.push(<option key={manufacturer[i].manufacturer_id} value={manufacturer[i].manufacturer_id}>{manufacturer[i].name}</option>);
        }
        return items;
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event){
        this.setState({disable:true})
        this.LoadingBar.continuousStart()
        console.log("submit", this.state.csvFile)
        let body = new FormData();
        body.append("csv", this.state.csvFile);
        body.append("manufacturerId", this.state.manufacturerId);

        API.post('product/import', body, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
                .then(response => {
                    this.setState({disable:false})
                    if (response.data.error){
                        this.LoadingBar.complete()
                        this.setState({
                            error: true,
                            errorMsg: response.data.message
                        })

                        setTimeout(() => {
                            this.setState({
                                error: false
                            })
                        }, 3000)
                    } else {
                        console.log("Sukses import product", response);
                        this.LoadingBar.complete()
                        this.props.history.push("/product/");
                    }
                })
                .catch(error => {
                    console.log("Error import product",error);
                });
    }

    render() {
        return(
            <div className="content">
                 <LoadingBar onRef={ref => (this.LoadingBar = ref)} />
                <div className="animated fadeIn">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="card-title">Import Product</strong>
                                    <div class="alert alert-danger" role="alert" hidden={!this.state.error}>
                                        {this.state.errorMsg}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div class="form-group">
                                        <label for="manufacturerId" class=" form-control-label">Manufacturer</label>
                                        <select name="manufacturerId" id="manufacturerId" class="form-control" onChange={this.handleChange}>
                                            {this.getManufacturerOption()}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="csvFile" className="control-label mb-1">CSV Files</label>
                                        <CustomInput
                                            type="file"
                                            id="csvFile"
                                            name="csvFile"
                                            label={this.state.csvFileLable}
                                            onChange={e => this.handleUploadChange(e)}
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="logoFile" data-valmsg-replace="true"></span>
                                    </div>
                                    <button disabled={this.state.disable} type="button" className="btn btn-info" onClick={this.handleSubmit}>Add</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}