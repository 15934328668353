import React, { Component } from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
  Spinner,
  Nav,
  NavItem,
  NavLink,
  Form,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupText
} from 'reactstrap';
import { Link } from 'react-router-dom';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import ConfirmModal from '../../components/notificationModal/ConfirmModal';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_MANAGE_MENU,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          sortable: true
        },
        {
          name: 'Action Title',
          selector: 'title',
          sortable: true
        },
        {
          name: 'Description',
          selector: 'description',
          sortable: true
        },
        {
          name: 'User',
          selector: 'username',
          sortable: true
        },
        {
          name: 'Created at',
          selector: 'created_at',
          sortable: true
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: role =>
            <div>
              <Link to={`/log/${role.id}?group=${this.state.group}`}>
                <button className="text-dark btn btn-sm btn-outline-link" ><li className="fa fa-eye"></li></button>
              </Link>
            </div>
        }
      ],
      loaderPage: true,
      data: [],
      editData: {},
      id: null,
      search: '*',
      startDate: 0,
      endDate: 0,
      showTable: false,
      showModal: false,
      showModalEdit: false,
      disableButtonModal: false,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 5,
      paginationTotalRows: 0,
      paginationPage: 1,
      group: 'dashboard_app'
    };
    // this.modalUser = this.modalUser.bind(this);
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
    this.setState({
      loaderPage: false
    })
  }

  changeGroup(group) {
    this.setState({
      group: group,
    }, () => {
      this.getList(group);
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    console.log(access)
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList(group) {
    API.get('userApp/log?size=' + this.state.paginationPerPage 
      + '&page=' + this.state.paginationPage 
      + '&group=' + this.state.group 
      + '&search=' + this.state.search 
      + '&startdate=' + this.state.startDate 
      + '&enddate=' + this.state.endDate, 
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changeEditData(body) {
    this.setState({ editData: body });
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModal: !this.state.showModal,
      id: id,
    });
  }

  afterSubmit(status, message) {
    this.getList()

    this.setState({
      alert: {
        show: true,
        status: status,
        message: message
      }
    });

    setTimeout(() => {
      this.setState({
        alert: {
          show: false,
          status: '',
          message: ''
        }
      });
    }, 3000)
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      search: e.target.value.length > 0 ? (name === 'search' ? e.target.value : '*' ) : '*',
      startDate: name === 'startDate'  ? value : this.state.startDate,
      endDate: name === 'endDate'  ? value : this.state.endDate,
    }, () => {
      this.getList()
    })
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header card-tabs">
                    <div className='row'>
                      <div className=''>
                        <strong className='card-title vertical-middle-p'>History Log</strong>
                        <Nav tabs className='mt-3'>
                          <NavItem>
                            <NavLink
                              active={this.state.group === 'dashboard_app' ? true : false}
                              className='fs-13'
                              onClick={() => this.changeGroup('dashboard_app')}
                            >
                              Backoffice
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              active={this.state.group === 'dashboard_partner' ? true : false}
                              className='fs-13'
                              onClick={() => this.changeGroup('dashboard_partner')}
                            >
                              Partner
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                      <Row className="row-cols-lg-auto g-3 align-items-center mb-4">
                        <Col>
                          <Input
                            id="search"
                            name='search'
                            placeholder="Search by Title/Description"
                            type="text"
                            onChange={this.onChange}
                          />
                        </Col>
                        <Col>
                          <InputGroup>
                            <InputGroupText>
                              Start Date
                            </InputGroupText>
                            <Input 
                              type="date"
                              name='startDate'
                              onChange={this.onChange}
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <InputGroup>
                            <InputGroupText>
                              End Date
                            </InputGroupText>
                            <Input 
                              type="date"
                              name='endDate'
                              onChange={this.onChange}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    {
                      // !this.state.showTable ? <>Loading ...</> :
                      <DataTable
                        noHeader={true}
                        columns={this.state.columns}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            open={this.state.showModal}
            close={() => this.showConfirmDelete()}
            id={this.state.id}
            url={`role/menu/${this.state.id}`}
          />
        </div>
    )
  }
}

export default index;