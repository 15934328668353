import React, { Component } from 'react';
import API from '../../api'
import { Form, Label } from 'reactstrap';
import { formatDay } from 'src/lib/helpers';

export default class GenerateQRCode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {}
        }
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.getManufacturer(id);
    }

    getManufacturer(id) {
        API.get("manufacturer/" + id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res.data.data) {
                    this.setState({
                        data: res.data.data[0]
                    })
                }
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    render() {
        return (
            <div className="content">
                <div className="animated fadeIn">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="card-title">Profil</strong>
                                </div>
                                <div className="card-body">
                                    <Form>
                                        <div className='text-center rounded-circle border-2 mb-4'>
                                            <img className='rounded-circle' src={this.state.data.logo} alt={this.state.data.name} width={100} height={100} />
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>ID</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.manufacturer_id}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>UID</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.manufacturer_uid ?? '-'}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Name</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.name}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Email</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.email_address ?? '-'}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Type Manufacturer</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.type ?? '-'}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Website Links</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.website_links ?? '-'}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Phone</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.phone ?? '-'}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Tag</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.manufacturer_tag ?? '-'}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Description</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.description ?? '-'}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Tanggal Dibuat</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{formatDay(this.state.data?.created_at, false)}</span> </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="card-title">Setting</strong>
                                </div>
                                <div className="card-body">
                                    <Form>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Type Certificate</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.flag_customcert ? 'Custom' : ( this.state.data?.flag_singlecert ? 'Single' : 'Default' )}</span> </div>
                                        </div>
                                        {/* <div className='d-flex g-5'>
                                            <Label className='w-50'> Custom Path Certificate</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.body?.point}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'> Path How to Scan</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.body?.point}</span> </div>
                                        </div> */}
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Variant Product</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.flag_productvariant ? 'Yes' : 'No'}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Claim Point</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.flag_claimpoint ? 'Yes' : 'No'}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Notification Suspicious</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.flag_suspiciousemail ? 'Yes' : 'No'}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Flag SKU</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.flag_sku ? 'Yes' : 'No'}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>URL Link</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.url_link ?? '-'}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Show</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.flag_show ? 'Yes' : 'No'}</span> </div>
                                        </div>
                                        <div className='d-flex g-5'>
                                            <Label className='w-50'>Positions</Label>
                                            <div className="text-dark">:  <span className='font-weight-bold'>{this.state.data?.positions}</span> </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}