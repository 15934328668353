import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../../api'
import {
  Button,
  Spinner,
  Badge,
  InputGroup,
  Input,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import constants from '../../../lib/constants';
import { _hasAccess } from '../../../middleware/accessMiddleware'
import CommentForumReportedDetail from './CommentForumReportedDetail';

export default class CommentForumReportedIndex extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_REPORTED_COMMENT,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          sortable: true,
          maxWidth: '5%'
        },
        {
          name: 'Customer Name',
          selector: 'created_by.username',
          sortable: true
        },
        {
          name: 'Comments',
          selector: 'comment',
          sortable: true,
        },
        {
          name: 'Created At',
          sortable: true,
          cell: data => {
            return new Date(data.created_at).toLocaleString('sv-SE') + ' WIB'
          }
        },
        {
          name: 'Status',
          sortable: true,
          cell: data =>
            <label>
              {data.is_banned === 1 ?
                <Badge
                  color="success"
                  pill
                >
                  Banned
                </Badge>
                :
                (
                  data.reports.filter(item => item.status === 1).length > 0 ?
                    <Badge
                      color="danger"
                      pill
                    >
                      On Review
                    </Badge>
                    :
                    (data.reports.filter(item => item.status === 3).length > 0 ?
                      <Badge
                        color="warning"
                        pill
                      >
                        Closed
                      </Badge>
                      :
                      <Badge
                        color="danger"
                        pill
                      >
                        On Review
                      </Badge>
                    )
                )
              }
            </label>
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: data =>
            <>
              <div>
                <button type='button' className="text-dark btn btn-sm btn-outline-link" onClick={() => this.showDetail(data.id)} ><li className="fa fa-eye"></li></button>
              </div>
            </>
        }
      ],
      loaderPage: true,
      data: [],
      detailData: {},
      detailDataLoading: false,
      showTable: false,
      showModalDetail: false,
      search: '',
      status: '',
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
    this.setState({
      loaderPage: false
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    console.log(access)
    if (access) {
      let scope = [];
      access.scope.map(item => item.status === 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    const search = this.state.search;
    console.log(search);
    API.get('forum/comment/reported?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      + '&status=' + this.state.status
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    this.setState({
      search: e.target.value
    }, () => {
      this.getList()
    })
  }

  onfilter(status) {
    this.setState({
      status: status
    }, () => {
      this.getList()
    })
  }

  showDetail(commentId) {
    this.setState({
      detailDataLoading: true,
      showModalDetail: true
    })
    API.get('forum/comment/detail/' + commentId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          detailData: result.data,
          detailDataLoading: false
        })

      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Reported Comments</strong>
                    <div className="float-right">
                      <ButtonGroup>
                        <UncontrolledDropdown size='sm'>
                          <DropdownToggle caret>
                            More
                          </DropdownToggle>
                          <DropdownMenu>
                            <Link to="/forum/reported/reason" className='text-decoration-none'>
                              <DropdownItem >
                                Reason to report
                              </DropdownItem>
                            </Link>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </ButtonGroup>
                    </div>
                  </div>
                  <div className="card-body">
                    {
                      this.state.status==='onreview' ? <Button onClick={() => this.onfilter(false)} size='sm' className='mb-2' color="success">On Review</Button>
                        : <Button onClick={() => this.onfilter('onreview')} size='sm' outline className='mb-2'>On Review</Button>
                    }
                    <InputGroup>
                      <Input
                        placeholder='Search by Customer Customer Name/Comments'
                        onChange={this.onChange}
                      />
                    </InputGroup>
                    <div style={{ maxWidth: window.innerWidth - 83 }}>
                      {
                        // !this.state.showTable ? <>Loading ...</> :
                        <DataTable
                          noHeader={true}
                          columns={this.state.columns}
                          data={this.state.data}
                          pagination={true}
                          paginationServer={true}
                          paginationPerPage={10}
                          paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                          paginationTotalRows={this.state.paginationTotalRows}
                          onChangePage={page => this.changePage(page)}
                          onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                        />
                      }
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <CommentForumReportedDetail
            open={this.state.showModalDetail}
            close={() => this.setState({ showModalDetail: false })}
            data={this.state.detailData}
            dataLoading={this.state.detailDataLoading}
            refresh={() => this.getList()}
          />
        </div>

    )
  }
}