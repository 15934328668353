import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../../api'
import {
  Spinner,
  Badge,
  Input,
  Col,
  Row,
  NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import constants from '../../../lib/constants';
import { _hasAccess } from '../../../middleware/accessMiddleware'
import ConfirmModal from '../../../components/notificationModal/ConfirmModal';

class index extends Component {

  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      id: query.get('id'),
      deleteId: null,
      menuCode: constants.MENU_LIST_MANUFACTURER,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          sortable: true,
          maxWidth: '5%'
        },
        {
          name: 'Product Code',
          selector: 'product_code',
          sortable: true
        },
        {
          name: 'SKU',
          selector: 'batch_number',
          sortable: true,
        },
        {
          name: 'Min. Redeem',
          selector: 'min_redeem',
          sortable: true,
        },
        {
          name: 'Created By',
          sortable: true,
          cell: data =>
            <label>
              {data.users?.username}
            </label>
        },
        {
          name: 'Status',
          sortable: true,
          cell: data =>
            <label>
              {data.status === 1 ?
                <Badge
                  color="success"
                  pill
                >
                  Active
                </Badge>
                :
                <Badge
                  color="danger"
                  pill
                >
                  Nonactive
                </Badge>
              }
            </label>
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: data =>
            <>
              <div>
                <Link to={`/product/batchnumber/config/${data.id}`}>
                  <button className="text-dark btn btn-sm btn-outline-link" ><li className="fa fa-eye"></li></button>
                </Link>
                {
                  this.state.scope.includes('update') ?
                    <Link to={`/product/batchnumber/config/edit/${data.id}`}>
                      <button className="text-primary btn btn-sm btn-outline-link" ><li className="fa fa-edit"></li></button>
                    </Link>
                    : null
                }
                {
                  this.state.scope.includes('delete') ?
                    <button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(data.id)}><li className="fa fa-trash"></li></button>
                    : null
                }
              </div>
            </>
        }
      ],
      loaderPage: true,
      data: [],
      editData: {},
      showTable: false,
      showModal: false,
      search: '',
      fake: false,
      wrong_report: false,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
    this.setState({
      loaderPage: false
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    API.get(`product/batchnumber/config/${this.state.id}?size=`
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModal: !this.state.showModal,
      deleteId: id,
    });
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    this.setState({
      search: e.target.value
    }, () => {
      if (this.state.search.length > 2 || this.state.search.length === 0) {
        this.getList();
      }
    })
  }

render() {
  return (
    !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">

            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title align-middle">SKU Config</strong>
                  <div className="float-right">
                    <Link to={`/product/batchnumber/config/add/${this.state.id}`}>
                      <button className='btn btn-sm btn-info'>
                        <i className='fa fa-plus-square mr-1'></i> Add Config
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <Row className="row-cols-lg-auto g-3 align-items-center mb-4">
                    <Col>
                      <Input
                        id="search"
                        name='search'
                        placeholder="Search by Product Code/Variant"
                        type="text"
                        onChange={this.onChange}
                      />
                    </Col>
                  </Row>
                  {
                    // !this.state.showTable ? <>Loading ...</> :
                    <DataTable
                      noHeader={true}
                      columns={this.state.columns}
                      data={this.state.data}
                      pagination={true}
                      paginationServer={true}
                      paginationPerPage={10}
                      paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                      paginationTotalRows={this.state.paginationTotalRows}
                      onChangePage={page => this.changePage(page)}
                      onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmModal
          open={this.state.showModal}
          close={() => this.showConfirmDelete()}
          afterSubmit={() => this.getList()}
          id={this.state.id}
          url={`product/batchnumber/config-delete/${this.state.deleteId}`}
        />
      </div>
  )
}
}

export default index;