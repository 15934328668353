import React, { Component } from 'react';
import API from '../../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import NotifToast from '../../../components/notificationModal/NotifToast';

export default class ConfigAdd extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      error: {},
      disabled: true,
      loading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      optionProductType: [],
      body: {
        tipe_produk_id: '',
        commission: ''
      },
      required: ['tipe_produk_id', 'commission'],
    }
  }

  componentDidMount() {
    //Load Tipe Produk
    API.get('affiliate/product-type?option=true', { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          optionProductType: resp.data.data
        })
      })
    
    API.get(`affiliate/config/${this.state.id}`, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          body: resp.data.data
        })
      })
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is Required'
    }
    else {
      delete error[name]
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    this.setState({ loading: true });
    // alert(JSON.stringify(this.state.body)); return;
    API.put('affiliate/config/'+ this.state.id, this.state.body, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({ loading: false });
        this.setState({
          body: {},
          notif: {
            title: 'Success',
            message: result.message,
          }
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false }
          });
          if (result.status) {
            this.props.history.push(`/affiliate/config/${result.data.id}`);
          }
        }, 1500);
      })
      .catch(err => {
        this.setState({ loading: false });
      })
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className='d-flex justify-content-center'>
              <div className="col-xl-5 px-0">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Edit Commission Config Affiliate</strong>
                  </div>
                  <div className="card-body">
                    <Form>
                      <FormGroup>
                        <Label className='w-100'>Product Type</Label>
                        <Input
                          name="tipe_produk_id"
                          type="select"
                          value={this.state.body.tipe_produk_id}
                          onChange={this.onChange}
                          required
                          invalid={this.state.error['tipe_produk_id']}
                        >
                          {
                            <>
                              <option value={''}>Choose</option>
                              {
                                this.state.optionProductType.map(item => {
                                  return <option key={item.value} value={item.value}>{item.label}</option>
                                })
                              }
                            </>
                          }
                        </Input>
                        <FormFeedback >{this.state.error['tipe_produk_id']}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>Commission</Label>
                        <InputGroup>
                          <Input
                            name="commission"
                            placeholder="Enter Commission"
                            type="text"
                            value={this.state.body.commission}
                            onChange={this.onChange}
                            required
                            invalid={this.state.error['commission']}
                          />
                        </InputGroup>
                        <FormFeedback >{this.state.error['commission']}</FormFeedback>
                      </FormGroup>
                      <Button className='w-100' color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                        {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
        />
      </div>
    )
  }
}