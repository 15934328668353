import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AxiosErrorHandler from './components/AxiosErrorHandler';
import Footer from './components/Footer';
import Header from './components/Header';

import LeftPanel from './components/LeftPanel';
import RightPanel from './components/RightPanel';
import Routes from './components/Routes';
import index from './pages/role/indexRole';

function App() {
  var login = localStorage.getItem('isLoggedIn');
  if (login === 'true') {
    return (
      <>
        <AxiosErrorHandler>
          <div id="right-panel" className="right-panel h-100">
            <Header />
            <div className='h-100'>
              <BrowserRouter>
                <LeftPanel />
                <div className='h-100 d-flex flex-column justify-content-between'>
                  <Routes />
                  <Footer />
                </div>
              </BrowserRouter>
            </div>
            {/* <div className="clearfix"></div> */}

          </div>
        </AxiosErrorHandler>
      </>
    )
  } else {
    return (
      <div className='h-100'>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </div>
    )
  }
}

export default App;
