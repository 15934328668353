import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import API from "src/api";
import NotifToast from "src/components/notificationModal/NotifToast";

const init_body = {
  name: ''
}

export default class ViewSurat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      notif: false,
      notifTitle: 'Success',
      notifMessage: '',
      error: {},
      buttonLoading: false,
      buttonLoadingAdd: false,
      body: init_body
    }
  }

  async downloadFile(url) {
    const FileSaver = require('file-saver');

    const response = await fetch(url);
    const blob = await response.blob();
    FileSaver.saveAs(blob, this.props.filename ? this.props.filename + '.pdf' : 'Surat Penawaran.pdf');
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="lg">
          <ModalHeader>
            <div className="d-flex justify-content-between w-100">
              Surat Penawaran
              <Button color="primary" onClick={() => this.downloadFile(this.props.path)}>
                <i className="fa fa-download mr-1"></i>Unduh
              </Button>
            </div>

          </ModalHeader>
          <ModalBody>
            {/* Load Object PDF */}
            <iframe
              title="Surat Penawaran"
              src={this.props.path}
              width="100%"
              height="750px"
              style={{ border: 'none' }}
            >
              <p>Your browser does not support iframes.</p>
            </iframe>
          </ModalBody>
        </Modal>
        <NotifToast
          show={this.state.notif}
          close={() => this.setState({ notif: false })}
          title={this.state.notifTitle}
          message={this.state.notifMessage}
        />
      </>
    )
  }
}