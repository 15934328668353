import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Badge,
  Input,
  FormFeedback,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { addCommas, formatDate } from "src/lib/helpers";
import API from 'src/api'
import NotifToast from "src/components/notificationModal/NotifToast";

export default class LeadsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
      modalReason: false,
      disabledReject: true,
      notif: {
        show: false,
        status: true,
        message: ''
      },
      previewImage: false,
      showDetail: false,
      body: {
        status: null,
        note: null
      },
      activeTab: 'profile'
    }
  }

  async onSubmit(status) {
    this.setState({
      buttonLoading: true
    })

    if (status == 3 && this.state.body.note == '') {
      this.setState({
        error: {
          note: 'Reason is required'
        },
        buttonLoading: false
      })
      return false;
    }
    let body = { status: status, note: this.state.body.note };
    // alert(JSON.stringify(body)); return;
    API.put('/scn/member/approval/' + this.props.data.id, body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          buttonLoading: false,
          notif: {
            show: true,
            status: true,
            message: res.data.message
          }
        });

        this.props.close()
        this.props.refresh();

        setTimeout(() => {
          this.setState({
            notif: {
              show: false,
              status: true,
              message: ''
            },
            error: {},
          })
        }, 3000);
      })
      .catch(err => {
        this.setState({
          buttonLoading: false
        })
        this.props.close()
        console.log(err);
      })
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="lg">
          <ModalHeader toggle={() => this.props.close()}>Detail Member</ModalHeader>
          <ModalBody>
            <Nav className="mb-4" tabs>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === 'profile' ? 'active font-weight-bold' : 'text-secondary'}
                  onClick={() => this.setState({ activeTab: 'profile' })}
                >
                  Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === 'config' ? 'active font-weight-bold' : 'text-secondary'}
                  onClick={() => this.setState({ activeTab: 'config' })}
                >
                  Config
                </NavLink>
              </NavItem>
            </Nav>
            {
              this.props.dataLoading ?
                <div className="">
                  <div className="skeleton skeleton-text" style={{ width: '25%' }}></div>
                  <div className="skeleton skeleton-body"></div>
                  <div className="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                  <div className="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                </div>
                :
                <>
                  <Form className={this.state.activeTab === 'profile' ? 'show' : 'd-none'}>
                    <div className="card-forum">
                      <div className="card-forum-body">
                        <div className="content-forum">
                          <label className="small text-primary font-weight-bold">Personal</label>
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Fullname</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.fullname} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Date of Birth</label>
                                <div className="text-dark font-weight-bold"> {formatDate(this.props.data.dob, false)} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Email</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.email} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Number Phone</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.phone ?? '-'} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Gender</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.gender} </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Full Address</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.address}, {this.props.data.city?.name}, {this.props.data.province?.name}, {this.props.data.postal_code} </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Bank Name</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.rekening_bank} </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Rekening Number</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.rekening_number} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Number Identity</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.ktp_number} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Identity Image</label>
                                <div className="text-dark font-weight-bold d-flex gap-2">
                                  <img
                                    src={this.props.data.ktp_image}
                                    width={135}
                                    onClick={() => {
                                      this.setState({
                                        previewImage: this.props.data.ktp_image,
                                      });
                                      this.props.close()
                                    }}
                                    alt=""
                                    className="img-fluid pe-auto"
                                    role="button"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Selfie Image</label>
                                <div className="text-dark font-weight-bold d-flex gap-2">
                                  <img
                                    src={this.props.data.ktp_image_selfie}
                                    width={135}
                                    onClick={() => {
                                      this.setState({
                                        previewImage: this.props.data.ktp_image_selfie,
                                      });
                                      this.props.close()
                                    }}
                                    alt=""
                                    className="img-fluid pe-auto"
                                    role="button"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <label className="small text-primary font-weight-bold mt-2">Social Media</label>
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Instagram</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.instagram} </div>
                                <div className="text-dark font-weight-normal small "> {addCommas(this.props.data.instagram_follower)} Followers </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Twitter</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.twitter} </div>
                                <div className="text-dark font-weight-normal small "> {addCommas(this.props.data.twitter_follower)} Followers </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Tiktok</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.tiktok} </div>
                                <div className="text-dark font-weight-normal small "> {addCommas(this.props.data.tiktok_follower)} Followers </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Category</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.sosmed_category.replaceAll(';', ', ')} </div>
                              </div>
                            </div>
                          </div>
                          <div className="row bg-light p-2 m-0 rounded mt-2">
                            <div className="col-md-8">
                              <div className="form-group">
                                <label className="small text-secondary">SCN Info from</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.scn_info_by ?? '-'} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary">Status</label>
                                <div className="text-dark font-weight-bold">
                                  <Badge
                                    className="badge-c"
                                    color={this.props.data.status == 1 ? 'warning' : (this.props.data.status == 2 ? 'success' : 'danger')}
                                    pill
                                  >
                                    {this.props.data.status_name}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="small text-secondary mb-2">Notes</label>
                                <div className="text-dark font-weight-bold">
                                  {
                                    this.props.data.status == 1 ? (
                                      <>
                                        <Input
                                          name="note"
                                          type="textarea"
                                          placeholder="Enter Note"
                                          rows="2"
                                          defaultValue={this.props.data.note}
                                          disabled
                                        />
                                        <FormFeedback>{this.state.error.note}</FormFeedback>
                                      </>
                                    ) : (
                                      <div className="text-dark font-weight-bold"> {this.props.data.note ?? '-'} </div>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className={this.state.activeTab === 'config' ? 'show' : 'd-none'}>
                    <div className="card-forum">
                      <div className="card-forum-body">
                        <div className="content-forum">
                          <label className="small text-primary font-weight-bold">Referral</label>
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Referral Code</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.scn_member_config?.referral_code ?? '-'} </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Point</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.scn_member_config?.point_referral ?? '-'} </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Max Mission</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.scn_member_config?.referral_max_mission ?? '-'} </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Finish Redeem</label>
                                <div>
                                  <Badge color={this.props.data.scn_member_config?.is_redeem_referral == 1 ? 'success' : (this.props.data.scn_member_config?.is_redeem_referral == 0 ? 'danger' : '-')} pill>
                                    {this.props.data.scn_member_config?.is_redeem_referral == 1 ? 'Yes' : (this.props.data.scn_member_config?.is_redeem_referral == 0 ? 'No' : '-')}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          </div>
                          <label className="small text-primary font-weight-bold mt-4">Referrer</label>
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Name</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.scn_member_config?.scn_member_referrer?.fullname ?? '-'} </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Point</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.scn_member_config?.point_referrer ?? '-'} </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Max Mission</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.scn_member_config?.referrer_max_mission ?? '-'} </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Finish Redeem</label>
                                <div>
                                <Badge color={this.props.data.scn_member_config?.is_redeem_referrer == 1 ? 'success' : (this.props.data.scn_member_config?.is_redeem_referrer == 0 ? 'danger' : '-')} pill>
                                    {this.props.data.scn_member_config?.is_redeem_referrer == 1 ? 'Yes' : (this.props.data.scn_member_config?.is_redeem_referrer == 0 ? 'No' : '-')}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
            }
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.previewImage} toggle={() => { this.setState({ previewImage: false }); this.props.reOpen(); }} size="lg">
          <i className="w-full h3  fa fa-times-circle position-absolute p-2 top-0 end-0" role="button" onClick={() => { this.setState({ previewImage: false }); this.props.reOpen(); }}></i>
          <img src={this.state.previewImage} alt="" className="img-fluid" />
        </Modal>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          status={this.state.notif.status}
          message={this.state.notif.message}
        />
      </>

    )
  }
}