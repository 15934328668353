import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from 'src/api'
import {
  Spinner,
  Badge,
  Input,
  Col,
  Row,
  Label,
  Button,
  FormText,
  FormGroup,
  CustomInput,
  FormFeedback,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import ConfirmModal from 'src/components/notificationModal/ConfirmModal';
import { Image } from 'react-bootstrap';
import NotifToast from 'src/components/notificationModal/NotifToast';

class index extends Component {

  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      id: query.get('id'),
      deleteId: null,
      menuCode: constants.MENU_LIST_MANUFACTURER,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          sortable: true,
          maxWidth: '5%'
        },
        {
          name: 'Question',
          selector: 'question',
          sortable: true
        },
        {
          name: 'Name',
          selector: 'name',
          sortable: true,
        },
        {
          name: 'Type',
          selector: 'type',
          sortable: true,
        },
        {
          name: 'Required',
          sortable: true,
          center: true,
          cell: data =>
            <label>
              {data.is_required === 1 ?
                <i className='text-success fa fa-check-circle'></i>
                :
                <i className='text-danger fa fa-times-circle'></i>
              }
            </label>
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: data =>
            <>
              <div>
                <Link to={{ pathname: `/config/scan-web/question/${data.id}`, state: { referrer: `config/scan-web?id=${this.state.id}` } }}>
                  <button className="text-dark btn btn-sm btn-outline-link" ><li className="fa fa-eye"></li></button>
                </Link>
                {
                  this.state.scope.includes('update') ?
                    <Link to={`/config/scan-web/question/edit/${data.id}`}>
                      <button className="text-primary btn btn-sm btn-outline-link" ><li className="fa fa-edit"></li></button>
                    </Link>
                    : null
                }
                {
                  this.state.scope.includes('delete') ?
                    <button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(data.id)}><li className="fa fa-trash"></li></button>
                    : null
                }
              </div>
            </>
        }
      ],
      loaderPage: true,
      buttonLoading: false,
      disabled: true,
      body: {
        manufacturer_id: query.get('id'),
        banner: null,
        url_link: '-',
      },
      bannerDefault: null,
      urlLinkDefault:null,
      required: ['banner'],
      error: {},
      data: [],
      editData: {},
      showTable: false,
      showModal: false,
      search: '',
      notif: {
        show: false,
        status: true,
        message: '',
        title: 'Success'
      },
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1
    };
  }

  async componentDidMount() {
    await this.checkAccess();
    this.onLoad();
    this.setState({
      loaderPage: false
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  onLoad = () => {
    API.get(`manufacturer/${this.state.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }).then(res => {
      this.setState({
        body: {
          ...this.state.body,
          banner: res.data.data[0].scanweb_banner,
        },
        bannerDefault: res.data.data[0].scanweb_banner,
        urlLinkDefault: res.data.data[0].url_link
      })
    });

    this.getList();
  }

  getList = () => {
    API.get(`/config/scanweb/question/${this.state.id}?size=`
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      + '&manId=' + this.state.id
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModal: !this.state.showModal,
      deleteId: id,
    });
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onSearch = (e) => {
    this.setState({
      search: e.target.value
    }, () => {
      if (this.state.search.length > 2 || this.state.search.length === 0) {
        this.getList();
      }
    })
  }

  onSubmit = () => {
    const data = this.state.body;

    let body = new FormData();
    body.append("manufacturer_id", data.manufacturer_id);
    body.append("banner", data.banner);
    body.append("url_link", data.url_link);

    API.put('config/scanweb/banner', body, { headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          body: {
            manufacturer_id: this.state.id,
            banner: null,
          },
          buttonLoading: false,
          bannerDefault: result.data.scanweb_banner,
          notif: {
            show: true,
            title: 'Success',
            message: result.message,
            status: result.status
          }
        });

        setTimeout(() => {
          this.setState({
            notif: {
              show: false
            }
          })
        }, 3000);
      })
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is Required'
    }
    else {
      delete error[name]
    }

    if (name === 'banner') {
      if (e.target.files[0].size > 2000000) {
        error[name] = 'File size must be less than 2MB';
        newBody[name] = '';
      }
      else if (!e.target.files[0].type.includes('image')) {
        error[name] = 'File must be an image';
        newBody[name] = '';
      }
      else {
        newBody[name] = e.target.files[0];
      }
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-md-3">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title align-middle">Banner Scan</strong>
                  </div>
                  <div className="card-body">
                    <Row className="row-cols-auto g-3 align-items-center mb-4">
                      <Col>
                        <FormGroup>
                          <Label>Upload Banner</Label>
                          <CustomInput
                            name='banner'
                            placeholder="Search by Product Code/Variant"
                            type="file"
                            accept="image/*"
                            onChange={this.onChange}
                            invalid={this.state.error.banner}
                          />
                          <FormText><div className={`${this.state.error.banner ? 'text-danger' : 'text-muted'} text-xs`}> {this.state.error.banner ?? 'Maximum file size 2MB'} </div></FormText>
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <Label>Url Link</Label>
                        <Input
                          id="url_link"
                          name='url_link'
                          type="text"
                          defaultValue={this.state.urlLinkDefault}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col className='w-100'>
                        <Button className='w-100' color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                          {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                        </Button>
                      </Col>
                      <Col className='w-100 border-bottom border-2' />
                      <Col>
                        <Image
                          src={this.state.bannerDefault}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title align-middle">List Question Form</strong>
                    <div className="float-right">
                      <Link to={`/config/scan-web/question/add?id=${this.state.id}`}>
                        <button className='btn btn-sm btn-info'>
                          <i className='fa fa-plus-square mr-1'></i> Add Question
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <Row className="row-cols-lg-auto g-3 align-items-center mb-4">
                      <Col>
                        <Input
                          id="search"
                          name='search'
                          placeholder="Search by Question"
                          type="text"
                          onChange={this.onSearch}
                        />
                      </Col>
                    </Row>
                    {
                      // !this.state.showTable ? <>Loading ...</> :
                      <DataTable
                        noHeader={true}
                        columns={this.state.columns}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            open={this.state.showModal}
            close={() => this.showConfirmDelete()}
            afterSubmit={() => this.getList()}
            id={this.state.id}
            url={`config/scanweb/question/${this.state.deleteId}`}
          />
          <NotifToast
            show={this.state.notif.show}
            close={() => this.setState({ notif: { show: false } })}
            title={this.state.notif.title}
            status={this.state.notif.status}
            message={this.state.notif.message}
          />
        </div>
    )
  }
}

export default index;