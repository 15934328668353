import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Alert,
  Nav,
  NavItem,
  FormGroup,
  Label,
  Input,
  Spinner,
  FormFeedback,
} from 'reactstrap';
import API from "../../api";
import ReportedForumCarousel from "./partials/ReportedForumCarousel";
import { NavLink } from "react-bootstrap";
import moment from "moment";
import NotifToast from "../../components/notificationModal/NotifToast";

const init_body = {
  reason: '',
  status: 1
}

const required = ['reason', 'status']

export default class ReportedReasonEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      body: init_body,
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: false,
      notifTitle: 'Success',
      notifMessage: '',
    }
  }

  onSubmit() {
    this.setState({ buttonLoading: true });
    API.put('forum/reason-report/' + this.props.data.id, this.state.body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          body: init_body,
          buttonLoading: false,
          notif: true,
          notifTitle: 'Success',
          notifMessage: resp.data.message,
        });

        setTimeout(() => {
          this.setState({
            notif: false,
          });
        }, 3000)

        this.props.refresh()
        this.props.close()
      })
      .catch(error => {
        this.props.refresh()
        this.props.close()
      })
  }

  onChange = (e) => {
    const error = this.state.error;
    const name = e.target.name;
    const isRequired = required.includes(name)
    if (!e.target.value && isRequired) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    const newBody = this.props.data
    newBody[e.target.name] = e.target.value
    this.setState({
      body: newBody,
      error: error
    }, () => {
      this.onValidation()
    });

  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="md">
          <ModalHeader toggle={() => this.props.close()}>Edit Reason Report</ModalHeader>
          <ModalBody>
            {
              this.props.dataLoading ?
                <div className="">
                  <div class="skeleton skeleton-text" style={{ width: '25%' }}></div>
                  <div class="skeleton skeleton-body"></div>
                  <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                  <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                </div>
                :
                <>
                  <Form>
                    <FormGroup>
                      <Label>
                        Reason
                      </Label>
                      <Input
                        id="reason"
                        name="reason"
                        placeholder="Enter Reason"
                        type="textarea"
                        onChange={this.onChange}
                        value={this.props.data.reason}
                        invalid={this.state.error['reason'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['reason']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label >
                        Status
                      </Label>
                      <Input
                        id="status"
                        name="status"
                        type="select"
                        onChange={this.onChange}
                        value={this.props.data.status}
                        invalid={this.state.error['status'] ? true : false}
                      >
                        <option value={'1'}>{'Active'}</option>
                        <option value={'0'}>{'Deleted'}</option>
                      </Input>
                      <FormFeedback >{this.state.error['status']}</FormFeedback>
                    </FormGroup>
                  </Form>
                </>
            }
          </ModalBody>
          <ModalFooter>
            <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled && this.state.error !== ''}>
              {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
            </Button>
            <Button color="danger" onClick={() => this.props.close()}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <NotifToast
          show={this.state.notif}
          close={() => this.setState({ notif: false })}
          title={this.state.notifTitle}
          message={this.state.notifMessage}
        />
      </>
    )
  }
}