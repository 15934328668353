import React, { Component } from 'react';
import API from '../../api'
import {
    CustomInput, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import { capitalize } from 'src/lib/helpers';

export default class GenerateQRCode extends Component {
    constructor(props) {
        super(props);
        this.handleUploadChange = this.handleUploadChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.query = new URLSearchParams(this.props.location.search);
        this.state = {
            data: {},
            logoFileLabel: "Choose File",
            logoFile: "",
            logo: "",
            website_links: "",
            type: "",
            manufacturer_tag: "",
            manufacturerTags: [],
            manufacturerTagSelected: [],
            flag_show: 1,
            positions: 1,
            email_address: ''
        }
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.getManufacturer(id);
        this.getManufacturerTag();
    }

    getManufacturer(id) {
        API.get("manufacturer/" + id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                console.log("Detail manufacturer", res.data.data[0]);
                this.setState({
                    data: res.data.data[0],
                    name: res.data.data[0].name,
                    type: res.data.data[0].type,
                    logo: res.data.data[0].logo,
                    positions: res.data.data[0].positions ?? 0,
                    flag_show: res.data.data[0].flag_show,
                    email_address: res.data.data[0].email_address,
                    website_links: res.data.data[0].website_links,
                    manufacturer_tag: res.data.data[0].manufacturer_tag
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    getManufacturerTag() {
        API.get('/manufacturer/tag', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                console.log(JSON.stringify(res.data))
                if (res && res.data.status) {
                    console.log(JSON.stringify(res.data.data))
                    this.setState({
                        manufacturerTags: res.data.data
                    })
                }
            })
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {
        let body = new FormData();
        if (this.state.logoFile == "") {
            body.append("logo", this.state.logo);
        } else {
            body.append("logo", this.state.logoFile);
        }

        body.append("name", this.state.name);
        body.append("email_address", this.state.email_address)
        body.append("flag_show", this.state.flag_show)
        body.append("positions", this.state.positions ?? 0)
        body.append("website_links", this.state.website_links)
        body.append("manufacturer_tag", this.state.manufacturer_tag)
        body.append("type", this.state.type)

        API.put('manufacturer/' + this.state.data.manufacturer_id, body, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                if (response.status == 200) {
                    // this.props.history.push("/manufacturer/" + (this.query.get('type') ?? ''));
                }
            })
            .catch(error => {
                console.log("Error generate qrcode", error);
            });
    }

    handleUploadChange(event) {
        console.log("Logo file: ", event.target.files[0]);

        this.setState({
            logoFileLabel: event.target.files[0].name,
            logoFile: event.target.files[0],
        });
    }

    toggleTagList() {
        this.getManufacturerTag();
        this.setState({
            showTagList: !this.state.showTagList,
        })
    }

    addTag() {
        let name = document.querySelector("input[name=manufacturer_tag_name]").value;
        API.post('/manufacturer/tag', JSON.stringify({ name: name, created_by: JSON.parse(localStorage.getItem('loginData')).users_id }), { headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res) {
                    // alert(JSON.stringify(res.data))
                    document.querySelector("input[name=manufacturer_tag_name]").value = '';
                    this.getManufacturerTag();
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 400) {
                    // alert(err.response.status)
                    alert(err.response.data.message)
                }
            })
    }

    editTag(id) {
        API.put('/manufacturer/tag/' + id, JSON.stringify({ name: this.state.editTagName }), { headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res) {
                    this.setState({
                        editTagId: '',
                        editTagName: ''
                    })
                    this.getManufacturerTag();
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 400) {
                    // alert(err.response.status)
                    alert(err.response.data.message)
                }
            })
    }

    editTagWithSelectedTag(id) {
        API.put('/manufacturer/tag/' + id, JSON.stringify({ name: this.state.editTagName }), { headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res) {
                    this.setState({
                        // manufacturerTagSelected:[this.state.editTagName],
                        manufacturer_tag: this.state.editTagName,
                        editTagId: '',
                        editTagName: ''
                    })
                    this.getManufacturerTag();
                }
            })
    }

    deleteTag(id, name) {
        API.delete('/manufacturer/tag/' + id, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res) {
                    this.getManufacturerTag();
                    if (this.state.manufacturer_tag === name) {
                        // alert('Yes')
                        this.setState({
                            manufacturer_tag: this.state.manufacturerTags[0].name
                        })
                    }
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 400) {
                    // alert(err.response.status)
                    alert(err.response.data.message)
                }
            })
    }

    render() {
        return (
            <div className="content">
                <div className="animated fadeIn">
                    <div className="row">
                        <div className='d-flex justify-content-center'>
                            <div className="col-xl-6 px-0">
                                <Modal isOpen={this.state.showTagList} toggle={() => { this.toggleTagList() }} >
                                    <ModalHeader>Select Tags</ModalHeader>
                                    <ModalBody>
                                        <table className='w-100'>
                                            <tr>
                                                <th>Name</th>
                                                <th className='w-50 text-center'>Actions</th>
                                            </tr>
                                            {
                                                this.state.manufacturerTags.map((item, index) => {
                                                    return (
                                                        <tr className='mb-3'>
                                                            {
                                                                item.id === this.state.editTagId
                                                                    ?
                                                                    <td>
                                                                        <form onSubmit={(e) => { e.preventDefault(); if (item.name === this.state.manufacturer_tag) { this.editTagWithSelectedTag(item.id); } else { this.editTag(item.id); } }}>
                                                                            <input value={this.state.editTagName} onChange={(e) => { this.setState({ editTagName: e.target.value }) }} className="form-control" />
                                                                        </form>
                                                                    </td>
                                                                    :
                                                                    <td>{item.name}</td>
                                                            }
                                                            <td className='text-center'>
                                                                <button onClick={() => { this.setState({ manufacturer_tag: item.name }); this.toggleTagList(); }} className='btn btn-success mr-1'>Select</button>
                                                                {
                                                                    item.id === this.state.editTagId
                                                                        ?
                                                                        <button onClick={() => { if (item.name === this.state.manufacturerTagSelected[0]) { this.editTagWithSelectedTag(item.id); } else { this.editTag(item.id); } }} className='btn btn-success mr-1'>Edit</button>
                                                                        :
                                                                        <button onClick={() => { this.setState({ editTagId: item.id, editTagName: item.name }) }} className='btn btn-success mr-1'>Edit</button>
                                                                }
                                                                <button onClick={() => { this.deleteTag(item.id, item.name) }} className='btn btn-success'>Delete</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </table>
                                    </ModalBody>
                                    <ModalFooter>
                                        <form style={{ position: 'relative' }} className='form-inline col-12' onSubmit={(e) => { e.preventDefault(); this.addTag(); }}>
                                            <input style={{ width: '88%' }} name="manufacturer_tag_name" className='form-control' />
                                            <button type='submit' className='btn btn-primary' style={{ position: 'absolute', right: 0 }}>Add</button>
                                        </form>
                                    </ModalFooter>
                                </Modal>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <strong className="card-title">Edit Manufacturer {this.query.get("type") ? capitalize(this.query.get("type")) : ''}</strong>
                                            </div>
                                            <div className="card-body">
                                                <div className="form-group has-success">
                                                    <label for="name" className="control-label mb-1">Name</label>
                                                    <input
                                                        id="name" name="name" type="text"
                                                        className="form-control valid"
                                                        data-val="true"
                                                        data-val-required="Please enter the the value of QR Code"
                                                        autocomplete="name" aria-required="true"
                                                        aria-invalid="false" aria-describedby="name"
                                                        value={this.state.name}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="help-block field-validation-valid" data-valmsg-for="name" data-valmsg-replace="true"></span>
                                                </div>
                                                <div className="form-group has-success">
                                                    <label for="name" className="control-label mb-1">Email</label>
                                                    <input
                                                        name="email_address"
                                                        type="email"
                                                        className="form-control valid"
                                                        value={this.state.email_address}
                                                        onChange={this.handleChange}
                                                        placeholder='Enter Email'
                                                    />
                                                    <span className="help-block field-validation-valid" data-valmsg-for="name" data-valmsg-replace="true"></span>
                                                </div>
                                                <div className='mb-3'>
                                                    <label for="name" className="control-label mb-1">Type Manufacturer</label>
                                                    <div className='d-flex gap-5'>
                                                        <FormGroup check>
                                                            <Input
                                                                name="manufacturer_type"
                                                                type="radio"
                                                                value={this.state.type}
                                                                checked={this.state.type === 'guest'}
                                                                onChange={() => {
                                                                    this.setState({
                                                                        type: 'guest'
                                                                    })
                                                                }}
                                                            />
                                                            <Label check>Guest Partner</Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Input
                                                                name="type"
                                                                type="radio"
                                                                value={this.state.type}
                                                                checked={this.state.type === 'partner'}
                                                                onChange={() => {
                                                                    this.setState({
                                                                        type: 'partner'
                                                                    })
                                                                }}
                                                            />
                                                            <Label check>Our Partner</Label>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className={`${this.state.type === 'partner' ? 'show' : 'hide'}`}>
                                                    <div className="form-group has-success">
                                                        <label for="website_links" className="control-label mb-1">Website Link</label>
                                                        <input
                                                            id="website_links" name="website_links" type="text"
                                                            className="form-control valid"
                                                            data-val="true"
                                                            data-val-required="Please enter the the value of Website Links"
                                                            autocomplete="website_links" aria-required="true"
                                                            aria-invalid="false" aria-describedby="website_links"
                                                            value={this.state.website_links}
                                                            onChange={this.handleChange}
                                                            placeholder='Enter Website Link'
                                                        />
                                                        <span className="help-block field-validation-valid" data-valmsg-for="name" data-valmsg-replace="true"></span>
                                                    </div>
                                                    <div className="form-group has-success">
                                                        <label for="website_links" className="control-label mb-1 d-flex justify-content-between">
                                                            Manufacturer Tag
                                                            <button className='btn btn-primary' onClick={() => { this.toggleTagList() }}>
                                                                <i className='fa fa-list' /> Manage Tag
                                                            </button>
                                                        </label>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <select className='form-control' onChange={(e) => { this.setState({ manufacturer_tag: e.target.value }) }}>
                                                                    {
                                                                        !this.state.manufacturer_tag
                                                                            ?
                                                                            <option disabled selected>Select Manufacturer Tag</option>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        this.state.manufacturerTags.map((item, index) => {
                                                                            return (
                                                                                this.state.manufacturer_tag === item.name
                                                                                    ?
                                                                                    <option selected disabled value={item.name}>{item.name}</option>
                                                                                    :
                                                                                    <option value={item.name}>{item.name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group has-success">
                                                        <label for="logoFile" className="control-label mb-1">Logo</label>
                                                        <div className='mb-2'>
                                                            {
                                                                this.state.log ? <img src={this.state.logo} alt={this.state.name} style={{ maxWidth: "150px" }} /> : null
                                                            }
                                                        </div>
                                                        <CustomInput
                                                            type="file"
                                                            id="logoFile"
                                                            name="logoFile"
                                                            label={this.state.logoFileLabel}
                                                            onChange={e => this.handleUploadChange(e)}
                                                        />
                                                        <span className="help-block field-validation-valid" data-valmsg-for="logoFile" data-valmsg-replace="true"></span>
                                                    </div>
                                                    <div className='form-group  has-success'>
                                                        <label>Show Brand</label>
                                                        <div>
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input form-switch form-switch" type="checkbox" value={this.state.flag_show} checked={this.state.flag_show === 1} role="switch" id="flexSwitchCheckDefault" onClick={() => { this.setState({ flag_show: this.state.flag_show === 1 ? 0 : 1 }) }} />
                                                                <label class="label-inline">{this.state.flag_show === 1 ? 'show' : 'hide'}</label>
                                                            </div>
                                                        </div>
                                                        {/* <input className='form-control' type='number' value={this.state.statusEdit} onChange={(e)=>{ this.setState({ password:e.target.value }) }}/> */}
                                                    </div>
                                                    <div className="form-group has-success">
                                                        <label for="positions" className="control-label mb-1">Positions</label>
                                                        <input
                                                            id="positions" name="positions" type="number"
                                                            className="form-control valid"
                                                            data-val="true"
                                                            data-val-required="Please enter the the value"
                                                            autocomplete="positions" aria-required="true"
                                                            aria-invalid="false" aria-describedby="positions"
                                                            value={this.state.positions}
                                                            onChange={this.handleChange}
                                                        />
                                                        <span className="help-block field-validation-valid" data-valmsg-for="name" data-valmsg-replace="true"></span>
                                                    </div>
                                                </div>
                                                <button type="button" className="btn btn-primary mt-3 w-100" onClick={this.handleSubmit}>Save Changes</button>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}