import React, { Component } from 'react';
import API from '../../../api';
export default class ManufacturerName extends Component{
    constructor(props){
        super(props);
        this.state = {
            name:''
        }
    }

    async componentDidMount(){
        let man = await API.get('manufacturer/'+this.props.manId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }});
        this.setState({
            name: man.data[0].name
        })
    }

    render(){
        return(
            <td>{this.state.name ? this.state.name : '-'}</td>
        )
    }
}