import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
  Spinner,
  InputGroup,
  Input,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import moment from 'moment';
import HistoryScanDetail from './HistoryScanDetail';
import { formatDate } from '../../lib/helpers';

class HistoryScan extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_LIST_FORUM,
      scope: [],
      columns: [
        {
          name: 'Username',
          sortable: true,
          cell: data => {
            return data.username ? data.username : '-'
          }
        },
        {
          name: 'QR Code ID 36',
          selector: 'qrcode_id36',
          sortable: true
        },
        {
          name: 'Product ID',
          selector: 'product_id',
          sortable: true,
        },
        {
          name: 'Manufacturer',
          selector: 'manufacturer_name',
          sortable: true,
        },
        {
          name: 'Varian 1',
          selector: 'variant',
          sortable: true,
        },
        {
          name: 'Date Scan',
          sortable: true,
          cell: data =>
            <>
              {formatDate(data.created_at)} WIB
            </>
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: data =>
            <>
              <button className="text-dark btn btn-sm btn-outline-link" onClick={() => this.showDetail(data.location_id)}><li className="fa fa-eye"></li></button>
            </>
        }
      ],
      loaderPage: true,
      data: [],
      editData: {},
      id: null,
      showTable: false,
      showModal: false,
      search: false,
      fake: false,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      detailData: {},
      detailDataLoading: false,
      showModalDetail: false
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
    this.setState({
      loaderPage: false
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    console.log(access)
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    const search = this.state.search;
    console.log(search);
    API.get('qrcode/history-scan?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    this.setState({
      search: e.target.value
    }, () => {
      this.getList()
    })
  }

  showDetail(historyId) {
    this.setState({
      detailDataLoading: true,
      showModalDetail: true
    })
    API.get('qrcode/history-scan/' + historyId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          detailData: result.data,
          detailDataLoading: false
        })

      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">History Scan</strong>
                  </div>
                  <div className="card-body">
                    <InputGroup>
                      <Input
                        placeholder='Search by Email/QR Code ID/ QR Code Base36ID'
                        onChange={this.onChange}
                      />
                    </InputGroup>
                    {
                      // !this.state.showTable ? <>Loading ...</> :
                      <DataTable
                        noHeader={true}
                        columns={this.state.columns}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HistoryScanDetail
            open={this.state.showModalDetail}
            close={() => this.setState({ showModalDetail: false })}
            data={this.state.detailData}
            dataLoading={this.state.detailDataLoading}
            refresh={() => this.getList()}
          />
        </div>
    )
  }
}

export default HistoryScan;