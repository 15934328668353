import React, { Component } from 'react';
import API from '../../api'
import {
  Input,
  Spinner,
  Table
} from 'reactstrap';
import { Link } from 'react-router-dom';

export default class mappingRole extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          name: 'ID',
          selector: 'id',
          sortable: true
        },
        {
          name: 'Name',
          selector: 'name',
          sortable: true
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: role =>
            <div>
              {/* <a href={`user/detail/${user.users_id}`} className="mr-2"><li className="text-dark fa fa-eye"></li></a> */}
              <Link to={`/role/edit/${role.id}`}>
                <button className="text-primary btn btn-sm btn-outline-link" ><li className="fa fa-edit"></li></button>
              </Link>
              {/* <button type="button" className="btn text-danger btn-sm btn-outline-link" onClick={this.toggle.bind(this, manufacturer.manufacturer_id)}>
                <li className="fa fa-trash"></li>
              </button> */}
            </div>
        }
      ],
      loaderPage: true,
      data: [],
      editData: {},
      editDataId: null,
      showTable: false,
      showModal: false,
      showModalEdit: false,
      disableButtonModal: false,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 5,
      paginationTotalRows: 0,
      paginationPage: 1
    };
    // this.modalUser = this.modalUser.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.setState({
      loaderPage: false
    })
  }

  getData() {
    API.get(`role/menu?id=${this.props.id}&group=${this.props.group}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data,
          showTable: true
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changeEditData(body) {
    this.setState({ editData: body });
  }

  onChange(e, id, scopeIndex, menuId, groupId) {
    const value = e.target.checked
    const body = {
      "role_id": this.props.id,
      "permission_id": id,
      "status": value
    }

    if (!this.props.readonly) {
      API.put('role/permission', body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((resp) => {
          const data = this.state.data
          data[groupId].group_menus[menuId].scope[scopeIndex].status = value

          this.setState({
            data: data
          })

        })
        .catch(err => {
          alert(err)
        })
    }
  }

  afterSubmit(status, message) {
    this.getListUser()

    this.setState({
      alert: {
        show: true,
        status: status,
        message: message
      }
    });

    setTimeout(() => {
      this.setState({
        alert: {
          show: false,
          status: '',
          message: ''
        }
      });
    }, 3000)
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <strong className="card-title">Manage Roles</strong>
              {/* <NavLink href="/user/add" > */}
              <div className="float-right">
                {!this.state.showTable && <Spinner size='sm'>Loading</Spinner>}

              </div>
              {/* </NavLink> */}
            </div>
            <div className="card-body">
              <Table
                className='table'
              >
                <thead>
                  <tr className='text-center'>
                    <th>
                      Menu
                    </th>
                    <th>
                      Hide
                    </th>
                    <th>
                      Create
                    </th>
                    <th>
                      Read
                    </th>
                    <th>
                      Update
                    </th>
                    <th>
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.data.map((item, itemIndex) => {
                      return (
                        <>
                          <tr className='font-weight-bold bg-dark text-white' style={{ fontSize: '13px' }}>
                            <td colSpan={6}>{item.group_name}</td>
                          </tr>
                          {
                            item.group_menus.map((menus, menusIndex) => {
                              const crud = ['hide', 'create', 'read', 'update', 'delete'];
                              return (
                                <tr style={{ fontSize: '13px' }}>
                                  <td>{menus.name}</td>
                                    {
                                      crud.map(cr => {
                                        let scope = menus.scope.filter(sc => sc.name === cr)
                                        let scopeIndex = menus.scope.findIndex(item => item.name === cr);
                                        return (
                                          <td className='text-center'>
                                            {
                                              scope.length ?
                                                <Input
                                                  disabled={this.props.readonly ? true : false}
                                                  style={{ marginLeft: '-0.25rem' }}
                                                  type="checkbox"
                                                  checked={scope[0].status == null ? false : scope[0].status}
                                                  onChange={(e) => this.onChange(e, scope[0].id, scopeIndex, menusIndex, itemIndex)}
                                                />
                                                : null
                                            }                                           
                                          </td>
                                        )
                                      })
                                    }
                                </tr>
                              )
                            })
                          }
                        </>
                      )
                    })
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}