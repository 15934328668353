import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from 'src/api'
import {
  Button,
  Spinner,
  Badge,
  InputGroup,
  Input
} from 'reactstrap';
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import ConfirmModal from 'src/components/notificationModal/ConfirmModal';
import { formatDate } from 'src/lib/helpers';
import Detail from './Detail';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_SCN_MEMBER,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          maxWidth: '5%'
        },
        {
          name: 'Register Date',
          cell: data =>
            formatDate(data.created_userapp_at) + ' WIB',
        },
        {
          name: 'Full Name',
          cell: data => data.fullname,
        },
        {
          name: 'Email',
          cell: data => data.email,
        },
        {
          name: 'Number Phone',
          cell: data => data.phone ?? '-',
        },
        {
          name: 'Gender',
          cell: data => data.gender ?? '-',
        },
        {
          name: 'City',
          cell: data => data.city?.name ?? '-',
        },
        {
          name: 'Referral Code',
          cell: data => data.scn_member_config?.referral_code ?? '-',
        },
        {
          name: 'Point',
          center: true,
          cell: data =>  data.point?.total_point ?? 0,
        },
        {
          name: 'Status',
          cell: data =>
            <label>
              <Badge
                color={data.status == 1 ? 'success' : 'danger'}
                pill
              >
                {data.status_name}
              </Badge>
            </label>
        },
        {
          name: 'Action',
          selector: 'action',
          cell: data =>
            <>
              <div>
                <button type='button' className="text-dark btn btn-sm btn-outline-link" onClick={() => this.showDetail(data.id)} ><li className="fa fa-eye"></li></button>
                {/* {
                  this.state.scope.includes('delete') && data.status == 1 ?
                    <button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(data.id)}><li className="fa fa-trash"></li></button>
                    : null
                } */}
              </div>
            </>
        }
      ],
      data: [],
      showTable: false,
      showModalDelete: false,
      showModalDetail: false,
      search: '',
      status: '',
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      totalWaiting: 0,
      selectedDataLoading: true,
      selectedData: {}
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status === 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    const search = this.state.search;
    console.log(search);
    API.get('scn/member?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      + '&status=' + this.state.status
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true,
          totalWaiting: result.data.totalWaiting
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
      id: id,
    });
  }

  filterApproval = (status) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.set('status', status);
    if (status === '') {
      params.delete('status');
    }
    url.search = params.toString();
    this.props.history.push(`${window.location.pathname}${url.search}`);
    this.setState({
      status: status
    }, () => {
      this.getList();
    })
  };

  showDetail(id) {
    this.setState({
      showModalDetail: true
    })

    this.getDetail(id);
  }

  getDetail = (id) => {
    this.setState({
      detailDataLoading: true,
    })
    API.get('scn/member/' + id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          selectedData: result.data,
          selectedDataLoading: false
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    if (e.target.value.length > 3 || e.target.value === '') {
      this.setState({
        search: e.target.value
      }, () => {
        this.getList()
      })
    }
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Data Member</strong>
                  </div>
                  <div className="card-body">
                    <div className='d-flex'>
                      <InputGroup className='w-auto'>
                        <Input
                          placeholder='Search by Name/Email/Phone'
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      <Link to={'/scn/member/registration'}>
                        <Button
                          onClick={this.state.status === 1 ? this.filterApproval.bind(this, '') : this.filterApproval.bind(this, 1)}
                          size='md'
                          className='ml-2'
                          outline={this.state.status === 1 ? false : true}
                          color={this.state.status === 1 ? 'success' : 'secondary'}
                        >
                          <i className='fa fa-user mr-2'></i>
                          Registration <Badge color='warning'>{this.state.totalWaiting}</Badge>
                        </Button>
                      </Link>
                    </div>
                    {
                      <DataTable
                        noHeader={true}
                        columns={this.state.columns}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            text={`Are you sure want to delete this member ?`}
            open={this.state.showModalDelete}
            close={() => this.showConfirmDelete()}
            afterSubmit={() => this.getList()}
            id={this.state.id}
            url={`scn/member/${this.state.id}`}
          />
          <Detail
            open={this.state.showModalDetail}
            close={() => this.setState({ showModalDetail: false })}
            reOpen={() => this.setState({ showModalDetail: true })}
            data={this.state.selectedData}
            dataLoading={this.state.selectedDataLoading}
            refresh={() => this.getList()}
            onSuccess
          />
        </div>
    )
  }
}

export default index;