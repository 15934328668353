import React, { Component } from 'react';

export default class UnauthorizedPage extends Component {
  render() {

    return (
      <div className="content">
        PAGE 403
      </div>)
  }
}