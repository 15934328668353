import React, { Component, useRef } from 'react';
import API from '../../api';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import * as htmlToImage from 'html-to-image';
import {
    CustomInput,
    Alert,
    Modal,
    ModalFooter,
    ModalHeader,
    ModalBody,
    Button
} from 'reactstrap';
import LoadingBar from 'react-top-loading-bar'
import imageCompression from 'browser-image-compression';
import { times } from 'lodash';
import { ThemeConsumer } from 'styled-components';
import ManufacturerName from './components/ManufacturerName';
import moment from 'moment';
const { convert } = require("any-to-any");

export default class ListDistributor extends Component{
    constructor(props){
        super(props);

        this.state = {
            menuCode: constants.MENU_LIST_DISTRIBUTOR,
            scope: [],
            distributors: [],
            manufacturer: [],
            pageList:1,
            items:10,
            totalPages: 1,
            manufacturerId:'',
            manufacturerIdEdit:'',
            usernameEdit:'',
            statusEdit:'',
            onEnterPressed: false,
            modalEdit: false,
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount(){
        this.checkAccess();
        this.getManufacturer();
        this.getInitDistributorList();
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    async handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        setTimeout(()=>{
           this.getInitDistributorList();
        }, 500)
    }

    getInitDistributorList(){
        this.setState({
            loading: true,
            distributors: []
        })
        console.log('/distributor?size='+this.state.items+'&manId='+this.state.manufacturerId+'&page='+this.state.pageList+'&pagination=true')
        API.get('/distributor?size='+this.state.items+'&manId='+this.state.manufacturerId+'&page='+this.state.pageList+'&pagination=true', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
        .then((res)=>{
            if(res.data.data.item.length > 0){
                this.setState({
                    distributors: res.data.data.item,
                    totalPages: res.data.data.totalPage,
                    loading: false
                })
            } else {
                this.setState({
                    distributors: res.data.data.item,
                    totalPages: res.data.data.totalPage,
                    loading: false
                })
                if(this.state.pageList > this.state.totalPages){
                    if(this.state.totalPages > 0){
                        this.setState({
                            pageList: this.state.totalPages
                        })
                        setTimeout(()=>{
                            this.getInitDistributorList();
                        }, 50)
                    }
                }
            }
        })
    }

    increaseOffset(){
        if(this.state.pageList < this.state.totalPages){
            this.setState({
                pageList: parseInt(this.state.pageList)+1,
                distributors: []
            })
            setTimeout(()=>{
                this.getInitDistributorList();
            }, 100)
        } else {
            alert('You have reached last page')
        }
    }

    decreaseOffset(){
        if(this.state.pageList > 1){
            this.setState({
                pageList: parseInt(this.state.pageList)-1,
                distributors: []
            })
            setTimeout(()=>{
                this.getInitDistributorList();
            }, 100)
        } else {
            alert('You have reached first page')
        }
    }

    async getManufacturer(){
        let manufacturer = await API.get('manufacturer?size=20&page=1', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }});
        let manufacturerOptions = await API.get('manufacturer/options', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
        this.setState({
            manufacturer: manufacturerOptions.data
        })
    }

    async getManDetails(id){
        let man = await API.get('manufacturer/'+id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }});
        let td = [<td>aaa</td>];
        return td;
    }

    getManufacturerOption(){
        let items = [<option key={0} value={''} selected disabled>Choose Manufacture</option>];
        let manufacturer = this.state.manufacturer;
        // console.log("List manufacturer", manufacturer)
        
        for(let i=0; i < manufacturer.length; i++){
            items.push(<option key={manufacturer[i].manufacturer_id} value={manufacturer[i].manufacturer_id} data-name={manufacturer[i].name}>{manufacturer[i].name}</option>);
        }
        return items;
    }

    updateDistributor(e){
        e.preventDefault();
        API.put('distributor/'+this.state.distIdEdit,
            JSON.stringify({
                name: this.state.usernameEdit,
                manufacturer_id: this.state.manufacturerIdEdit,
                status: this.state.statusEdit,
                updated_by: 1
            }), 
            {
                headers: { 
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}` 
                }
            }
        )
        .then(res=>{
            if(res.data.status){
                alert('Success Update Distributor')
                this.setState({
                    modalEdit: !this.state.modalEdit, 
                    distIdEdit:'', 
                    manufacturerIdEdit:'', 
                    usernameEdit:'', 
                    statusEdit:''
                })
                this.getInitDistributorList();
            } else {
                alert('Failed Update Distributor')
                this.setState({
                    modalEdit: !this.state.modalEdit, 
                    distIdEdit:'', 
                    manufacturerIdEdit:'', 
                    usernameEdit:'', 
                    statusEdit:''
                })
            }
        })
    }

    setDropTimeoutEnterEvent(){
        setTimeout(()=>{ this.setState({ onEnterPressed:!this.state.onEnterPressed }) }, 100)
    }
    
    render(){
        return(
            <div className='content'>
                <Modal isOpen={this.state.modalEdit}
                    toggle={()=>this.setState({ modalEdit: !this.state.modalEdit, distIdEdit:'', manufacturerIdEdit:'', usernameEdit:'', statusEdit:'' })}
                    // modalTransition={{ timeout: 500 }}
                >
                    <ModalHeader>
                        <h5>Edit Distributor Data</h5>
                    </ModalHeader>
                    <ModalBody>
                        <form onSubmit={(e)=> { this.updateDistributor(e) }}>
                            <div className='form-group'>
                                <label>Select Manufacturer</label>
                                <select disabled className='form-control' onChange={(e)=>{ this.setState({ manufacturerId:e.target.value }) }}>
                                    {
                                        this.state.manufacturer.length > 0
                                        ?
                                        this.state.manufacturer.map((item, index)=>{
                                            return(
                                                item.manufacturer_id === this.state.manufacturerIdEdit
                                                ?
                                                <option selected value={item.manufacturer_id}>{item.name}</option>
                                                :
                                                <option value={item.manufacturer_id}>{item.name}</option>
                                            )
                                        })
                                        :
                                        <option>-</option>
                                    }
                                </select>
                            </div>
                            <div className='form-group'>
                                <label>Username</label>
                                <input className='form-control' type='username' value={this.state.usernameEdit} onChange={(e)=>{ this.setState({ usernameEdit:e.target.value }) }}/>
                            </div>
                            <div className='form-group'>
                                <label>Status</label>
                                <div>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input form-switch form-switch" type="checkbox" value={this.state.statusEdit} checked={this.state.statusEdit === 1} role="switch" id="flexSwitchCheckDefault" onClick={()=>{ this.setState({ statusEdit: this.state.statusEdit === 1 ? 0 : 1 }) }} />
                                        <label class="label-inline">{this.state.statusEdit === 1 ? 'active' : 'inactive'}</label>
                                    </div>
                                </div>
                                {/* <input className='form-control' type='number' value={this.state.statusEdit} onChange={(e)=>{ this.setState({ password:e.target.value }) }}/> */}
                            </div>
                            <button className='btn btn-primary' type='submit'>Submit</button>
                        </form>
                    </ModalBody>
                </Modal>
                <div className='card'>
                    <div className='card-header'>
                        <strong>List Distributor</strong>
                    </div>
                    <div className='card-body' style={{ height: this.state.distributors.length === 10 ? 560 : null, minHeight:560 }}>
                        <div class="form-group">
                            <label for="manufacturerId" class=" form-control-label">Manufacturer</label>
                            <select name="manufacturerId" id="manufacturerId" class="form-control" onChange={this.handleChange}>
                                {this.getManufacturerOption()}
                            </select>
                        </div>
                        {/* <p>{this.state.pageList}</p> */}
                        {/* fixed-height-list-queue-table */}
                        <table class="table vert-middle-table">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Distributor Name</th>
                                    <th scope="col">Manufacturer</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Created At</th>
                                    <th scope="col">Created By</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.distributors.length > 0
                                    ?
                                    this.state.distributors.map((item, index)=>{
                                        return(
                                            <tr>
                                                <td>{item.id ? item.id : '-'}</td>
                                                <td>{item.name ? item.name : '-'}</td>
                                                {
                                                    item.manufacturer_name
                                                    ?
                                                    <td>{item.manufacturer_name}</td>
                                                    // <ManufacturerName manId={item.manufacturer_id}/>
                                                    :
                                                    <td>-</td>
                                                }
                                                <td>{item.status === 1 ? 'Active' : 'Inactive'}</td>
                                                <td>{item.created_at ? moment(item.created_at).format('MMMM D YYYY HH:mm:ss') : '-'}</td>
                                                <td className='px-3'>{item.username ? item.username : '-'}</td>
                                                <td>
                                                    <div>
                                                        <button type="button" title="Edit" className="btn btn-sm btn-outline-link" onClick={()=>{ this.setState({ modalEdit:!this.state.modalEdit, manufacturerIdEdit:item.manufacturer_id, usernameEdit:item.name, statusEdit:item.status, distIdEdit:item.id }) }}>
                                                            <li className="fa fa-edit"></li>
                                                        </button> 
                                                        {/* <button type="button" title="Edit" className="btn btn-sm btn-outline-link" onClick={()=>{ alert('test') }}>
                                                            <li className="fa fa-trash"></li>
                                                        </button>  */}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    !this.state.loading
                                    ?
                                    <td colSpan={7} className="text-center">
                                        <span>No Distributor Data Found</span>
                                    </td>
                                    :
                                    <td colSpan={7} className="text-center">
                                        <span>Loading...</span>
                                    </td>
                                }
                            </tbody>
                        </table>
                        {/* <div className='pull-right'>
                            <button className='btn btn-info mr-2' onClick={()=>this.decreaseOffset()}>Prev</button>
                            <button className='btn btn-info' onClick={()=>this.increaseOffset()}>Next</button>
                        </div> */}
                    </div>
                    <div className='card-footer'>
                        <div className='row m-0 pt-2 pagination' style={{  }}>
                            <div className='col-md-4 col-3 offset-md-4 offset-6 text-right mb-2'>
                                <div className='row'>
                                    <div className='col-md-6 vertical-middle px-0 text-center'>
                                        <p className='vertical-middle-p'>Items per page</p>
                                    </div>
                                    <div className='col-md-4'>
                                        <select className='form-control' onChange={(e)=> { this.state.items = e.target.value; this.getInitDistributorList(); }}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 col-3 text-right mb-2'>
                                <div className='row'>
                                    <div className='col-md-1 vertical-middle px-0 text-center p-relative'>
                                        <button className='btn mr-2 button-center-div' onClick={()=>{ this.decreaseOffset() }}>
                                            <i className='fa fa-chevron-left'/>
                                        </button>
                                    </div>
                                    <div className='col-md-3 vertical-middle px-0 text-center'>
                                        <p className='vertical-middle-p'>Showing</p>
                                    </div>
                                    <div className='col-md-3 col-md-3-custom'>
                                        <form onSubmit={(e)=>{ e.preventDefault(); this.setState({ onEnterPressed: true }); this.setDropTimeoutEnterEvent();  this.getInitDistributorList(); this.pageInput.blur(); }}>
                                            <input onBlur={()=> { setTimeout(()=>{ if(!this.state.onEnterPressed){ this.getInitDistributorList(); } }, 50) }} ref={(input) => { this.pageInput = input; }}  className='form-control text-center pt-1' value={this.state.pageList} onChange={(e)=>{ this.setState({ pageList: e.target.value }) }}/>
                                        </form>
                                    </div>
                                    <div className='col-md-3 vertical-middle px-0 text-center'>
                                        <p className='vertical-middle-p'>of {this.state.totalPages === 0 ? '' : this.state.totalPages} pages</p>
                                    </div>
                                    <div className='col-md-1 vertical-middle px-0 text-center p-relative'>
                                        <button className='btn ml-2 button-center-div' onClick={()=>{ this.increaseOffset() }}>
                                            <i className='fa fa-chevron-right'/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}