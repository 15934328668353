import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Alert,
  Nav,
  NavItem,
} from 'reactstrap';
import API from "../../api";
import ReportedForumCarousel from "./partials/ReportedForumCarousel";
import { NavLink } from "react-bootstrap";
import moment from "moment";

export default class ReportedForumDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
      activeTab: 'detail'
    }
  }

  switchTab(tab) {
    this.setState({
      activeTab: tab
    })
  }

  submitHiddenForum() {
    this.setState({
      buttonLoading: true
    })

    API.put(`/forum/banned/${this.props.data.id}`, {}, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          buttonLoading: false
        })
        this.props.close();
        this.props.refresh();
      })
      .catch(err => {
        this.setState({
          buttonLoading: false
        })
        console.log(err)
      })
  }

  submitCloseReport() {
    this.setState({
      buttonLoading: true
    })

    API.put(`/forum/close-report-forum/${this.props.data.id}`, {}, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          buttonLoading: false
        })
        this.props.close();
        this.props.refresh();
      })
      .catch(err => {
        this.setState({
          buttonLoading: false
        })
        console.log(err)
      })
  }

  render() {
    return (
      <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="md">
        <ModalHeader toggle={() => this.props.close()}>Detail Reported Forum</ModalHeader>
        <ModalBody>
          <Nav className="mb-4" tabs>
            <NavItem>
              <NavLink
                className={this.state.activeTab === 'detail' ? 'active font-weight-bold' : 'text-secondary'}
                onClick={() => this.switchTab('detail')}
              >
                Forum
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab === 'report' ? 'active font-weight-bold' : 'text-secondary'}
                onClick={() => this.switchTab('report')}
              >
                User Report
              </NavLink>
            </NavItem>
          </Nav>
          {
            this.props.dataLoading ?
              <div className="">
                <div class="skeleton skeleton-text" style={{ width: '25%' }}></div>
                <div class="skeleton skeleton-body"></div>
                <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
              </div>
              :
              <>
                <Form className={this.state.activeTab === 'detail' ? 'show' : 'd-none'}>
                  <div className="card-forum">
                    <div className="card-forum-header">
                      <div className="font-weight-bold">
                        {this.props.data.fullname}
                      </div>
                      <div className="text-secondary small">
                        {moment.utc(this.props.data.created_at).format('DD MMMM YYYY HH:mm:ss')} WIB
                      </div>
                    </div>
                    <div className="card-forum-body">
                      <div className="mt-4">
                        <ReportedForumCarousel images={this.props.data.images} />
                      </div>
                      <div className="content-forum mt-4">
                        <div className="title font-weight-bold">
                          {this.props.data.product_name}
                        </div>
                        <div className="description">
                          {this.props.data.description}
                        </div>
                        <h6 className="mt-4 mb-2">Product Information</h6>
                        <div className="row bg-light p-2 m-0 rounded">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="small text-secondary">Brand</label>
                              <div className="text-dark font-weight-bold"> {this.props.data.brand_name} </div>
                            </div>
                            <div className="form-group">
                              <label className="small text-secondary">Place to Buy</label>
                              <div className="text-dark font-weight-bold"> {this.props.data.place_to_buy?.name} </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="small text-secondary">Purchase Date</label>
                              <div className="text-dark font-weight-bold"> {moment(this.props.data.purchase_date).format('DD MMMM YYYY')} </div>
                            </div>
                            <div className="form-group">
                              <label className="small text-secondary">Store Name</label>
                              <div className="text-dark font-weight-bold"> {this.props.data.is_sensor_store_name ? this.props.data.store_name.substring(0, 3) + '****' : this.props.data.store_name} </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  {
                    this.props.data.is_fake_product ?
                      <Alert color="danger mt-4 p-2">
                        Counterfeit Product Report
                      </Alert>
                      : null
                  }
                  {
                    this.props.data.is_banned ?
                      <Alert color="danger p-2">
                        This Forum is already hidden for all users
                      </Alert>
                      : null
                  }
                </Form>
                <div className={this.state.activeTab === 'report' ? 'show' : 'd-none'}>
                  {
                    this.props.data.user_reports?.length > 0 ?
                      this.props.data.user_reports.map((item, index) => {
                        return (
                          <div className={`row bg-light p-2 m-0 rounded mb-2`} >
                            <div className="font-weight-bold font-sm">
                              {item.customer.fullname}
                            </div>
                            <div className="text-secondary font-xs">
                              {moment.utc(item.created_at).format('DD MMMM YYYY HH:mm:ss')}
                            </div>
                            <div className="font-sm">
                              {item.description}
                            </div>
                          </div>
                        )
                      }) : null
                  }
                </div>
              </>
          }
        </ModalBody>
        <ModalFooter>          
          {
            !this.props.data.is_banned ?
              <>
                <Button color="success" className="float-left" onClick={() => this.submitHiddenForum()}>Hidden From All</Button>
                <Button color="warning" onClick={() => this.submitCloseReport()}>Close Report</Button>
              </>
              : <Button color="danger" onClick={() => this.props.close()}>Close</Button>
          }
        </ModalFooter>
      </Modal>
    )
  }
}