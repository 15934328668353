import React, { Component } from 'react';

export default class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {
            usernameLogin: null
        }
        this.getLoginData = this.getLoginData.bind(this)
    }

    componentDidMount() {
        this.getLoginData();
    }

    getLoginData() {
        var loginData = localStorage.getItem('loginData');
        var parseLoginData = JSON.parse(loginData)
        if (parseLoginData.data.username) {
            this.setState({
                usernameLogin: parseLoginData.data.username
            })
        }
    }

    render() {
        return (
            <header id="header" className="header">
                {/* <div className="top-left"> */}
                <div className="navbar-header d-flex">
                    <div className='d-flex'>
                        <a className="navbar-brand" href="./"><img src="/Shieldtag_Icon_Header.png" alt="Logo" /></a>
                        {/* <a className="navbar-brand hidden" href="./"><img src={process.env.PUBLIC_URL + 'Shieldtag_Icon_Header.png'} alt="Logo"/></a> */}
                        <a id="menuToggle" className="menutoggle visible-mobile"><i className="fa fa-bars"></i></a>
                        <a className="menutoggle visible-desktop cursor-default"><i className="fa fa-bars"></i></a>
                    </div>
                    <div className="dropdown user-area">
                        <button className="btn border-0 dropdown-toggle" type="button" id="user" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <p style={{ marginBottom: 0, paddingLeft: 8, paddingRight: 8 }}>{this.state.usernameLogin}</p><i className="fa fa-user-o"></i>
                        </button>
                        <div className="user-menu dropdown-menu" style={{ top: '17px!important' }} aria-labelledby="user">
                            <a className="nav-link" href="/logout"><i className="fa fa-power-off"></i>Logout</a>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                {/* <div className="top-right">
                    <div className="header-menu">
                        <div className="header-left">
                            <div className="dropdown user-area">
                                <button className="btn btn-secondary dropdown-toggle" type="button" id="user" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <p style={{marginBottom:0, paddingLeft:8, paddingRight:8}}>{this.state.usernameLogin}</p><i className="fa fa-user-o"></i>
                                </button>
                                <div className="user-menu dropdown-menu" style={{top: '17px!important'}} aria-labelledby="user">

                                    <a className="nav-link" href="/logout"><i className="fa fa-power-off"></i>Logout</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> */}
            </header>
        )
    }
}