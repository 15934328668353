import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
  Spinner,
  InputGroup,
  Input,
  Form,
  Row,
  Col,
  InputGroupText,
  Button,
  Alert,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import ConfirmModal from '../../components/notificationModal/ConfirmModal';
import CustomerDeletedDetail from './CustomerDeletedDetail';
import { formatDate } from '../../lib/helpers';
// import HistoryScanDetail from './HistoryScanDetail';

class CustomerDeletedIndex extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_LIST_FORUM,
      scope: [],
      columns: [
        {
          name: '#',
          selector: '#',
          width: '10px',
          style: {
            paddingLeft: '34px',
            paddingRight: '0px'
          },
          cell: data =>
            <Input type="checkbox" onChange={() => this.handleRowSelected(data.users_id)}  disabled={data.duration < 30} checked={this.state.selectedRows.includes(data.users_id) ? true : false} />
        },
        {
          name: 'Username',
          selector: 'username',
          sortable: true
        },
        {
          name: 'Fullname',
          selector: 'fullname',
          sortable: true
        },
        {
          name: 'Last Login',
          sortable: true,
          cell: data =>
            <>
              {formatDate(data.last_login)} WIB
            </>
        },
        {
          name: 'User Type',
          selector: 'user_type',
          sortable: true,
        },
        {
          name: 'Deleted At',
          cell: data =>
            <>
              {formatDate(data.deactived_at)} WIB
            </>
        },
        {
          name: 'Duration (day)',
          selector: 'duration',
          sortable: true,
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: data =>
            <>
              <button className="text-dark btn btn-sm btn-outline-link" onClick={() => this.showDetail(data.users_id)}><li className="fa fa-eye"></li></button>
            </>
        }
      ],
      loaderPage: true,
      data: [],
      editData: {},
      id: null,
      showTable: false,
      showModalDelete: false,
      showModalReactived: false,
      search: '',
      deactivedDate: '',
      removed: false,
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      selectedRows: [],
      selectedRowAll: false,
      toggleCleared: false,
      detailData: {},
      detailDataLoading: false,
      showModalDetail: false
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
    this.setState({
      loaderPage: false
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    console.log(access)
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    const search = this.state.search;
    API.get('user/deleted?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      + '&date=' + this.state.deactivedDate
      + '&removed=' + this.state.removed
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        const data = result.data.item;

        data.map(item => {
          this.state.selectedRows.map(selected => {
            if (item.users_id === selected) {
              item.is_selected = true;
            }
          })
        })

        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  handleRowSelected(usersId) {
    let oldState = this.state.selectedRows;

    if (this.state.selectedRows.includes(usersId)) {
      let index = oldState.indexOf(usersId);
      oldState.splice(index, 1);
    } else {
      oldState.push(usersId);
    }
    let unique = oldState.filter((value, index, array) => array.indexOf(value) === index);

    this.setState({
      selectedRows: unique,
      selectedRowAll: false
    }, () => {
      this.getList();
    })
  }

  async getSelectAll() {
    try {
      const res = await API.get('user/deleted?size=10000'
        + '&page=1'
        + '&search=' + this.state.search
        + '&date=' + this.state.deactivedDate
        + '&removed=' + this.state.removed,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      const result = res.data.data.item?.filter(item => item.duration > 30);
      return result
    } catch (err) {
      console.log("Error", err);
    }
  }

  async handleRowSelectedAll() {
    await this.getSelectAll().then(res => {
      let selected = [];
      if (!this.state.selectedRowAll) {
        res.map(item => {
          selected.push(item.users_id)
        })
      }

      this.setState({
        selectedRows: selected,
        selectedRowAll: !this.state.selectedRowAll
      }, () => {
        this.getList();
      })
      return;
    })
  }


  onChange = (e) => {
    if (e.target.name === 'deactivedDate') {
      this.setState({
        deactivedDate: e.target.value
      }, () => {
        this.getList()
      })
      return;
    }

    this.setState({
      search: e.target.value
    }, () => {
      this.getList()
    })
  }

  showDetail(userAppId) {
    this.setState({
      detailDataLoading: true,
      showModalDetail: true
    })
    API.get('user/' + userAppId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          detailData: result.data,
          detailDataLoading: false
        })

      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  showConfirmDelete = () => {
    this.setState({
      showModalDelete: !this.state.showModalDelete
    });
  }

  showConfirmReactived = () => {
    this.setState({
      showModalReactived: !this.state.showModalReactived
    });
  }

  shouldRemoved() {
    this.setState({
      removed: !this.state.removed
    }, () => {
      this.getList()
    })
  }

  render() {

    // const rowSelectCritera = row => row.users_id > 2;
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Account Customer</strong>
                  </div>
                  <div className="card-body">
                    <Form className='mb-4'>
                      <Row className="row-cols-lg-auto g-3 align-items-center">
                        <Col>
                          <Input
                            placeholder='Search by Email/Username/Phone Number'
                            onChange={this.onChange}
                          />
                        </Col>
                        <Col>
                          <InputGroup>
                            <InputGroupText>
                              Deactived Dates
                            </InputGroupText>
                            <Input
                              type="date"
                              name='deactivedDate'
                              onChange={this.onChange}
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          {
                            this.state.removed ? <Button onClick={() => this.shouldRemoved()} size='md' color="success">Should be Removed </Button>
                              : <Button onClick={() => this.shouldRemoved()} size='md' outline>Should be Removed</Button>
                          }
                        </Col>
                      </Row>
                    </Form>
                    {
                      this.state.selectedRows.length > 0 && (
                        <Alert className='d-flex justify-content-between p-2'>
                          <div className='mt-2'>
                            {this.state.selectedRows.length} Item Selected
                          </div>
                          <ButtonGroup>
                            <ButtonGroup>
                              <UncontrolledDropdown>
                                <DropdownToggle color='warning' caret>
                                  Action
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem onClick={() => this.showConfirmDelete()} >
                                    <span className='text-danger'><i className='fa fa-trash mr-2'></i>Delete</span>
                                  </DropdownItem>
                                  <DropdownItem onClick={() => this.showConfirmReactived()}>
                                    <span className='text-success'><i className='fa fa-refresh mr-2'></i>Reactive</span>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </ButtonGroup>
                          </ButtonGroup>
                        </Alert>
                      )
                    }
                    {
                      this.state.data.length > 0 && <button onClick={() => this.handleRowSelectedAll()} className={`btn btn-sm btn-outline-${this.state.selectedRowAll ? 'danger' : 'primary'}`}>
                        {this.state.selectedRowAll ? 'Unselect' : 'Select'} All
                      </button>
                    }
                    {
                      <DataTable
                        noHeader={true}
                        columns={this.state.columns}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CustomerDeletedDetail
            open={this.state.showModalDetail}
            close={() => this.setState({ showModalDetail: false })}
            data={this.state.detailData}
            dataLoading={this.state.detailDataLoading}
            refresh={() => this.getList()}
          />
          <ConfirmModal
            open={this.state.showModalDelete}
            close={() => this.showConfirmDelete()}
            afterSubmit={() => this.getList()}
            id={null}
            method='PUT'
            text={`Are you sure want to delete ${this.state.selectedRows.length} users ? All data related to deleted users cannot be recovered.`}
            body={{ users_id: this.state.selectedRows }}
            url={`user/deleted/batch`}
          />
          <ConfirmModal
            open={this.state.showModalReactived}
            close={() => this.showConfirmReactived()}
            afterSubmit={() => this.getList()}
            id={null}
            method='PUT'
            text={`Are you sure want to reactive ${this.state.selectedRows.length} users ?`}
            body={{ users_id: this.state.selectedRows }}
            url={`user/reactive/batch`}
          />
        </div>
    )
  }
}

export default CustomerDeletedIndex;