import React, { Component, useRef } from 'react';
import API from '../../api';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import * as htmlToImage from 'html-to-image';
import {
    CustomInput,
    Alert,
    Modal,
    ModalFooter,
    ModalHeader,
    ModalBody,
    Button
} from 'reactstrap';
import LoadingBar from 'react-top-loading-bar'
import imageCompression from 'browser-image-compression';
import { times } from 'lodash';
import { ThemeConsumer } from 'styled-components';
const { convert } = require("any-to-any");

export default class AddDistributor extends Component{
    constructor(props){
        super(props);
        this.state = {
            menuCode: constants.MENU_ADD_DISTRIBUTOR,
            scope: [],
            alertError: false,
            manufacturer: []
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        this.checkAccess();
        this.getManufacturer();
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'create');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    async handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        // setTimeout(()=>{
        //     alert(this.state.manufacturerId)
        // }, 500)
    }

    getManufacturerOption(){
        let items = [<option key={0} value={''} >Choose Manufacture</option>];
        let manufacturer = this.state.manufacturer;
        // console.log("List manufacturer", manufacturer)
        
        for(let i=0; i < manufacturer.length; i++){
            items.push(<option key={manufacturer[i].manufacturer_id} value={manufacturer[i].manufacturer_id} data-name={manufacturer[i].name}>{manufacturer[i].name}</option>);
        }
        return items;
    }

    async getManufacturer(){
        let manufacturer = await API.get('manufacturer?size=20&page=1', { headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` }});
        let manufacturerOptions = await API.get('manufacturer/options', { headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` }})
        this.setState({
            manufacturer: manufacturerOptions.data
        })
    }

    submitForm(e){
        e.preventDefault();
        let userId = JSON.parse(localStorage.getItem('loginData')).data.users_id
        let body = {
            name: this.state.distributor,
            manufacturer_id: this.state.manufacturerId,
            created_by: userId
        }
        // alert(JSON.stringify(body))
        API.post('/distributor', body, { headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` }})
        .then((res)=>{
            if(res.data.status){
                // alert(JSON.stringify(res.data))
                alert(res.data.message)
                this.props.history.push('/distributor')
            }
        })
        .catch((err) => {
            console.log(err)
            if(err.response && err.response.status === 400){
                // alert(err.response.status)
                alert(err.response.data.message)
            }
        })
    }

    render(){
        return(
            <div className="content">
                <LoadingBar onRef={ref => (this.LoadingBar = ref)} />
                <div className="animated fadeIn">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="card-title">Add Distributor</strong>
                                    {/* <p>{this.state.variantId}</p> */}
                                    <Alert 
                                        color="danger"
                                        isOpen={this.state.alertError}
                                    >
                                        {this.state.message}
                                    </Alert>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={(e)=>{ this.submitForm(e) }}>
                                        <div class="form-group">
                                            <label for="manufacturerId" class=" form-control-label">Manufacturer</label>
                                            <select name="manufacturerId" id="manufacturerId" class="form-control" onChange={this.handleChange}>
                                                {this.getManufacturerOption()}
                                            </select>
                                        </div>
                                        <div className="form-group has-success">
                                            <label for="distributor" className="control-label mb-1">Distributor Name</label>
                                            <input 
                                                id="distributor" name="distributor" type="text" 
                                                className="form-control valid" 
                                                data-val="true" 
                                                data-val-required="Please enter the Distributor Name" 
                                                autocomplete="distributor" aria-required="true" 
                                                aria-invalid="false" aria-describedby="distributor"
                                                value={this.state.distributor}
                                                onChange={this.handleChange}
                                            />
                                            {/* <span className="help-block field-validation-valid" data-valmsg-for="productCode" data-valmsg-replace="true"></span> */}
                                        </div>
                                        <button disabled={this.state.disable} type="button" className="btn btn-info" onClick={(e)=>{ this.submitForm(e) }}>Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}