import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from 'src/api'
import {
  Spinner,
  Badge,
  InputGroup,
  Input
} from 'reactstrap';
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import ConfirmModal from 'src/components/notificationModal/ConfirmModal';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_LEADS_TRACKING_TYPE_PRODUCT,
      scope: [],
      data: [],
      showTable: false,
      showModalDelete: false,
      showModalDetail: false,
      search: '',
      status: '',
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      totalWaiting: 0,
      selectedDataLoading: true,
      selectedData: {}
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status === 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    const search = this.state.search;
    console.log(search);
    API.get('product/type?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      + '&status=' + this.state.status
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true,
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    if (e.target.value.length > 3 || e.target.value === '') {
      this.setState({
        search: e.target.value
      }, () => {
        this.getList()
      })
    }
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Data Type Product</strong>
                    <div className="float-right">
                      <Link to='/leads-tracking/type-product/add'>
                        <button className='btn btn-sm btn-info'>
                          <i className='fa fa-plus-square mr-1'></i> Add Type Product
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className='d-flex'>
                      <InputGroup className='w-auto'>
                        <Input
                          placeholder='Search by Type Product'
                          onChange={this.onChange}
                        />
                      </InputGroup>
                    </div>
                    {
                      <DataTable
                        columns={[
                          {
                            name: 'ID',
                            selector: 'id',
                            maxWidth: '5%'
                          },
                          {
                            name: 'Tipe Produk',
                            selector: 'tipe_produk',
                          },
                          {
                            name: 'Deskripsi',
                            cell: data => data.deskripsi?.length > 80 ? data.deskripsi.substring(0, 80) + '...' : data.deskripsi,
                            width: '30%'
                          },
                          {
                            name: 'Created By',
                            cell: data => data.users?.username,
                          },
                          {
                            name: 'Status',
                            cell: data =>
                              <label>
                                <Badge
                                  color={data.status == 1 ? 'success' : 'danger'}
                                  pill
                                >
                                  {data.status_name}
                                </Badge>
                              </label>
                          },
                          {
                            name: 'Action',
                            selector: 'action',
                            cell: data =>
                              <>
                                <div>
                                  <Link to={`/leads-tracking/type-product/${data.id}`}>
                                    <button type='button' className="text-dark btn btn-sm btn-outline-link"  ><li className="fa fa-eye"></li></button>
                                  </Link>
                                  {
                                    this.state.scope.includes('update') && data.status == 1 ?
                                      <Link to={`/leads-tracking/type-product/edit/${data.id}`}>
                                        <button className="text-primary btn btn-sm btn-outline-link" ><li className="fa fa-edit"></li></button>
                                      </Link>
                                      : null
                                  }
                                </div>
                              </>
                          }
                        ]}
                        noHeader={true}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            text={`Are you sure want to delete this product ?`}
            open={this.state.showModalDelete}
            close={() => this.showConfirmDelete()}
            afterSubmit={() => this.getList()}
            id={this.state.id}
            url={`scn/member/${this.state.id}`}
          />
        </div>
    )
  }
}

export default index;