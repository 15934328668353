import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Badge
} from 'reactstrap';
import API from "../../../api";
import moment from "moment";
import { addCommas } from "../../../lib/helpers";

export default class LeadsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
      modalReason: false,
      disabledReject: true,
      notif: {
        show: false,
        status: true,
        message: ''
      },
      previewImage:false
    }
  }

  

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="lg">
          <ModalHeader toggle={() => this.props.close()}>Detail Leads</ModalHeader>
          <ModalBody>
            {
              this.props.dataLoading ?
                <div className="">
                  <div className="skeleton skeleton-text" style={{ width: '25%' }}></div>
                  <div className="skeleton skeleton-body"></div>
                  <div className="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                  <div className="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                </div>
                :
                <>
                  <Form>
                    <div className="card-forum">
                      <div className="card-forum-body">
                        <div className="content-forum">
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Nama PT</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.nama_pt} </div>
                              </div>
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Nomor Handphone</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.telp_pic} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Nama PIC</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.nama_pic} </div>
                              </div>
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Email PIC</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.email_pic} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Jabatan PIC</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.posisi_pic} </div>
                              </div>
                            </div>
                          </div>
                          <div className="row bg-light p-2 m-0 rounded mt-2">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="small text-secondary">Detail Kebutuhan</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.detail_kebutuhan} </div>
                              </div>
                            </div>
                          </div>
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary">Tujuan Utama Menggunakan Shieldtag</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.tujuan_penggunaan?.tujuan_penggunaan} </div>
                              </div>
                              <div className="form-group">
                                <label className="small text-secondary">Jumlah yang Ingin Dipesan dalam 1 Tahun</label>
                                <div className="text-dark font-weight-bold"> {addCommas(this.props.data.qty_pertahun)} </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary">Tipe Produk</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.tipe_produk?.tipe_produk} </div>
                              </div>
                              <div className="form-group">
                                <label className="small text-secondary">Jumlah Pemesanan Awal</label>
                                <div className="text-dark font-weight-bold"> {addCommas(this.props.data.qty_awal)} </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary">Tanggal Penggunaan</label>
                                <div className="text-dark font-weight-bold"> {moment(this.props.data.tgl_penggunaan).format('DD MMMM YYYY')} </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary">Status</label>
                                <div className="text-dark font-weight-bold">
                                  <Badge
                                    className="badge-c"
                                    color={this.props.data.status?.color}
                                    pill
                                  >
                                    {this.props.data.status?.name}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row bg-light p-2 m-0 rounded mt-2">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary">Jumlah Komisi</label>
                                <div className="text-dark font-weight-bold">Rp. {addCommas(this.props.data.estimasi_komisi)} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary">Tanggal Kadaluarsa</label>
                                <div className="text-dark font-weight-bold"> {moment(this.props.data.tgl_expired).format('DD MMMM YYYY')} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary">Dibuat oleh:</label>
                                <div className="text-dark font-weight-bold"> { this.props.data.partner?.username } </div>
                              </div>
                            </div>
                          </div>
                          <div className="row p-2 m-0 rounded">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="small text-secondary">Bukti Meeting</label>
                                <div className="text-dark font-weight-bold d-flex justify-content-between">
                                  {
                                    this.props.data.bukti_meeting.split(',')?.map((item, index) => {
                                      return (
                                        <img 
                                          key={index} 
                                          src={item} 
                                          width={135} 
                                          onClick={() => {
                                            this.setState({
                                            previewImage:item,
                                            });
                                            this.props.close()
                                          }}
                                          alt="" 
                                          className="img-fluid pe-auto" 
                                          role="button"
                                        />
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
            }
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => this.props.close()}>Close</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.previewImage} toggle={() => {this.setState({previewImage:false}); this.props.reOpen();}} size="lg">
          <i className="w-full h3  fa fa-times-circle position-absolute p-2 top-0 end-0" role="button" onClick={() => {this.setState({previewImage:false}); this.props.reOpen();}}></i>
          <img src={this.state.previewImage} alt="" className="img-fluid" />
        </Modal>
      </>

    )
  }
}