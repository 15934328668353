import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
    Button,
    Spinner,
    Badge,
    Modal,
    ModalFooter,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

class indexDashboardScn extends Component {

    constructor(props) {

        super(props);

        this.state = {
            filter: '',
            menuCode: constants.MENU_SCN_DASHBOARD,
            scope: [],
            modalDetail: false,
            columns: [
                {
                    name: 'ID Referral',
                    selector: 'member_id_referrer',
                    sortable: true,
                    maxWidth: '10%'
                },
                {
                    name: <strong>Email User</strong>,
                    selector: 'email',
                    sortable: true,
                    cell: data =>
                        <label>
                            {data.email}
                        </label>

                },
                {
                    name: <strong>Nama User</strong>,
                    cell: data =>
                        <label>
                            {data.fullname}
                        </label>
                },
                {
                    name: <strong>Point Referral</strong>,
                    sortable: true,
                    cell: data =>
                        <label>
                            {data.total_point_referral}
                        </label>
                },
                {
                    name: <strong>Jumlah Referral Member</strong>,
                    cell: data =>
                        <label>
                            {data.total_referral}
                        </label>
                },
                {
                    name: <strong>Detail</strong>,
                    cell: data =>

                        <button type="button" title="Detail" className="btn btn-sm btn-outline-link" onClick={() => { this.getListDetail(data.member_id_referrer) }}>
                            <li className="fa fa-eye"></li>
                        </button>
                },
            ],
            loaderPage: true,
            loadingExport: false,
            data: [],
            detailHistory: [],
            id: null,
            showTable: false,
            search: '',
            totalPointReferral: 0,
            totalReferral: 0,
            totalReferrer: 0,
            startDate: '',
            endDate: '',
            dateEnd: '',
            type: '',
            alert: {
                show: false,
                status: 'success',
                message: ''
            },
            startDate1: new Date(),
            endDate1: new Date(),
            paginationPerPage: 10,
            paginationTotalRows: 0,
            paginationPage: 1
        };
    }

    componentDidMount() {
        this.checkAccess();
        this.getList();
        this.getDashboard();
        this.setState({
            loaderPage: false
        })
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    getDashboard() {
        API.get('scn/dashboard-referral', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                const result = res.data;


                this.setState({
                    totalPointReferral: result.data.totalPointReferral,
                    totalReferral: result.data.totalReferral,
                    totalReferrer: result.data.totalReferrer,
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }
    getListDetail(id) {
        API.get('/scn/detail-referral/' + id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                const result = res.data;
                this.setState({
                    detailHistory: result.data,
                    modalDetail: !this.state.modalDetail
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    getList() {
        const search = this.state.search;
        API.get('scn/list-referral?size='
            + this.state.paginationPerPage
            + '&page=' + this.state.paginationPage
            , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                const result = res.data;
                this.setState({
                    data: result.data.item,
                    paginationTotalRows: result.data.totalItem,
                    showTable: true
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    changePage(page) {
        this.setState({
            paginationPage: page
        }, () => {
            this.getList();
        })
    }

    changeRowsPerPage(rowsPerPage) {
        this.setState({
            paginationPerPage: rowsPerPage
        }, () => {
            this.getList();
        })
    }

    onChange = (e) => {
        this.setState({
            search: e.target.value
        }, () => {
            this.getList()
        })
    }

    onChangeFilter = (e) => {
        // console.log(e);
        this.setState({
            filter: e.target.value
        }, () => {
            this.getList()
            this.getDashboard()
        })
    }

    onChangeLabel = (e) => {
        // console.log(e);
        this.setState({
            type: e.target.value
        }, () => {
            this.getList()
            this.getDashboard()
        })
    }

    handleEvent = (e, picker) => {
        const startDate = picker.startDate.format('YYYY-MM-DD');
        const endDate = picker.endDate.format('YYYY-MM-DD');
        console.log(picker);
        this.setState({
            startDate: startDate,
            endDate: endDate,
        }, () => {
            this.getList()
            this.getDashboard()
        })
    }
    closeModal = () => {
        this.setState({
            modalDetail: !this.state.modalDetail
        })
    }

    render() {
        const styles = {
            titleText: {
                fontSize: 32,
                fontWeight: 700,
                textAlign: 'left',
                paddingLeft: 16,
            },
            subTitleText: {
                fontSize: 15,
                fontWeight: 600,
                color: '#6C757D',
                marginBottom: 0,
            }
        };

        const options = [
            { value: 'new', label: 'New' },
            { value: 'total', label: 'Total' }
        ]
        return (

            !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
                <div className="content">
                    <Modal isOpen={this.state.modalDetail} size='lg'
                    >
                        <ModalHeader>
                            <h5>Detail History Point Referral User</h5>
                        </ModalHeader>
                        <ModalBody>
                            <ol>
                                {
                                    this.state.detailHistory.map((data) => {
                                        return data.mission_work.map((res) => {
                                            return (
                                                <li>Referral point sebesar {data.point_referral} Point pengerjaan {res.mission?.title} ({res.mission?.manufacturer?.name}) ID {data.id} dari user {data.scn_member?.fullname}</li>

                                            )

                                        })
                                    })
                                }
                            </ol>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={() => this.closeModal()}>Close</Button>
                        </ModalFooter>
                    </Modal>
                    <div className="animated fadeIn">
                        {/* <div className="row mb-4 align-items-end justify-content-end">
                            <div className="col-md-2 align-self-end">
                                <select placeholder="Select filter" class="form-control" onChange={this.onChangeFilter}>
                                    <option value="">Select filter</option>
                                    <option value="1">Cold</option>
                                    <option value="2">Hot</option>
                                    <option value="3">Super Hot</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <select placeholder="Select label" class="form-control" onChange={this.onChangeLabel}>
                                    <option value="">Select label</option>
                                    <option value="new">New</option>
                                    <option value="total">Total</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <DateRangePicker onApply={this.handleEvent}>
                                    <input type="text" className="form-control" />
                                </DateRangePicker>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div>
                                        <img class="float-left" src={process.env.PUBLIC_URL + '/assets/cold_leads.svg'}></img>
                                        <div class="float-left" style={styles.titleText} >{this.state.totalPointReferral ?? 0}</div>
                                    </div>
                                    <p style={styles.subTitleText}>Total Point Referral</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div>
                                        <img class="float-left" src={process.env.PUBLIC_URL + '/assets/leads.svg'}></img>
                                        <div class="float-left" style={styles.titleText} >{this.state.totalReferral ?? 0}</div>
                                    </div>
                                    <p style={styles.subTitleText}>Pengguna Referral</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div>
                                        <img class="float-left" src={process.env.PUBLIC_URL + '/assets/super_hot_leads.svg'}></img>
                                        <div class="float-left" style={styles.titleText} >{this.state.totalReferrer ?? 0}</div>
                                    </div>
                                    <p style={styles.subTitleText}>Total Referrer</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <strong className="card-title">List User Referral</strong>
                                        {
                                            <DataTable
                                                noHeader={true}
                                                columns={this.state.columns}
                                                data={this.state.data}
                                                pagination={true}
                                                paginationServer={true}
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                                                paginationTotalRows={this.state.paginationTotalRows}
                                                onChangePage={page => this.changePage(page)}
                                                onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default indexDashboardScn;