import React, { Component } from 'react';
import API from '../../api';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import moment from 'moment-timezone';

export default class SendNotif extends Component{

    constructor(props){
        super(props);
        this.state = {
            menuCode: constants.MENU_LIST_UNDONE_QUEUE_JOBS,
            scope: [],
            offset: 0,
            queueList: [],
            hours: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
            minutes: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,
                    31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59],
            selectedHour: null,
            selectedMinute: null,
            selectedDate: null,
        }
    }

    async componentDidMount(){
        this.checkAccess();
        let queueList = await API.get('/getQueueListPending/', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }});
        // alert(JSON.stringify(queueList.data))
        if(queueList.data.length > 0){
            this.setState({
                queueList: queueList.data
            })
        }
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    async increaseOffset(){
        this.state.offset = this.state.offset + 10;
        let queueList = await API.get('/getQueueList/'+this.state.offset, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }});
        // alert(JSON.stringify(queueList.data))
        if(queueList.data.length > 0){
            this.setState({
                queueList: queueList.data
            })
        }
        // alert(this.state.offset);
    }

    async decreaseOffset(){
        if(this.state.offset > 0){
            this.state.offset = this.state.offset - 10;
            let queueList = await API.get('/getQueueList/'+this.state.offset, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }});
            // alert(JSON.stringify(queueList.data))
            if(queueList.data.length > 0){
                this.setState({
                    queueList: queueList.data
                })
            }
            // alert(this.state.offset);
        }
    }

    async submitDate(){
        if(this.state.selectedDate && this.state.selectedHour && this.state.selectedMinute){
            // alert(this.state.selectedHour.length)
            if(this.state.selectedMinute.length === 1){
                this.state.selectedMinute = '0'+this.state.selectedMinute
            }
            if(this.state.selectedHour.length === 1){
                this.state.selectedHour = '0'+this.state.selectedHour
            }
            let result = await API.get('/getQueueListPendingByDate/'+this.state.selectedDate+' '+this.state.selectedHour+':'+this.state.selectedMinute, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
            this.setState({
                queueList: result.data
            })
            // console.log(this.state.selectedDate+' '+this.state.selectedHour+':'+this.state.selectedMinute)
        } else {
            alert('Please fill all date time fields')
        }
    }

    render(){
        return(
            <div className='container-fluid'>
                <div className='content'>
                    <div className='card'>
                        <div className='card-header'>
                            <strong>List Undone Queue Jobs</strong>
                        </div>
                        <div className='card-body'>
                            <div className='col-md-3 pull-right form-group inline-flex'>
                                <input className='form-control' type="date" onChange={(e)=>this.setState({selectedDate:e.target.value})}/>
                                <button onClick={()=>{ this.submitDate() }} className='btn btn-info ml-2'>Submit</button>
                            </div>
                            <div className='col-fl-10 ml-2 pull-right form-group'>
                                <div class='dropdown'>
                                    {
                                        this.state.selectedMinute
                                        ?
                                        <button class="btn btn-secondary dropdown-toggle selected" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {this.state.selectedMinute}m
                                        </button>
                                        :
                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Select Minutes
                                        </button>
                                    }
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {
                                            this.state.minutes.map((item,index)=>{
                                                return(
                                                    <a onClick={()=>this.setState({selectedMinute:item.toString()})} class="dropdown-item" href="#">{item}m</a>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-fl-15 pull-right form-group'>
                                <div class="dropdown">
                                    {
                                        this.state.selectedHour
                                        ?
                                        <button class="btn btn-secondary dropdown-toggle selected" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {this.state.selectedHour}H
                                        </button>
                                        :
                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Select Hours
                                        </button>
                                    }
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {
                                            this.state.hours.map((item,index)=>{
                                                return(
                                                    <a onClick={()=>this.setState({selectedHour:item.toString()})} class="dropdown-item" href="#">{item}H</a>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <table class="table fixed-height-list-queue-table">
                                <thead>
                                    <tr>
                                        <th scope="col">Job UID</th>
                                        <th scope="col">QR ID Range</th>
                                        <th scope="col">Manufacturer</th>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">SKU</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Created By</th>
                                        <th scope="col">Created At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.queueList.length > 0
                                        ?
                                        this.state.queueList.map((item, index)=>{
                                            return(
                                                <tr>
                                                    <td>{item.jobs_uid}</td>
                                                    <td>{item.qrid_range}</td>
                                                    <td>{item.name ? item.name : '-'}</td>
                                                    <td>{item.product_name ? item.product_name : '-'}</td>
                                                    <td>{item.sku ? item.sku : '-'}</td>
                                                    <td>{item.status}</td>
                                                    <td>{item.created_by}</td>
                                                    <td>{moment(item.created_at).format('MMMM D YYYY HH:mm:ss')}</td>
                                                </tr>
                                            )
                                        })
                                        :
                                        null
                                    }
                                </tbody>
                            </table>
                            {/* <div className='pull-right'>
                                <button className='btn btn-info mr-2' onClick={()=>this.decreaseOffset()}>Prev</button>
                                <button className='btn btn-info' onClick={()=>this.increaseOffset()}>Next</button>
                            </div> */}
                        </div>
                        {/* <div className='card-footer'>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}