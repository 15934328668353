import React, { Component } from 'react';
import API from '../../api';
import API_TOKEN from '../../apitoken';
import DataTable from 'react-data-table-component';
import {
    CustomInput
} from 'reactstrap';
import LoadingBar from 'react-top-loading-bar'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { addLogUsers } from '../../helpers/log';
import { _hasAccess } from '../../middleware/accessMiddleware';
import { formatDate } from '../../lib/helpers';

export default class ListRedeemVoucher extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            menuCode: 'list-redeem-voucher',
            menuId: null,
            scope: [],
            columns: [
                {
                    name: 'Id',
                    selector: 'id_voucher',
                    sortable: true,
                    maxWidth: '30px'
                },
                {
                    name: 'Voucher Code',
                    selector: 'voucher_code',
                    sortable: true
                },
                {
                    name: 'Name',
                    selector: 'nama_ktp',
                    sortable: true
                },
                {
                    name: 'Gender',
                    selector: 'jk',
                    sortable: true
                },
                {
                    name: 'Phone',
                    selector: 'no_wa',
                    sortable: true
                },
                {
                    name: 'Product Name',
                    selector: 'product_code',
                    sortable: true
                },
                {
                    name: 'Product Variant',
                    selector: 'batch_number',
                    sortable: true
                },
                {
                    name: 'Register Date',
                    cell: data =>
                        formatDate(data.created_at) + ' WIB',
                },
                {
                    name: 'Created By',
                    cell: data => data.user_redeem?.fullname
                },
            ],
            keyword: "",
            manufacturerId: "",
            madeIn: "",
            productionDate: "",
            expiredDate: "",
            manufacturer: [],
            countToken: 0,
            currentToken: 0,
            paginationPerPage: 10,
            paginationTotalRows: 0,
            paginationPage: 1,
            progressToken: null,
            disabled: false
        }
    }

    componentDidMount() {
        this.checkAccess();
        this.getRedeemVoucher();
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                menuId: access.id,
                scope: scope
            })
            return;
        }


        this.props.history.push(`/unauthorized`);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleChangeDate(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    getRedeemVoucher() {
        API.get('voucher-redeem?size=' + this.state.paginationPerPage + '&page=' + this.state.paginationPage, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                const data = res.data.data
                this.setState({
                    data: data.item,
                    paginationTotalRows: data.totalItem
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }
    searchRedeemVoucher(e) {
        API.get('voucher-redeem?size=' + this.state.paginationPerPage + '&page=' + this.state.paginationPage + '&keyword=' + this.state.keyword, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                console.log("List history", res.data.data.item);
                const data = res.data.data
                this.setState({
                    data: data.item,
                    paginationTotalRows: data.totalItem
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    changePage(page) {
        this.setState({
            paginationPage: page
        }, () => {
            this.searchRedeemVoucher();
        })
    }

    changeRowsPerPage(rowsPerPage) {
        this.setState({
            paginationPerPage: rowsPerPage
        }, () => {
            this.searchRedeemVoucher();
        })
    }

    startFetch = () => {
        this.LoadingBar.continuousStart()
    }

    onFinishFetch = () => {
        this.LoadingBar.complete()
    }

    render() {
        return (
            <div className="content">
                <LoadingBar onRef={ref => (this.LoadingBar = ref)} />
                <div className="animated fadeIn">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="card-title">List Redeem Voucher</strong>
                                </div>
                                <div className="card-body">

                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <input
                                                id="keyword" name="keyword" type="text"
                                                className="form-control valid"
                                                data-val="true"
                                                data-val-required="keyword"
                                                autocomplete="keyword" aria-required="true"
                                                aria-invalid="false" aria-describedby="keyword"
                                                placeholder="keyword by voucheer code or name"
                                                value={this.state.keyword}
                                                onChange={this.handleChange}
                                            // style={{ textTransform: 'uppercase' }}
                                            />
                                        </div>
                                        <div className='col-md-1'>
                                            <button type="button" className="btn btn-sm btn-outline-success w-50" onClick={(e) => this.searchRedeemVoucher(e)}><li className="fa fa-search"></li></button>
                                        </div>
                                    </div>
                                    <DataTable
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        pagination={true}
                                        paginationServer={true}
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                        paginationTotalRows={this.state.paginationTotalRows}
                                        onChangePage={page => this.changePage(page)}
                                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                                    />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}