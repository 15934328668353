import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner
} from 'reactstrap';

const required = ['username','email','role_id']

export default class DetailPlaceToBuy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
    }
  }

  componentDidMount() {

  }

  onChange = (e) => {
    
  }
  
  render() {
    return (
      <Modal isOpen={this.props.open} toggle={() => this.props.close()}>
        <ModalHeader toggle={() => this.props.close()}>Edit User</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>
                Name
              </Label>
              <Input
                type="text"
                disabled={true}
                value ={this.props.body.name}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                Status
              </Label>
              <Input
                type="text"
                disabled={true}
                value ={this.props.body.status ? 'Active' : 'Non-Active'}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => this.props.close()}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
}