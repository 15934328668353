import React, { Component } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";



export default class ConfirmModalSweet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      buttonLoading: this.props.loading ?? false,
      notif: false,
      status: false,
    }
  }

  componentDidUpdate() {
    if (this.props.show) {
      const MySwal = withReactContent(Swal)
      MySwal.fire({
        title: this.props.title ?? 'Are you sure ?',
        text: this.props.subtitle,
        icon: 'error',
        iconHtml: '<i class="fa fa-exclamation-triangle"></i>',
        iconColor: '#dc3545',
        showCancelButton: true,
        confirmButtonText: this.props.confirmText ?? 'Yes',
        cancelButtonText: this.props.cancelText ?? 'No, Cancel it',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-confirm-swal ' + this.props.confirmClass ?? '',
          cancelButton: 'btn-cancel-swal',
          title: 'text-dark text-xl2',
          popup: 'my-popup-class'
        }
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (this.props.onConfirm) {
              try {
                await this.props.onConfirm();
                console.log('onConfirm has been called');
              } catch (error) {
                console.error('An error occurred when calling onConfirm:', error);
              }
            }
            // MySwal.fire({
            //   title: "Deleted!",
            //   text: "Your file has been deleted.",
            //   icon: "success"
            // });
          }
          else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            this.props.onCancel();
            //   MySwal.fire({
            //     title: "Cancelled",
            //     text: "Your imaginary file is safe :)",
            //     icon: "error"
            //   });
          }
        })
    }
  }

  render() {
    return (<></>);
  }
}