/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import API from 'src/api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  InputGroupText,
  InputGroup,
  Badge,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NotifToast from 'src/components/notificationModal/NotifToast';
import { addCommas } from 'src/lib/helpers';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import ConfirmModalSweet from 'src/components/notificationModal/ConfrimModalSweet';
import { DownloadExcel } from 'src/lib/xlsx';

export default class Add extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      code: '',
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      body: {
        note: '',
        user_app_point_ids: [],
      },
      data: {},
      listMember: [],
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      required: [],
      page: {
        error: false,
        message: '',
        data: {},
      },
      confirmModal: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      loadingExport: false,
      selectedRows: [],
      selectedRowAll: false,
    }
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    })
    await this.getDetail();
    await this.handleRowSelectedAll();
    this.setState({
      loading: false,
    })
  }

  async getDetail() {
    const ids = this.state.selectedRows.join(',');
    await API.get('scn/withdrawal/detail-create?size='
    + this.state.paginationPerPage
    + '&page=' + this.state.paginationPage
    , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const data = resp.data.data;
        this.setState({
          data: data,
          listMember: data?.user_app_point?.item,
          page: {
            error: !resp.data.status ? true : false,
            message: resp.data.message,
            data: resp.data.data,
          }
        })
      })
  }

  async getDetailCount() {
    const ids = this.state.selectedRows.join(',');
    await API.get('scn/withdrawal/detail-create'
    + (ids.length > 0 ? '?ids=' + ids : '?ids=0')
    , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const data = resp.data.data;
        this.setState({
          data: data,
        })
      })
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is Required'
    }
    else {
      delete error[name]
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    this.setState({
      notif: {
        show: true,
        status: true,
        title: 'Processing...',
      },
      buttonLoading: true,
      confirmModal: false,
    });

    let body = this.state.body;
    // alert(JSON.stringify(this.state.body)); 
    // return;
    API.post('scn/withdrawal', body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          body: {},
          notif: {
            title: 'Success',
            status: result.status,
            message: result.message,
          },
          buttonLoading: false,
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false },
          });
          this.props.history.push(`/scn/withdrawal`);
        }, 1500);
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
            confirmModal: false,
          }
        });
      })
  }

  downloadExcel() {
    this.setState({
      loadingExport: true
    })
    API.get('scn/withdrawal/detail-create?size=all'
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const newDatalist = res.data.data?.user_app_point?.item.map((data) => {
          return {
            ID: data.id,
            Fullname: data.scn_member?.fullname,
            Email: data.scn_member?.email,
            Bank: data.scn_member?.rekening_bank,
            "Nomor Rekening": data.scn_member?.rekening_number,
            "Rekening Kota": data.scn_member?.rekening_city?.name,
            "Total Misi": data.scn_member?._count?.mission_work,
            Point: data.total_point,
            Nominal: data.total_price,
            Phone: data.scn_member?.phone,
          };
        });
        DownloadExcel(newDatalist, `Export Withdrawal_${moment().format("YYYYDDMMHHmm")} `);
        this.setState({
          loadingExport: false
        })
      })
      .catch((err) => {

      });
  };

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getDetail();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getDetail();
    })
  }

  handleRowSelected(id) {
    let oldState = this.state.selectedRows;

    if (this.state.selectedRows.includes(id)) {
      let index = oldState.indexOf(id);
      oldState.splice(index, 1);
    } else {
      oldState.push(id);
    }
    let unique = oldState.filter((value, index, array) => array.indexOf(value) === index);

    this.setState({
      selectedRows: unique,
      selectedRowAll: false,
      body: {
        ...this.state.body,
        user_app_point_ids: unique
      }
    }, () => {
      this.getDetailCount();
    })
  }

  async handleRowSelectedAll() {
    await this.getSelectAll().then(resp => {
      let selected = [];
      if (!this.state.selectedRowAll) {
        if(resp?.length > 0){
          resp.map(item => {
            selected.push(item.id)
          })
        } 
      }

      this.setState({
        selectedRows: selected,
        selectedRowAll: !this.state.selectedRowAll,
        body: {
          ...this.state.body,
          user_app_point_ids: selected
        }
      }, () => {  
      this.getDetailCount();
      })
      return;
    })
  }

  async getSelectAll() {
    const ids = this.state.selectedRows.join(',');
    try {
      const res = await API.get('scn/withdrawal/detail-create?size=all'
        + '&page=1'
        // + '&search=' + this.state.search
        + (ids.length > 0 ? + '&ids=' + ids : '')
        , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      const result = res.data.data?.user_app_point?.item;
      return result
    } catch (err) {
      console.log("Error", err);
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner>
      )
    }

    if ((this.state.data?.user_app_point?.item?.length === 0 || this.state.page.error === true) && !this.state.loading) {
      return (
        <div className="content h-100" >
          <div className="row h-100">
            <div className='d-flex justify-content-center align-items-center'>
              <div className='d-grid text-center'>
                <i className='fa fa-exclamation-circle text-secondary' style={{ fontSize: '150px' }}></i>
                {this.state.page.message}
                {
                  this.state.page?.message === 'No member has reached the minimum withdrawal point' && (
                    <Link to="/scn/member" className="float-right">See Point Member</Link>
                  )
                }
                {
                  this.state.page?.message === 'Periode of Point Conversion not found' && (
                    <Link to="/scn/point-conversion" className="float-right">See Point Conversion</Link>
                  )
                }
                {
                  this.state.page?.message === 'There is incomplete Withdrawal, please complete or cancel it first' && (
                    <Link to={`/scn/withdrawal/${this.state.page?.data?.id}`} className="float-right">See Withdrawal</Link>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="content" >
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <div className="card-header vertical-middle">
                  <strong className="card-title vertical-middle-p">Add Withdrawal</strong>
                  <Link to="/scn/withdrawal" className="float-right">
                    <button
                      color="info"
                      size="sm"
                      className="btn btn-outline-link"
                    >
                      <i className='fa fa-arrow-left'></i>
                    </button>
                  </Link>
                </div>
                <div className="card-body">
                  <Form>
                    <div className='d-xl-flex'>
                      <div className='col-xl-6 pl-0'>
                        <FormGroup>
                          <Label className='w-100 text-secondary'>Total Member</Label>
                          <div className='font-weight-bold'>{this.state.data?.total_member ?? 0} member</div>
                          <FormFeedback >{this.state.error['point']}</FormFeedback>
                        </FormGroup>
                      </div>
                      <div className='col-xl-6 pl-0'>
                        <FormGroup>
                          <Label className='w-100 text-secondary'>Total Mission Success</Label>
                          <div className='font-weight-bold'>{this.state.data?.total_mission ?? 0} mission</div>
                          <FormFeedback >{this.state.error['point']}</FormFeedback>
                        </FormGroup>
                      </div>
                    </div>
                    <div className='d-xl-flex'>
                      <div className='col-xl-6 pl-0'>
                        <FormGroup>
                          <Label className='w-100 text-secondary'>Total Point</Label>
                          <div className='font-weight-bold'>{this.state.data?.total_point ?? 0} point</div>
                          <FormFeedback >{this.state.error['point']}</FormFeedback>
                        </FormGroup>
                      </div>
                      <div className='col-xl-6 pl-0'>
                        <FormGroup>
                          <Label className='w-100 text-secondary'>Conversion</Label>
                          <div className='font-weight-bold'>{this.state.data?.conversion?.point ?? 0} point = Rp. {addCommas(this.state.data?.conversion?.point_price ?? 0)}</div>
                          <FormFeedback >{this.state.error['point']}</FormFeedback>
                        </FormGroup>
                      </div>
                    </div>
                    <Alert color="primary">
                      <div className='d-flex flex-column'>
                        <div className='small'>Total Withdrawal</div>
                        <div className='font-weight-bold text-xl'>Rp. {addCommas(this.state.data?.total_price ?? 0)}</div>
                        <div className='small text-secondary'><small>Total Point * Conversion</small></div>
                      </div>
                    </Alert>
                    <FormGroup>
                      <Label className='w-100'>Notes</Label>
                      <Input
                        name="note"
                        type="textarea"
                        placeholder="Enter Note"
                        rows="3"
                        // value={this.state.body.note ?? this.props.data.note}
                        onChange={this.onChange}
                        invalid={this.state.error['note']}
                      />
                      <FormFeedback >{this.state.error['point_withdrawal_min']}</FormFeedback>
                    </FormGroup>
                    {
                      this.state.data?.total_price > 0 && (
                        <Button className='w-100' color="primary" onClick={() => {
                          this.setState({
                            confirmModal: true
                          })
                        }} >
                          {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                        </Button>
                      )
                    }

                  </Form>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-header vertical-middle">
                  <strong className="card-title vertical-middle-p">List Point Member</strong>
                  <Button onClick={() => this.downloadExcel()} size='sm' className='ml-2 mb-2 float-right' color={'primary'}>
                    {this.state.loadingExport ? <Spinner size='sm' color='light' /> : <><i className="fa fa-download mr-2"></i>Export</>}
                  </Button>
                </div>
                <div className="card-body">
                  <DataTable
                    noHeader={true}
                    data={this.state.listMember}
                    columns={
                      [
                        {
                          name: <Input type="checkbox" style={{ marginLeft: '-2px' }} onChange={() => this.handleRowSelectedAll()} checked={this.state.selectedRowAll} />,
                          selector: '#',
                          width: '10px',
                          style: {
                            paddingLeft: '34px',
                            paddingRight: '0px'
                          },
                          cell: data =>
                            <Input type="checkbox" onChange={() => this.handleRowSelected(data.id)} checked={this.state.selectedRows.includes(data.id) ? true : false} />
                        },
                        {
                          name: 'ID',
                          selector: 'id',
                          maxWidth: '5%'
                        },
                        {
                          name: 'Fullname',
                          cell: row => row.scn_member?.fullname
                        },
                        {
                          name: 'Bank',
                          cell: row => <>{row.scn_member?.rekening_bank}</>
                        },
                        {
                          name: 'Rekening',
                          cell: row => <>{row.scn_member?.rekening_number}</>
                        },
                        {
                          name: 'Rekening City',
                          cell: row => <>{row.scn_member?.rekening_city?.name}</>
                        },
                        {
                          name: 'Nominal (Rp)',
                          cell: row => <>{addCommas(row.total_price)}</>
                        },
                        {
                          name: 'Phone',
                          cell: row => row.scn_member?.phone
                        },
                        {
                          name: 'Mission',
                          cell: row => <div className='text-primary'>{row.scn_member?._count?.mission_work} mission</div>,
                        },
                        {
                          name: 'Point',
                          cell: row => <Badge color="success">{row.total_point}</Badge>,
                        },
                      ]
                    }
                    pagination={true}
                    paginationServer={true}
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    paginationTotalRows={this.state.paginationTotalRows}
                    onChangePage={page => this.changePage(page)}
                    onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
          status={this.state.notif.status}
        />
        <ConfirmModalSweet
          show={this.state.confirmModal}
          title='Are you sure want process point withdrawal ?'
          subtitle='Make sure you have checked the data'
          confirmText='Yes, Process now!'
          onConfirm={() => this.onSubmit()}
          onCancel={() => { this.setState({ confirmModal: false })}}
        />
      </div>
    )
  }
}