import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Badge,
  Input,
  Spinner,
} from 'reactstrap';
import API from "../../../api";
import moment from "moment";
import { addCommas } from "../../../lib/helpers";
import NotifToast from "../../../components/notificationModal/NotifToast";


export default class LeadsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
      modalReason: false,
      disabledReject: true,
      notif: {
        show: false,
        status: true,
        message: ''
      },
      notifEmail: {
        show: false,
        status: true,
        message: ''
      },
      required: ['qty_pertahun', 'qty_awal', 'status', 'tgl_penggunaan'],
      body: this.props.data,
      optionProductType: [],
      optionStatus: [],
      previewImage:false
    }
  }

  componentDidMount() {
    //LOAD OPTION PRODUCT TYPE
    API.get(`/affiliate/product-type`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          optionProductType: res.data.data
        })
      })
      .catch(err => {
        console.log(err);
      })

    //LOAD OPTION STATUS
    API.get(`/affiliate/leads/status`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          optionStatus: res.data.data
        })
      })
      .catch(err => {
        console.log(err);
      })
  }

  onClosed = () => {
    this.setState({
      error: {},
      disabled: true,
      body: {}
    })
    this.props.close();
  }

  onSubmit() {
    this.setState({
      buttonLoading: true
    })

    const body = {
      qty_pertahun: this.state.body.qty_pertahun ?? this.props.data.qty_pertahun,
      qty_awal: this.state.body.qty_awal ?? this.props.data.qty_awal,
      tgl_penggunaan: this.state.body.tgl_penggunaan ?? this.props.data.tgl_penggunaan,
      status: this.state.body.status ?? this.props.data.status?.id,
      alasan_reject: this.state.body.alasan_reject ?? this.props.data.alasan_reject,
      estimasi_komisi: this.state.body.estimasi_komisi ?? this.props.data.estimasi_komisi,
    }

    // alert(JSON.stringify(body)); return;    
    API.put(`/affiliate/leads/${this.props.data.id}`, body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          buttonLoading: false,
          notif: {
            show: true,
            status: true,
            message: res.data.message
          }
        });

        if(!res.data.data.email_sent){
          this.setState({
            notifEmail: {
              show: true,
              status: false,
              message: 'Email not sent'
            }
          });
        }

        this.onClosed();
        this.props.refresh();

        setTimeout(() => {
          this.setState({
            notif: {
              show: false,
              status: true,
              message: ''
            },
            notifEmail: {
              show: false,
              status: true,
              message: ''
            }
          })
        }, 3000);
      })
      .catch(err => {
        this.setState({
          buttonLoading: false
        })
        this.onClosed();
        console.log(err);
      })
  }

  onChange = (e) => {
    const error = this.state.error;
    const name = e.target.name;
    let value = e.target.value;
    const isRequired = this.state.required.includes(name)
    if (!e.target.value && isRequired) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    const newBody = this.state.body

    let required = this.state.required;
    if (name === 'status') {
      if (value === '3') {
        required.push('alasan_reject');
        newBody['alasan_reject'] = '';
      }
    }

    if (name === 'qty_pertahun') {
      newBody['estimasi_komisi'] = this.props.data.tipe_produk?.commission ? ((parseInt(value) * this.props.data.tipe_produk?.commission)) : 0;
    }

    newBody[e.target.name] = value;
    this.setState({
      body: newBody,
      error: error
    });

    this.setState({
      required: required
    }, this.onValidation())
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.onClosed()} size="lg">
          <ModalHeader toggle={() => this.onClosed()}>Edit Leads</ModalHeader>
          <ModalBody>
            {
              this.props.dataLoading ?
                <div className="">
                  <div className="skeleton skeleton-text" style={{ width: '25%' }}></div>
                  <div className="skeleton skeleton-body"></div>
                  <div className="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                  <div className="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                </div>
                :
                <>
                  <Form>
                    <div className="card-forum">
                      <div className="card-forum-body">
                        <div className="content-forum">
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Nama PT</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.nama_pt} </div>
                              </div>
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Nomor Handphone</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.telp_pic} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Nama PIC</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.nama_pic} </div>
                              </div>
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Email PIC</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.email_pic} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Jabatan PIC</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.posisi_pic} </div>
                              </div>
                            </div>
                          </div>
                          <div className="row bg-light p-2 m-0 rounded mt-2">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Detail Kebutuhan</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.detail_kebutuhan} </div>
                              </div>
                            </div>
                          </div>
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Tujuan Utama Menggunakan Shieldtag</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.tujuan_penggunaan?.tujuan_penggunaan} </div>
                              </div>
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Jumlah yang Ingin Dipesan dalam 1 Tahun</label>
                                <div className="text-dark font-weight-bold pr-3">
                                  <Input
                                    name="qty_pertahun"
                                    placeholder='Enter Jumlah'
                                    value={this.state.body.qty_pertahun ?? this.props.data.qty_pertahun}
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Tipe Produk</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.tipe_produk?.tipe_produk} </div>
                              </div>
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Jumlah Pemesanan Awal</label>
                                <div className="text-dark font-weight-bold pr-3">
                                  <Input
                                    name="qty_awal"
                                    placeholder='Enter Jumlah'
                                    value={this.state.body.qty_awal ?? this.props.data.qty_awal}
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Tanggal Penggunaan</label>
                                <div className="text-dark font-weight-bold pr-3">
                                  <Input
                                    name="tgl_penggunaan"
                                    type="date"
                                    placeholder='Enter Jumlah'
                                    value={this.state.body.tgl_penggunaan ?? moment(this.props.data.tgl_penggunaan).format('YYYY-MM-DD')}
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Status</label>
                                <div className="text-dark font-weight-bold pr-3">
                                  <Input
                                    name="status"
                                    type="select"
                                    onChange={this.onChange}
                                    value={this.state.body.status ?? this.props.data.status?.id}
                                    invalid={this.state.error['role_id'] ? true : false}
                                  >
                                    <option value={''}>Choose</option>
                                    {
                                      this.state.optionStatus.map(item => {
                                        return <option value={item.id}>{item.name}</option>
                                      })
                                    }
                                  </Input>
                                </div>
                              </div>
                            </div>
                            {
                              this.props.data.alasan_reject || this.state.body.status === '3' ? (
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label className="small text-secondary mb-0">Alasan Ditolak</label>
                                    <div className="text-dark font-weight-bold pr-3">
                                      <Input
                                        name="alasan_reject"
                                        type="textarea"
                                        placeholder="Enter Reason"
                                        rows="2"
                                        value={this.state.body.alasan_reject ?? this.props.data.alasan_reject}
                                        onChange={this.onChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : ''
                            }
                          </div>
                          <div className="row bg-light p-2 m-0 rounded mt-2">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Jumlah Komisi ({this.props.data.tipe_produk?.commission})</label>
                                <div className="text-dark font-weight-bold">Rp. {addCommas(this.state.body.estimasi_komisi ?? this.props.data.estimasi_komisi)} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Tanggal Kadaluarsa</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.tgl_expired ? moment(this.props.data.tgl_expired).format('DD MMMM YYYY') : '-'} </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary">Dibuat oleh:</label>
                                <div className="text-dark font-weight-bold"> { this.props.data.partner?.username } </div>
                              </div>
                            </div>
                          </div>
                          <div className="row p-2 m-0 rounded">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Bukti Meeting</label>
                                <div className="text-dark font-weight-bold d-flex justify-content-between">
                                  {
                                    this.props.data.bukti_meeting.split(',')?.map((item, index) => {
                                      return (
                                        <img 
                                          key={index} 
                                          src={item} 
                                          width={135} 
                                          onClick={() => {
                                            this.setState({
                                            previewImage:item,
                                            });
                                            this.onClosed()
                                          }}
                                          alt="" 
                                          className="img-fluid pe-auto" 
                                          role="button"
                                        />
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
            }
          </ModalBody>
          <ModalFooter>
            <Button color="success" className="float-left" onClick={() => this.onSubmit()} disabled={this.state.disabled} >
              {
                this.state.buttonLoading ? (<Spinner size={'sm'} />) : 'Submit'
              }
            </Button>
            <Button color="danger" onClick={() => this.onClosed()}>Close</Button>
          </ModalFooter>
        </Modal>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          message={this.state.notif.message}
          status={this.state.notif.status}
        />
        <NotifToast
          show={this.state.notifEmail.show}
          close={() => this.setState({ notifEmail: { show: false } })}
          message={this.state.notifEmail.message}
          status={this.state.notifEmail.status}
        />
        <Modal isOpen={this.state.previewImage} toggle={() => {this.setState({previewImage:false}); this.props.reOpen();}} size="lg">
          <i className="w-full h3  fa fa-times-circle position-absolute p-2 top-0 end-0" role="button" onClick={() => {this.setState({previewImage:false}); this.props.reOpen();}}></i>
          <img src={this.state.previewImage} alt="" className="img-fluid" />
        </Modal>
      </>

    )
  }
}