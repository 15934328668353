import React, { Component, useState } from 'react';
import API from '../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  FormText
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NotifToast from '../../components/notificationModal/NotifToast';

const init_body = {
  description: '',
  method: '',
  version: 2,
  path_1: null,
  path_2: null,
  path_3: null,
  role_permission_id: '',
  save_log: 1,
  status: 1,
}

class add extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      body: init_body,
      error: {},
      disabled: true,
      buttonLoading: false,
      accessMenus : [],
      accessAction : [],
      notif: {
        title: '',
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
    }
  }

  componentDidMount() {
    // this.getAccessMenu();
    console.log(this.state.showModal, this.state.error.length)

    const query = new URLSearchParams(this.props.location.search);
    const group = query.get('group')
    if(group){
      this.getAccessMenu(group);

      const newBody = this.state.body
      newBody['role_group_name'] = group
      this.setState({
        body: newBody
      })
    }
  }

  getAccessMenu(group) {
    API.get(`role/menu/list?option=true&group=${group}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          accessMenus: result.data,
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  onChange = (e) => {
    const newBody = this.state.body
    const name = e.target.name;
    const value = e.target.value;

    const error = this.state.error;   
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }
    
    if(name=='menu'){
      API.get(`role/permission?menu_id=${value}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          accessAction: result.data,
        });

        if(result.data.length === 1){          
          newBody['role_permission_id'] = result.data[0].id;
        }
      })
      .catch(err => {
        alert(err)
      })
    }
    else if(name=='path'){
      const newPath = value.split('/');
      if(newPath.length>3){
        error[name] = 'No more than 3 URL Segment'
      }else {
        delete error[name]
      }

      newBody['path_1'] = newPath[0] 
      newBody['path_2'] = newPath[1] ? newPath[1] : null 
      newBody['path_3'] = newPath[2] ? newPath[2] : null
    } else if(name === 'role_group_name'){
      this.getAccessMenu(value)
    }
    else{
      newBody[e.target.name] = e.target.value
    }
    

    this.setState({
      body: newBody,
      error: error
    });
    console.log(this.state.body)
    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);

    let filtered = asArray.filter(([key, value]) => value === '' && key!=='path_2' && key!=='path_3' && key!=='role_permission_id');

    if(this.state.accessAction.length === 1){
      filtered = asArray.filter(([key, value]) => value === '' && key!=='path_2' && key!=='path_3' && key!=='role_permission_id');
    }
    const result = filtered.length ? true : false

    this.setState({
      disabled: result,
    });

    return result
  }

  onSubmit() {
    this.setState({
      // buttonLoading: true,
      notif: { show: true, title: 'Processing ...' }
    });
    API.post('role/api', this.state.body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          notif: {
            title: 'Success',
            message: result.message,
          }
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false }
          });
          this.props.history.push(`/api/edit/${result.data.insertId}`);
        }, 1500);
      })
      .catch((err) => {
        this.setState({
          notif: {
            title: 'Failed',
            message: err.response.data.message,
          }
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false }
          });
        }, 1500);
      })
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <Link to="/api">
                    <button
                      color="info"
                      size="sm"
                      className="btn btn-outline-link"
                    >
                      <i className='fa fa-arrow-left'></i>
                    </button>
                  </Link>
                  <strong className="card-title align-middle">Add API Endpoint</strong>
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label>Description</Label>
                      <Input
                        id="description"
                        name="description"
                        placeholder="Enter Description"
                        type="text"
                        onChange={this.onChange}
                        required
                        invalid={this.state.error['description'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['description']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label >Method Name</Label>
                      <Input
                        id="method"
                        name="method"
                        type="select"
                        onChange={this.onChange}
                        required
                        invalid={this.state.error['group_name'] ? true : false}
                      >
                        <option value={''}>Choose</option>
                        <option value={'GET'}>GET</option>
                        <option value={'POST'}>POST</option>
                        <option value={'PUT'}>PUT</option>
                        <option value={'DELETE'}>DELETE</option>
                      </Input>
                      <FormFeedback >{this.state.error['group_name']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>API version</Label>
                      <Input
                        id="version"
                        name="version"
                        placeholder="Enter API Version"
                        type="text"
                        onChange={this.onChange}
                        value={2}
                        required
                        invalid={this.state.error['version'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['version']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>Path URL</Label>
                      <Input
                        id="path"
                        name="path"
                        placeholder="Enter Path URL"
                        type="text"
                        onChange={this.onChange}
                        required
                        invalid={this.state.error['path'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['path']}</FormFeedback>
                      <FormText>
                        Example without path param : urlname/urlname2,
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <Label className='w-100'>
                        Group Application
                      </Label>
                      <Input
                        id="role_group_name"
                        name="role_group_name"
                        type="select"
                        onChange={this.onChange}
                        value={this.state.body.role_group_name}
                        invalid={this.state.error['role_group_name'] ? true : false}
                      >
                        <option value={''}>Choose</option>
                        <option value={'dashboard_app'}>Dashboard App</option>
                        <option value={'dashboard_partner'}>Dashboard Partner</option>
                      </Input>
                      <FormFeedback >{this.state.error['role_group_name']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label >Access Menu</Label>
                      <Input
                        id="menu"
                        name="menu"
                        type="select"
                        onChange={this.onChange}
                        required
                        invalid={this.state.error['menu'] ? true : false}
                      >
                        <option value={''}>Choose</option>
                        {
                          this.state.accessMenus.map((item,index) => {
                            return (
                                <optgroup key={index} label={item.label}>
                                  {
                                    item.menu.map(men => {
                                      return <option key={men.id} value={men.id}>{men.name}</option>
                                    })
                                  }
                                </optgroup>
                            )
                          })
                        }
                      </Input>
                      <FormFeedback >{this.state.error['menu']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label >Access Action</Label>
                      <Input
                        id="role_permission_id"
                        name="role_permission_id"
                        type="select"
                        onChange={this.onChange}
                        value={this.state.accessAction.length === 1 ? this.state.accessAction[0].id : null}
                        required
                        invalid={this.state.error['role_permission_id'] ? true : false}
                      >
                        <option value={''}>{this.state.accessAction.length ? 'Choose' : 'Please select Access Menu first'}</option>
                        {
                          this.state.accessAction.map(item => {
                            return <option key={item.id} value={item.id}>{item.name}</option>
                          })
                        }
                      </Input>
                      <FormFeedback >{this.state.error['role_permission_id']}</FormFeedback>
                    </FormGroup>
                    <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                      {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
        />
      </div>
    )
  }
}

export default add;