import ColorPicker from "src/components/elements/form/ColorPicker";
import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody,
  Spinner,
  Input,
  FormFeedback,
  FormGroup,
  Form
} from 'reactstrap';
import API from "src/api";
import NotifToast from "src/components/notificationModal/NotifToast";
import ConfirmModal from "src/components/notificationModal/ConfirmModal";

const init_body = {
  label: '',
  color: '#000000'
}

export default class QrcodeLabelModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      showModalDelete: false,
      selectedId: null,
      notif: false,
      notifTitle: 'Success',
      notifMessage: '',
      error: {},
      buttonLoading: false,
      buttonLoadingAdd: false,
      formRequired: ['label','color'],
      body: init_body
    }
  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      error: error
    });
  }

  onDelete(id, index) {
    this.setState({ buttonLoading: true });

    API.delete(`qrcode/label/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          body: init_body,
          buttonLoading: false,
          notif: true,
          notifTitle: 'Success',
          notifMessage: resp.data.message,
        });

        setTimeout(() => {
          this.setState({
            notif: false,
          });
        }, 3000)

        this.props.afterSubmit()
        this.props.close()
      })
      .catch(err => {
        this.props.afterSubmit()
        this.props.close()
      })
  }

  onEdit(id, body) {
    // alert(JSON.stringify(body)); return;
    this.setState({
      buttonLoading: true,
    });
    API.put(`qrcode/label/${id}`, body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          body: init_body,
          buttonLoading: false,
          notif: true,
          notifTitle: 'Success',
          notifMessage: resp.data.message,
        });

        setTimeout(() => {
          this.setState({
            notif: false,
          });
        }, 3000)

        this.props.afterSubmit()
        this.props.close()

      })
      .catch(err => {
        this.props.afterSubmit()
        this.props.close()
      })
  }

  onAdd() {
    const body = this.state.body;
    const error = this.state.error;
    
    this.state.formRequired.map(item => {
      if(body[item] == ''){
        error[item] = 'Field is Required';
      }
      this.setState({
        error: error
      })
    })

    if(Object.entries(error).length) return;

    this.setState({
      buttonLoadingAdd: true,
    });

    API.post(`qrcode/label`, this.state.body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          body: {},
          buttonLoadingAdd: false,
          notif: true,
          notifTitle: 'Success',
          notifMessage: resp.data.message,
        });

        setTimeout(() => {
          this.setState({
            notif: false,
          });
        }, 3000)

        this.props.afterSubmit()
        this.props.close()
      })
      .catch(err => {
        this.props.afterSubmit()
        this.props.close()
      })
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="lg">
          <ModalHeader>More QR Code Label</ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='mb-3'>
                QR Code Label Available
              </div>
              <div className='col-md-12'>
                {
                  this.props.data.length > 0
                    ?
                    <div className='table-modal-wrapper overflow-auto' style={{ maxHeight: '300px' }}>
                      <table className='table'>
                        <tbody>
                          <tr>
                            <td className='align-middle' width="30%">Name</td>
                            <td className='align-middle' width="30%">Color</td>
                            <td className='text-right align-middle' width="30%">Action</td>
                          </tr>
                          {
                            this.props.data.map((item, index) => (
                              <RowComponent
                                key={index}
                                data={item}
                                onEdit={(body) => this.onEdit(item.value, body)}
                                onDelete={() => {
                                  this.props.close();
                                  this.setState({
                                    selectedId: item.value,
                                    showModalDelete: true,
                                  })
                                }}
                                buttonLoading={this.state.buttonLoading}
                                disabled={false}
                              />
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                    :
                    null
                }

              </div>
              <div className='col-md-12'>
                <div className='my-2'>
                  <span>Add Label</span>
                </div>
                <div className="d-flex gap-2">
                  <FormGroup>
                    <Input
                      id="name"
                      name="label"
                      placeholder="Enter Name"
                      className="form-control valid"
                      type="text"
                      onChange={this.onChange}
                      required
                      invalid={this.state.error['label'] ? true : false}
                    />
                    <FormFeedback>{this.state.error['label']}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <ColorPicker color={this.state.body.color} setColor={(c) => this.setState({ body: { ...this.state.body, color: c } })} />
                  </FormGroup>
                  <FormGroup>
                    <Button color="primary" onClick={() => this.onAdd()} disabled={this.state.buttonLoadingAdd}>
                      {!this.state.buttonLoadingAdd ? 'Add QR Code Label' : <Spinner size="sm">Loading...</Spinner>}
                    </Button>
                  </FormGroup>
                </div>
              </div>
              <div className='col-md-2'>

              </div>
            </div>
          </ModalBody>
        </Modal>
        <NotifToast
          show={this.state.notif}
          close={() => this.setState({ notif: false })}
          title={this.state.notifTitle}
          message={this.state.notifMessage}
        />
        <ConfirmModal
          open={this.state.showModalDelete}
          close={() => {
            this.setState({ showModalDelete: false })
            this.props.reOpen()
          }}
          afterSubmit={() => {
            this.props.afterSubmit();
            this.props.close();
          }}
          id={this.state.selectedId}
          url={`qrcode/label/${this.state.selectedId}`}
        />
      </>
    )
  }
}

class RowComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editTab: false,
      body: {
        label: this.props.data.label,
        color: this.props.data.color,
      }
    }
  }

  onChange = (e) => {
    const newBody = this.state.body

    newBody[e.target.name] = e.target.value
    this.setState({ body: newBody })
  }

  render() {
    return (
      <>
        <tr id="box">
          <td className={`align-middle ${this.state.editTab ? 'pl-0' : null}`}>
            <span hidden={this.state.editTab}>
              {this.props.data.label}
            </span>
            <Input
              name="label"
              placeholder="sm"
              onChange={(e) => this.onChange(e)}
              hidden={!this.state.editTab}
              value={this.state.body.label}
            />
          </td>
          <td className={`align-middle ${this.state.editTab ? 'pl-0' : null}`}>
            <ColorPicker
              color={this.state.body.color}
              setColor={(c) => { this.setState({ body: {...this.state.body, color: c}}) }}
              disabled={!this.state.editTab}
            />
          </td>
          <td className='text-right text-sm'>
            <Button hidden={this.state.editTab} onClick={() => this.setState({ editTab: true })} color="link"><i className="fa fa-pencil"/></Button>
            <Button hidden={this.state.editTab} onClick={() => this.props.onDelete()} color="link"><i className="text-danger fa fa-trash"/></Button>
            <Button hidden={!this.state.editTab} onClick={() => this.props.onEdit(this.state.body)} color="link">
              {!this.props.buttonLoading ? <i className="text-success fa fa-save"/>: <Spinner size="sm">Loading...</Spinner>}
            </Button>
            <Button 
              hidden={!this.state.editTab} 
              onClick={() => this.setState({ 
                editTab: false,
                body: {
                  label: this.props.data.label,
                  color: this.props.data.color,
                }
              })} 
              color="link"
              className='ml-2'
            >
              <i className="text-warning fa fa-times"/>
            </Button>
          </td>
        </tr>
      </>
    );
  };

}