import React, { Component, useState } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import {
  NavLink,
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Alert,
  CustomInput,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  Badge
} from 'reactstrap';

class IndexCustomer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_LIST_CUSTOMER,
      scope: [],
      columns: [
        {
          name: 'Name',
          selector: 'customer_name',
          sortable: true
        },
        {
          name: 'Number Phone',
          selector: 'number_phone',
          sortable: true
        },
        {
          name: 'QR Code',
          selector: 'qrcode',
          sortable: true
        },
        {
          name: 'Created at',
          selector: 'created_at',
          sortable: true
        },
      ],
      data: [],
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 5,
      paginationTotalRows: 0,
      paginationPage: 1
    };
    // this.modalUser = this.modalUser.bind(this);
  }

  componentDidMount() {
    this.checkAccess();
    this.getListUser();
  }

  async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
    console.log(access)
    if (access) {
        let scope = [];
        access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

        this.setState({
            scope: scope
        })
        return;
    }

    this.props.history.push(`/unauthorized`);
}

  getListUser() {
    API.get('customer?size=' + this.state.paginationPerPage + '&page=' + this.state.paginationPage, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(this.state.data)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getListUser();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getListUser();
    })
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                {
                  this.state.alert.show ?
                    <Alert
                      color="success"
                      isOpen={this.state.alertSuccess}
                    >
                      Manufacturer deleted successfully
                    </Alert>
                    : null
                }

                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Customer Data Scan</strong>
                  </div>
                  <div className="card-body">
                    {
                      !this.state.showTable ? <>Loading ...</> :
                        <DataTable
                          noHeader={true}
                          columns={this.state.columns}
                          data={this.state.data}
                          pagination={true}
                          paginationServer={true}
                          paginationPerPage={5}
                          paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                          paginationTotalRows={this.state.paginationTotalRows}
                          onChangePage={page => this.changePage(page)}
                          onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                        />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default IndexCustomer;