import React, { Component, useState } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
  NavLink,
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Alert,
  CustomInput,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  Spinner,
  Toast,
  ToastHeader,
  ToastBody
} from 'reactstrap';
import MappingRole from './mappingRole';
import { Link, useParams } from 'react-router-dom';
import NotifToast from '../../components/notificationModal/NotifToast';

const init_body = {
  name: '',
  group_name: '',
}

export default class detailRole extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      body: init_body,
      error: {},
      disabled: true,
      loading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
    }
  }

  componentDidMount() {
    this.getRole()
  }

  getRole() {
    this.setState({ loading: true });
    API.get(`role/${this.state.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          body: {
            name: result.data.name,
            group_name: result.data.group_name,
          },
          loading: false
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  onChange = (e) => {

  }

  

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Detail Roles</strong>
                  {/* <NavLink href="/user/add" > */}
                  <div className="float-right">
                    <Link to="/role">
                      <button
                        color="info"
                        size="sm"
                        className="btn btn-outline-link"
                      >
                        <i className='fa fa-arrow-left'></i>
                      </button>
                    </Link>

                  </div>
                  {/* </NavLink> */}
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Name
                      </Label>
                      <Input
                        id="name"
                        name="name"
                        placeholder="Enter Name"
                        type="text"
                        onChange={this.onChange}
                        value={this.state.body.name}
                        invalid={this.state.error['name'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['name']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label >
                        Group Name
                      </Label>
                      <Input
                        id="group_name"
                        name="group_name"
                        type="select"
                        onChange={this.onChange}
                        value={this.state.body.group_name}
                        invalid={this.state.error['group_name'] ? true : false}
                      >
                        <option value={''}>Choose</option>
                        <option value={'dashboard_app'}>Dashboard App</option>
                      </Input>
                      <FormFeedback >{this.state.error['group_name']}</FormFeedback>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          {
            !this.state.loading ?
              <MappingRole
                id={this.state.id}
                readonly={true}
                group={this.state.body.group_name}
              /> : null
          }

        </div>
      </div>
    )
  }
}