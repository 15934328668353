import React, { Component, useState } from 'react';
import { withRouter } from 'react-router-dom';
import "./css/LoginUser.css";
import API from '../../api'
import {
    NavLink,
    Button, Container, Row, Col, FormGroup,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Alert,
    Spinner
} from 'reactstrap';

class LoginUser extends Component {

    constructor(props) {
        super(props);
        this.login = this.login.bind(this);
        this.setUsername = this.setUsername.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);

        this.state = {
            username: null,
            password: null,
            errorText: null,
            loadingButton: false
        }
    }

    componentDidMount() {
        localStorage.setItem('isLoggedIn', 'false');
        // window.stop()
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.login()
        }
    }

    setUsername(event) {
        this.setState({
            username: event.target.value
        })
    }

    setPassword(event) {
        this.setState({
            password: event.target.value
        })
    }

    login() {
        this.setState({
            loadingButton: true
        })
        const data = {
            username: this.state.username,
            password: this.state.password
        }
        API.post('/login', data, {
            'Content-Type': 'application/json'
        })
            .then(res => {
                console.log('ini respon data login', res.data)
                if (res.data.result === true) {
                    const token = {
                        headers: { Authorization: `Bearer ${res.data.token}` }
                    }
                    localStorage.setItem('isLoggedIn', 'true');
                    localStorage.setItem('loginData', JSON.stringify(res.data))
                    localStorage.setItem('config', JSON.stringify(token))
                    localStorage.setItem('token', res.data.token)
                    this.setState({
                        errorText: null,
                        loadingButton: false
                    })
                    this.props.history.push("/");
                    window.location.reload();
                } else {
                    this.setState({
                        errorText: res.data.message ?? 'Username or Password is wrong/empty',
                        loadingButton: false
                    })
                }
            })
            .catch(err => {
                console.log("Error", err);
                this.setState({
                    errorText: 'Username or Password is wrong/empty',
                    loadingButton: false
                })
            })
    }

    render() {
        console.log(this.props.location.pathname);
        return (
            <div className="bg-dark h-100 padding-content d-flex justify-content-center align-items-center ">
                <div className='col-lg-3 bg-white p-5 rounded rounded-lg' style={{ maxHeight: '400px' }}>
                    <div className='w-100 d-flex justify-content-center'>
                        <img width={'200'} className="img-fluid img-header-login" src="/Shieldtag_Icon_Header.png" />
                    </div>
                    <div>
                        <FormGroup>
                            <FormGroup>
                                <p>Username</p>
                                <input
                                    id="username"
                                    className="form-control"
                                    value={this.state.username}
                                    onChange={this.setUsername}
                                    onKeyDown={this._handleKeyDown}
                                />
                            </FormGroup>
                            <FormGroup>
                                <p>Password</p>
                                <input
                                    id="password"
                                    className="form-control"
                                    type="password"
                                    value={this.state.password}
                                    onChange={this.setPassword}
                                    onKeyDown={this._handleKeyDown}
                                />
                            </FormGroup>
                            { this.state.errorText ? <div className='text-danger text-bold mb-2 text-sm'>{this.state.errorText}</div> : null }
                            <FormGroup>
                                <Button className='w-100' color="dark" onClick={this.login}>
                                    {this.state.loadingButton ? <Spinner size={'sm'} /> : 'Login'}
                                </Button>
                            </FormGroup>
                        </FormGroup>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(LoginUser);