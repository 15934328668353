import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../../api'
import {
  Button,
  Spinner,
  Badge,
  InputGroup,
  Input
} from 'reactstrap';
import { Link } from 'react-router-dom';
import constants from '../../../lib/constants';
import { _hasAccess } from '../../../middleware/accessMiddleware'
import ConfirmModal from '../../../components/notificationModal/ConfirmModal';
import { formatDate } from '../../../lib/helpers';
import LeadsDetail from './leadsDetail';
import LeadsEdit from './leadsEdit';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_AFFILIATE_LEADS,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          maxWidth: '5%'
        },
        {
          name: 'Register Date',
          cell: data =>
            formatDate(data.created_at) + ' WIB',
        },
        {
          name: 'Company Name',
          selector: 'nama_pt',
        },
        {
          name: 'PIC Name',
          selector: 'nama_pt',
        },
        {
          name: 'Email PIC',
          selector: 'email_pic',
        },
        {
          name: 'Number Phone',
          selector: 'telp_pic',
        },
        {
          name: 'Manufactur Name',
          cell: data => data.partner.manufacturer.name
        },
        {
          name: 'Created By',
          cell: data => data.partner?.username
        },
        {
          name: 'Status',
          cell: data =>
            <label>
              <Badge
                color={data.status?.color}
                pill
              >
                {data.status?.name}
              </Badge>
            </label>
        },
        {
          name: 'Action',
          selector: 'action',
          cell: data =>
            <>
              <div>
                <button type='button' className="text-dark btn btn-sm btn-outline-link" onClick={() => this.showDetail(data.id)} ><li className="fa fa-eye"></li></button>

                {
                  this.state.scope.includes('update') ?
                    <button
                      type='button'
                      className="text-primary btn btn-sm btn-outline-link"
                      onClick={() => {
                        this.setState({
                          showModalEdit: true,
                        })

                        this.getDetail(data.id);
                      }} >
                      <li className="fa fa-edit"></li>
                    </button>
                    : null
                }
                {
                  this.state.scope.includes('delete') ?
                    <button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(data.id)}><li className="fa fa-trash"></li></button>
                    : null
                }
              </div>
            </>
        }
      ],
      loaderPage: true,
      data: [],
      editData: {},
      id: null,
      showTable: false,
      showModalDelete: false,
      showModalDetail: false,
      showModalEdit: false,
      search: '',
      status: '',
      fake: false,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      totalWaiting: 0,
      selectedDataLoading: true,
      selectedData: {}
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
    this.setState({
      loaderPage: false
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status === 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    const search = this.state.search;
    console.log(search);
    API.get('affiliate/leads?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      + '&status=' + this.state.status
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true,
          totalWaiting: result.data.totalWaiting
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
      id: id,
    });
  }

  filterApproval = (status) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.set('status', status);
    if (status === '') {
      params.delete('status');
    }
    url.search = params.toString();
    this.props.history.push(`${window.location.pathname}${url.search}`);
    this.setState({
      status: status
    }, () => {
      this.getList();
    })
  };

  showDetail(id) {
    this.setState({
      showModalDetail: true
    })

    this.getDetail(id);
  }

  getDetail = (id) => {
    this.setState({
      detailDataLoading: true,
    })
    API.get('affiliate/leads/' + id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          selectedData: result.data,
          selectedDataLoading: false
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    if (e.target.value.length > 3 || e.target.value === '') {
      this.setState({
        search: e.target.value
      }, () => {
        this.getList()
      })
    }
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Data Affiliasi</strong>
                  </div>
                  <div className="card-body">
                    <div className='d-flex'>
                      <InputGroup className='w-auto'>
                        <Input
                          placeholder='Search by Company Name'
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      {/* <Button
                        onClick={() => this.wrongReport()}
                        size='sm'
                        className='ml-2'
                        outline={this.state.wrong_report ? false : true}
                        color={this.state.wrong_report ? 'success' : 'secondary'}
                      >
                        <i className='fa fa-filter mr-2'></i>
                        Filter
                      </Button> */}
                      <Button
                        onClick={this.state.status === 1 ? this.filterApproval.bind(this, '') : this.filterApproval.bind(this, 1)}
                        size='sm'
                        className='ml-2'
                        outline={this.state.status === 1 ? false : true}
                        color={this.state.status === 1 ? 'success' : 'secondary'}
                      >
                        <i className='fa fa-check-circle mr-2'></i>
                        Waiting Approval <Badge color='warning'>{this.state.totalWaiting}</Badge>
                      </Button>
                    </div>
                    {
                      <DataTable
                        noHeader={true}
                        columns={this.state.columns}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            text={`Are you sure want to delete this affiliate ?`}
            open={this.state.showModalDelete}
            close={() => this.showConfirmDelete()}
            afterSubmit={() => this.getList()}
            id={this.state.id}
            url={`affiliate/leads/${this.state.id}`}
          />
          <LeadsDetail
            open={this.state.showModalDetail}
            close={() => this.setState({ showModalDetail: false })}
            reOpen={() => this.setState({ showModalDetail: true })}
            data={this.state.selectedData}
            dataLoading={this.state.selectedDataLoading}
            refresh={() => this.getList()}
          />
          <LeadsEdit
            open={this.state.showModalEdit}
            close={() => this.setState({ showModalEdit: false })}
            reOpen={() => this.setState({ showModalEdit: true })}
            data={this.state.selectedData}
            dataLoading={this.state.selectedDataLoading}
            refresh={() => this.getList()}
          />
        </div>
    )
  }
}

export default index;