import React, { Component, useRef } from 'react';
import API from '../../../api';
import * as htmlToImage from 'html-to-image';
import {
  CustomInput,
  Alert,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Button
} from 'reactstrap';
import LoadingBar from 'react-top-loading-bar'
import imageCompression from 'browser-image-compression';
import { times } from 'lodash';
import { ThemeConsumer } from 'styled-components';
const { convert } = require("any-to-any");


const init_body = {
  manufacturer_id: '',
  email: '',
  username: '',
  password_default: '',
  user_id: localStorage.getItem('loginData') ? JSON.parse(localStorage.getItem('loginData')).data.users_id : null
}

export default class EditDistributorUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      alertError: false,
      manufacturer: [],
      body: init_body
    }
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    let manufacturerOptions = await API.get('manufacturer/options', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
    this.setState({
      manufacturer: manufacturerOptions.data
    })

    this.getData()
  }

  async getData() {
    await API.get(`/distributor/user/${this.state.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then(resp => {
        const result = resp.data;
        this.setState({ body: result.data })
        console.log(this.state.body)
      })
      .catch(err => alert(err))
  }

  async handleChange(e) {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    this.setState({
      body: newBody
    });


  }

  getManufacturerOption() {
    let items = [<option key={0} value={''} >Choose Manufacture</option>];
    let manufacturer = this.state.manufacturer;

    for (let i = 0; i < manufacturer.length; i++) {
      items.push(<option key={manufacturer[i].manufacturer_id} value={manufacturer[i].manufacturer_id} data-name={manufacturer[i].name}>{manufacturer[i].name}</option>);
    }
    return items;
  }

  submitForm(e) {
    // alert(JSON.stringify(this.state.body))
    API.put(`/distributor/user/${this.state.id}`, this.state.body, { headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then((res) => {
        const result = res.data;
        if (res.data.status) {
          alert(res.data.message)
            window.location.href = '/distributor/user/'
        }
      })
      .catch(err => alert(err))
  }

  render() {
    return (
      <div className="content">
        <LoadingBar onRef={ref => (this.LoadingBar = ref)} />
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Add User Admin Distributor (Step 2 of 2)</strong>
                  {/* <p>{this.state.variantId}</p> */}
                  <Alert
                    color="danger"
                    isOpen={this.state.alertError}
                  >
                    {this.state.message}
                  </Alert>
                </div>
                <div className="card-body">
                  <form onSubmit={(e) => { this.submitForm(e) }}>
                    <div class="form-group">
                      <label for="manufacturer_id" class=" form-control-label">Manufacturer</label>
                      <select
                        name="manufacturer_id"
                        id="manufacturer_id"
                        class="form-control"
                        value={this.state.body.manufacturer_id}
                        onChange={this.handleChange}>
                        {this.getManufacturerOption()}
                      </select>
                    </div>
                    <div className="form-group has-success">
                      <label for="distributor" className="control-label mb-1">Email</label>
                      <input
                        id="distributor"
                        name="email"
                        type="email"
                        className="form-control valid"
                        placeholder='Please enter email'
                        value={this.state.body.email}
                        onChange={this.handleChange}
                        required={true}
                      />
                    </div>
                    <div className="form-group has-success">
                      <label for="distributor" className="control-label mb-1">Username</label>
                      <input
                        id="distributor"
                        name="username"
                        type="text"
                        className="form-control valid"
                        placeholder='Please enter email'
                        value={this.state.body.username}
                        onChange={this.handleChange}
                        // disabled={true}
                      />
                    </div>
                    <div className="form-group has-success">
                      <div className='' >
                        <label for="distributor" className="control-label mb-1">PIN</label>
                        <input
                          id="distributor"
                          name="password_default"
                          type="number"
                          className="form-control valid"
                          placeholder='Please enter email'
                          value={this.state.body.password_default}
                          onChange={this.handleChange}
                          disabled={false}
                        />
                      </div>

                    </div>
                    <button disabled={this.state.disable} type="button" className="btn btn-info" onClick={(e) => { this.submitForm(e) }}>Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}