import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import {
  Badge,
} from 'reactstrap';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import { addCommas, formatDate } from 'src/lib/helpers';
import HistoryDetail from './HistoryDetail';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showModalDetail: false,
      selectedData: {},
      status: [
        { value: '0', label: 'Deleted', color: 'secondary' },
        { value: '1', label: 'Cold', color: 'info' },
        { value: '2', label: 'Hot', color: 'warning' },
        { value: '3', label: 'Super Hot', color: 'primary' },
        { value: '4', label: 'Accepted', color: 'success' },
        { value: '5', label: 'Losses', color: 'danger' },
      ]
    };
  }

  render() {
    return (
      <>
        <div className="card">
          <div className="card-header">
            <strong className="card-title">History Leads</strong>
          </div>
          <div className="card-body">
            {
              <DataTable
                noHeader
                columns={[
                  {
                    name: 'Date',
                    cell: data => formatDate(data.created_at) + ' WIB'
                  },
                  {
                    name: 'QTY',
                    cell: data => addCommas(data.qty_total)
                  },
                  {
                    name: 'Price',
                    cell: data => addCommas(data.price_total)
                  },
                  {
                    name: 'Status',
                    cell: data =>
                      <label>
                        <Badge
                          color={this.state.status.filter(item => item.value == data.status)[0].color}
                          pill
                        >
                          {this.state.status.filter(item => item.value == data.status)[0].label}
                        </Badge>
                      </label>
                  },
                  {
                    name: 'Action',
                    selector: 'action',
                    cell: data =>
                      <>
                        <div>
                          <button type='button' className="text-dark btn btn-sm btn-outline-link" onClick={() => {
                            this.setState({ 
                              showModalDetail: true,
                              selectedData: data
                            });
                          }}>
                            <li className="fa fa-eye"></li>
                          </button>
                        </div>
                      </>
                  }
                ]}
                data={this.props.data}
                paginationPerPage={5}
                pagination
              />
            }
          </div>
        </div>
        <HistoryDetail
          open={this.state.showModalDetail}
          close={() => this.setState({ showModalDetail: false })}
          data={this.state.selectedData}
          optionSource={this.props.optionSource}
          optionChannel={this.props.optionChannel}
          status={this.state.status}
        />
      </>
    )
  }
}

export default index;