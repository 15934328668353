import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner
} from 'reactstrap';
import API from "../../api";

const init_body = {
  username: '',
  email: '',
  password: '',
  role_id: '',
  password_default: null
}

export default class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      body: init_body,
      error: {},
      disabled: true,
      buttonLoading: false
    }
  }

  componentDidMount() {
    // this.getLoginData();
    console.log(this.state.showModal, this.state.error.length)

  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      const name = e.target.name;
      const value = e.target.value;
      if (name == 'password' || name == 'repassword') {
        const type = name == 'password' ? 1 : 2;
        const invalidPassword = this.checkPassword(value, type);
        if (invalidPassword) {
          error[e.target.name] = invalidPassword;
        }
        else {
          delete error[e.target.name]
        }
      }
      else {
        delete error[e.target.name]
      }
    }



    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  checkPassword(password, type) {
    if (type == 1) {
      let passError = [];
      if (password.length < 8) {
        passError.push('be atleast 8 characters')
      }

      if (password.search(/[A-Z]/) < 0) {
        passError.push('an uppser case letter')
      }

      if (password.search(/[0-9]/) < 0) {
        passError.push('a number')
      }

      if (passError.length > 0) {
        passError.join(', ')
        return 'Password Must ' + passError
      } else {
        return false
      }
    }
    else if (type == 2) {
      if (password != this.state.body.password) {
        return 'Password are not same ';
      }
      else {
        return false
      }
    }
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '');
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    this.setState({ buttonLoading: true });

    let body = this.state.body;
    body.password_default = body.password;

    API.post('userApp', this.state.body, { headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then((resp) => {
        this.setState({
          body: {},
        });
        this.setState({ buttonLoading: false });
        this.props.afterSubmit('success', resp.message)
        this.props.close()
      })
      .catch(error => {
        this.props.afterSubmit('success', error)
        this.props.close()
      })
  }



  render() {
    return (
      <Modal isOpen={this.props.open} toggle={() => this.props.close()}>
        <ModalHeader toggle={() => this.props.close()}>Add User</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">
                Username
              </Label>
              <Input
                id="username"
                name="username"
                placeholder="Enter Username"
                type="text"
                onChange={this.onChange}
                required
                invalid={this.state.error['username'] ? true : false}
              />
              <FormFeedback >{this.state.error['username']}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">
                Email
              </Label>
              <Input
                id="email"
                name="email"
                placeholder="Enter Email"
                type="email"
                onChange={this.onChange}
                invalid={this.state.error['email'] ? true : false}
              />
              <FormFeedback >{this.state.error['username']}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">
                Password
              </Label>
              <Input
                id="password"
                name="password"
                placeholder="Enter Password"
                type="password"
                onChange={this.onChange}
                invalid={this.state.error['password'] ? true : false}
              />
              <FormFeedback >{this.state.error['password']}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">
                Re-enter Password
              </Label>
              <Input
                id="repassword"
                name="repassword"
                placeholder="Re-enter Password"
                type="password"
                onChange={this.onChange}
                invalid={this.state.error['repassword'] ? true : false}
              />
              <FormFeedback >{this.state.error['repassword']}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label >
                Role Akses
              </Label>
              <Input
                id="role"
                name="role_id"
                type="select"
                onChange={this.onChange}
                invalid={this.state.error['role_id'] ? true : false}
              >
                <option value={''}>Choose</option>
                {
                  this.props.roleOption.map( item => {
                    return <option value={item.id}>{item.name}</option>
                  })
                }
              </Input>
              <FormFeedback >{this.state.error['username']}</FormFeedback>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
            {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
          </Button>
          <Button color="danger" onClick={() => this.props.close()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}