import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from 'src/api'
import {
  Spinner,
  Input,
  Col,
  Row,
  Label,
  FormGroup,
  FormFeedback,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import DetailConfig from './config/Detail';

class index extends Component {

  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      id: this.props.match.params.id,
      menuCode: constants.MENU_LEADS_TRACKING_TYPE_PRODUCT,
      scope: [],
      buttonLoading: false,
      disabled: true,
      body: {
        tipe_produk: null,
        deskripsi: null,
        status: 1,
      },
      showTable: false,
      search: '',
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      showModalDetail: false,
      selectedData: {},
    };
  }

  async componentDidMount() {
    await this.checkAccess();
    this.onLoad();
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  onLoad = () => {
    API.get(`product/type/${this.state.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }).then(res => {
      console.log(res.data.data)
      this.setState({
        body: {
          ...this.state.body,
          tipe_produk: res.data.data.tipe_produk,
          deskripsi: res.data.data.deskripsi,
        },
      })
    });

    this.getList();
  }

  getList = () => {
    API.get(`/product/leads/config`
      + '?size=' + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      + '&product_id=' + this.state.id
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModal: !this.state.showModal,
      deleteId: id,
    });
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onSearch = (e) => {
    this.setState({
      search: e.target.value
    }, () => {
      if (this.state.search.length > 2 || this.state.search.length === 0) {
        this.getList();
      }
    })
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title align-middle">Detail Type Product</strong>
                  </div>
                  <div className="card-body">
                    <Row className="row-cols-auto g-3 align-items-center mb-4">
                      <Col className='w-100'>
                        <FormGroup>
                          <Label>Type Product</Label>
                          <Input
                            type="text"
                            value={this.state.body.tipe_produk}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <Label>Description</Label>
                        <Input
                          type="textarea"
                          value={this.state.body.deskripsi}
                          rows="5"
                          disabled
                        />
                      </Col>
                      <Col className='w-100'>
                        <FormGroup switch>
                          <Label>Status</Label>
                          <div className='d-flex'>
                            <div class="form-switch">
                              <input disabled name='status' class="form-check-input form-switch" type="checkbox" value={1} checked={this.state.body.status == 1} role="switch" onClick={() => {
                                this.setState({
                                  body: {
                                    ...this.state.body,
                                    status: this.state.body.status == 1 ? 0 : 1
                                  }
                                });
                              }} />
                            </div>
                            {this.state.body.status ? 'Active' : 'Non Active'}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <Link to='/leads-tracking/type-product' className='btn btn-danger w-100'>Back</Link>
                        <Link to={'/leads-tracking/type-product/edit/'+this.state.id} className='btn btn-primary w-100 mt-2'>Edit</Link>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title align-middle">Config/Price List</strong>
                  </div>
                  <div className="card-body">
                    {
                      // !this.state.showTable ? <>Loading ...</> :
                      <DataTable
                        noHeader={true}
                        columns={[
                          {
                            name: 'ID',
                            selector: 'id',
                            sortable: true,
                            maxWidth: '5%'
                          },
                          {
                            name: 'Size',
                            selector: 'size',
                            sortable: true
                          },
                          {
                            name: 'Qty',
                            sortable: true,
                            cell: data => data.qty_from + ' - ' + data.qty_to
                          },
                          {
                            name: 'Price',
                            selector: 'price',
                            sortable: true,
                          },
                          {
                            name: 'Action',
                            selector: 'action',
                            sortable: false,
                            cell: data =>
                              <>
                                <div>
                                  <button className="text-dark btn btn-sm btn-outline-link"
                                    onClick={() =>
                                      this.setState({
                                        showModalDetail: true,
                                        selectedData: data
                                      })
                                    }>
                                    <li className="fa fa-eye"></li>
                                  </button>
                                </div>
                              </>
                          }
                        ]}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DetailConfig
            open={this.state.showModalDetail}
            close={() => this.setState({ showModalDetail: false })}
            data={this.state.selectedData}
          />
        </div>
    )
  }
}

export default index;