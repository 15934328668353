import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Alert,
  Nav,
  NavItem,
  Badge,
} from 'reactstrap';
import API from "../../api";
import { NavLink } from "react-bootstrap";
import moment from "moment";
import DataTable from "react-data-table-component";

export default class UsersListAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      columns: [
        {
          name: 'Username',
          selector: 'fullname',
          sortable: true
        },
        {
          name: 'Fullname',
          selector: 'fullname',
          sortable: true,
        },
        {
          name: 'Status',
          sortable: true,
          cell: data =>
            <label>
              {data.active === 1 ?
                <Badge
                  color="success"
                  pill
                >
                  Active
                </Badge>
                :
                <Badge
                  color="danger"
                  pill
                >
                  Non Active
                </Badge>
              }
            </label>
        },
        {
          name: 'Action',
          cell: data => {
            
          }
        }
      ],
      disabled: true,
      buttonLoading: false,
      activeTab: 'detail'
    }
  }

  submitHiddenForum() {
    this.setState({
      buttonLoading: true
    })

    API.put(`/forum/banned/${this.props.data.id}`, {}, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          buttonLoading: false
        })
        this.props.close();
        this.props.refresh();
      })
      .catch(err => {
        this.setState({
          buttonLoading: false
        })
        console.log(err)
      })
  }

  submitCloseReport() {
    this.setState({
      buttonLoading: true
    })

    API.put(`/forum/close-report-forum/${this.props.data.id}`, {}, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          buttonLoading: false
        })
        this.props.close();
        this.props.refresh();
      })
      .catch(err => {
        this.setState({
          buttonLoading: false
        })
        console.log(err)
      })
  }

  render() {
    return (
      <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="md">
        <ModalHeader toggle={() => this.props.close()}>User App Linked</ModalHeader>
        <ModalBody>
          {
            this.props.dataLoading ?
              <div className="">
                <div class="skeleton skeleton-text" style={{ width: '25%' }}></div>
                <div class="skeleton skeleton-body"></div>
                <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
              </div>
              :
              <>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="font-weight-bold small text-secondary">
                      Partner Name
                    </div>
                    <div className="text-dark font-sm font-weight-bold">
                      {this.props.data.partner_name}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="font-weight-bold small text-secondary">
                      Manufacture
                    </div>
                    <div className="text-dark font-sm font-weight-bold">
                      {this.props.data.manufacturer_name}
                    </div>
                  </div>
                </div>
                <DataTable
                  noHeader={true}
                  columns={this.state.columns}
                  data={this.props.data?.items}
                  style={{
                    color: '#202124',
                    border: 'none',
                    fontSize: '14px',
                  }}
                />
              </>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => this.props.close()}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
}