import React, { Component, useState } from 'react';

class Logout extends Component {
    constructor(props){
        super(props);
    }

    componentDidMount(){
        localStorage.setItem('isLoggedIn', 'false');
        localStorage.setItem('token', '');
        localStorage.setItem('config', '');
        this.props.history.push("/");
        window.location.reload();
    }

    render(){
        return(
            <div>
                <p>You are logout</p>
            </div>
        )
    }
}

export default Logout;