import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Routes from './Routes';
// import {browserHistory} from "react-router";

export default class RightPanel extends Component {

    constructor(props){
        super(props);
    }
    
    componentDidMount(){
        // this.props.history.push("/login")
    }

    render() {
        var login = localStorage.getItem('isLoggedIn');
        console.log(login)
        if(login === 'true'){
            return(
                <div id="right-panel" className="right-panel">
                    <Header/>
                    <Routes/>
                    <div className="clearfix"></div>
                    <Footer/>
            </div>
            )
        } else {
            return(
                <div>
                    <Routes/>
                </div>
            )
        }
    }
}