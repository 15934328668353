/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import API from '../../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner
} from 'reactstrap';
import { Link } from 'react-router-dom';
import AddProductCode from '../code/addProductCode';
import AddProductVariant from '../variant/addProductVariant';
import ColorPicker from '../../../components/elements/form/ColorPicker';
import NotifToast from '../../../components/notificationModal/NotifToast';


class detail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      showModal: this.props.open,
      body: {},
      code: '',
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        title: 'Success',
        message: 'Batch Number Config updated successfully'
      },
      optionProductCode: [],
      modalProductCode: false,
      optionProductVariant: [],
      modalProductVariant: false,
      variantInputIndexes: [{ value: '', id: 0 }],
      changeVariantIndex: 0,
      multipleVariant: false,
      manufacturer: {},
      showData: false,

    }
  }

  componentDidMount() {
    this.detail();
  }

  async detail() {
    await API.get(`product/batchnumber/config-detail/${this.state.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data.data;
        const manufacturer_id = result.manufacturer_id;
        this.getManufacturer(manufacturer_id);

        this.setState({
          body: {
            product_code: result.product_code,
            product_code_id: result.product_code_id,
            batch_number: result.batch_number,
            product_variant_ids: JSON.parse(result.product_variant_ids),
            min_redeem: result.min_redeem,
            color_primary_code: result.color_primary_code,
            color_secondary_code: result.color_secondary_code,
            status: result.status,
          },
          manufacturer: {
            manufacturer_id: result.manufacturer_id,
            manufacturer_name: result.manufacturer?.name,
          }
        }, async () => {
          await this.getOptionProductCode(manufacturer_id)
          this.setState({
            showData: true
          })
        })
      })
  }

  getManufacturer(id) {
    API.get(`manufacturer/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data.data;
        if (result.length > 0) {
          this.setState({
            manufacturer: result[0],
            multipleVariant: result[0].flag_productvariant === 1 ? true : false
          });
        }
      })
      .catch(err => {
        alert(err)
      })
  }

  async getOptionProductCode(id) {
    await API.get(`product/getProductCodes/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data.result;

        this.setState({
          optionProductCode: result,
        }, async () => {
          const variantInputIndexes = [];
          await Promise.all(this.state.body.product_variant_ids.map(async (item, index) => {
            await this.getOptionProductVariant(index === 0 ? this.state.body.product_code_id : this.state.body.product_variant_ids[index - 1], index);
            console.log('kesini')
            let value = this.state.optionProductVariant.length > 0 ? this.state.optionProductVariant[index].find(x => x.id === parseInt(item)) : '';
            variantInputIndexes[index] = { value: value?.variant, id: item }
          }));
          this.setState({
            variantInputIndexes: variantInputIndexes
          })
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  async getOptionProductVariant(id, index = 0) {
    let url = `product/getVariant/${this.state.manufacturer.manufacturer_id}/${this.state.body.product_code_id}/${id}`;
    if (index === 0) url = `product/getVariant/${this.state.manufacturer.manufacturer_id}/${this.state.body.product_code_id}/0`;

    await API.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data.result;
        let optionNew = this.state.optionProductVariant;
        optionNew[index] = result
        console.log(optionNew)
        this.setState({
          optionProductVariant: optionNew
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  onChange = async (e) => {

  }

  render() {
    return (
      // !this.state.showData ? <>Loading...</> :
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <Link to={`/product/batchnumber/config?id=${this.state.manufacturer.manufacturer_id}`}>
                    <button
                      color="info"
                      size="sm"
                      className="btn btn-outline-link"
                    >
                      <i className='fa fa-arrow-left'></i>
                    </button>
                  </Link>
                  <strong className="card-title align-middle">Detail SKU Config</strong>
                  <div className="float-right">
                    <Link to={`/product/batchnumber/config/edit/${this.state.id}`}>
                      <button className='btn btn-sm btn-primary'>
                        <i className='fa fa-pencil'></i>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label className='w-100'>
                        Manufacturer
                      </Label>
                      <Input
                        id="manufacturer_id"
                        name="manufacturer_id"
                        type="select"
                        onChange={this.onChange}
                        disabled={true}
                      >
                        {
                          this.state.optionProductCode.length ? (
                            <>
                              <option value={this.state.manufacturer.manufacturer_id}>{this.state.manufacturer.name}</option>
                              {
                                this.state.optionProductCode.map(item => {
                                  return <option key={item.id} value={item.id}>{item.code}</option>
                                })
                              }
                            </>) : null
                        }
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label className='w-100'>
                        Product Code
                        <button type='button' onClick={() => this.setState({ modalProductCode: !this.state.modalProductCode })} className='pull-right text-primary btn btn-outline-none' style={{ fontSize: '14px' }} >+ More Item</button>
                      </Label>
                      <Input
                        id="product_code"
                        name="product_code"
                        type="select"
                        disabled
                        value={this.state.body.product_code_id}
                        invalid={this.state.error['product_code'] ? true : false}
                      >
                        {
                          this.state.optionProductCode.length ? (
                            <>
                              <option value={''}>Choose</option>
                              {
                                this.state.optionProductCode.map(item => {
                                  return <option key={item.id} value={item.id}>{item.code}</option>
                                })
                              }
                            </>) : null
                        }
                      </Input>
                      <FormFeedback >{this.state.error['product_code']}</FormFeedback>
                    </FormGroup>
                    {
                      this.state.variantInputIndexes.length > 0
                        ?
                        this.state.variantInputIndexes.map((item, index) => {
                          return (
                            <div key={index} className="form-group has-success">
                              <div className='row'>
                                <div className='col-md-6'>
                                  <label className="control-label mb-1">Variant/SKU {this.state.multipleVariant ? ` ${index + 1}` : ' '}</label>
                                </div>
                              </div>
                              <div className='row'>
                                <div className={'col-md-12'}>
                                  <select name="variant" class="form-control" disabled>
                                    {
                                      this.state.optionProductVariant.length > 0 ?
                                        <>
                                          <option value={''}>Choose</option>
                                          {
                                            this.state.optionProductVariant[index].map(item => {
                                              return <option key={item.id} data-id={item.id} value={item.variant} selected={item.id === parseInt(this.state.body.product_variant_ids[index]) ? true : false}>{item.variant}</option>
                                            })
                                          }
                                        </>
                                        : (
                                          this.state.body.product_code ? <option label="Empty list" value={''} disabled selected /> : <option value={''} label="Please select the product first" disabled selected />
                                        )
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>
                          )
                        })
                        :
                        null
                    }
                    <FormGroup>
                      <Label>
                        Batch Number
                      </Label>
                      <Input
                        id="batch_number"
                        name="batch_number"
                        placeholder="Auto Filled"
                        type="text"
                        value={this.state.body.batch_number}
                        readOnly
                        invalid={this.state.error['batch_number'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['batch_number']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Min. Redeem
                      </Label>
                      <Input
                        id="min_redeem"
                        name="min_redeem"
                        placeholder="Minimal Redeem"
                        type="text"
                        value={this.state.body.min_redeem}
                        readOnly
                        invalid={this.state.error['min_redeem'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['min_redeem']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Color Theme
                      </Label>
                      <div>
                        <ColorPicker title="Primary" color={this.state.body.color_primary_code} />
                        <ColorPicker title="Secondary" color={this.state.body.color_secondary_code} />
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default detail;