import React, { Component, useState } from 'react';
import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';
import API from '../../api'
import { BlockPicker } from 'react-color';
// import "bootstrap/js/src/collapse.js"; 
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import {
    CustomInput,
    NavLink,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Alert, Collapse
} from 'reactstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class ListManufacturer extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.deleteManufacturer = this.deleteManufacturer.bind(this);

        this.state = {
            menuCode: constants.MENU_LIST_MANUFACTURER,
            flag_show: 'all',
            scope: [],
            columns: [
                {
                    name: 'Id',
                    selector: 'manufacturer_id',
                    sortable: true,
                    maxWidth: '30px'
                },
                {
                    name: 'Status',
                    selector: 'flag_show',
                    sortable: true,
                    maxWidth: '50px',
                    cell: manufacturer =>
                        <div>
                            {
                                manufacturer.flag_show === 1 ? 
                                <span className="badge badge-info badge-pill">Show</span> : 
                                <span className="badge badge-secondary badge-pill">Hide</span> 
                            }
                        </div>
                },
                {
                    name: 'Positions',
                    maxWidth: '30px',
                    selector: 'positions',
                    sortable: true
                },
                {
                    name: 'Name',
                    selector: 'name',
                    sortable: true
                },
                {
                    name: 'Logo',
                    selector: 'logo',
                    sortable: true,
                    cell: manufacturer => <img src={API.defaults.baseURL === 'http://localhost:3030/v2' ? manufacturer.logo.replace('https', 'http') : manufacturer.logo} alt={manufacturer.name} style={{ maxWidth: '100px' }} />,
                },
                {
                    name: 'Email Notification',
                    selector: 'action',
                    sortable: false,
                    maxWidth: '60px',
                    cell: manufacturer =>
                        <div>
                            <div class="form-check form-switch">
                                <input class="form-check-input form-switch" type="checkbox" value={manufacturer.flag_suspiciousemail} checked={manufacturer.flag_suspiciousemail === 1} role="switch" id="flexSwitchCheckDefault" onClick={this.updateManufacturerSusEmailFlag.bind(this, manufacturer.manufacturer_id, manufacturer.flag_suspiciousemail === 0 ? true : false)} />
                            </div>
                        </div>
                },
                {
                    name: 'Action',
                    selector: 'action',
                    sortable: false,
                    cell: manufacturer =>
                        <div>
                            <button type="button" className="btn btn-sm btn-outline-link" onClick={this.linkDetail.bind(this, manufacturer.manufacturer_id)}>
                                <li className="fa fa-eye"></li>
                            </button>&nbsp;
                            {
                                this.state.scope.includes('update') ?
                                    <button type="button" className="btn btn-sm btn-outline-info" onClick={this.linkEdit.bind(this, manufacturer.manufacturer_id)}>
                                        <li className="fa fa-pencil"></li>
                                    </button>
                                    : null
                            }&nbsp;
                            {

                                this.state.scope.includes('delete') ?
                                    <button type="button" className="btn btn-sm btn-outline-danger" onClick={this.toggle.bind(this, manufacturer.manufacturer_id)}>
                                        <li className="fa fa-trash"></li>
                                    </button>
                                    : null
                            }&nbsp;
                            {
                                manufacturer.flag_singlecert === 1 && manufacturer.path_singlecert !== null ?
                                    <a onClick={() => { this.openModalPreviewSingleCert(manufacturer.manufacturer_id) }} className="btn btn-sm btn-outline-success" target="_blank" data-toggle="tooltip" title={manufacturer.path_singlecert}>
                                        <i class="fa fa-file-pdf-o" /> Single Cert
                                    </a>
                                    :
                                    ''

                            }
                            {
                                manufacturer.flag_customcert === 1 && manufacturer.path_customcert !== null ?
                                    <a onClick={() => { this.showCustomCert(manufacturer.manufacturer_id) }} className="btn btn-sm btn-outline-success" target="_blank" data-toggle="tooltip" title={manufacturer.path_singlecert}>
                                        <i class="fa fa-file-pdf-o" /> Custom Cert
                                    </a>
                                    :
                                    ''
                            }
                        </div>
                },
                // {
                //     name: 'Manage Variant',
                //     selector: 'action',
                //     sortable: false,
                //     maxWidth: '60px',
                //     cell: manufacturer =>
                //     <div>
                //             <div class="form-check form-switch">
                //                 <input class="form-check-input" type="checkbox" value={manufacturer.flag_productvariant} checked={manufacturer.flag_productvariant === 1} role="switch" id="flexSwitchCheckDefault" onClick={this.updateManufacturerFlag.bind(this, manufacturer.manufacturer_id)} />
                //             </div>
                //         </div>
                // },
                {
                    name: 'Config',
                    selector: 'action',
                    sortable: false,
                    maxWidth: '120px',
                    cell: manufacturer =>
                        <div>
                            <button type="button" className="btn btn-sm btn-outline-info" onClick={() => { this.openModalCertConfig(manufacturer) }}>
                                <li className="fa fa-gear"></li> Config
                            </button>
                        </div>
                }
                // {
                //     name: 'Single Cert',
                //     selector: 'action',
                //     sortable: false,
                //     maxWidth: '100px',
                //     cell: manufacturer =>
                //         <div>
                //             <div class="form-check form-switch">
                //                 <input class="form-check-input" type="checkbox" value={manufacturer.flag_singlecert} checked={manufacturer.flag_singlecert === 1} role="switch" id="singleCertFlag" onClick={this.updateManufacturerSingleCertFlag.bind(this, manufacturer.manufacturer_id)} />

                //             </div>
                //         </div>
                // },
                // {
                //     name: 'File Cert',
                //     selector: 'action',
                //     sortable: false,
                //     maxWidth: '50px',
                //     cell: manufacturer =>
                //         <div>
                //             {
                //                 manufacturer.flag_singlecert === 1 ?

                //                     <CustomInput
                //                         type="file"
                //                         id="certFile"
                //                         name="certFile"
                //                         // disabled={manufacturer.flag_singlecert}
                //                         label={this.state.certFileLabel}
                //                         onChange={e => this.handleUploadChange(e, manufacturer.manufacturer_id)}
                //                     />
                //                     :
                //                     ''

                //             }
                //         </div>
                // }
            ],
            attributeValues: [{ name: 'SKU', value: "sku" }, { name: 'Production Date', value: "production_date" }, { name: 'Warranty', value: "warranty" }],
            data: [],
            isModalDeleteOpen: false,
            alertSuccess: false,
            alertFlagSuccess: false,
            alertError: false,
            deleteManufacturer: "",
            search: "",
            paginationPerPage: 10,
            paginationTotalRows: 0,
            paginationPage: 1,
            customCertConfig: [
                {
                    "location_x": 10,
                    "duration": 0,
                    "location_y": 20,
                    "attribute_value": "sku",
                    "font": "assets/GothamBook.ttf",
                    "font_weight": 6,
                    "font_size": 15,
                    "font_color": "#000"
                }
            ],
            customCertConfigData: [],
            attributeList: [{
                "duration": 0,
                "location_x": 400,
                "location_y": 1040.5,
                "attribute_value": "sku",
                "font": "assets/GothamBook.ttf",
                "font_weight": 6,
                "font_size": 22.5,
                "font_color": "#2c2c2c"
            }],
            previewCustomCertUrl: null
        };
    }

    async componentDidMount() {
        await this.checkAccess();
        this.getListManufacturer();
    }

    openModalCertConfig(manufacturer) {
        this.setState({
            modalConfigCert: !this.state.modalConfigCert,
            manIdEdit: manufacturer.manufacturer_id,
        })
        this.getManufacturerEditDetail(manufacturer.manufacturer_id);
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }
        this.props.history.push(`/unauthorized`);
    }

    getListManufacturer() {
        console.log('this.state.flag_show ', this.state.flag_show)
        API.get('manufacturer?size=' 
            + this.state.paginationPerPage 
            + '&page=' + this.state.paginationPage 
            + '&search=' + this.state.search 
            + '&flag_show=' + this.state.flag_show
            + '&type=partner'
        , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                console.log("List Manufacturer", res.data);
                this.setState({
                    data: res.data.item,
                    paginationTotalRows: res.data.totalItem
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    linkEdit(id) {
        this.props.history.push("/manufacturer/edit/" + id);
    }

    toggle(id) {
        console.log("Id delete", typeof id)
        this.setState({
            isModalDeleteOpen: !this.state.isModalDeleteOpen,
        })

        if (typeof id == "number") {
            this.setState({
                deleteManufacturer: id
            })
        }
    }

    updateManufacturerFlag(data, id) {
        API.put('manufacturer/productvariant/' + data + '/' + id, this.state.body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                console.log("manufacturer deleted");
                this.getListManufacturer();
                // this.toggleAlertFlagSuccess();
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    updateManufacturerSusEmailFlag(data, id) {
        API.put('manufacturer/suspiciousemail/' + data + '/' + id, this.state.body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                // console.log("manufacturer deleted");
                this.getListManufacturer();
                // this.toggleAlertFlagSuccess();
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    updateManufacturerSingleCertFlag(data, id) {
        API.put('manufacturer/productsingle/' + data + '/' + id, this.state.body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                this.getListManufacturer();
                // this.toggleAlertFlagSuccess();
            })
            .catch(err => {
                console.log("Error", err)
            })
    }
    async handleUploadChange(event, id) {
        console.log("Logo file: ", event.target.files[0]);

        if (event.target.files[0] && event.target.files[0].size > 5120000) {
            alert('This certificate file is too big. Please select another certificate file')
        } else {
            if (event.target.files[0] && event.target.files[0].name && event.target.files[0].size <= 5120000) {

                this.setState({
                    certFileLabel: event.target.files[0].name
                });
                let body = new FormData();
                body.append("manufacturer_id", id);
                body.append("certFile", event.target.files[0]);

                await API.post('manufacturer/uploadsinglecert', body, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }).then(res => {
                    this.getListManufacturer();
                    this.toggleAlertFlagSuccess();
                    this.setState({
                        certFileLabel: null,
                        certFile: null
                    });
                    this.getManufacturerPath(this.state.manIdEdit);
                })
            } else {
                this.setState({
                    certFileLabel: null,
                    certFile: null
                });
                // this.getManufacturerEditDetail(this.state.manIdEdit);
            }
        }
    }

    async uploadCustomCert(event, id) {
        console.log("Logo file: ", event.target.files[0]);

        if (event.target.files[0] && event.target.files[0].size > 5120000) {
            alert('This certificate file is too big. Please select another certificate file')
        } else {
            if (event.target.files[0] && event.target.files[0].name && event.target.files[0].size <= 5120000) {

                this.setState({
                    customCertFileLabel: event.target.files[0].name
                });
                let body = new FormData();
                body.append("manufacturer_id", id);
                body.append("certFile", event.target.files[0]);

                await API.post('manufacturer/uploadcustomcert', body, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }).then(res => {
                    this.getListManufacturer();
                    this.toggleAlertFlagSuccess();
                    this.setState({
                        customCertFileLabel: null,
                        customCertFile: null
                    });
                    this.getManufacturerPath(this.state.manIdEdit);
                })
            } else {
                this.setState({
                    customCertFileLabel: null,
                    customCertFile: null
                });
                // this.getManufacturerEditDetail(this.state.manIdEdit);
            }
        }
    }

    getManufacturerEditDetail(manId) {
        API.get('manufacturer/' + manId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res.data.data.length > 0) {
                    this.setState({
                        flagProductvariantEdit: parseInt(res.data.data[0].flag_productvariant),
                        flagOneTimeScanEdit: parseInt(res.data.data[0].one_time_scans),
                        flagSKU: parseInt(res.data.data[0].flag_sku),
                        flagSinglecertEdit: parseInt(res.data.data[0].flag_singlecert),
                        flagCustomCertEdit: parseInt(res.data.data[0].flag_customcert),
                        pathSingleCertEdit: res.data.data[0].path_singlecert,
                        pathCustomCertEdit: res.data.data[0].path_customcert,
                        customCertConfigData: res.data.data[0].customCert.length > 0 ? res.data.data[0].customCert : this.state.customCertConfig
                    })
                }
            })
    }
    getManufacturerPath(manId) {
        API.get('manufacturer/' + manId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res.data.data.length > 0) {
                    this.setState({
                        pathSingleCertEdit: res.data.data[0].path_singlecert,
                        pathCustomCertEdit: res.data.data[0].path_customcert,
                    })
                }
            })
    }

    deleteManufacturer() {
        API.delete('manufacturer/' + this.state.deleteManufacturer, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                console.log("manufacturer deleted");
                this.getListManufacturer();
                this.toggle();
                this.toggleAlertSuccess();
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    toggleAlertSuccess() {
        this.setState({
            alertSuccess: !this.state.alertSuccess
        })

        setTimeout(() => {
            this.setState({
                alertSuccess: !this.state.alertSuccess
            })
        }, 2000)
    }

    toggleAlertFlagSuccess() {
        this.setState({
            alertFlagSuccess: !this.state.alertFlagSuccess
        })

        setTimeout(() => {
            this.setState({
                alertFlagSuccess: !this.state.alertFlagSuccess
            })
        }, 2000)
    }

    toggleAlertError() {
        this.setState({
            alertError: !this.state.alertError
        })

        setTimeout(() => {
            this.setState({
                alertError: !this.state.alertError
            })
        }, 2000)
    }

    linkDetail(id) {
        this.props.history.push("/manufacturer/" + id);
    }

    changePage(page) {
        this.setState({
            paginationPage: page
        }, () => {
            this.getListManufacturer();
        })
    }

    changeRowsPerPage(rowsPerPage) {
        this.setState({
            paginationPerPage: rowsPerPage
        }, () => {
            this.getListManufacturer();
        })
    }

    searchManufacturer(e) {
        console.log('e ', e)
        console.log('e.target.id ', e.target.id)
        if (e.target.id === 'flag_show') {
            console.log('masuk sini ', e.target.value)
            this.state.flag_show = e.target.value
            this.setState({ flag_show: e.target.value })
            setTimeout(() => {
                this.getListManufacturer();
            }, 100);
        } else {
            e.preventDefault();
            this.getListManufacturer();

        }
    }

    async updateAllManufacturerFlag(flag_productvariant, flag_singlecert, flag_customcert, flag_SKU, isByLink = false) {
        // alert(this.state.flagCustomCertEdit)
        // if(this.state.pathCustomCertEdit===null){
        //     alert('nulls')
        // }
        // alert(this.state.flagCustomCertEdit)
        if (isNaN(this.state.flagProductvariantEdit)) {
            this.state.flagProductvariantEdit = 0;
        }
        if (
            parseInt(this.state.flagSinglecertEdit) === 0 &&
            parseInt(this.state.flagCustomCertEdit) === 0 || !parseInt(this.state.flagSinglecertEdit) &&
            !parseInt(this.state.flagCustomCertEdit) || this.state.pathSingleCertEdit !== null &&
            parseInt(this.state.flagSinglecertEdit) === 1 || this.state.pathCustomCertEdit !== null &&
            parseInt(this.state.flagCustomCertEdit) === 1
        ) {
            this.setState({
                disableUpdateCertConfigBtn: !this.state.disableUpdateCertConfigBtn
            })
            let customCertArr = this.state.customCertConfigData;
            let newCustomCertArr = []
            for (let i = 0; i < customCertArr; i++) {
                if (customCertArr[i].visible) {
                    customCertArr[i].visible = ''
                }
            }
            newCustomCertArr = customCertArr.filter(elements => {
                return (elements != null && elements !== undefined && elements !== "");
            });
            console.log(JSON.stringify(newCustomCertArr))
            API.put('/manufacturer/config/' + this.state.manIdEdit,
                JSON.stringify({
                    flag_productvariant: parseInt(flag_productvariant),
                    one_time_scans: parseInt(this.state.flagOneTimeScanEdit),
                    flag_sku: parseInt(flag_SKU),
                    flag_singlecert: parseInt(flag_singlecert),
                    flag_customcert: parseInt(flag_customcert),
                    customCert: flag_customcert === 1 ? newCustomCertArr : []
                }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            )
                .then(res => {
                    if (res) {
                        // alert(JSON.stringify(res.data))
                        let manId = this.state.manIdEdit;
                        this.setState({ disableUpdateCertConfigBtn: !this.state.disableUpdateCertConfigBtn, modalConfigCert: !this.state.modalConfigCert, flagProductvariantEdit: '', flagOneTimeScanEdit: '', flagSinglecertEdit: '', manIdEdit: '', flagCustomCertEdit: '', previewCustomCertUrl: null, pathSingleCertEdit: '', pathCustomCertEdit: '' })
                        this.toggleAlertFlagSuccess();
                        this.getListManufacturer();

                        if (isByLink) {
                            this.props.history.push(isByLink)
                        }
                    }
                })
                .catch((err) => {
                    this.toggleAlertError();
                    setTimeout(() => {
                        this.setState({
                            disableUpdateCertConfigBtn: !this.state.disableUpdateCertConfigBtn
                        })
                    }, 5000)
                    console.log(err)
                })
        } else if (parseInt(this.state.flagProductvariantEdit) === 0 || null && parseInt(this.state.flagSinglecertEdit) === 0 || null && parseInt(this.state.flagCustomCertEdit) === 0 || null) {
            alert('Please enable at least one config cert')
        } else {
            alert('Please upload single/custom cert first')
        }
    }

    onChangeAttribute(e, index, type) {
        let attrArray = this.state.customCertConfig;
        let attrObj = attrArray.at(index);
        if (type !== 'visible') {
            e.preventDefault();
            attrObj[type] = e.target.value;
        } else {
            if (attrObj[type] === true) {
                attrObj[type] = !attrObj[type]
            } else {
                attrObj[type] = e
            }
        }
        console.log(JSON.stringify(attrArray))
        this.setState({
            customCertConfig: attrArray
        })
    }

    addAttribute() {
        let certConfigArr = this.state.customCertConfig;
        let attrObj = {
            "location_x": 10,
            "location_y": 20,
            "attribute_value": "sku",
            "font": "assets/GothamBook.ttf",
            "font_weight": 6,
            "font_size": 15,
            "font_color": "#000"
        };
        // alert(certConfigArr.length)
        // attrObj.idx = certConfigArr.length-1;
        certConfigArr.push(attrObj)
        this.setState({
            customCertConfig: certConfigArr
        })
    }

    deleteAttribute(index) {
        let confCertArr = this.state.customCertConfig;
        delete confCertArr[index]
        this.setState({
            customCertConfig: confCertArr
        })
    }

    onChangeAttributeData(e, index, type) {
        let attrArray = this.state.customCertConfigData;
        // alert(this.state.customCertConfig.length)
        // alert(index+1)
        let attrObj = attrArray.at(index);
        // alert(JSON.stringify(attrObj))
        if (type !== 'visible') {
            // e.preventDefault();
            if (type === 'font_weight') {
                attrObj[type] = parseInt(e.target.value);
            } else
                if (type === 'font_color') {
                    attrObj[type] = e;
                } else {
                    attrObj[type] = e.target.value;
                }
        } else {
            if (attrObj[type] === true) {
                attrObj[type] = !attrObj[type]
            } else {
                attrObj[type] = e
            }
        }
        console.log(JSON.stringify(attrArray) + ' data')
        this.setState({
            customCertConfigData: attrArray
        })
    }

    addAttributeData() {
        let certConfigArr = this.state.customCertConfigData;
        let attrObj = {
            "location_x": 10,
            "location_y": 20,
            "attribute_value": "sku",
            "font": "assets/GothamBook.ttf",
            "font_weight": 6,
            "font_size": 22.5,
            "font_color": "#2c2c2c"
        };
        // alert(certConfigArr.length)
        // attrObj.idx = certConfigArr.length-1;
        certConfigArr.push(attrObj)
        this.setState({
            customCertConfigData: certConfigArr
        })
    }

    deleteAttributeData(index) {
        let confCertArr = this.state.customCertConfigData;
        delete confCertArr[index]
        this.setState({
            customCertConfigData: confCertArr
        })
    }

    previewCustomCert() {
        this.setState({
            loadingCert: !this.state.loadingCert
        })
        if (this.state.previewCustomCertUrl) {
            this.setState({
                previewCustomCertUrl: null
            })
        }
        API.put('/manufacturer/previewcert/' + this.state.manIdEdit, JSON.stringify({ customCert: this.state.customCertConfigData }), { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res.data.status) {
                    setTimeout(() => {
                        this.setState({
                            previewCustomCertUrl: res.data.data,
                            loadingCert: !this.state.loadingCert
                        })
                    }, 500)
                }
            })
    }

    showCustomCert(manId) {
        API.get('/manufacturer/' + manId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res.data.data.length > 0) {
                    this.previewCustomCertParams(manId, res.data.data[0].customCert)
                }
            })
    }

    previewCustomCertParams(manId, customCertJson) {
        this.setState({
            loadingCert: !this.state.loadingCert
        })
        if (this.state.previewCustomCertUrl) {
            this.setState({
                previewCustomCertUrl: null
            })
        }
        API.put('/manufacturer/previewcert/' + manId, JSON.stringify({ customCert: customCertJson }), { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res.data.status) {
                    setTimeout(() => {
                        this.setState({
                            previewCustomCertUrl: res.data.data,
                            loadingCert: !this.state.loadingCert
                        })
                    }, 500)
                }
            })
    }

    async openModalPreviewSingleCert(manId) {
        this.setState({
            loadingCert: !this.state.loadingCert
        })
        if (this.state.previewSingleCertUrl) {
            this.setState({
                previewSingleCertUrl: null
            })
        }
        API.get('/manufacturer/' + manId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res.data.data.length > 0) {
                    this.setState({
                        loadingCert: !this.state.loadingCert,
                        previewSingleCertUrl: API.defaults.baseURL.slice(0, -2) + res.data.data[0].path_singlecert
                    })
                }
            })
    }

    async openModalBasePreviewCustomCert(manId) {
        this.setState({
            loadingCert: !this.state.loadingCert
        })
        if (this.state.previewBaseCustomCertUrl) {
            this.setState({
                previewBaseCustomCertUrl: null
            })
        }
        API.get('/manufacturer/' + manId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                if (res.data.data.length > 0) {
                    this.setState({
                        loadingCert: !this.state.loadingCert,
                        previewBaseCustomCertUrl: API.defaults.baseURL.slice(0, -2) + res.data.data[0].path_customcert
                    })
                }
            })
    }

    setFontColor = (color, index) => {
        // alert(color.hex)
        this.onChangeAttributeData(color.hex, index, 'font_color')
    }

    clearForm = () => {
        this.setState({
            modalConfigCert: !this.state.modalConfigCert,
            flagProductvariantEdit: '',
            flagOneTimeScanEdit: '',
            flagSinglecertEdit: '',
            manIdEdit: '',
            flagCustomCertEdit: '',
            previewCustomCertUrl: null,
            pathSingleCertEdit: '',
            pathCustomCertEdit: '',
            showColorPicker: false
        })
    }


    render() {
        return (
            <div className="content">
                <Modal className="modal-cert-config" isOpen={this.state.modalConfigCert} toggle={() => this.clearForm()}>
                    {
                        this.state.showColorPicker
                            ?
                            <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, zIndex: 998 }} onClick={() => { if (this.state.showColorPicker) { this.setState({ showColorPicker: false }) } }}></div>
                            :
                            null
                    }
                    <ModalHeader toggle={() => this.clearForm()}>Manufacturer Config</ModalHeader>
                    <ModalBody>
                        <div className='row'>
                            <Alert
                                color="danger"
                                className="alert-modal"
                                isOpen={this.state.alertError}
                            >
                                Failed to save manufacturer config, please try again a few seconds.
                            </Alert>
                            <div className='col-4'>
                                <div className='row border-bottom'>
                                    <div className='col-5'>
                                        <label>Manage Variant</label>
                                    </div>
                                    <div className='col-7 text-right'>
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={this.state.flagProductvariantEdit === 1 ? true : false}
                                                onChange={(e) => { this.setState({ flagProductvariantEdit: this.state.flagProductvariantEdit === 1 ? 0 : 1 }) }}
                                            />
                                            <div class="slider round">
                                                <span class="on">ON</span>
                                                <span class="off">OFF</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-2'>
                                    <div className='col-5'>
                                        <label>Required SKU</label>
                                    </div>
                                    <div className='col-7 text-right'>
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={this.state.flagSKU === 1 ? true : false}
                                                onChange={(e) => { this.setState({ flagSKU: this.state.flagSKU === 1 ? 0 : 1 }) }}
                                            />
                                            <div class="slider round">
                                                <span class="on">ON</span>
                                                <span class="off">OFF</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-2'>
                                    <div className='col-5'>
                                        <label>One Time Scan Cert</label>
                                    </div>
                                    <div className='col-7 text-right'>
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={this.state.flagOneTimeScanEdit === 1 ? true : false}
                                                onChange={(e) => { this.setState({ flagOneTimeScanEdit: this.state.flagOneTimeScanEdit === 1 ? 0 : 1 }) }}
                                            />
                                            <div class="slider round">
                                                <span class="on">ON</span>
                                                <span class="off">OFF</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-2'>
                                    <div className='col-5'>
                                        <label>Single Cert</label>
                                    </div>
                                    <div className='col-7 text-right'>
                                        <label class="switch">
                                            <input type="checkbox"
                                                disabled={this.state.flagCustomCertEdit === 1}
                                                checked={this.state.flagSinglecertEdit === 1 ? true : false}
                                                onChange={(e) => { this.setState({ flagSinglecertEdit: this.state.flagSinglecertEdit === 1 ? 0 : 1 }) }}
                                            />
                                            <div class="slider round">
                                                <span class="on">ON</span>
                                                <span class="off">OFF</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-2'>
                                    <div className='col-5'>
                                        <label>Use Custom Cert</label>
                                    </div>
                                    <div className='col-7 text-right'>
                                        <label class="switch">
                                            <input type="checkbox"
                                                disabled={this.state.flagSinglecertEdit === 1}
                                                checked={this.state.flagCustomCertEdit === 1 ? true : false}
                                                onChange={(e) => { this.setState({ flagCustomCertEdit: this.state.flagCustomCertEdit === 1 ? 0 : 1 }) }}
                                            />
                                            <div class="slider round">
                                                <span class="on">ON</span>
                                                <span class="off">OFF</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div>
                                        {
                                            this.state.flagCustomCertEdit === 0 || !this.state.flagCustomCertEdit
                                                ?
                                                null
                                                :
                                                <button onClick={() => { this.browseCustomCertFile.click(); }} className='btn btn-sm btn-outline-success mr-2 mb-1'>Browse...</button>
                                        }
                                        <input name="customCertFile" onChange={(e) => { this.uploadCustomCert(e, this.state.manIdEdit) }} type="file" ref={ref => (this.browseCustomCertFile = ref)} style={{ display: 'none' }} />
                                        {
                                            this.state.customCertFileLabel ? <label> {this.state.customCertFileLabel} </label> : null
                                        }
                                    </div>
                                </div>
                                <div className='row border-bottom mt-2'>
                                    <div className='col-5'>
                                        <label>Product SKU</label>
                                    </div>
                                    <div className='col-7 text-right mb-2'>
                                        <button type="button" className="btn btn-sm btn-outline-none" onClick={() => this.updateAllManufacturerFlag(this.state.flagProductvariantEdit, this.state.flagSinglecertEdit, this.state.flagCustomCertEdit, this.state.flagSKU, `/product/batchnumber/config?id=${this.state.manIdEdit}`)}>
                                            Show Detail Config  <li className="fa fa-chevron-right"></li>
                                        </button>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-2'>
                                    <div className='col-5'>
                                        <label>Web Scan</label>
                                    </div>
                                    <div className='col-7 text-right mb-2'>
                                        <button type="button" className="btn btn-sm btn-outline-none" onClick={() => this.updateAllManufacturerFlag(this.state.flagProductvariantEdit, this.state.flagSinglecertEdit, this.state.flagCustomCertEdit, this.state.flagSKU, `/config/scan-web?id=${this.state.manIdEdit}`)}>
                                            Show Detail Config  <li className="fa fa-chevron-right"></li>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-8'>
                                {
                                    this.state.flagCustomCertEdit === 1
                                        ?
                                        <>
                                            <h6 className='modal-title mb-2 text-success'>Custom Cert Settings:</h6>
                                            {/* <p>{JSON.stringify(this.state.customCertConfig)}</p> */}
                                            <div className='form-group'>
                                                {
                                                    this.state.customCertConfigData.length > 0
                                                        ?
                                                        this.state.customCertConfigData.map((item, index) => {
                                                            return (
                                                                <div>
                                                                    <div className='vertical-middle' style={{ height: 36 }}>
                                                                        <span className='color-black vertical-middle-p'>Attribute Style for {item.attribute_value}</span>
                                                                        {
                                                                            index === 0
                                                                                ?
                                                                                <button class="btn btn-sm btn-outline-success p-a-r-1em" type="button" onClick={() => { this.addAttributeData() }}>
                                                                                    <i className='fa fa-plus'></i>
                                                                                </button>
                                                                                :
                                                                                <button class="btn btn-sm btn-outline-danger p-a-r-1em" type="button" onClick={() => { this.deleteAttributeData(index) }}>
                                                                                    <i className='fa fa-trash'></i>
                                                                                </button>
                                                                        }
                                                                        <button class="btn btn-sm btn-outline-success p-a-r-3-5em" type="button" onClick={() => { this.onChangeAttributeData(true, index, 'visible') }}>
                                                                            {
                                                                                this.state.customCertConfigData[index].visible
                                                                                    ?
                                                                                    <i className='fa fa-chevron-up'></i>
                                                                                    :
                                                                                    <i className='fa fa-chevron-down'></i>
                                                                            }
                                                                        </button>
                                                                    </div>
                                                                    <Collapse isOpen={this.state.customCertConfigData[index].visible}>
                                                                        <div className='form-group'>
                                                                            <label className='mb-2'>Attribute Value</label>
                                                                            <select className='form-control' onChange={(e) => { this.onChangeAttributeData(e, index, 'attribute_value') }}>
                                                                                {
                                                                                    this.state.attributeValues.map((item2, index) => {
                                                                                        return (
                                                                                            item2.value === item.attribute_value
                                                                                                ?
                                                                                                <option value={item2.value} selected>{item2.name}</option>
                                                                                                :
                                                                                                <option value={item2.value}>{item2.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        <div className='row'>
                                                                            {
                                                                                item.attribute_value === 'warranty' ?
                                                                                    <div className='col-2 col-md-3'>
                                                                                        <div className='form-group'>
                                                                                            <label className='mb-2'>Duration (Days)</label>
                                                                                            <input type="number" step=".01" className='form-control' value={item.duration} onChange={(e) => { this.onChangeAttributeData(e, index, 'duration') }} />
                                                                                        </div>
                                                                                    </div>
                                                                                    : null

                                                                            }
                                                                            <div className='col-2 col-md-3'>
                                                                                <div className='form-group'>
                                                                                    <label className='mb-2'>Location X</label>
                                                                                    <input type="number" step=".01" className='form-control' value={item.location_x} onChange={(e) => { this.onChangeAttributeData(e, index, 'location_x') }} />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-2 col-md-3'>
                                                                                <div className='form-group'>
                                                                                    <label className='mb-2'>Location Y</label>
                                                                                    <input type="number" step=".01" className='form-control' value={item.location_y} onChange={(e) => { this.onChangeAttributeData(e, index, 'location_y') }} />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-2 col-md-3 pr-0'>
                                                                                <div className='form-group'>
                                                                                    <label className='mb-2'>Font Weight</label>
                                                                                    <input type="number" className='form-control' step="1" min={0} value={item.font_weight} onChange={(e) => { this.onChangeAttributeData(e, index, 'font_weight') }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='form-group'>
                                                                            <div className='row'>
                                                                                <div className='col-5'>
                                                                                    <label className='mb-2'>Font Family</label>
                                                                                    <select className='form-control' onChange={(e) => { this.onChangeAttributeData(e, index, 'font') }}>
                                                                                        <option value="assets/GothamBook.ttf" selected={item.font === 'assets/GothamBook.ttf'}>GothamBook</option>
                                                                                        <option value="assets/Arial.ttf" selected={item.font === 'assets/Arial.ttf'}>Arial</option>
                                                                                        <option value="assets/Montserrat-VariableFont_wght.ttf" selected={item.font === 'assets/Montserrat-VariableFont_wght.ttf'}>Montserrat</option>
                                                                                        <option value="assets/Montserrat-SemiBold.ttf" selected={item.font === 'assets/Montserrat-SemiBold.ttf'}>Montserrat Semibold</option>
                                                                                    </select>
                                                                                    {/* <input type="number" step=".1" className='form-control' value={item.font_size} onChange={(e)=>{ this.onChangeAttributeData(e, index, 'font_size') }}/> */}
                                                                                </div>
                                                                                <div className='col-3'>
                                                                                    <label className='mb-2'>Font Size</label>
                                                                                    <input type="number" step=".1" className='form-control' value={item.font_size} onChange={(e) => { this.onChangeAttributeData(e, index, 'font_size') }} />
                                                                                </div>
                                                                                <div className='col-4 text-center'>
                                                                                    <label className='mb-2 text-center w-100'>Font Color</label>
                                                                                    <a onClick={() => { this.setState({ showColorPicker: true, indexColorPicker: index }) }} className="btn btn-sm btn-outline-success btn-color-picker" target="_blank" data-toggle="tooltip">
                                                                                        <i class="fa fa-file-pdf-o line-height-29 hide-trick" /> {this.state.showColorPicker && this.state.indexColorPicker === index ? 'Hide Color Picker' : 'Show Color Picker'}
                                                                                    </a>
                                                                                    {
                                                                                        this.state.showColorPicker && this.state.indexColorPicker === index
                                                                                            ?
                                                                                            <BlockPicker
                                                                                                className='w-100 pos-absolute zIndex-999'
                                                                                                color={item.font_color}
                                                                                                colors={['#2c2c2c', '#000000', '#ffffff', '#cccccc', '#ff0808']}
                                                                                                onChangeComplete={(color) => { this.setFontColor(color, index) }}
                                                                                            />
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                    {/* <input type="text" className='form-control' value={item.font_color} onChange={(e)=>{ this.onChangeAttributeData(e, index, 'font_color') }}/> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Collapse>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        null
                                                }
                                            </div>
                                            {
                                                this.state.pathCustomCertEdit
                                                    ?
                                                    <a onClick={() => { this.previewCustomCert() }} className="btn btn-sm btn-outline-success" target="_blank" data-toggle="tooltip">
                                                        <i class="fa fa-file-pdf-o" /> Show Preview
                                                    </a>
                                                    :
                                                    null
                                            }
                                        </>
                                        :
                                        null
                                }
                                {
                                    this.state.flagSinglecertEdit === 1 ?
                                        <div>
                                            <h6 className='modal-title mb-2 text-success'>Single Cert Settings:</h6>
                                            {
                                                this.state.flagSinglecertEdit === 0 || !this.state.flagSinglecertEdit
                                                    ?
                                                    null
                                                    :
                                                    <button onClick={() => { this.browseSingleCertFile.click(); }} className='btn btn-sm btn-outline-success mr-2 mb-1'>Browse...</button>
                                            }
                                            <input className='mb-1' name="certFile" onChange={(e) => { this.handleUploadChange(e, this.state.manIdEdit) }} type="file" ref={ref => (this.browseSingleCertFile = ref)} style={{ display: 'none' }} />
                                            {
                                                this.state.pathSingleCertEdit && this.state.flagSinglecertEdit === 1
                                                    ?
                                                    <a onClick={() => { this.openModalPreviewSingleCert(this.state.manIdEdit) }} className="btn btn-sm btn-outline-success mb-1" target="_blank" data-toggle="tooltip" title={this.state.pathSingleCertEdit}>
                                                        <i class="fa fa-file-pdf-o" /> Show Preview
                                                    </a>
                                                    :
                                                    null
                                            }
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button disabled={this.state.disableUpdateCertConfigBtn} className='btn btn-primary' onClick={() => { this.updateAllManufacturerFlag(this.state.flagProductvariantEdit, this.state.flagSinglecertEdit, this.state.flagCustomCertEdit, this.state.flagSKU) }}>Save</button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.previewCustomCertUrl && this.state.previewCustomCertUrl.length > 0} toggle={() => { this.setState({ previewCustomCertUrl: null }) }}>
                    <ModalHeader toggle={() => { this.setState({ previewCustomCertUrl: null }) }}>
                        Custom Cert Preview
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.previewCustomCertUrl
                                ?
                                <img className='img img-fluid mb-3' src={API.defaults.baseURL.slice(0, -2) + this.state.previewCustomCertUrl + `?random=` + new Date().getTime()} />
                                :
                                this.state.loadingCert
                                    ?
                                    <div className='mb-3'>
                                        <span>Loading...</span>
                                    </div>
                                    :
                                    null
                        }
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.previewSingleCertUrl && this.state.previewSingleCertUrl.length > 0} toggle={() => { this.setState({ previewSingleCertUrl: null }) }}>
                    <ModalHeader toggle={() => { this.setState({ previewSingleCertUrl: null }) }}>
                        Single Cert Preview
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.previewSingleCertUrl
                                ?
                                <img className='img img-fluid mb-3' src={this.state.previewSingleCertUrl + `?random=` + new Date().getTime()} />
                                :
                                this.state.loadingCert
                                    ?
                                    <div className='mb-3'>
                                        <span>Loading...</span>
                                    </div>
                                    :
                                    null
                        }
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.previewBaseCustomCertUrl && this.state.previewBaseCustomCertUrl.length > 0} toggle={() => { this.setState({ previewBaseCustomCertUrl: null }) }}>
                    <ModalHeader toggle={() => { this.setState({ previewBaseCustomCertUrl: null }) }}>
                        Custom Cert Base Preview
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.previewBaseCustomCertUrl
                                ?
                                <img className='img img-fluid mb-3' src={this.state.previewBaseCustomCertUrl + '?random=' + new Date().getTime()} />
                                :
                                this.state.loadingCert
                                    ?
                                    <div className='mb-3'>
                                        <span>Loading...</span>
                                    </div>
                                    :
                                    null
                        }
                    </ModalBody>
                </Modal>
                <div className="animated fadeIn">
                    <div className="row">

                        <div className="col-md-12">
                            <Alert
                                color="success"
                                isOpen={this.state.alertSuccess}
                            >
                                Manufacturer deleted successfully
                            </Alert>
                            <Alert
                                color="success"
                                isOpen={this.state.alertFlagSuccess}
                                className='page-alert'
                            >
                                Manufacturer/certificate config saved successfully
                            </Alert>
                            <div className="card">
                                {
                                    this.state.scope.includes('read') ?
                                        <div className="card-header vertical-middle">
                                            <strong className="card-title vertical-middle-p">Data Manufacturer</strong>
                                            <div className='mb-3'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <form style={{ display: 'initial' }} onSubmit={(e) => { this.searchManufacturer(e) }}>
                                                            <input onChange={(e) => { this.setState({ search: e.target.value }) }} value={this.state.search} className='form-control offset-md-4 offset-7' placeholder='Search by Manufacturer Name' style={{ maxWidth: 250, display: 'inline-flex' }} />
                                                        </form>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <label>Show Brand</label>
                                                    </div>
                                                    <div className='col-md-2'>

                                                        <select name="flag_show" id="flag_show" class="form-control" onChange={(e) => { this.searchManufacturer(e) }}>

                                                            <option value="all"> All </option>
                                                            <option value="1"> Show </option>
                                                            <option value="0"> Hide </option>
                                                        </select>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        {
                                                            this.state.scope.includes('read') ?
                                                                <NavLink href="/manufacturer/add" className="float-right">
                                                                    <button type="button" className="btn btn-info">Add Manufacturer</button>
                                                                </NavLink>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }



                                <div className="card-body">
                                    <DataTable
                                        title="List Manufacturer"
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        pagination={true}
                                        paginationServer={true}
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                        paginationTotalRows={this.state.paginationTotalRows}
                                        onChangePage={page => this.changePage(page)}
                                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                                    // onChangePage={}
                                    // onChangeRowsPerPage={}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.isModalDeleteOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Are you sure?</ModalHeader>
                    <ModalFooter>
                        <Button color="info" onClick={this.deleteManufacturer}>Delete</Button>{' '}
                        <Button color="danger" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default ListManufacturer;