import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
  Button,
  Spinner,
  InputGroup,
  Input
} from 'reactstrap';
import { Link } from 'react-router-dom';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import ConfirmModal from '../../components/notificationModal/ConfirmModal';
import AddPlaceToBuy from './partials/AddPlaceToBuy';
import EditPlaceToBuy from './partials/EditPlaceToBuy';
import DetailPlaceToBuy from './partials/DetailPlaceToBuy';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_LIST_FORUM,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          sortable: true,
          maxWidth: '5%'
        },
        {
          name: 'Name',
          selector: 'name',
          sortable: true
        },
        {
          name: 'Status',
          sortable: true,
          cell: data =>
            <>
              {data.status ? 'Active' : 'Non-Active'}
            </>
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: data =>
            <>
              {
                this.state.scope.includes('read') ?
                  <button className="text-dark btn btn-sm btn-outline-link" onClick={() => this.showModalData('detail', data.id)}><li className="fa fa-eye"></li></button>
                  : null
              }
              {
                this.state.scope.includes('read') ?
                  <button className="text-primary btn btn-sm btn-outline-link" onClick={() => this.showModalData('edit', data.id)}><li className="fa fa-edit"></li></button>
                  : null
              }
              {
                this.state.scope.includes('read') ?
                  <button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(data.id)}><li className="fa fa-trash"></li></button>
                  : null
              }
            </>
        }
      ],
      loaderPage: true,
      data: [],
      id: {},
      editData: {},
      editDataId: '',
      showTable: false,
      showModalConfirm: false,
      showModal: false,
      showModalEdit: false,
      showModalDetail: false,
      disableButtonModal: false,
      search: '',
      fake: false,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
    this.setState({
      loaderPage: false
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    console.log(access)
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    const search = this.state.search;
    console.log(search);
    API.get('forum/place-to-buy?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  showModalData = (type, id) => {
    if (type === 'edit') {
      this.setState({
        showModalEdit: !this.state.showModalEdit,
        editDataId: id
      });
      const data = this.state.data.filter((item) => item.id == id)
      if (data.length) {
        const newData = data[0]
        this.setState({
          editData: {
            name: newData.name,
            status: newData.status,
          }
        });
      }
    }
    else if (type === 'add') {
      this.setState({ showModal: !this.state.showModal });
    }
    else if (type === 'detail') {
      this.setState({
        showModalDetail: !this.state.showModalDetail,
      });

      const data = this.state.data.filter((item) => item.id == id)
      if (data.length) {
        const newData = data[0]
        this.setState({
          editData: {
            name: newData.name,
            status: newData.status
          }
        });
      }
    }
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModalConfirm: !this.state.showModalConfirm,
      id: id,
    });
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    this.setState({
      search: e.target.value
    }, () => {
      this.getList()
    })
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <Link to="/forum">
                      <button
                        color="info"
                        size="sm"
                        className="btn btn-outline-link"
                      >
                        <i className='fa fa-arrow-left'></i>
                      </button>
                    </Link>
                    <strong className="card-title align-middle">Place to Buy</strong>
                    {
                      this.state.scope.includes('read') ?
                        <div className="float-right">
                          <Button
                            color="info"
                            size="sm"
                            onClick={() => this.showModalData('add',)}
                          >
                            Add Place to Buy
                          </Button>
                        </div>
                        : null
                    }
                  </div>
                  <div className="card-body">
                    <InputGroup>
                      <Input
                        placeholder='Search by Name'
                        onChange={this.onChange}
                      />
                    </InputGroup>
                    {
                      // !this.state.showTable ? <>Loading ...</> :
                      <DataTable
                        noHeader={true}
                        columns={this.state.columns}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddPlaceToBuy
            open={this.state.showModal}
            close={() => this.showModalData('add')}
            afterSubmit={() => this.getList()}
          />
          <EditPlaceToBuy
            open={this.state.showModalEdit}
            close={() => this.showModalData('edit', this.state.editDataId)}
            afterSubmit={() => this.getList()}
            body={this.state.editData}
            id={this.state.editDataId}
          />
          <DetailPlaceToBuy
            open={this.state.showModalDetail}
            close={() => this.showModalData('detail', this.state.editDataId)}
            afterSubmit={() => this.getList()}
            body={this.state.editData}
          />
          <ConfirmModal
            open={this.state.showModalConfirm}
            close={() => this.showConfirmDelete()}
            afterSubmit={() => this.getList()}
            id={this.state.id}
            url={`forum/place-to-buy/${this.state.id}`}
          />
        </div>
    )
  }
}

export default index;