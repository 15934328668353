import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Alert,
  Nav,
  NavItem,
} from 'reactstrap';
import API from "../../api";
import { NavLink } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default class HistoryScanDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
      activeTab: 'detail'
    }
  }

  switchTab(tab) {
    this.setState({
      activeTab: tab
    })
  }

  submitHiddenForum() {
    this.setState({
      buttonLoading: true
    })

    API.put(`/forum/banned/${this.props.data.id}`, {}, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          buttonLoading: false
        })
        this.props.close();
        this.props.refresh();
      })
      .catch(err => {
        this.setState({
          buttonLoading: false
        })
        console.log(err)
      })
  }

  submitCloseReport() {
    this.setState({
      buttonLoading: true
    })

    API.put(`/forum/close-report-forum/${this.props.data.id}`, {}, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          buttonLoading: false
        })
        this.props.close();
        this.props.refresh();
      })
      .catch(err => {
        this.setState({
          buttonLoading: false
        })
        console.log(err)
      })
  }

  render() {
    return (
      <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="lg">
        <ModalHeader toggle={() => this.props.close()} style={{borderBottom:'none', marginBottom:'-20px', width:'100%'}} >
          <Nav className="font-xs" tabs>
            <NavItem>
              <NavLink
                className={this.state.activeTab === 'detail' ? 'active font-weight-bold' : 'text-secondary'}
                onClick={() => this.switchTab('detail')}
              >
                Detail
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className={this.state.activeTab === 'report' ? 'active font-weight-bold' : 'text-secondary'}
                onClick={() => this.switchTab('report')}
              >
                Other Scan QR Code
              </NavLink>
            </NavItem> */}
          </Nav>
        </ModalHeader>
        <ModalBody>
          {
            this.props.dataLoading ?
              <div className="">
                <div class="skeleton skeleton-text" style={{ width: '25%' }}></div>
                <div class="skeleton skeleton-body"></div>
                <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
              </div>
              :
              <>
                <Form className={this.state.activeTab === 'detail' ? 'show' : 'd-none'}>
                  <div className="card-forum">
                    <div className="card-forum-header">
                      <div className="font-weight-bold">
                        {this.props.data?.history?.user_created?.fullname}
                      </div>
                      <div className="text-secondary font-xs">
                        {this.props.data?.history?.user_created?.username}
                      </div>
                      <div className="text-secondary font-xs">
                        {moment(this.props.data.created_at).format('DD MMMM YYYY HH:mm:ss')} WIB
                      </div>
                    </div>
                    <div className="card-forum-body">
                      <div className="content-forum mt-4">
                        <h6 className="mt-4 mb-2">Location Information</h6>
                        <div className="row bg-light p-2 m-0 rounded">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="font-sm text-secondary">Latlong</label>
                              <div className="text-dark font-weight-bold font-sm"> {this.props.data.latitude + ',' + this.props.data.longitude} </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="font-sm text-secondary">Country</label>
                              <div className="text-dark font-weight-bold font-sm"> {this.props.data.country ?? '-'} </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="font-sm text-secondary">Province</label>
                              <div className="text-dark font-weight-bold font-sm"> {this.props.data.province ?? '-'} </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="font-sm text-secondary">City Name</label>
                              <div className="text-dark font-weight-bold font-sm"> {this.props.data.city_name} </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="font-sm text-secondary">Platform</label>
                              <div className="text-dark font-weight-bold font-sm"> {this.props.data.platform ?? '-'} </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="font-sm text-secondary">Status</label>
                              <div className="text-dark font-weight-bold font-sm"> {this.props.data.status} </div>
                            </div>
                          </div>
                        </div>
                        <h6 className="mt-4 mb-2">Product Information</h6>
                        <div className="row bg-light p-2 m-0 rounded">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="small text-secondary">Batch Number</label>
                              <div className="text-dark font-weight-bold"> {this.props.data.product?.batch_number} </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="small text-secondary">Product Code</label>
                              <div className="text-dark font-weight-bold"> {this.props.data.product?.product_code} </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="small text-secondary">Certificate</label>
                              <div className="text-dark font-weight-bold"> {this.props.data.product?.certificate ? <button type="button" className="btn btn-success btn-sm" onClick={() => window.open(this.props.data.product?.certificate)}>Show Certificate</button>: 'Tidak Tersedia'} </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="small text-secondary">QR Code</label>
                              <div className="text-dark font-weight-bold"> {this.props.data.product?.qrcode?.qrcode_id36} </div>
                              <div className="text-secondary font-xs"> Token:  {this.props.data.product?.qrcode?.token} </div>
                              <div className="text-secondary font-xs"> PIN:  {this.props.data.product?.qrcode?.pin} </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="small text-secondary">Status Info</label>
                              {
                                this.props.data.product?.under_monitoring ?
                                  <div className=" font-sm">
                                    - Product Detected Suspicious Activity
                                  </div>
                                  : null
                              }
                              {
                                this.props.data.product?.suspected ?
                                  <div className=" font-sm">
                                    - Product Suspected
                                  </div>
                                  : null
                              }
                              {
                                this.props.data.product?.is_redeemed ?
                                  <div className=" font-sm">
                                    - Product is Redeemed
                                  </div>
                                  : null
                              }
                              <div className="text-dark font-weight-bold"> </div>
                            </div>
                          </div>
                        </div>
                        <h6 className="mt-4 mb-2">Manufacturer Information</h6>
                        <div className="row bg-light p-2 m-0 rounded">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="font-sm text-secondary">Name</label>
                              <div className="text-dark font-weight-bold font-sm"> {this.props.data.manufacturer?.name} </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="font-sm text-secondary">Active Fitur</label>
                              {
                                this.props.data.manufacturer?.flag_claimpoint ?
                                  <div className=" font-sm">- Claim Point </div>
                                  : null
                              }
                              {
                                this.props.data.manufacturer?.flag_suspiciousemail ?
                                  <div className=" font-sm">- Notification Email </div>
                                  : null
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    this.props.data.is_fake_product ?
                      <Alert color="danger mt-4 p-2">
                        Counterfeit Product Report
                      </Alert>
                      : null
                  }
                  {
                    this.props.data.is_banned ?
                      <Alert color="danger p-2">
                        This Forum is already hidden for all users
                      </Alert>
                      : null
                  }
                </Form>
                <div className={this.state.activeTab === 'report' ? 'show' : 'd-none'}>
                  {
                    this.props.data.user_reports?.length > 0 ?
                      this.props.data.user_reports.map((item, index) => {
                        return (
                          <div className={`row bg-light p-2 m-0 rounded mb-2`} >
                            <div className="font-weight-bold font-sm">
                              {item.customer.fullname}
                            </div>
                            <div className="text-secondary font-xs">
                              {moment(item.created_at).format('DD MMMM YYYY HH:mm:ss')}
                            </div>
                            <div className="font-sm">
                              {item.description}
                            </div>
                          </div>
                        )
                      }) : null
                  }
                </div>
              </>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => this.props.close()}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
}