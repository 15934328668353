import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import API from '../api';

class LeftPanel extends Component {
    constructor(props){
        super(props);
        this.delay = this.delay.bind(this)
        this.state = {
            usernameLogin: '',
            menus: []
        }
    }

    async componentDidMount() {
        await this.getLoginData();
    }

    async getLoginData() {
        fetch(process.env.REACT_APP_API_URL+'/checkauth', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }
        ).then(async (resp) => {
            if (resp.status === 401) {
                console.log("ini masuk logout")
                localStorage.setItem('isLoggedIn', 'false');
                localStorage.setItem('token', '');
                localStorage.setItem('config', '');
                window.location.href = "/";
            } else {
                var loginData = localStorage.getItem('loginData');
                var parseLoginData = JSON.parse(loginData)
                if (parseLoginData && parseLoginData.data.username) {
                    this.setState({
                        usernameLogin: parseLoginData.data.username
                    })
                }

                await this.getRole(parseLoginData.data.role_id)
            }
        }).catch((err)=>{
            console.log("masuk sini ", err)
        })
    }

    getRole(role_id) {
        API.get(`role/menuBasedRole?id=${role_id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then((resp) => {
                const result = resp.data;
                this.setState({
                    menus: result.data,
                });
                localStorage.setItem('menus', JSON.stringify(result.data));
            })
            .catch(err => {
                alert(err)
            })
    }


    delay(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

    hideMenu(){
        document.getElementById("left-panel").style.display = 'none';
    }

    render(){
        var login = localStorage.getItem('isLoggedIn');
        if (login === 'true') {
            return (
                <aside id="left-panel" className="left-panel h-100">
                    <nav className="navbar navbar-expand-sm navbar-default">
                        <div id="main-menu" className="main-menu collapse navbar-collapse">
                            <ul className="nav navbar-nav">
                            <Accordion className='w-300px w-100-mobile'>
                                {
                                    this.state.menus.map((group, index) => {
                                        return (
                                            <>      
                                            <Accordion.Item eventKey={index}>
                                                <Accordion.Header>
                                                    <li className="menu-title">{group.group_name}</li>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                {
                                                    group.group_menus.map(menu => {
                                                        if(!menu.scope.find(item => item.name == 'hide' && item.status == 1)){
                                                            return (
                                                                <li>
                                                                    {
                                                                        this.props.location.pathname === menu.url
                                                                        ?
                                                                        <>
                                                                            <Link onClick={()=>{ this.hideMenu() }} to={menu.url} className="visible-mobile bold text-primary"><i className={`menu-icon ${menu.icon}`}></i>{menu.name}</Link>
                                                                            <Link to={menu.url} className="visible-desktop bold text-primary"><i className={`menu-icon ${menu.icon}`}></i>{menu.name}</Link>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Link onClick={()=>{ this.hideMenu() }} to={menu.url} className="visible-mobile"><i className={`menu-icon ${menu.icon}`}></i>{menu.name}</Link>
                                                                            <Link to={menu.url} className="visible-desktop"><i className={`menu-icon ${menu.icon}`}></i>{menu.name}</Link>
                                                                        </>

                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                    })
                                                }
                                                </Accordion.Body>
                                            </Accordion.Item>  
                                            </>
                                        )
                                    })
                                }
                            </Accordion>
                            </ul>
                        </div>
                    </nav>
                </aside>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}

const LeftPanelComponent = withRouter(LeftPanel)

export default LeftPanelComponent;