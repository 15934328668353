import React, { Component } from 'react';
import API from 'src/api'
import {
  Spinner,
  Input,
  Col,
  Row,
  Label,
  Button,
  FormGroup,
  FormFeedback,
} from 'reactstrap';
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import NotifToast from 'src/components/notificationModal/NotifToast';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      menuCode: constants.MENU_LEADS_TRACKING_TYPE_PRODUCT,
      scope: [],
      buttonLoading: false,
      disabled: true,
      body: {
        tipe_produk: null,
        deskripsi: null,
        status: 1,
      },
      required: ['tipe_produk', 'deskripsi'],
      error: {},
      notif: {
        show: false,
        status: true,
        message: '',
        title: 'Success'
      },
    };
  }

  async componentDidMount() {
    await this.checkAccess();
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'create');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  onSubmit = () => {
    const body = this.state.body;
    this.setState({
      buttonLoading: true,
    });

    // alert(JSON.stringify(body)); return;
    API.post('product/type', body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          buttonLoading: false,
          notif: {
            show: true,
            title: 'Success',
            message: result.message,
            status: result.status
          }
        });

        setTimeout(() => {
          this.props.history.push('/leads-tracking/type-product/edit/' + result.data.id);
        }, 3000);
      })
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is Required'
    }
    else {
      delete error[name]
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className='d-flex justify-content-center'>
              <div className="col-xl-5 px-0">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title align-middle">Add Type Product</strong>
                  </div>
                  <div className="card-body">
                    <Row className="row-cols-auto g-3 align-items-center mb-4">
                      <Col className='w-100'>
                        <FormGroup>
                          <Label>Type Product</Label>
                          <Input
                            placeholder='Enter Type Product'
                            name='tipe_produk'
                            type="text"
                            value={this.state.body.tipe_produk}
                            onChange={this.onChange}
                            invalid={this.state.error['tipe_produk'] ? true : false}
                          />
                          <FormFeedback >{this.state.error['tipe_produk']}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <Label>Description</Label>
                        <Input
                          name="deskripsi"
                          placeholder="Enter Description"
                          type="textarea"
                          value={this.state.body.deskripsi}
                          onChange={this.onChange}
                          rows="5"
                          invalid={this.state.error['deskripsi'] ? true : false}
                        />
                        <FormFeedback >{this.state.error['deskripsi']}</FormFeedback>
                      </Col>
                      <Col className='w-100'>
                        <FormGroup switch>
                          <Label>Status</Label>
                          <div className='d-flex'>
                            <div class="form-switch">
                              <input name='status' class="form-check-input form-switch" type="checkbox" value={1} checked={this.state.body.status == 1} role="switch" onClick={() => {
                                this.setState({
                                  body: {
                                    ...this.state.body,
                                    status: this.state.body.status == 1 ? 0 : 1
                                  }
                                });
                                this.onValidation();
                              }} />
                            </div>
                            {this.state.body.status ? 'Active' : 'Non Active'}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <Button className='w-100' color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                          {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NotifToast
            show={this.state.notif.show}
            close={() => this.setState({ notif: { show: false } })}
            title={this.state.notif.title}
            status={this.state.notif.status}
            message={this.state.notif.message}
          />
        </div>
      </div>
    )
  }
}

export default index;