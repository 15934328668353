import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const PreviewImage = (props) => {
  const [show, setShow] = useState(props.show);
  const [image, setImage] = useState(props.image);
  const [selectedImage, setSelectedImage] = useState(props.image?.length > 0 ? props.image[0] : '');
  const [emptyImage, setEmptyImage] = useState(false);

  const onClose = () => {
    setShow(false);
    props.onClose();
  }

  useEffect(() => {
    setShow(props.show);
    setImage(props.image);

    const checkImage = async () => {
      const isImageExist = await checkImageExists(props.image?.length ? props.image[0] : '');
      setEmptyImage(!isImageExist);
    }

    checkImage();
    setSelectedImage(props.image?.length > 0 ? props.image[0] : '');

  }, [props.show, props.image]);

  const selectLeft = () => {
    const currentIndex = image.indexOf(selectedImage);
    const newIndex = (currentIndex - 1 + image.length) % image.length;
    setSelectedImage(image[newIndex]);
  };

  const selectRight = () => {
    const currentIndex = image.indexOf(selectedImage);
    const newIndex = (currentIndex + 1) % image.length;
    setSelectedImage(image[newIndex]);
  };

  if (emptyImage) {
    return (
      <Modal isOpen={show} toggle={() => onClose()}>
        <ModalHeader toggle={() => onClose()}>
          Certificates
        </ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          <p>No Image found</p>
        </ModalBody>
      </Modal>
    )
  }

  return (
    <Modal isOpen={show} className='custom-modal' toggle={() => onClose()}>
      <div className="d-flex w-100 justify-content-end pr-3 pb-2">
        <CloseIcon onClick={onClose} />
      </div>
      <div className="d-flex gap-3 align-items-center justify-content-center">
        <ChevronLeftIcon onClick={selectLeft} show={image?.length > 1} />
        <img src={selectedImage} width={'80%'} alt="Preview" />
        <ChevronRightIcon onClick={selectRight} show={image?.length > 1} />
      </div>
      <div className='d-flex justify-content-center gap-2 mt-4'>
        {
          image?.length > 1 && image.map((img, index) => {
            return (
              <div key={index} className={`cursor-pointer dot-image ${selectedImage == img ? 'dot-image-active' : 'bg-white'}`} onClick={() => setSelectedImage(img)}></div>
            )
          })
        }
      </div>
      <div className='d-flex justify-content-center gap-2 mt-4'>
        {
          image?.length > 1 && image.map((img, index) => {
            return (
              <div key={index} className={`${selectedImage == img ? 'border border-primary' : ''}`}>
                <img key={index} src={img} width={50} className='cursor-pointer' alt={`Thumbnail ${index}`} onClick={() => setSelectedImage(img)} />
              </div>
            )
          })
        }
      </div>
    </Modal>
  );
};

const ChevronLeftIcon = (props) => {
  if(!props.show) return null;

  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onClick} className="cursor-pointer">
      <rect x="0.237305" width="30" height="30" rx="15" fill="white" />
      <path d="M18.2373 8L12.2373 15L18.2373 22" stroke="#060606" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
};

const ChevronRightIcon = (props) => {
  if(!props.show) return null;

  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onClick} className="cursor-pointer">
      <rect x="0.762695" width="30" height="30" rx="15" fill="white" />
      <path d="M12.7627 8L18.7627 15L12.7627 22" stroke="#060606" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

const CloseIcon = (props) => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onClick} className="cursor-pointer">
      <rect x="0.237305" width="30" height="30" rx="15" fill="white" />
      <path d="M8.2373 8L22.2373 22" stroke="#060606" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.2373 8L8.2373 22" stroke="#060606" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

const checkImageExists = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
};

export default PreviewImage;