import React, { Component, useState } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
  NavLink,
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Alert,
  CustomInput,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  Badge
} from 'reactstrap';
import Add from '../../components/config-popup/Add';
import Edit from '../../components/config-popup/Edit';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import Detail from '../../components/config-popup/Detail';
import ConfirmModal from '../../components/notificationModal/ConfirmModal';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_CONFIG_POPUP,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          sortable: true
        },
        {
          name: 'Condition Name',
          selector: 'condition_name',
          sortable: true
        },
        {
          name: 'Condition Value',
          selector: 'condition_value',
          sortable: true
        },
        {
          name: 'Status',
          selector: 'status',
          sortable: true,
          cell: user =>
            <label>
              {user.status ?
                <Badge
                  color="success"
                  pill
                >
                  Active
                </Badge>
                :
                <Badge
                  color="danger"
                  pill
                >
                  Non-Active
                </Badge>
              }
            </label>
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: data =>
            <div>
              <button className="text-dark btn btn-sm btn-outline-link" onClick={() => this.showModalUser('detail', data.id)}><li className="fa fa-eye"></li></button>
              <button className="text-primary btn btn-sm btn-outline-link" onClick={() => this.showModalUser('edit', data.id)}><li className="fa fa-edit"></li></button>
              <button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(data.id)}><li className="fa fa-trash"></li></button>

            </div>
        }
      ],
      id: null,
      data: [],
      editData: {},
      editDataId: null,
      roleOption: [],
      showTable: false,
      showConfirm: false,
      showModal: false,
      showModalEdit: false,
      showModalDetail: false,
      disableButtonModal: false,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 5,
      paginationTotalRows: 0,
      paginationPage: 1
    };
    // this.modalUser = this.modalUser.bind(this);
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    console.log(access)
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }
  getList() {
    API.get('popup/condition/config?size=' + this.state.paginationPerPage + '&page=' + this.state.paginationPage, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(this.state.data)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changeEditData(body) {
    this.setState({ editData: body });
  }

  showModalUser = (type, id) => {
    if (type === 'edit') {
      this.setState({
        showModalEdit: !this.state.showModalEdit,
        editDataId: id
      });
      const data = this.state.data.filter((item) => item.id == id)
      console.log("data", data)
      if (data.length) {
        const newData = data[0]
        this.setState({
          editData: {
            condition_name: newData.condition_name,
            condition_value: newData.condition_value,
            url_image: newData.url_image,
            url_link: newData.url_link,
            status: newData.status,
          }
        });
      }
    }
    else if (type === 'add') {
      this.setState({ showModal: !this.state.showModal });
    }
    else if (type === 'detail') {
      this.setState({
        showModalDetail: !this.state.showModalDetail,
      });

      const data = this.state.data.filter((item) => item.id == id)
      if (data.length) {
        const newData = data[0]
        this.setState({
          editData: {
            condition_name: newData.condition_name,
            condition_value: newData.condition_value,
            url_image: newData.url_image,
            url_link: newData.url_link,
            status: newData.status,
          }
        });
      }
    }
  }

  afterSubmit(status, message) {
    this.getList()

    this.setState({
      alert: {
        show: false,
        status: status,
        message: message
      }
    });

    setTimeout(() => {
      this.setState({
        alert: {
          show: false,
          status: '',
          message: ''
        }
      });
    }, 3000)
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  showConfirmDelete = (id) => {
    this.setState({
      showConfirm: !this.state.showConfirm,
      id: id,
    });
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                {
                  this.state.alert.show ?
                    <Alert
                      color="success"
                      isOpen={this.state.alertSuccess}
                    >
                      successfully
                    </Alert>
                    : null
                }

                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Manage Config Popup {this.state.showModal}</strong>
                    {/* <NavLink href="/user/add" > */}
                    {
                      this.state.scope.includes('create') ?
                        <div className="float-right">
                          <Button
                            color="info"
                            size="sm"
                            onClick={() => this.showModalUser('add',)}
                          >
                            Add Config Popup
                          </Button>
                        </div>
                        : null
                    }
                    {/* </NavLink> */}
                  </div>
                  <div className="card-body">
                    {
                      !this.state.showTable ? <>Loading ...</> :
                        <DataTable
                          noHeader={true}
                          columns={this.state.columns}
                          data={this.state.data}
                          pagination={true}
                          paginationServer={true}
                          paginationPerPage={5}
                          paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                          paginationTotalRows={this.state.paginationTotalRows}
                          onChangePage={page => this.changePage(page)}
                          onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                        />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Add
            open={this.state.showModal}
            close={() => this.showModalUser('add')}
            afterSubmit={() => this.afterSubmit()} />
          <Edit
            open={this.state.showModalEdit}
            close={() => this.showModalUser('edit', this.state.editDataId)}
            afterSubmit={() => this.afterSubmit()}
            body={this.state.editData}
            id={this.state.editDataId}
          />
          <Detail
            open={this.state.showModalDetail}
            close={() => this.showModalUser('detail', this.state.editDataId)}
            afterSubmit={() => this.afterSubmit()}
            body={this.state.editData}
            id={this.state.editDataId}
          />
          <ConfirmModal
            open={this.state.showConfirm}
            close={() => this.showConfirmDelete()}
            id={this.state.id}
            afterSubmit={() => this.afterSubmit()}
            url={`popup/condition/config/${this.state.id}`}
          />
        </div>
    )
  }
}

export default index;