/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import API from '../../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  CustomInput,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NotifToast from '../../../components/notificationModal/NotifToast';

export default class UsersAdd extends Component {

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      optionManufactur: [],
      optionRole: [],
      body: {
        manufacturer: '',
        fullname: '',
        email: '',
        ktp_image: '',
        npwp_number: '',
        npwp_image: '',
      },
      required: ['manufacturer_id', 'fullname', 'email', 'ktp_image','useranme', 'password', 're_password', 'role_id'],
    }
  }

  componentDidMount() {
    //Load Manufactur
    API.get('manufacturer?option=true', { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          optionManufactur: resp.data
        })
      })

    //Load Role
    API.get('role?option=true&group=dashboard_partner', { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          optionRole: resp.data.data
        })
      })
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is Required'
    }
    else {
      delete error[name]
    }

    if (name === 'ktp_image' || name === 'npwp_image') {
      newBody[name] = e.target.files[0]
    }

    if (name === 'password' || name === 're_password') {
      if (newBody['re_password']) {
        if (newBody['password'] !== newBody['re_password']) {
          error['re_password'] = 'Password not match'
        }
        else {
          delete error['re_password']
        }
      }
    }

    if(e.target.name === 'username'){
      await API.post(`affiliate/users/check-username`,{username:value}, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        if(!resp.data.status){
          error['username'] = resp.data.message
        }
      })
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    // this.setState({
    //   notif: { show: true, title: 'Processing ...' }
    // });
    const data = this.state.body;

    let body = new FormData();
    body.append("manufacturer_id", data.manufacturer_id);
    body.append("fullname", data.fullname);
    body.append("email", data.email);
    body.append("ktp_image", data.ktp_image);
    body.append("npwp_number", data.npwp_number);
    body.append("npwp_image", data.npwp_image);
    body.append("username", data.username);
    body.append("password", data.password);
    body.append("re_password", data.re_password);
    body.append("role_id", data.role_id);

    API.post('affiliate/users', body, { headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          body: {},
          notif: {
            title: 'Success',
            message: result.message,
          }
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false }
          });
          this.props.history.push(`/affiliate/users/${result.data.partner_id}`);
        }, 1500);
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
          }
        });
      })
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className='d-flex justify-content-center'>
              <div className="col-xl-5 px-0">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Add Users Affiliate</strong>
                    <div className="float-right">
                      <Link to="/menu">
                        <button
                          color="info"
                          size="sm"
                          className="btn btn-outline-link"
                        >
                          <i className='fa fa-arrow-left'></i>
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <Form>
                      <FormGroup>
                        <Label className='w-100'>Manufactur</Label>
                        <Input
                          name="manufacturer_id"
                          type="select"
                          onChange={this.onChange}
                          invalid={this.state.error['manufacturer_id']}
                        >
                          {
                            <>
                              <option value={''}>Choose</option>
                              {
                                this.state.optionManufactur.map(item => {
                                  return <option key={item.value} value={item.value}>{item.label}</option>
                                })
                              }
                            </>
                          }
                        </Input>
                        <FormFeedback >{this.state.error['manufacturer_id']}</FormFeedback>
                      </FormGroup>
                      <div className='d-xl-flex'>
                        <div className='col-xl-6 pl-0'>
                          <FormGroup>
                            <Label>Fullname</Label>
                            <Input
                              name="fullname"
                              placeholder="Enter Fullname"
                              type="text"
                              onChange={this.onChange}
                              required
                              invalid={this.state.error['fullname']}
                            />
                            <FormFeedback >{this.state.error['fullname']}</FormFeedback>
                          </FormGroup>
                        </div>
                        <div className='col-xl-6 pl-0'>
                          <FormGroup>
                            <Label>Email</Label>
                            <Input
                              name="email"
                              placeholder="Enter Email"
                              type="text"
                              onChange={this.onChange}
                              required
                              invalid={this.state.error['email']}
                            />
                            <FormFeedback >{this.state.error['email']}</FormFeedback>
                          </FormGroup>
                        </div>
                      </div>
                      <FormGroup>
                        <Label>KTP</Label>
                        <CustomInput
                          type="file"
                          name="ktp_image"
                          label={this.state.logoFileLabel}
                          onChange={e => this.onChange(e)}
                        />
                        <FormFeedback >{this.state.error['ktp_image']}</FormFeedback>
                      </FormGroup>
                      <div className='d-xl-flex'>
                        <div className='col-xl-6 pl-0'>
                          <FormGroup>
                            <Label>NPWP Number</Label>
                            <Input
                              name="npwp_number"
                              placeholder="Enter NPWP Number"
                              type="text"
                              onChange={this.onChange}
                            />
                          </FormGroup>
                        </div>
                        <div className='col-xl-6 pl-0'>
                          <FormGroup>
                            <Label>NPWP Image</Label>
                            <CustomInput
                              type="file"
                              name="npwp_image"
                              onChange={e => this.onChange(e)}
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <FormGroup>
                        <Label>Username</Label>
                        <Input
                          name="username"
                          placeholder="Enter Username"
                          type="text"
                          onChange={this.onChange}
                          required
                          invalid={this.state.error['username']}
                        />
                        <FormFeedback >{this.state.error['username']}</FormFeedback>
                      </FormGroup>
                      <div className='d-xl-flex'>
                        <div className='col-xl-6 pl-0'>
                          <FormGroup>
                            <Label>Password</Label>
                            <Input
                              name="password"
                              placeholder="Enter Password"
                              type="password"
                              onChange={this.onChange}
                              invalid={this.state.error['password']}
                            />
                            <FormFeedback >{this.state.error['password']}</FormFeedback>
                          </FormGroup>
                        </div>
                        <div className='col-xl-6 pl-0'>
                          <FormGroup>
                            <Label>Re-enter Password</Label>
                            <Input
                              type="password"
                              name="re_password"
                              placeholder="Re-enter Password"
                              onChange={this.onChange}
                              invalid={this.state.error['re_password']}
                            />
                            <FormFeedback >{this.state.error['re_password']}</FormFeedback>
                          </FormGroup>
                        </div>
                      </div>
                      <FormGroup>
                        <Label className='w-100'>Role Akses</Label>
                        <Input
                          name="role_id"
                          type="select"
                          onChange={this.onChange}
                          invalid={this.state.error['manufacturer']}
                        >
                          {
                            <>
                              <option value={''}>Choose</option>
                              {
                                this.state.optionRole.map(item => {
                                  return <option key={item.id} value={item.id}>{item.name}</option>
                                })
                              }
                            </>
                          }
                        </Input>
                        <FormFeedback >{this.state.error['manufacturer']}</FormFeedback>
                      </FormGroup>
                      <Button className='w-100' color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                        {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
        />
      </div>
    )
  }
}