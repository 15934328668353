import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
    Spinner,
    Badge,
} from 'reactstrap';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

class indexDashboard extends Component {

    constructor(props) {

        super(props);

        this.state = {
            filter: '',
            menuCode: constants.MENU_LEADS_TRACKING_DASHBOARD,
            scope: [],
            columns: [
                {
                    name: 'ID',
                    selector: 'id',
                    sortable: true,
                    maxWidth: '5%'
                },
                {
                    name: <strong>Registration Date</strong>,
                    selector: 'created_at',
                    sortable: true,
                    cell: data =>
                        <label>
                            {moment(data.created_at).format('DD MMM YYYY')}
                        </label>

                },
                {
                    name: <strong>Company Name</strong>,
                    cell: data =>
                        <label>
                            {data.company_name}
                        </label>
                },
                {
                    name: <strong>PIC</strong>,
                    selector: 'pic_name',
                    sortable: true,
                },
                {
                    name: <strong>Source</strong>,
                    sortable: true,
                    cell: data =>
                        <label>
                            {data.md_leads_source_type.source_type}
                        </label>
                },
                {
                    name: <strong>Channel</strong>,
                    cell: data =>
                        <label>
                            {data.md_leads_channel.channel}
                        </label>
                },
                {
                    name: <strong>Status</strong>,
                    sortable: true,
                    cell: data =>
                        <label>
                            {data.status === 2 ?
                                <Badge
                                    color="warning"
                                    pill
                                >
                                    Hot
                                </Badge>
                                : null
                            }
                            {data.status === 1 ?
                                <Badge
                                    color="info"
                                    pill
                                >
                                    Cold
                                </Badge>
                                : null
                            }
                            {data.status === 3 ?
                                <Badge
                                    color="danger"
                                    pill
                                >
                                    Super Hot
                                </Badge>
                                : null
                            }
                            {data.status === 4 ?
                                <Badge
                                    color="success"
                                    pill
                                >
                                    Accepted
                                </Badge>
                                : null
                            }
                            {data.status === 5 ?
                                <Badge
                                    color="dark"
                                    pill
                                >
                                    Losses
                                </Badge>
                                : null
                            }
                            {data.status === 0 ?
                                <Badge
                                    color="secondary"
                                    pill
                                >
                                    Deleted
                                </Badge>
                                : null
                            }
                        </label>
                },
            ],
            loaderPage: true,
            loadingExport: false,
            data: [],
            id: null,
            showTable: false,
            search: '',
            totalNewLead: 0,
            totalInbound: {
                cold: 0,
                hot: 0,
                super_hot: 0
            },
            totalOutbound: {
                cold: 0,
                hot: 0,
                super_hot: 0
            },
            startDate: '',
            endDate: '',
            dateEnd: '',
            type: '',
            alert: {
                show: false,
                status: 'success',
                message: ''
            },
            startDate1: new Date(),
            endDate1: new Date(),
            paginationPerPage: 10,
            paginationTotalRows: 0,
            paginationPage: 1
        };
    }

    componentDidMount() {
        this.checkAccess();
        this.getList();
        this.getDashboard();
        this.setState({
            loaderPage: false
        })
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    getDashboard() {
        API.get('dashboard-leads?type=' + this.state.type
            + '&status=' + this.state.filter
            + '&start_date=' + this.state.startDate
            + '&end_date=' + this.state.endDate
            , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                const result = res.data;
                
                const outbound = result.data.totalLeadsBySourceType.find(item => item.source_type === 'Outbound');
                const inbound = result.data.totalLeadsBySourceType.find(item => item.source_type === 'Inbound');
                

                this.setState({
                    totalNewLead: result.data.totalLeads,
                    totalOutbound: {
                        cold: outbound?.total_cold ?? 0,
                        hot: outbound?.total_hot ?? 0,
                        super_hot: outbound?.total_super_hot ?? 0
                    },
                    totalInbound: {
                        cold: inbound?.total_cold ?? 0,
                        hot: inbound?.total_hot ?? 0,
                        super_hot: inbound?.total_super_hot ?? 0
                    }
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    getList() {
        const search = this.state.search;
        API.get('leads?size='
            + this.state.paginationPerPage
            + '&page=' + this.state.paginationPage
            + '&type=' + this.state.type
            + '&status=' + this.state.filter
            + '&start_date=' + this.state.startDate
            + '&end_date=' + this.state.endDate
            , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                const result = res.data;
                this.setState({
                    data: result.data.item,
                    paginationTotalRows: result.data.totalItem,
                    showTable: true
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    changePage(page) {
        this.setState({
            paginationPage: page
        }, () => {
            this.getList();
        })
    }

    changeRowsPerPage(rowsPerPage) {
        this.setState({
            paginationPerPage: rowsPerPage
        }, () => {
            this.getList();
        })
    }

    onChange = (e) => {
        this.setState({
            search: e.target.value
        }, () => {
            this.getList()
        })
    }

    onChangeFilter = (e) => {
        // console.log(e);
        this.setState({
            filter: e.target.value
        }, () => {
            this.getList()
            this.getDashboard()
        })
    }

    onChangeLabel = (e) => {
        // console.log(e);
        this.setState({
            type: e.target.value
        }, () => {
            this.getList()
            this.getDashboard()
        })
    }

    handleEvent = (e, picker) => {
        const startDate = picker.startDate.format('YYYY-MM-DD');
        const endDate = picker.endDate.format('YYYY-MM-DD');
        console.log(picker);
        this.setState({
            startDate: startDate,
            endDate: endDate,
        }, () => {
            this.getList()
            this.getDashboard()
        })
    }

    render() {
        const styles = {
            titleText: {
                fontSize: 32,
                fontWeight: 700,
                textAlign: 'left',
                paddingLeft: 16,
            },
            subTitleText: {
                fontSize: 15,
                fontWeight: 600,
                color: '#6C757D',
                marginBottom: 0,
            }
        };

        const options = [
            { value: 'new', label: 'New' },
            { value: 'total', label: 'Total' }
        ]
        return (

            !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="row mb-4 align-items-end justify-content-end">
                            <div className="col-md-2 align-self-end">
                                <select placeholder="Select filter" class="form-control" onChange={this.onChangeFilter}>
                                    <option value="">Select filter</option>
                                    <option value="1">Cold</option>
                                    <option value="2">Hot</option>
                                    <option value="3">Super Hot</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <select placeholder="Select label" class="form-control" onChange={this.onChangeLabel}>
                                    <option value="">Select label</option>
                                    <option value="new">New</option>
                                    <option value="total">Total</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <DateRangePicker onApply={this.handleEvent}>
                                    <input type="text" className="form-control" />
                                </DateRangePicker>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card p-3">
                                    <div>
                                        <img class="float-left" src={process.env.PUBLIC_URL + '/assets/leads.svg'}></img>
                                        <div class="float-left" style={styles.titleText} >{this.state.totalNewLead ?? 0}</div>
                                    </div>
                                    <p style={styles.subTitleText}>Total New Leads</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div>
                                        <img class="float-left" src={process.env.PUBLIC_URL + '/assets/cold_leads.svg'}></img>
                                        <div class="float-left" style={styles.titleText} >{this.state.totalInbound.cold ?? 0}</div>
                                    </div>
                                    <p style={styles.subTitleText}>Cold Leads From Inbound</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div>
                                        <img class="float-left" src={process.env.PUBLIC_URL + '/assets/hot_leads.svg'}></img>
                                        <div class="float-left" style={styles.titleText} >{this.state.totalInbound.hot ?? 0}</div>
                                    </div>
                                    <p style={styles.subTitleText}>Hot Leads From Inbound</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div>
                                        <img class="float-left" src={process.env.PUBLIC_URL + '/assets/super_hot_leads.svg'}></img>
                                        <div class="float-left" style={styles.titleText} >{this.state.totalInbound.super_hot ?? 0}</div>
                                    </div>
                                    <p style={styles.subTitleText}>Super Hot Leads From Inbound</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div>
                                        <img class="float-left" src={process.env.PUBLIC_URL + '/assets/cold_leads_outbound.svg'}></img>
                                        <div class="float-left" style={styles.titleText} >{this.state.totalOutbound.cold}</div>
                                    </div>
                                    <p style={styles.subTitleText}>Cold Leads From Outbound</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div>
                                        <img class="float-left" src={process.env.PUBLIC_URL + '/assets/super_hot_leads_outbound.svg'}></img>
                                        <div class="float-left" style={styles.titleText} >{this.state.totalOutbound.hot}</div>
                                    </div>
                                    <p style={styles.subTitleText}>Hot Leads From Outbound</p>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div>
                                        <img class="float-left" src={process.env.PUBLIC_URL + '/assets/super_hot_outbound.svg'}></img>
                                        <div class="float-left" style={styles.titleText} >{this.state.totalOutbound.super_hot}</div>
                                    </div>
                                    <p style={styles.subTitleText}>Super Hot Leads From Outbound</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <strong className="card-title">List Leads</strong>
                                        {
                                            <DataTable
                                                noHeader={true}
                                                columns={this.state.columns}
                                                data={this.state.data}
                                                pagination={true}
                                                paginationServer={true}
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                                                paginationTotalRows={this.state.paginationTotalRows}
                                                onChangePage={page => this.changePage(page)}
                                                onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default indexDashboard;