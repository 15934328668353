import React, { Component, useEffect } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  Col,
  Row
} from 'reactstrap';
import API from "src/api";
import NotifToast from "src/components/notificationModal/NotifToast";

const init_body = {
  size: '',
  qty_from: 0,
  qty_to: 0,
  price: 0,
  description: '',
  tipe_produk_id: null
}

export default class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      body: init_body,
      notif: false,
      notifTitle: 'Success',
      notifMessage: '',
      notifStatus: true,
      required: ['size', 'qty_from', 'qty_to', 'price','description'],
      error: {},
      disabled: true,
      buttonLoading: false
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      if (this.props.open) {
        this.setState({
          body: {
            size: this.props.data.size,
            qty_from: this.props.data.qty_from,
            qty_to: this.props.data.qty_to,
            price: this.props.data.price,
            description: this.props.data.description,
            tipe_produk_id: this.props.id
          }
        })
      }
    }
  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value && this.state.required.includes(e.target.name)) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    this.setState({ buttonLoading: true });

    const body = this.state.body;
    body.tipe_produk_id = parseInt(this.props.id);
    body.qty_from = parseInt(body.qty_from);
    body.qty_to = parseInt(body.qty_to);
    body.price = parseInt(body.price);

    // alert(JSON.stringify(body)); return;
    API.put('product/leads/config/'+this.props.data.id, body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          body: init_body,
          buttonLoading: false,
          notif: true,
          notifTitle: 'Success',
          notifMessage: resp.data.message,
          notifStatus: true,
        });

        setTimeout(() => {
          this.setState({
            notif: false,
          });
        }, 3000)

        this.props.afterSubmit()
        this.props.close()
      })
      .catch(error => {
        this.setState({ 
          buttonLoading: false,
          notif: true,
          notifTitle: 'Fail',
          notifMessage: error,
          notifStatus: false,
        });

        setTimeout(() => {
          this.setState({
            notif: false,
          });
        }, 3000)

        this.props.afterSubmit()
        this.props.close()
      })
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()}>
          <ModalHeader toggle={() => this.props.close()}>Edit Config/Price</ModalHeader>
          <ModalBody>
            <Form>
              <Row className="row-cols-auto g-1 align-items-center mb-4">
                <Col md={12} className="w-100">
                  <FormGroup>
                    <Label>Size</Label>
                    <Input
                      name="size"
                      placeholder="Enter Size Hologram"
                      type="text"
                      value={this.state.body.size}
                      onChange={this.onChange}
                      required
                      invalid={this.state.error['size'] ? true : false}
                    />
                    <FormFeedback >{this.state.error['size']}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6} className="w-50">
                  <FormGroup>
                    <Label>Qty From</Label>
                    <Input
                      name="qty_from"
                      placeholder="Enter Qty From"
                      type="text"
                      value={this.state.body.qty_from}
                      onChange={this.onChange}
                      required
                      invalid={this.state.error['qty_from'] ? true : false}
                    />
                    <FormFeedback >{this.state.error['name']}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6} className="w-50">
                  <FormGroup>
                    <Label>Qty To</Label>
                    <Input
                      name="qty_to"
                      placeholder="Enter QTY To"
                      type="text"
                      value={this.state.body.qty_to}
                      onChange={this.onChange}
                      required
                      invalid={this.state.error['qty_to'] ? true : false}
                    />
                    <FormFeedback >{this.state.error['name']}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={12} className="w-100">
                  <FormGroup>
                    <Label>Price</Label>
                    <Input
                      name="price"
                      placeholder="Enter Price"
                      type="text"
                      value={this.state.body.price}
                      onChange={this.onChange}
                      required
                      invalid={this.state.error['price'] ? true : false}
                    />
                    <FormFeedback >{this.state.error['price']}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={12} className="w-100">
                  <FormGroup>
                    <Label>Description</Label>
                    <Input
                      name="description"
                      placeholder="Enter Description"
                      type="textarea"
                      value={this.state.body.description}
                      onChange={this.onChange}
                      required
                      invalid={this.state.error['description'] ? true : false}
                    />
                    <FormFeedback >{this.state.error['description']}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
              {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
            </Button>
            <Button color="danger" onClick={() => this.props.close()}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <NotifToast
          show={this.state.notif}
          close={() => this.setState({ notif: false })}
          title={this.state.notifTitle}
          message={this.state.notifMessage}
          status={this.state.notifStatus}
        />
      </>
    )
  }
}