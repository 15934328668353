import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from 'src/api'
import {
  Spinner,
  Input,
  Col,
  Row,
  Label,
  Button,
  FormGroup,
  FormFeedback,
} from 'reactstrap';
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import ConfirmModal from 'src/components/notificationModal/ConfirmModal';
import NotifToast from 'src/components/notificationModal/NotifToast';
import AddConfig from './config/Add';
import EditConfig from './config/Edit';
import DetailConfig from './config/Detail';

class index extends Component {

  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      id: this.props.match.params.id,
      menuCode: constants.MENU_LEADS_TRACKING_TYPE_PRODUCT,
      scope: [],
      buttonLoading: false,
      disabled: true,
      body: {
        tipe_produk: null,
        deskripsi: null,
        status: 1,
      },
      required: ['tipe_produk', 'deskripsi'],
      error: {},
      data: [],
      editData: {},
      showTable: false,
      showModal: false,
      search: '',
      notif: {
        show: false,
        status: true,
        message: '',
        title: 'Success'
      },
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      showModalAdd: false,
      showModalDetail: false,
      showModalEdit: false,
      showModalConfirm: false,
      selectedData: {},
    };
  }

  async componentDidMount() {
    await this.checkAccess();
    this.onLoad();
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  onLoad = () => {
    API.get(`product/type/${this.state.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }).then(res => {
      console.log(res.data.data)
      this.setState({
        body: {
          ...this.state.body,
          tipe_produk: res.data.data.tipe_produk,
          deskripsi: res.data.data.deskripsi,
        },
      })
    });

    this.getList();
  }

  getList = () => {
    API.get(`/product/leads/config`
      + '?size=' + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      + '&product_id=' + this.state.id
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModal: !this.state.showModal,
      deleteId: id,
    });
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onSearch = (e) => {
    this.setState({
      search: e.target.value
    }, () => {
      if (this.state.search.length > 2 || this.state.search.length === 0) {
        this.getList();
      }
    })
  }

  onSubmit = () => {
    const body = this.state.body;
    this.setState({
      buttonLoading: true,
    });

    // alert(JSON.stringify(body)); return;
    API.put('product/type/' + this.state.id, body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          buttonLoading: false,
          notif: {
            show: true,
            title: 'Success',
            message: result.message,
            status: result.status
          }
        });

        setTimeout(() => {
          this.setState({
            notif: {
              show: false
            }
          })
        }, 3000);
      })
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is Required'
    }
    else {
      delete error[name]
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title align-middle">Detail Tipe Produk</strong>
                  </div>
                  <div className="card-body">
                    <Row className="row-cols-auto g-3 align-items-center mb-4">
                      <Col className='w-100'>
                        <FormGroup>
                          <Label>Type Product</Label>
                          <Input
                            placeholder='Enter Type Product'
                            name='tipe_produk'
                            type="text"
                            value={this.state.body.tipe_produk}
                            onChange={this.onChange}
                            invalid={this.state.error['tipe_produk'] ? true : false}
                          />
                          <FormFeedback >{this.state.error['tipe_produk']}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <Label>Description</Label>
                        <Input
                          name="deskripsi"
                          placeholder="Enter Description"
                          type="textarea"
                          value={this.state.body.deskripsi}
                          onChange={this.onChange}
                          rows="5"
                          invalid={this.state.error['deskripsi'] ? true : false}
                        />
                        <FormFeedback >{this.state.error['deskripsi']}</FormFeedback>
                      </Col>
                      <Col className='w-100'>
                        <FormGroup switch>
                          <Label>Status</Label>
                          <div className='d-flex'>
                            <div class="form-switch">
                              <input name='status' class="form-check-input form-switch" type="checkbox" value={1} checked={this.state.body.status == 1} role="switch" onClick={() => {
                                this.setState({
                                  body: {
                                    ...this.state.body,
                                    status: this.state.body.status == 1 ? 0 : 1
                                  }
                                });
                                this.onValidation();
                              }} />
                            </div>
                            {this.state.body.status ? 'Active' : 'Non Active'}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <Button className='w-100' color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                          {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title align-middle">Config/Price List</strong>
                    <div className="float-right">
                      <button className='btn btn-sm btn-info' onClick={() => this.setState({ showModalAdd: true })}>
                        <i className='fa fa-plus-square mr-1'></i> Add
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    {
                      // !this.state.showTable ? <>Loading ...</> :
                      <DataTable
                        noHeader={true}
                        columns={[
                          {
                            name: 'ID',
                            selector: 'id',
                            sortable: true,
                            maxWidth: '5%'
                          },
                          {
                            name: 'Size',
                            selector: 'size',
                            sortable: true
                          },
                          {
                            name: 'Qty',
                            sortable: true,
                            cell: data => data.qty_from + ' - ' + data.qty_to
                          },
                          {
                            name: 'Price',
                            selector: 'price',
                            sortable: true,
                          },
                          {
                            name: 'Action',
                            selector: 'action',
                            sortable: false,
                            cell: data =>
                              <>
                                <div>
                                  <button className="text-dark btn btn-sm btn-outline-link"
                                    onClick={() =>
                                      this.setState({
                                        showModalDetail: true,
                                        selectedData: data
                                      })
                                    }>
                                    <li className="fa fa-eye"></li>
                                  </button>
                                  {
                                    this.state.scope.includes('update') ?
                                      <button className="text-primary btn btn-sm btn-outline-link"
                                        onClick={() =>
                                          this.setState({
                                            showModalEdit: true,
                                            selectedData: data
                                          })
                                        }>
                                        <li className="fa fa-edit"></li>
                                      </button>
                                      : null
                                  }
                                  {
                                    this.state.scope.includes('delete') ?
                                      <button className="text-danger btn btn-sm btn-outline-link" type='button' onClick={() => this.setState({
                                        showModalConfirm: true,
                                        selectedData: data
                                      })}><li className="fa fa-trash"></li></button>
                                      : null
                                  }
                                </div>
                              </>
                          }
                        ]}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NotifToast
            show={this.state.notif.show}
            close={() => this.setState({ notif: { show: false } })}
            title={this.state.notif.title}
            status={this.state.notif.status}
            message={this.state.notif.message}
          />
          <AddConfig
            open={this.state.showModalAdd}
            close={() => this.setState({ showModalAdd: false })}
            afterSubmit={() => this.getList()}
            id={this.state.id}
          />
          <EditConfig
            open={this.state.showModalEdit}
            close={() => this.setState({ showModalEdit: false })}
            afterSubmit={() => this.getList()}
            id={this.state.id}
            data={this.state.selectedData}
          />
          <DetailConfig
            open={this.state.showModalDetail}
            close={() => this.setState({ showModalDetail: false })}
            data={this.state.selectedData}
          />
          <ConfirmModal 
            text={`Are you sure want to delete this config/price ?`}
            open={this.state.showModalConfirm}
            close={() => this.setState({ showModalConfirm: false })}
            onSuccess={() => this.getList()}
            url={`product/leads/config/${this.state.selectedData.id}`}
          />
        </div>
    )
  }
}

export default index;