/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import API from '../../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner
} from 'reactstrap';
import { Link } from 'react-router-dom';
import AddProductCode from '../code/addProductCode';
import AddProductVariant from '../variant/addProductVariant';
import ColorPicker from '../../../components/elements/form/ColorPicker';
import NotifToast from '../../../components/notificationModal/NotifToast';
import BannerVoucherNotif from './_components/bannerVoucherNotif';


class edit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      showModal: this.props.open,
      body: {},
      code: '',
      error: {},
      disabled: true,
      loading: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        title: 'Success',
        message: 'Batch Number Config updated successfully'
      },
      optionProductCode: [],
      modalProductCode: false,
      optionProductVariant: [],
      modalProductVariant: false,
      variantInputIndexes: [{ value: '', id: 0 }],
      changeVariantIndex: 0,
      multipleVariant: false,
      manufacturer: {},
      showData: false,

    }
  }

  componentDidMount() {
    this.detail();
  }

  async detail() {
    this.setState({ loading: true})
    await API.get(`product/batchnumber/config-detail/${this.state.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data.data;
        const manufacturer_id = result.manufacturer_id;
        this.getManufacturer(manufacturer_id);

        this.setState({
          body: {
            product_code: result.product_code,
            product_code_id: result.product_code_id,
            batch_number: result.batch_number,
            product_variant_ids: JSON.parse(result.product_variant_ids),
            min_redeem: result.min_redeem,
            color_primary_code: result.color_primary_code,
            color_secondary_code: result.color_secondary_code,
            status: result.status,
          },
          manufacturer: {
            manufacturer_id: result.manufacturer_id,
            manufacturer_name: result.manufacturer?.name,
          },
          loading: false
        }, async () => {
          await this.getOptionProductCode(manufacturer_id)
          this.setState({
            showData: true,
            loading: false
          })
        })
      })
  }

  getManufacturer(id) {
    API.get(`manufacturer/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data.data;
        if (result?.length > 0) {
          this.setState({
            manufacturer: result[0],
            multipleVariant: result[0].flag_productvariant === 1 ? true : false
          });
        }
      })
      .catch(err => {
        alert(err)
      })
  }

  async getOptionProductCode(id) {
    await API.get(`product/getProductCodes/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data.result;

        this.setState({
          optionProductCode: result,
        }, async () => {
          const variantInputIndexes = [];
          await Promise.all(this.state.body.product_variant_ids.map(async (item, index) => {
            await this.getOptionProductVariant(index === 0 ? this.state.body.product_code_id : this.state.body.product_variant_ids[index - 1], index);
            console.log('kesini')
            let value = this.state.optionProductVariant?.length > 0 ? this.state.optionProductVariant[index].find(x => x.id === parseInt(item)) : '';
            variantInputIndexes[index] = { value: value?.variant, id: item }
          }));
          this.setState({
            variantInputIndexes: variantInputIndexes
          })
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  async getOptionProductVariant(id, index = 0) {
    let url = `product/getVariant/${this.state.manufacturer.manufacturer_id}/${this.state.body.product_code_id}/${id}`;
    if (index === 0) url = `product/getVariant/${this.state.manufacturer.manufacturer_id}/${this.state.body.product_code_id}/0`;

    await API.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data.result;
        let optionNew = this.state.optionProductVariant;
        optionNew[index] = result
        console.log(optionNew)
        this.setState({
          optionProductVariant: optionNew
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  onChange = async (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    if (e.target.name === 'product_code') {
      newBody['product_code_id'] = this.state.optionProductCode[e.target.selectedIndex - 1].id
      newBody['product_code'] = this.state.optionProductCode[e.target.selectedIndex - 1].code

      this.setState({
        body: {
          ...this.state.body,
          product_code_id: newBody['product_code_id'],
          product_code: newBody['product_code']
        }
      }, () => this.getOptionProductVariant(e.target.value, 0));
    }

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '');
    const result = filtered?.length ? true : false

    this.setState({
      disabled: result,
    });
    console.log(this.state.body, result);
    return result
  }

  onSubmit() {
    this.setState({
      notif: { show: true, title: 'Processing ...' }
    });
    const body = this.state.body;
    delete body.manufacturer_id

    API.put(`product/batchnumber/config/${this.state.id}`, body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          notif: {
            title: 'Success',
            message: result.message,
          }
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false }
          });
        }, 1500);
      })
      .catch(err => {
        this.setState({
          notif: {
            title: 'Failed',
            message: err,
          }
        });
      })
  }

  handleChangeSKU(event, index) {
    const indexOpt = event.target.selectedIndex;
    const optionElement = event.target.childNodes[indexOpt]
    const variantId = optionElement.getAttribute('data-id');

    const value = event.target.value;
    let newVariant = this.state.variantInputIndexes;
    newVariant[index].value = value
    newVariant[index].id = variantId

    const batchnumber = newVariant.map((item) => {
      return item.value
    })

    const productVariantIds = newVariant.map((item) => {
      return item.id
    })

    this.setState({
      variantInputIndexes: newVariant,
      body: {
        ...this.state.body,
        batch_number: batchnumber.join('-'),
        product_variant_ids: productVariantIds
      }
    }, () => {
      this.onValidation()

    });

  }

  async PushArrayVariant() {
    let arrLength = this.state.variantInputIndexes?.length;
    let arr = this.state.variantInputIndexes;
    if (this.state.variantInputIndexes[arrLength - 1].value === '') {
      alert('Please Select SKU Before ' + arrLength);
    } else {
      await this.getOptionProductVariant(this.state.variantInputIndexes[arrLength - 1].id, arrLength);
      arr.push({ value: '', id: null })
      this.setState({
        variantInputIndexes: arr
      })
    }
  }

  RemoveArrayVariant(index) {
    this.state.variantInputIndexes.splice(index, 99);
    this.setState({
      variantInputIndexes: this.state.variantInputIndexes
    })

    const batchnumber = this.state.variantInputIndexes.map((item) => {
      return item.value
    })

    const productVariantIds = this.state.variantInputIndexes.map((item) => {
      return item.id
    })

    this.setState({
      body: {
        ...this.state.body,
        batch_number: batchnumber.join('-'),
        product_variant_id: productVariantIds.join('-')
      }
    });
  }

  changeVariant(index) {
    this.setState({
      modalProductVariant: !this.state.modalProductVariant,
      changeVariantIndex: index
    })
  }

  changeSelectPicker(c, type) {
    if (type === 'primary') {
      this.setState({ body: { ...this.state.body, color_primary_code: c } })
    } else {
      this.setState({ body: { ...this.state.body, color_secondary_code: c } })
    }
    this.onValidation()
  }


  render() {
    return (
      // !this.state.showData ? <>Loading...</> :
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-5">
              <div className="card">
                <div className="card-header">
                  <Link to={`/product/batchnumber/config?id=${this.state.manufacturer.manufacturer_id}`}>
                    <button
                      color="info"
                      size="sm"
                      className="btn btn-outline-link"
                    >
                      <i className='fa fa-arrow-left'></i>
                    </button>
                  </Link>
                  <strong className="card-title align-middle">Edit SKU Config</strong>
                  <div className="float-right">
                    <Link to={`/product/batchnumber/config/add/${this.state.manufacturer.manufacturer_id}`}>
                      <button className='btn btn-sm btn-info'>
                        <i className='fa fa-plus-square mr-1'></i> Add More
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label className='w-100'>
                        Manufacturer
                      </Label>
                      <Input
                        id="manufacturer_id"
                        name="manufacturer_id"
                        type="select"
                        onChange={this.onChange}
                        disabled={true}
                      >
                        {
                          this.state.optionProductCode?.length ? (
                            <>
                              <option value={this.state.manufacturer.manufacturer_id}>{this.state.manufacturer.name}</option>
                              {
                                this.state.optionProductCode.map(item => {
                                  return <option key={item.id} value={item.id}>{item.code}</option>
                                })
                              }
                            </>) : null
                        }
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label className='w-100'>
                        Product Code
                        <button type='button' onClick={() => this.setState({ modalProductCode: !this.state.modalProductCode })} className='pull-right text-primary btn btn-outline-none' style={{ fontSize: '14px' }} >+ Item</button>
                      </Label>
                      <Input
                        id="product_code"
                        name="product_code"
                        type="select"
                        onChange={this.onChange}
                        value={this.state.body.product_code_id}
                        invalid={this.state.error['product_code'] ? true : false}
                      >
                        {
                          this.state.optionProductCode?.length ? (
                            <>
                              <option value={''}>Choose</option>
                              {
                                this.state.optionProductCode.map(item => {
                                  return <option key={item.id} value={item.id}>{item.code}</option>
                                })
                              }
                            </>) : null
                        }
                      </Input>
                      <FormFeedback >{this.state.error['product_code']}</FormFeedback>
                    </FormGroup>
                    {
                      this.state.variantInputIndexes?.length > 0
                        ?
                        this.state.variantInputIndexes.map((item, index) => {
                          return (
                            <div key={index} className="form-group has-success">
                              <div className='row'>
                                <div className='col-md-6'>
                                  <label className="control-label mb-1">Variant/SKU {this.state.multipleVariant ? ` ${index + 1}` : ' '}</label>
                                </div>
                                <div className='col-md-6 text-right'>
                                  {
                                    this.state.body.product_code && (
                                      <button type='button' onClick={() => this.changeVariant(index)} className='pull-right text-primary btn btn-outline-none' style={{ fontSize: '14px' }} >+ Change Variant/SKU {index + 1}</button>
                                    )
                                  }
                                </div>
                              </div>
                              <div className='row'>
                                <div className={this.state.multipleVariant && this.state.body.product_code ? 'col-md-10' : 'col-md-12'}>
                                  <select name="variant" class="form-control" onChange={(event) => this.handleChangeSKU(event, index)}>
                                    {
                                      this.state.optionProductVariant?.length > 0 ?
                                        <>
                                          <option value={''}>Choose</option>
                                          {
                                            this.state.optionProductVariant[index]?.map(item => {
                                              return <option key={item.id} data-id={item.id} value={item.variant} selected={item.id === parseInt(this.state.body.product_variant_ids[index]) ? true : false}>{item.variant}</option>
                                            })
                                          }
                                        </>
                                        : (
                                          this.state.body.product_code ? <option label="Empty list" value={''} disabled selected /> : <option value={''} label="Please select the product first" disabled selected />
                                        )
                                    }
                                  </select>
                                </div>
                                {
                                  this.state.multipleVariant && this.state.body.product_code && (
                                    index === 0
                                      ?
                                      <div className='col-md-1-5 p-0' >
                                        <button type='button' style={{ fontSize: '17px' }} onClick={() => { this.PushArrayVariant(item) }} className='btn btn-sm btn-primary text-xs'>
                                          <i className='ti-plus'></i> Item
                                        </button>
                                      </div>
                                      :
                                      <div className='col-md-1-5 text-left p-0'>
                                        <a onClick={() => { this.RemoveArrayVariant(index) }} className='btn text-danger bg-none'>
                                          <i className='ti-trash'></i>
                                        </a>
                                      </div>
                                  )
                                }
                              </div>
                            </div>
                          )
                        })
                        :
                        null
                    }
                    <FormGroup>
                      <Label>
                        Batch Number
                      </Label>
                      <Input
                        id="batch_number"
                        name="batch_number"
                        placeholder="Auto Filled"
                        type="text"
                        value={this.state.body.batch_number}
                        readOnly={true}
                        invalid={this.state.error['batch_number'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['batch_number']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Min. Redeem
                      </Label>
                      <Input
                        id="min_redeem"
                        name="min_redeem"
                        placeholder="Minimal Redeem"
                        type="text"
                        value={this.state.body.min_redeem}
                        onChange={this.onChange}
                        invalid={this.state.error['min_redeem'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['min_redeem']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Color Theme
                      </Label>
                      <div className='d-flex'>
                        <ColorPicker title="Primary" color={this.state.body.color_primary_code} setColor={(c) => this.changeSelectPicker(c, 'primary')} />
                        <ColorPicker title="Secondary" color={this.state.body.color_secondary_code} setColor={(c) => this.changeSelectPicker(c, 'secondary')} />
                      </div>
                    </FormGroup>
                    <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                      {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              {
                !this.state.loading && (
                  <BannerVoucherNotif
                    id={this.state.id}
                    manufacturer_id={this.state.manufacturer.manufacturer_id}
                  />
                )
              }                  
            </div>
          </div>
        </div>
        <AddProductCode
          open={this.state.modalProductCode}
          close={() => this.setState({ modalProductCode: !this.state.modalProductCode })}
          afterSubmit={() => this.getOptionProductCode()}
          data={this.state.optionProductCode}
          manufacturer_id={this.state.id}
        />
        <AddProductVariant
          open={this.state.modalProductVariant}
          close={() => this.setState({ modalProductVariant: !this.state.modalProductVariant })}
          afterSubmit={() =>
            this.getOptionProductVariant(
              this.state.changeVariantIndex === 0 ? 0 : this.state.variantInputIndexes[this.state.changeVariantIndex - 1]?.id,
              this.state.changeVariantIndex
            )
          }
          data={this.state.optionProductVariant?.length > 0 ? this.state.optionProductVariant[this.state.changeVariantIndex] : []}
          manufacturer_id={this.state.manufacturer.manufacturer_id}
          product_code_id={this.state.body.product_code_id}
          variant_index={this.state.changeVariantIndex}
          variant_input_indexes={this.state.variantInputIndexes}
        />
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { ...this.state.notif, show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
        />
      </div>
    )
  }
}

export default edit;