import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Alert,
  Nav,
  NavItem,
  Spinner,
} from 'reactstrap';
import API from "../../api";
import { NavLink } from "react-bootstrap";
import moment from "moment";
import { formatDate } from "../../lib/helpers";

export default class CustomerDeletedDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
      activeTab: 'detail'
    }
  }

  switchTab(tab) {
    this.setState({
      activeTab: tab
    })
  }

  submitDeleteAccount() {
    this.setState({
      buttonLoading: true
    })

    // API.put(`/forum/banned/${this.props.data.id}`, {}, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
    //   .then(res => {
    this.setState({
      buttonLoading: false
    })
    //     this.props.close();
    //     this.props.refresh();
    //   })
    //   .catch(err => {
    //     this.setState({
    //       buttonLoading: false
    //     })
    //     console.log(err)
    //   })
  }

  render() {
    return (
      <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="md">
        <ModalHeader toggle={() => this.props.close()} style={{ borderBottom: 'none', marginBottom: '-20px', width: '100%' }} >
          <Nav className="font-xs" tabs>
            <NavItem>
              <NavLink
                className={this.state.activeTab === 'detail' ? 'active font-weight-bold' : 'text-secondary'}
                onClick={() => this.switchTab('detail')}
              >
                Detail
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className={this.state.activeTab === 'history' ? 'active font-weight-bold' : 'text-secondary'}
                onClick={() => this.switchTab('history')}
              >
                History
              </NavLink>
            </NavItem> */}
          </Nav>
        </ModalHeader>
        <ModalBody>
          {
            this.props.dataLoading ?
              <div className="">
                <div class="skeleton skeleton-text" style={{ width: '25%' }}></div>
                <div class="skeleton skeleton-body"></div>
                <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
              </div>
              :
              <>
                <div className={this.state.activeTab === 'detail' ? 'card-forum' : 'd-none'}>
                  <div className="card-forum-body">
                    <div className="content-forum mt-4">
                      <h6 className="mt-4 mb-2">Biodata Information</h6>
                      <div className="row bg-light p-2 m-0 rounded">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-sm text-secondary">Fullname</label>
                            <div className="text-dark font-weight-bold font-sm"> {this.props.data.fullname} </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-sm text-secondary">Gender</label>
                            <div className="text-dark font-weight-bold font-sm"> {this.props.data.gender} </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-sm text-secondary">Date of Birth</label>
                            <div className="text-dark font-weight-bold font-sm"> {formatDate(this.props.data.dob)} </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-sm text-secondary">Phone Number</label>
                            <div className="text-dark font-weight-bold font-sm"> {this.props.data.phone} </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-sm text-secondary">Username/Email</label>
                            <div className="text-dark font-weight-bold font-sm"> {this.props.data.username} </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-sm text-secondary">User Type</label>
                            <div className="text-dark font-weight-bold font-sm"> {this.props.data.user_type} </div>
                          </div>
                        </div>
                      </div>
                      <h6 className="mt-4 mb-2">Account Information</h6>
                      <div className="row bg-light p-2 m-0 rounded">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-sm text-secondary">Registered Date</label>
                            <div className="text-dark font-weight-bold font-sm"> {formatDate(this.props.data.created_at)} WIB</div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-sm text-secondary">Last Login</label>
                            <div className="text-dark font-weight-bold font-sm"> {formatDate(this.props.data.last_login)} WIB</div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-sm text-secondary">Deleted Date</label>
                            <div className="text-dark font-weight-bold font-sm"> {formatDate(this.props.data.deactived_at)} WIB</div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-sm text-secondary">Deleted Duration</label>
                            <div className="text-dark font-weight-bold font-sm"> {this.props.data.duration} days</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={this.state.activeTab === 'report' ? 'show' : 'd-none'}>
                  {
                    this.props.data.user_reports?.length > 0 ?
                      this.props.data.user_reports.map((item, index) => {
                        return (
                          <div className={`row bg-light p-2 m-0 rounded mb-2`} >
                            <div className="font-weight-bold font-sm">
                              {item.customer.fullname}
                            </div>
                            <div className="text-secondary font-xs">
                              {moment(item.created_at).format('DD MMMM YYYY HH:mm:ss')}
                            </div>
                            <div className="font-sm">
                              {item.description}
                            </div>
                          </div>
                        )
                      }) : null
                  }
                </div>
              </>
          }
        </ModalBody>
        <ModalFooter>
          {
            this.props.data.can_delete ?
              <Button color="danger" onClick={() => this.submitDeleteAccount()} disabled={this.state.buttonLoading ? true : false}>
                {this.state.buttonLoading ? <Spinner size="sm" className="mr-2" /> : ''}
                Delete Account
              </Button> : null
          }

        </ModalFooter>
      </Modal>
    )
  }
}