import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../../api'
import {
  Button,
  Spinner,
  Badge,
  InputGroup,
  Input
} from 'reactstrap';
import constants from '../../../lib/constants';
import { _hasAccess } from '../../../middleware/accessMiddleware'
import ConfirmModal from '../../../components/notificationModal/ConfirmModal';
import { addCommas } from '../../../lib/helpers';

export default class ConfigIndex extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_COMMISSION_CONFIG,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          maxWidth: '5%'
        },
        {
          name: 'Name',
          cell: data => data.md_tipe_produk?.tipe_produk
        },
        {
          name: 'Description',
          cell: data => data.md_tipe_produk?.deskripsi
        },
        {
          name: 'Commission',
          className: 'text-center',
          cell: data => addCommas(data.commission)
        },
        {
          name: 'Status',
          cell: data =>
            <Badge className='badge-c' color={data.status === 1 ? 'success' : 'danger'}>{data.status === 1 ? 'Active' : 'Inactive'}</Badge>
        },
        {
          name: 'Action',
          selector: 'action',
          cell: data =>
            <>
              <div>
                <button type='button' className="text-dark btn btn-sm btn-outline-link" onClick={() => { this.props.history.push(`/affiliate/config/${data.id}`) }} ><li className="fa fa-eye"></li></button>

                {
                  this.state.scope.includes('update') ?
                    <button
                      type='button'
                      className="text-primary btn btn-sm btn-outline-link"
                      onClick={() => { this.props.history.push(`/affiliate/config/edit/${data.id}`) }} >
                      <li className="fa fa-edit"></li>
                    </button>
                    : null
                }
                {
                  this.state.scope.includes('delete') ?
                    <button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(data.id)}><li className="fa fa-trash"></li></button>
                    : null
                }
              </div>
            </>
        }
      ],
      loaderPage: true,
      data: [],
      editData: {},
      id: null,
      showTable: false,
      search: '',
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
    this.setState({
      loaderPage: false
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status === 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    const search = this.state.search;
    console.log(search);
    API.get('affiliate/config?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true,
          totalWaiting: result.data.totalWaiting
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
      id: id,
    });
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    if (e.target.value.length > 3 || e.target.value === '') {
      this.setState({
        search: e.target.value
      }, () => {
        this.getList()
      })
    }
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Commission Config Affiliate</strong>
                    {
                      this.state.scope.includes('create') ?
                        <div className="float-right">
                          <Button
                            color="info"
                            size="sm"
                            onClick={() => this.props.history.push(`/affiliate/config/add`)}
                          >
                            Add Commission Config
                          </Button>
                        </div>
                        : null
                    }
                  </div>
                  <div className="card-body">
                    <div className='d-flex'>
                      <InputGroup className='w-auto'>
                        <Input
                          placeholder='Search by Name'
                          onChange={this.onChange}
                        />
                      </InputGroup>
                    </div>
                    {
                      <DataTable
                        noHeader={true}
                        columns={this.state.columns}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            text={`Are you sure want to delete this config ?`}
            open={this.state.showModalDelete}
            close={() => this.showConfirmDelete()}
            afterSubmit={() => this.getList()}
            id={this.state.id}
            url={`affiliate/config/${this.state.id}`}
          />
        </div>
    )
  }
}