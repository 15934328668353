import React, { Component } from 'react';
import API from '../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  FormText
} from 'reactstrap';
import { Link } from 'react-router-dom';

const init_body = {
  description: '',
  method: '',
  version: 2,
  path_1: '',
  path_2: '',
  path_3: '',
  role_permission_id: '',
  save_log: 1,
  status: 1,
}

class detail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      body: init_body,
      bodyPath: '',
      accessMenus: [],
      accessAction: [],
    }
  }

  componentDidMount() {
    this.getAccessMenu();
    this.getData();
  }

  getData() {
    API.get(`role/api?id=${this.state.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        const bodyPath = [result.data.path_1];
        result.data.path_2 && bodyPath.push(result.data.path_2)
        result.data.path_3 && bodyPath.push(result.data.path_3)

        this.getAccessAction(result.data.menu_id)

        this.setState({
          body: result.data,
          bodyPath: bodyPath.join('/')
        });
        console.log(result.data)
      })
      .catch(err => {
        alert(err)
      })
  }

  getAccessMenu() {
    API.get(`role/menu/list?option=true`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          accessMenus: result.data,
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  getAccessAction(value) {
    API.get(`role/permission?menu_id=${value}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        console.log(result.data);
        this.setState({
          accessAction: result.data,
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  onChange = (e) => {
    
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Detail API Endpoint</strong>
                  {/* <NavLink href="/user/add" > */}
                  <div className="float-right">
                    <Link to="/api">
                      <button
                        color="info"
                        size="sm"
                        className="btn btn-outline-link"
                      >
                        <i className='fa fa-arrow-left'></i>
                      </button>
                    </Link>

                  </div>
                  {/* </NavLink> */}
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label>Description</Label>
                      <Input
                        id="description"
                        name="description"
                        placeholder="Enter Description"
                        type="text"
                        onChange={this.onChange}
                        value={this.state.body.description}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label >Method</Label>
                      <Input
                        id="method"
                        name="method"
                        type="select"
                        onChange={this.onChange}
                        value={this.state.body.method}
                        disabled
                      >
                        <option value={''}>Choose</option>
                        <option value={'GET'}>GET</option>
                        <option value={'POST'}>POST</option>
                        <option value={'PUT'}>PUT</option>
                        <option value={'DELETE'}>DELETE</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label>API version</Label>
                      <Input
                        id="version"
                        name="version"
                        placeholder="Enter API Version"
                        type="text"
                        onChange={this.onChange}
                        value={2}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Path URL</Label>
                      <Input
                        id="path"
                        name="path"
                        placeholder="Enter Path URL"
                        type="text"
                        onChange={this.onChange}
                        value={this.state.bodyPath}
                        disabled
                      />
                      <FormText>
                        Example without path param : urlname/urlname2,
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <Label >Access Menu</Label>
                      <Input
                        id="menu"
                        name="menu"
                        type="select"
                        onChange={this.onChange}
                        value={this.state.body.menu_id}
                        disabled
                      >
                        <option value={''}>Choose</option>
                        {
                          this.state.accessMenus.map(item => {
                            return (
                              <optgroup key={item.label} label={item.label}>
                                {
                                  item.menu.map(men => {
                                    return <option key={men.id} value={men.id}>{men.name}</option>
                                  })
                                }
                              </optgroup>
                            )
                          })
                        }
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label >Access Action</Label>
                      <Input
                        id="role_permission_id"
                        name="role_permission_id"
                        type="select"
                        onChange={this.onChange}
                        value={this.state.body.role_permission_id}
                        disabled
                      >
                        <option value={''}>{this.state.accessAction.length ? 'Choose' : 'Please select Access Menu first'}</option>
                        {
                          this.state.accessAction.map(item => {
                            return <option key={item.id} value={item.id}>{item.name}</option>
                          })
                        }
                      </Input>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default detail;