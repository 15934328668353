import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
  NavLink,
  Button,
  Spinner,
  Badge,
  Nav,
  NavItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import ConfirmModal from '../../components/notificationModal/ConfirmModal';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_ROLE_AKSES,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          sortable: true
        },
        {
          name: 'Name',
          selector: 'name',
          sortable: true
        },
        {
          name: 'Status',
          selector: 'status',
          sortable: true,
          cell: role =>
            <label>
              {role.status ?
                <Badge
                  color="success"
                  pill
                >
                  Active
                </Badge>
                :
                <Badge
                  color="danger"
                  pill
                >
                  Non-Active
                </Badge>
              }
            </label>
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: role =>
            <div>
              <Link to={`/role/${role.id}`}>
                <button className="text-dark btn btn-sm btn-outline-link" data-toggle="tooltip" title="Detail"><li className="fa fa-eye"></li></button>
              </Link>
              {
                this.state.scope.includes('update') ?
                  <Link to={`/role/edit/${role.id}`}>
                    <button className="text-primary btn btn-sm btn-outline-link" data-toggle="tooltip" title="Edit"><li className="fa fa-edit"></li></button>
                  </Link>
                  : null
              }
              {
                this.state.scope.includes('delete') ?
                  <button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(role.id)} data-toggle="tooltip" title="Delete"><li className="fa fa-trash"></li></button>
                  : null
              }
              {
                this.state.scope.includes('create') ?
                  <Link to={`/role/add?role_id_reff=${role.id}&group=${this.state.group}`}>
                    <button className="text-success btn btn-sm btn-outline-link" data-toggle="tooltip" title="Duplicate"><li className="fa fa-refresh"></li></button>
                  </Link>
                  : null
              }
            </div>
        }
      ],
      loaderPage: true,
      id: null,
      data: [],
      editData: {},
      editDataId: null,
      showTable: false,
      showConfirm: false,
      showModal: false,
      showModalEdit: false,
      disableButtonModal: false,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 5,
      paginationTotalRows: 0,
      paginationPage: 1,
      query: new URLSearchParams(this.props.location.search), 
      type: 'backoffice',
      type_alias:{
        'backoffice': 'dashboard_app',
        'partner': 'dashboard_partner',
      },
      group: 'dashboard_app'
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();

  }

  changeType(type) {
    this.setState({
      type: type,
      group: this.state.type_alias[type]
    },() => {
      this.getList();
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    console.log(access)
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    API.get(`role?size=` 
      + this.state.paginationPerPage 
      + `&page=` + this.state.paginationPage
      + `&group=` + this.state.group 
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changeEditData(body) {
    this.setState({ editData: body });
  }

  showModalUser = (type, id) => {
    if (type === 'edit') {
      this.setState({
        showModalEdit: !this.state.showModalEdit,
        editDataId: id
      });
      const data = this.state.data.filter((item) => item.users_id == id)
      if (data.length) {
        const newData = data[0]
        this.setState({
          editData: {
            username: newData.username,
            email: newData.email,
            role_id: newData.role_id,
          }
        });
      }
    }
    else if (type === 'add') {
      this.setState({ showModal: !this.state.showModal });
    }
  }

  afterSubmit(status, message) {
    this.getList()

    this.setState({
      alert: {
        show: true,
        status: status,
        message: message
      }
    });

    setTimeout(() => {
      this.setState({
        alert: {
          show: false,
          status: '',
          message: ''
        }
      });
    }, 3000)
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  showConfirmDelete = (id) => {
    this.setState({
      showConfirm: !this.state.showConfirm,
      id: id,
    });
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-header card-tabs">
                    <strong className="card-title">Manage Roles</strong>
                    <div className="float-right">
                      {
                        this.state.scope.includes('create') ?
                          <Link to="/role/add">
                            <Button
                              color="primary"
                              size="sm"
                            >
                              <i className='fa fa-plus-square mr-1'></i> { this.state.query.role_id_reff ? 'Duplicate' : 'Add'} Role
                            </Button>
                          </Link>
                          : null
                      }
                    </div>
                    <Nav tabs className='mt-3'>
                      <NavItem>
                        <NavLink 
                          active={ this.state.type === 'backoffice' ? true : false} 
                          className='fs-13 cursor-pointer' 
                          onClick={() => this.changeType('backoffice')}
                        >
                          Backoffice
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink 
                          active={ this.state.type === 'partner' ? true : false} 
                          className='fs-13 cursor-pointer' 
                          onClick={() => this.changeType('partner')}
                        >
                          Partner
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <div className="card-body">
                    {
                      // !this.state.showTable ? <>Loading ...</> :
                      <DataTable
                        noHeader={true}
                        columns={this.state.columns}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            open={this.state.showConfirm}
            close={() => this.showConfirmDelete()}
            id={this.state.id}
            url={`role/${this.state.id}`}
          />
        </div>
    )
  }
}

export default index;