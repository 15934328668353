/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import API from 'src/api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  InputGroupText,
  InputGroup,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NotifToast from 'src/components/notificationModal/NotifToast';
import { addCommas } from 'src/lib/helpers';

export default class UsersAdd extends Component {

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      body: {
        point: '',
        point_price: '',
        point_withdrawal_min: '',
        start_date: '',
        end_date: '',
        status: 1,
      },
      required: ['point', 'point_price', 'point_withdrawal_min', 'start_date', 'end_date', 'status'],
    }
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is Required'
    }
    else {
      delete error[name]
    }

    if (name === 'point_price') {
      newBody[name] = addCommas(value.replace(/\./g, ''))
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    this.setState({
      notif: {
        ...this.state.notif,
        show: true,
        title: 'Processing...',
      },
      buttonLoading: true,
    });
    let body = this.state.body;
    body.point_price = body.point_price.replace(/\./g, '');

    // alert(JSON.stringify(this.state.body)); return;
    API.post('scn/point-conversion', body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          body: {},
          notif: {
            title: 'Success',
            status: result.status,
            message: result.message,
          },
          buttonLoading: false,
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false }
          });
          this.props.history.push(`/scn/point-conversion`);
        }, 1500);
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
          }
        });
      })
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className='d-flex justify-content-center'>
              <div className="col-xl-5 px-0">
                <div className="card">
                  <div className="card-header vertical-middle">
                    <strong className="card-title vertical-middle-p">Add Point Conversion</strong>
                    <Link to="/scn/point-conversion" className="float-right">
                      <button
                        color="info"
                        size="sm"
                        className="btn btn-outline-link"
                      >
                        <i className='fa fa-arrow-left'></i>
                      </button>
                    </Link>
                  </div>
                  <div className="card-body">
                    <Form>
                      <FormGroup>
                        <Label className='w-100'>Point</Label>
                        <Input
                          name="point"
                          placeholder="Enter Point"
                          type="number"
                          onChange={this.onChange}
                          required
                          invalid={this.state.error['point']}
                        />
                        <FormFeedback >{this.state.error['point']}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label className='w-100'>Price</Label>
                        <InputGroup>
                          <InputGroupText>
                            Rp.
                          </InputGroupText>
                          <Input
                            name="point_price"
                            placeholder="Enter Price"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.body?.point_price}
                            required
                            invalid={this.state.error['point_price']} />
                          <FormFeedback >{this.state.error['point_price']}</FormFeedback>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label className='w-100'>Minimal Point Withdrawal</Label>
                        <Input
                          name="point_withdrawal_min"
                          placeholder="Enter Minimal Point Withdrawal"
                          type="text"
                          onChange={this.onChange}
                          value={this.state.body?.point_withdrawal_min}
                          required
                          invalid={this.state.error['point_withdrawal_min']} />
                        <FormFeedback >{this.state.error['point_withdrawal_min']}</FormFeedback>
                      </FormGroup>
                      <div className='d-xl-flex'>
                        <div className='col-xl-6 pl-0'>
                          <FormGroup>
                            <Label>Start Date</Label>
                            <Input
                              name="start_date"
                              type="date"
                              onChange={this.onChange}
                              required
                              invalid={this.state.error['start_date']}
                            />
                            <FormFeedback >{this.state.error['start_date']}</FormFeedback>
                          </FormGroup>
                        </div>
                        <div className='col-xl-6 pl-0'>
                          <FormGroup>
                            <Label>End Date</Label>
                            <Input
                              name="end_date"
                              type="date"
                              onChange={this.onChange}
                              required
                              invalid={this.state.error['end_date']}
                            />
                            <FormFeedback >{this.state.error['end_date']}</FormFeedback>
                          </FormGroup>
                        </div>
                      </div>
                      <FormGroup switch>
                        <Label>Status</Label>
                        <div class="form-check form-switch d-flex">
                          <input name='status' class="form-check-input form-switch" type="checkbox" value={1} checked={this.state.body.status == 1} role="switch" onClick={() => this.setState({
                            body: {
                              ...this.state.body,
                              status: this.state.body.status == 1 ? 0 : 1
                            }
                          })} />
                          {this.state.body.status ? 'Active' : 'Non Active'}
                        </div>
                      </FormGroup>
                      <Button className='w-100' color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                        {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
          status={this.state.notif.status}
        />
      </div>
    )
  }
}