import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, Spinner, ToastBody
} from 'reactstrap';

const init_body = {
  username: '',
  email: '',
  password: '',
  role_id: '',
}

export default class NotifToast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    }
  }

  render() {
    return (
      <Toast className="toast-show-top-right" transition={true} isOpen={this.props.show}>
        <ToastHeader 
          icon={this.props.status ? 'success' : 'danger'} 
          toggle={() => ''}
        >
          {this.props.title ? this.props.title : ( this.props.status ? 'Success' : 'Error' )}
        </ToastHeader>
        <ToastBody>
        {this.props.message}
        </ToastBody>
      </Toast>
    )
  }
}