import React, { Component } from 'react';
import API from '../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NotifToast from '../../components/notificationModal/NotifToast';

const init_body = {
  name: '',
  role_menu_group_id: '',
  url: '',
  icon: '',
  status: 1,
  permission:[]
}

export default class edit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      body: init_body,
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      optionGroup: [],
    }
  }

  componentDidMount() {
    this.getData()
    // this.getOptionGroup()
  }

  getOptionGroup(group){
    API.get(`role/menu/group?type=options&group=${group}`,{headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
    .then((resp) => {
      const result = resp.data.data;
      this.setState({
        optionGroup: result
      });
    })
    .catch(err => {
      alert(err)
    })
  }

  getData() {
    API.get(`role/menu/list?id=${this.state.id}`,{headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          body: {
            name: result.data.name,
            role_menu_group_id: result.data.role_menu_group_id,
            url: result.data.url,
            icon: result.data.icon,
            status: result.data.status,
            permission: result.data.permission.split(","),
            role_group_name: result.data.role_group_name,
          },
        }, () => {
          this.getOptionGroup(this.state.body.role_group_name)
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    if (e.target.name == 'permission') {
      var options = e.target.options;
      var value = [];
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      newBody[e.target.name] = value
    } else if (e.target.name === 'role_group_name') {
      this.getOptionGroup(e.target.value)
    }

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '');
    const result = filtered.length ? true : false

    this.setState({
      disabled: result,
    });

    return result
  }

  onSubmit() {
    this.setState({
      disabled: true,
      notif: { show: true, title: 'Processing ...' }
    });
    API.put(`role/menu/${this.state.id}`, this.state.body, { headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          body: {},
          notif: {
            title: 'Success',
            message: result.message,
          }
        });

        setTimeout(() => {
          this.setState({
            disabled: false,
            notif: { show: false }
          });
          this.props.history.push(`/menu/edit/${this.state.id}`);
        }, 2500);
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
          }
        });
      })
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Edit Menu</strong>
                  {/* <NavLink href="/user/add" > */}
                  <div className="float-right">
                    <Link to="/menu">
                      <button
                        color="info"
                        size="sm"
                        className="btn btn-outline-link"
                      >
                        <i className='fa fa-arrow-left'></i>
                      </button>
                    </Link>

                  </div>
                  {/* </NavLink> */}
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Name
                      </Label>
                      <Input
                        id="name"
                        name="name"
                        placeholder="Enter Name"
                        type="text"
                        onChange={this.onChange}
                        value={this.state.body.name}
                        invalid={this.state.error['name'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['name']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label className='w-100'>
                        Group Application
                      </Label>
                      <Input
                        id="role_group_name"
                        name="role_group_name"
                        type="select"
                        onChange={this.onChange}
                        value={this.state.body.role_group_name}
                        invalid={this.state.error['role_group_name'] ? true : false}
                      >
                        <option value={''}>Choose</option>
                        <option value={'dashboard_app'}>Dashboard App</option>
                        <option value={'dashboard_partner'}>Dashboard Partner</option>
                      </Input>
                      <FormFeedback >{this.state.error['role_group_name']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label >
                        Group Menu
                      </Label>
                      <Input
                        id="role_menu_group_id"
                        name="role_menu_group_id"
                        type="select"
                        onChange={this.onChange}
                        value={this.state.body.role_menu_group_id}
                        invalid={this.state.error['role_menu_group_id'] ? true : false}
                      >
                        <option value={''}>Choose</option>
                        {
                          this.state.optionGroup.map( item => {
                            return <option key={item.id} value={item.id}>{item.name}</option>
                          })
                        }
                      </Input>
                      <FormFeedback >{this.state.error['role_menu_group_id']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        URL
                      </Label>
                      <Input
                        id="url"
                        name="url"
                        placeholder="Enter URL"
                        type="text"
                        onChange={this.onChange}
                        value={this.state.body.url}
                        required
                        invalid={this.state.error['url'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['url']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Font Awesome Icon Name
                      </Label>
                      <Input
                        id="icon"
                        name="icon"
                        placeholder="Enter Icon Name"
                        type="text"
                        onChange={this.onChange}
                        value={this.state.body.icon}
                        required
                        invalid={this.state.error['icon'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['icon']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label >
                        Status
                      </Label>
                      <Input
                        id="status"
                        name="status"
                        type="select"
                        onChange={this.onChange}
                        value={this.state.body.status}
                        invalid={this.state.error['group_name'] ? true : false}
                      >
                        <option value={''}>Choose</option>
                        <option value={'1'}>ACTIVE</option>
                        <option value={'0'}>NON-ACTIVE</option>
                      </Input>
                      <FormFeedback >{this.state.error['group_name']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelectMulti">
                        Permission Type
                      </Label>
                      <Input
                        id="permission"
                        multiple
                        name="permission"
                        type="select"
                        value={this.state.body.permission}
                        onChange={this.onChange}
                      >
                        <option>create</option>
                        <option>read</option>
                        <option>update</option>
                        <option>delete</option>
                      </Input>
                    </FormGroup>
                    <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                      {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
        />
      </div>
    )
  }
}