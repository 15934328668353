
import API from '../api';
export async function _hasAccess(code, action) {
  const data = await fetch(process.env.REACT_APP_API_URL+'/checkauth', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }
  ).then(async (resp) => {
    // console.log("ini responsenya ",resp)
    if (resp.status === 401) {
      // console.log("ini masuk logout")
      localStorage.setItem('isLoggedIn', 'false');
      localStorage.setItem('token', '');
      localStorage.setItem('config', '');
      window.location.href = "/";
    } else {
      var loginData = localStorage.getItem('menus');
      var parseLoginData = JSON.parse(loginData)

      const data = await parseLoginData.filter(
        item =>
          item.group_menus.filter(
            menu => menu.code == code && menu.scope.filter(scope => scope.name == action && scope.status == 1).length
          ).length
      )
      let menuDetail = [];
      if (data.length) {
        menuDetail = data[0].group_menus.filter(item => item.code == code)[0];
      }
      return data.length ? menuDetail : false
    }
  })
  // console.log(data);
  return data;
}

