import React, { Component } from 'react';
import API from '../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NotifToast from '../../components/notificationModal/NotifToast';

const init_body = {
  name: '',
  role_menu_group_id: '',
  url: '',
  icon: '',
  status: 1,
}

export default class detail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      body: init_body,
      error: {},
      optionGroup: [],
    }
  }

  componentDidMount() {
    this.getData()
    this.getOptionGroup()
  }

  getOptionGroup(){
    API.get(`role/menu/group?type=options`,{headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
    .then((resp) => {
      const result = resp.data.data;
      this.setState({
        optionGroup: result
      });
    })
    .catch(err => {
      alert(err)
    })
  }

  getData() {
    API.get(`role/menu/list?id=${this.state.id}`,{headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          body: {
            name: result.data.name,
            role_menu_group_id: result.data.role_menu_group_id,
            url: result.data.url,
            icon: result.data.icon,
            status: result.data.status,
          },
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  onChange = (e) => {

  }


  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Detail Menu</strong>
                  {/* <NavLink href="/user/add" > */}
                  <div className="float-right">
                    <Link to="/menu">
                      <button
                        color="info"
                        size="sm"
                        className="btn btn-outline-link"
                      >
                        <i className='fa fa-arrow-left'></i>
                      </button>
                    </Link>

                  </div>
                  {/* </NavLink> */}
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Name
                      </Label>
                      <Input
                        id="name"
                        name="name"
                        disabled={true}
                        type="text"
                        onChange={this.onChange}
                        value={this.state.body.name}
                        invalid={this.state.error['name'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['name']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label >
                        Group Name
                      </Label>
                      <Input
                        id="role_menu_group_id"
                        name="role_menu_group_id"
                        disabled={true}
                        type="select"
                        onChange={this.onChange}
                        value={this.state.body.role_menu_group_id}
                        invalid={this.state.error['role_menu_group_id'] ? true : false}
                      >
                        <option value={''}>Choose</option>
                        {
                          this.state.optionGroup.map( item => {
                            return <option key={item.id} value={item.id}>{item.name}</option>
                          })
                        }
                      </Input>
                      <FormFeedback >{this.state.error['role_menu_group_id']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        URL
                      </Label>
                      <Input
                        id="url"
                        name="url"
                        disabled={true}
                        type="text"
                        onChange={this.onChange}
                        value={this.state.body.url}
                        required
                        invalid={this.state.error['url'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['url']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Font Awesome Icon Name
                      </Label>
                      <Input
                        id="icon"
                        name="icon"
                        disabled={true}
                        type="text"
                        onChange={this.onChange}
                        value={this.state.body.icon}
                        required
                        invalid={this.state.error['icon'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['icon']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label >
                        Status
                      </Label>
                      <Input
                        id="status"
                        name="status"
                        type="select"
                        disabled={true}
                        onChange={this.onChange}
                        value={this.state.body.status}
                        invalid={this.state.error['group_name'] ? true : false}
                      >
                        <option value={''}>Choose</option>
                        <option value={'1'}>ACTIVE</option>
                        <option value={'0'}>NON-ACTIVE</option>
                      </Input>
                      <FormFeedback >{this.state.error['group_name']}</FormFeedback>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}