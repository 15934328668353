import React, { Component } from 'react';
import API from '../../api'
import {
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment/moment';

const init_body = {
  name: '',
  group_name: '',
}

export default class detail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      body: init_body,
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
    }
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const group = query.get('group');
    this.getLog(group)
  }

  getLog(group) {
    API.get(`userApp/log/${this.state.id}?&group=${group}`,{headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          body: result,
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  onChange = (e) => {

  }

  

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Detail Log</strong>
                  {/* <NavLink href="/user/add" > */}
                  <div className="float-right">
                    <Link to="/log">
                      <button
                        color="info"
                        size="sm"
                        className="btn btn-outline-link"
                      >
                        <i className='fa fa-arrow-left'></i>
                      </button>
                    </Link>

                  </div>
                  {/* </NavLink> */}
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label>Action By</Label>
                      <Input
                        type="text"
                        disabled = {true}
                        value = {this.state.body.username}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Title</Label>
                      <Input
                        type="text"
                        disabled = {true}
                        value = {this.state.body.title}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Description</Label>
                      <Input
                        type="text"
                        disabled = {true}
                        value = {this.state.body.description}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>URL</Label>
                      <Input
                        type="text"
                        disabled = {true}
                        value = {this.state.body.url}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Params</Label>
                      <Input
                        type="text"
                        disabled = {true}
                        value = {this.state.body.params}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Response</Label>
                      <Input
                        type="text"
                        disabled = {true}
                        value = {this.state.body.response}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Created At</Label>
                      <Input
                        type="text"
                        disabled = {true}
                        value={moment(this.state.body.created_at).format('LLLL')}
                      />
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}