import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
  Button,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  Form,
  Row,
  Col,
  Input,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import ConfirmModal from '../../components/notificationModal/ConfirmModal';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_API_ENDPOINT,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          sortable: true
        },
        {
          name: 'Description',
          selector: 'description',
          sortable: true
        },
        {
          name: 'Method',
          selector: 'method',
          sortable: true
        },
        {
          name: 'Path URL',
          sortable: true,
          cell: data => {
            return `${data.path_1}`+(data.path_2 ? '/'+data.path_2 : '')+(data.path_3 ? '/'+data.path_3 : '')
          }
        },
        {
          name: 'Role',
          selector: 'role_permission_id',
          sortable: true
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: data =>
            <>
              <div>
                <Link to={`/api/${data.id}`}>
                  <button className="text-dark btn btn-sm btn-outline-link" ><li className="fa fa-eye"></li></button>
                </Link>
              </div>
              {
                this.state.scope.includes('update') ?
                  <Link to={`/api/edit/${data.id}`}>
                    <button className="text-primary btn btn-sm btn-outline-link" ><li className="fa fa-edit"></li></button>
                  </Link>
                  : null
              }

              {
                this.state.scope.includes('delete') ?
                  <button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(data.id)}><li className="fa fa-trash"></li></button>
                  : null
              }
            </>
        }
      ],
      loaderPage: true,
      data: [],
      editData: {},
      id: null,
      group: 'dashboard_app',
      showTable: false,
      showModal: false,
      showModalEdit: false,
      disableButtonModal: false,
      search: '*',
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1
    };
    // this.modalUser = this.modalUser.bind(this);
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
    this.setState({
      loaderPage: false
    })
  }

  changeGroup(group) {
    this.setState({
      group: group,
    }, () => {
      this.getList(group);
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList(group) {
    API.get('role/api?size=' + this.state.paginationPerPage + '&page=' + this.state.paginationPage + '&group='+this.state.group+ '&search=' + this.state.search, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changeEditData(body) {
    this.setState({ editData: body });
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModal: !this.state.showModal,
      id: id,
    });
  }

  afterSubmit(status, message) {
    this.getList()

    this.setState({
      alert: {
        show: true,
        status: status,
        message: message
      }
    });

    setTimeout(() => {
      this.setState({
        alert: {
          show: false,
          status: '',
          message: ''
        }
      });
    }, 3000)
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-header card-tabs">
                    <div className='row'>
                      <strong className='card-title vertical-middle-p'>
                        API Endpoint
                        <div className="float-right">
                        {
                          this.state.scope.includes('create') ?
                            <Link to={`/api/add?group=${this.state.group}`}>
                              <Button
                                color="info"
                                size="sm"
                              >
                                Add API Endpoint
                              </Button>
                            </Link>
                            : null
                        }
                      </div>
                      </strong>
                    </div>
                    <Nav tabs className='mt-3'>
                      <NavItem>
                        <NavLink
                          active={this.state.group === 'dashboard_app' ? true : false}
                          className='fs-13'
                          onClick={() => this.changeGroup('dashboard_app')}
                        >
                          Backoffice
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          active={this.state.group === 'dashboard_partner' ? true : false}
                          className='fs-13'
                          onClick={() => this.changeGroup('dashboard_partner')}
                        >
                          Partner
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <div className="card-body">
                      <Row className="row-cols-lg-auto g-3 align-items-center mb-4">
                        <Col>
                          <Input
                            id="search"
                            name='search'
                            placeholder="Search by Description/Path URL"
                            type="text"
                            onChange={(e) => {
                              this.setState({ 
                                search: e.target.value.length > 0 ? e.target.value.replace('/',',') : '*', 
                                page: 1 
                              },() => 
                                this.getList()
                              ) 
                            }}
                          />
                        </Col>
                      </Row>
                    {
                      // !this.state.showTable ? <>Loading ...</> :
                      <DataTable
                        noHeader={true}
                        columns={this.state.columns}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            open={this.state.showModal}
            close={() => this.showConfirmDelete()}
            id={this.state.id}
            url={`role/api/${this.state.id}`}
          />
        </div>
    )
  }
}

export default index;