import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from 'src/api'
import {
  Button,
  Spinner,
  Badge,
  InputGroup,
  Input,
  InputGroupText,
  Form,
  Row,
  Col
} from 'reactstrap';
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import ConfirmModal from 'src/components/notificationModal/ConfirmModal';
import { addCommas } from 'src/lib/helpers';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_SCN_WITHDRAWAL,
      scope: [],
      data: [],
      editData: {},
      id: null,
      showTable: false,
      showModalDelete: false,
      showModalDetail: false,
      showModalEdit: false,
      search: '',
      startDate: '',
      endDate: '',
      status: '',
      fake: false,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      totalWaiting: 0,
      selectedDataLoading: true,
      selectedData: {}
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status === 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    const search = this.state.search;
    console.log(search);
    API.get('scn/withdrawal?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      // + '&search=' + this.state.search
      + '&startDate=' + this.state.startDate
      + '&endDate=' + (this.state.endDate != '' ? this.state.endDate : this.state.startDate)
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true,
          totalWaiting: result.data.totalWaiting
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
      id: id,
    });
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    // if (e.target.value.length > 3 || e.target.value === '') {
    this.setState({
      search: e.target.value
    }, () => {
      this.getList()
    })
    // }
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header vertical-middle">
                    <strong className="card-title vertical-middle-p">History Withdrawal</strong>
                    {
                      this.state.scope.includes('create') ?
                        <Link to={`/scn/withdrawal/add`} className="float-right">
                          <Button type='button' size="sm" color='info'>Add New</Button>
                        </Link>
                        : null
                    }
                  </div>
                  <div className="card-body">
                    <Form className='mb-4'>
                      <Row className="row-cols-lg-auto g-3 align-items-center">
                        {/* <Col>
                          <Input
                            type='number'
                            placeholder='Search by Point/Price'
                            onChange={this.onChange}
                          />
                        </Col> */}
                        <Col>
                          <InputGroup>
                            <InputGroupText>
                              Start Date
                            </InputGroupText>
                            <Input
                              type="date"
                              name='deactivedDate'
                              onChange={(e) => {
                                this.setState({
                                  startDate: e.target.value
                                }, () => {
                                  this.getList()
                                })
                              }}
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <InputGroup>
                            <InputGroupText>
                              End Date
                            </InputGroupText>
                            <Input
                              type="date"
                              name='deactivedDate'
                              onChange={(e) => {
                                this.setState({
                                  endDate: e.target.value
                                }, () => {
                                  this.getList()
                                })
                              }}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Form>
                    {
                      <DataTable
                        noHeader={true}
                        columns={
                          [
                            {
                              name: 'ID',
                              selector: 'id',
                              maxWidth: '5%'
                            },
                            {
                              name: 'Date',
                              cell: data =>
                                <div className='grid'>
                                  <div className='font-weight-bold'>{moment.utc(data.date).format('DD MMMM YYYY')}</div>
                                  {/* <div className='small text-secondary mt-1'>Pkl. {moment.utc(data.start_date).format('HH:mm:ss')} WIB</div> */}
                                </div>
                            },
                            {
                              name: 'Total Member',
                              cell: data => (addCommas(data._count.detail) ?? 0) + ' member'
                            },
                            {
                              name: 'Total Misi',
                              cell: data => (addCommas(data._count.mission_work) ?? 0) + ' misi'
                            },
                            // {
                            //   name: 'Total Point',
                            //   cell: data =>
                            //     <div className='grid g-2'>
                            //       <div className='font-weight-bold'>{moment.utc(data.end_date).format('DD MMMM YYYY')}</div>
                            //       {/* <div className='small text-secondary mt-1'>Pkl. {moment.utc(data.end_date).format('HH:mm:ss')} WIB</div> */}
                            //     </div>
                            // },
                            {
                              name: 'Conversion (Rp.)',
                              cell: data => (addCommas(data.conversion.point_price) ?? 0) + '/' + (addCommas(data.conversion.point) ?? 0) + ' point'
                            },
                            // {
                            //   name: 'Total Withdrawal (Rp.)',
                            //   cell: data => addCommas(data.point_price)
                            // },
                            {
                              name: 'Updated By',
                              cell: data => data.user_updated?.username ?? '-',
                            },
                            {
                              name: 'Status',
                              cell: data =>
                                <label>
                                  <Badge
                                    color={data.status == 2 ? 'success' : (data.status == 1 ? 'warning' : 'danger')}
                                    pill
                                  >
                                    {data.status_name}
                                  </Badge>
                                </label>
                            },
                            {
                              name: 'Action',
                              selector: 'action',
                              cell: data =>
                                <>
                                  <div>
                                    {
                                      this.state.scope.includes('update') && data.status == 1 ?
                                        <Link to={`/scn/withdrawal/edit/${data.id}`}>
                                          <button className="text-primary btn btn-sm btn-outline-link" ><li className="fa fa-edit"></li></button>
                                        </Link>
                                        : null
                                    }
                                    <Link to={`/scn/withdrawal/${data.id}`}>
                                      <button className="text-dark btn btn-sm btn-outline-link" ><li className="fa fa-eye"></li></button>
                                    </Link>
                                    {/* {
                                      this.state.scope.includes('delete') ?
                                        <button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(data.id)}><li className="fa fa-trash"></li></button>
                                        : null
                                    } */}
                                  </div>
                                </>
                            }
                          ]
                        }
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            text={`Are you sure want to delete this point conversion ?`}
            open={this.state.showModalDelete}
            close={() => this.showConfirmDelete()}
            afterSubmit={() => this.getList()}
            id={this.state.id}
            url={`scn/point-conversion/${this.state.id}`}
          />
        </div>
    )
  }
}

export default index;