import React, { Component } from 'react';
import API from '../../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  InputGroup,
} from 'reactstrap';
import NotifToast from '../../../components/notificationModal/NotifToast';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default class ConfigAdd extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      error: {},
      disabled: true,
      loading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      optionManufactur: [],
      body: {
        manufacturer_id: null,
        question: '',
        name: '',
        placeholder: '',
        type: '',
        is_required: 0,
      },
      required: ['manufacturer_id', 'question', 'name', 'placeholder', 'type'],
    }
  }

  componentDidMount() {
    //Load Manufactur
    API.get('manufacturer?option=true', { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
    .then((resp) => {
      this.setState({
        optionManufactur: resp.data
      })
    })

    API.get(`config/scanweb/question-detail/${this.state.id}`, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.setState({
          body: resp.data.data
        })
      })
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is Required'
    }
    else {
      delete error[name]
    }

    if(name === 'is_required') {
      newBody[name] = e.target.checked ? 1 : 0
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    // alert(JSON.stringify(this.state.body)); return;
    this.setState({ loading: true });
    API.put('config/scanweb/question/'+ this.state.id, this.state.body, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({ loading: false });
        this.setState({
          body: {},
          notif: {
            title: 'Success',
            message: result.message,
            status: result.status,
          }
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false }
          });
          if (result.status) {
            this.props.history.push(`/config/scan-web?id=${result.data.manufacturer_id}`);
          }
        }, 3000);
      })
      .catch(err => {
        this.setState({ loading: false });
      })
  }

  render() {
  return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className='d-flex justify-content-center'>
              <div className="col-xl-5 px-0">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Detail Question</strong>
                  </div>
                  <div className="card-body">
                    <Form>
                      <FormGroup>
                        <Label className='w-100'>Manufacturer</Label>
                        <Input
                          name="manufacturer_id"
                          type="select"
                          onChange={this.onChange}
                          invalid={this.state.error['manufacturer_id']}
                          value={this.state.body.manufacturer_id}
                          disabled
                        >
                          {
                            <>
                              <option value={''}>All Manufacturer</option>
                              {
                                this.state.optionManufactur.map(item => {
                                  return <option key={item.value} value={item.value}>{item.label}</option>
                                })
                              }
                            </>
                          }
                        </Input>
                        <FormFeedback >{this.state.error['manufacturer_id']}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>Question</Label>
                          <Input
                            name="question"
                            placeholder="Enter Question"
                            type="text"
                            onChange={this.onChange}
                            required
                            readOnly
                            invalid={this.state.error['question']}
                            value={this.state.body.question}
                          />
                        <FormFeedback >{this.state.error['question']}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>Name</Label>
                          <Input
                            name="name"
                            placeholder="Enter Name"
                            type="text"
                            readOnly
                            required
                            invalid={this.state.error['name']}
                            value={this.state.body.name}
                          />
                        <FormFeedback >{this.state.error['name']}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>Placeholder</Label>
                          <Input
                            name="placeholder"
                            placeholder="Enter Placeholder"
                            type="text"
                            readOnly
                            required
                            invalid={this.state.error['placeholder']}
                            value={this.state.body.placeholder}
                          />
                        <FormFeedback >{this.state.error['placeholder']}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label className='w-100'>Type</Label>
                        <Input
                          name="type"
                          type="select"
                          disabled
                          invalid={this.state.error['type']}
                          value={this.state.body.type}
                        >
                          <option value={''}>Choose</option>
                          <option value={'text'}>Text</option>
                          <option value={'number'}>Number</option>
                        </Input>
                        <FormFeedback >{this.state.error['type']}</FormFeedback>
                      </FormGroup>
                      <FormGroup check>
                        <Input type="checkbox" name='is_required' onChange={this.onChange} checked={this.state.body.is_required}/>
                        <Label check>Required</Label>
                      </FormGroup>
                      {/* <Link to={this.props.location.state ? this.props.location.state.referrer : '/config/scan-web?id=49'}> */}
                        <Button className='w-100' color="primary" onClick={() => this.props.history.goBack()}>
                          Back
                        </Button>
                      {/* </Link> */}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
          status={this.state.notif.status}
        />
      </div>
    )
  }
}