import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row
} from 'reactstrap';

export default class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()}>
          <ModalHeader toggle={() => this.props.close()}>Detail Config/Price</ModalHeader>
          <ModalBody>
            <Form>
              <Row className="row-cols-auto g-1 align-items-center mb-4">
                <Col md={12} className="w-100">
                  <FormGroup>
                    <Label>Size</Label>
                    <Input
                      type="text"
                      value={this.props.data.size}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={6} className="w-50">
                  <FormGroup>
                    <Label>Qty From</Label>
                    <Input
                      type="text"
                      value={this.props.data.qty_from}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={6} className="w-50">
                  <FormGroup>
                    <Label>Qty To</Label>
                    <Input
                      type="text"
                      value={this.props.data.qty_to}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={12} className="w-100">
                  <FormGroup>
                    <Label>Price</Label>
                    <Input
                      type="text"
                      value={this.props.data.price}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={12} className="w-100">
                  <FormGroup>
                    <Label>Description</Label>
                    <Input
                      type="textarea"
                      value={this.props.data.description}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="danger" onClick={() => this.props.close()}>Close</Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}