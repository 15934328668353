/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import API from 'src/api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  InputGroupText,
  InputGroup,
  Badge,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NotifToast from 'src/components/notificationModal/NotifToast';
import { addCommas } from 'src/lib/helpers';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import ConfirmModalSweet from 'src/components/notificationModal/ConfrimModalSweet';
import { DownloadExcel } from 'src/lib/xlsx';

export default class Edit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      loading: true,
      code: '',
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      body: {
        note: '',
        withdrawal_date: null,
        status: 2,
      },
      data: {},
      listMember: [],
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      required: ['withdrawal_date'],
      page: {
        error: false,
        message: '',
        data: {},
      },
      confirmModal: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
    }
  }

  async componentDidMount() {
    //Load Data
    await API.get('scn/withdrawal/' + this.state.id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const data = resp.data.data;
        
        this.setState({
          body: {
            note: data.note,
            withdrawal_date: null,
            status: this.state.body.status,
          },
          data: data,
          listMember: data?.detail?.item,
          page: {
            error: !resp.data.status ? true : false,
            message: resp.data.message,
            data: resp.data.data,
          }
        }, () => {
          this.setState({
            loading: false,
          })
        })
      })
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is Required'
    }
    else {
      delete error[name]
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => (value === '' || value === null) && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    this.setState({
      notif: {
        show: true,
        status: true,
        title: 'Processing...',
      },
      // buttonLoading: true,
      confirmModal: false,
    });

    let body = this.state.body;
    // alert(JSON.stringify(this.state.body)); 
    // return;
    API.put('scn/withdrawal/'+this.state.id, body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          body: {},
          notif: {
            title: 'Success',
            status: result.status,
            message: result.message,
          },
          buttonLoading: false,
        });

        setTimeout(() => {
          this.setState({
            notif: { show: false },
          });
          this.props.history.push(`/scn/withdrawal`);
        }, 1500);
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
            confirmModal: false,
          }
        });
      })
  }

  downloadExcel() {
    this.setState({
      loadingExport: true
    })
    API.get(`scn/withdrawal/${this.state.id}?size=10000`
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const newDatalist = res.data.data?.detail?.item.map((data) => {
          return {
            ID: data.id,
            Fullname: data.member?.fullname,
            Email: data.member?.email,
            Bank: data?.rekening_bank,
            "Nomor Rekening": data?.rekening_number,
            "Rekening Kota": data.member?.rekening_city?.name,
            "Total Misi": data.member?._count?.mission_work,
            Point: data.total_point,
            Nominal: data.total_price,
            Phone: data.member?.phone,
          };
        });
        DownloadExcel(newDatalist, `Export Withdrawal_${moment().format("YYYYDDMMHHmm")} `);
        this.setState({
          loadingExport: false
        })
      })
      .catch((err) => {

      });
  };

  render() {
    if (this.state.loading) {
      return (
        <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner>
      )
    }

    return (
      <div className="content" >
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <div className="card-header vertical-middle">
                  <strong className="card-title vertical-middle-p">Add Withdrawal</strong>
                  <Link to="/scn/withdrawal" className="float-right">
                    <button
                      color="info"
                      size="sm"
                      className="btn btn-outline-link"
                    >
                      <i className='fa fa-arrow-left'></i>
                    </button>
                  </Link>
                </div>
                <div className="card-body">
                  <Form>
                    <div className='d-xl-flex'>
                      <div className='col-xl-6 pl-0'>
                        <FormGroup>
                          <Label className='w-100 text-secondary'>Total Member</Label>
                          <div className='font-weight-bold'>{this.state.data?.total_member} member</div>
                          <FormFeedback >{this.state.error['point']}</FormFeedback>
                        </FormGroup>
                      </div>
                      <div className='col-xl-6 pl-0'>
                        <FormGroup>
                          <Label className='w-100 text-secondary'>Total Mission Success</Label>
                          <div className='font-weight-bold'>{this.state.data?.total_mission} mission</div>
                          <FormFeedback >{this.state.error['point']}</FormFeedback>
                        </FormGroup>
                      </div>
                    </div>
                    <div className='d-xl-flex'>
                      <div className='col-xl-6 pl-0'>
                        <FormGroup>
                          <Label className='w-100 text-secondary'>Total Point</Label>
                          <div className='font-weight-bold'>{this.state.data?.total_point} point</div>
                          <FormFeedback >{this.state.error['point']}</FormFeedback>
                        </FormGroup>
                      </div>
                      <div className='col-xl-6 pl-0'>
                        <FormGroup>
                          <Label className='w-100 text-secondary'>Conversion</Label>
                          <div className='font-weight-bold'>{this.state.data?.conversion?.point} point = Rp. {addCommas(this.state.data?.conversion?.point_price)}</div>
                          <FormFeedback >{this.state.error['point']}</FormFeedback>
                        </FormGroup>
                      </div>
                    </div>
                    <Alert color="primary">
                      <div className='d-flex flex-column'>
                        <div className='small'>Total Withdrawal</div>
                        <div className='font-weight-bold text-xl'>Rp. {addCommas(this.state.data?.total_price)}</div>
                        <div className='small text-secondary'><small>Total Point * Conversion</small></div>
                      </div>
                    </Alert>
                    <FormGroup>
                      <Label className='w-100'>Notes</Label>
                      <Input
                        name="note"
                        type="textarea"
                        placeholder="Enter Note"
                        rows="3"
                        value={this.state.body.note}
                        onChange={this.onChange}
                        invalid={this.state.error['note']}
                      />
                      <FormFeedback >{this.state.error['point_withdrawal_min']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>Withdrawal Date</Label>
                      <Input
                        name="withdrawal_date"
                        type="datetime-local"
                        onChange={this.onChange}
                        value={this.state.body?.withdrawal_date}
                        required
                        invalid={this.state.error['withdrawal_date']}
                      />
                      <FormFeedback >{this.state.error['withdrawal_date']}</FormFeedback>
                    </FormGroup>
                    {
                      this.state.data?.total_price > 0 && (
                        <>
                          <Button className='w-100' color="primary" onClick={() => {
                            this.setState({
                              confirmModal: true
                            })
                          }} disabled={this.state.disabled} >
                            {!this.state.buttonLoading ? 'Withdrawal Completed' : <Spinner size="sm">Loading...</Spinner>}
                          </Button>
                        </>
                      )
                    }

                  </Form>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-header vertical-middle">
                  <strong className="card-title vertical-middle-p">List Point Member</strong>
                  <Button onClick={() => this.downloadExcel()} size='sm' className='ml-2 mb-2 float-right' color={'primary'}>
                    {this.state.loadingExport ? <Spinner size='sm' color='light' /> : <><i className="fa fa-download mr-2"></i>Export</>}
                  </Button>
                </div>
                <div className="card-body">
                  <DataTable
                    noHeader={true}
                    data={this.state.listMember}
                    columns={
                      [
                        {
                          name: 'ID',
                          selector: 'id',
                          maxWidth: '5%'
                        },
                        {
                          name: 'Fullname',
                          cell: row => row.member?.fullname
                        },
                        {
                          name: 'Bank',
                          cell: row => <>{row?.rekening_bank}</>
                        },
                        {
                          name: 'Rekening',
                          cell: row => <>{row?.rekening_number}</>
                        },
                        {
                          name: 'Rekening City',
                          width: '170px',
                          cell: row => <>{row.member?.rekening_city?.name}</>
                        },
                        {
                          name: 'Nominal (Rp)',
                          cell: row => <>{addCommas(row.total_price)}</>
                        },
                        {
                          name: 'Phone',
                          cell: row => row.member?.phone
                        },
                        {
                          name: 'Mission',
                          cell: row => <div className='text-primary'>{row.member?._count?.mission_work} mission</div>,
                        },
                        {
                          name: 'Point',
                          cell: row => <Badge color="success">{row.total_point}</Badge>,
                        },
                      ]
                    }
                    pagination={true}
                    paginationServer={true}
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    paginationTotalRows={this.state.paginationTotalRows}
                    onChangePage={page => this.changePage(page)}
                    onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
          status={this.state.notif.status}
        />
        <ConfirmModalSweet
          show={this.state.confirmModal}
          title='Are you sure want process point withdrawal ?'
          subtitle='Make sure you have checked the data'
          confirmText='Yes, Process now!'
          onConfirm={() => this.onSubmit()}
          onCancel={() => { this.setState({ confirmModal: false }) }}
        />
      </div>
    )
  }
}