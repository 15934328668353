import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Badge,
  Input,
  FormFeedback,
  Alert
} from 'reactstrap';
import { addCommas, formatDate } from "src/lib/helpers";
import API from 'src/api'
import ConfirmModalSweet from 'src/components/notificationModal/ConfrimModalSweet';
import NotifToast from 'src/components/notificationModal/NotifToast';

export default class LeadsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
      modalReason: false,
      disabledReject: true,
      notif: {
        show: false,
        status: true,
        message: ''
      },
      confirmText: {
        title: 'Are you sure ?',
        subtitle: 'Make sure you have checked the data',
        confirmText: 'Yes, Process now!'
      },
      previewImage: false,
      showDetail: false,
      body: {
        status: null,
        note: ''
      }
    }
  }

  async onSubmit() {
    this.setState({
      buttonLoading: true
    })
    const body = this.state.body;
    // alert(JSON.stringify(body)); return;
    API.put('/scn/point-purchased/' + this.props.data.id, body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          buttonLoading: false,
          notif: {
            show: true,
            status: true,
            message: res.data.message
          },
        });

        this.props.close()
        this.props.refresh();

        setTimeout(() => {
          this.setState({
            notif: {
              show: false,
              status: true,
              message: ''
            },
            error: {},
          })
        }, 3000);
      })
      .catch(err => {
        this.setState({
          buttonLoading: false
        })
        this.props.close()
        console.log(err);
      })
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="md">
          <ModalHeader toggle={() => this.props.close()}>Detail Purchased Point</ModalHeader>
          <ModalBody>
            {
              this.props.dataLoading ?
                <div className="">
                  <div className="skeleton skeleton-text" style={{ width: '25%' }}></div>
                  <div className="skeleton skeleton-body"></div>
                  <div className="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                  <div className="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                </div>
                :
                <>
                  <Form>
                    <div className="card-forum">
                      <div className="card-forum-body">
                        <div className="content-forum">
                          {/* <label className="small text-primary font-weight-bold">Data Point</label> */}
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Manufacturer</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.manufacturer?.name} </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Status</label>
                                <div className="text-dark font-weight-bold">
                                  <Badge
                                    className="badge-c"
                                    color={this.props.data.status == 0 ? 'secondary' : (this.props.data.status == 1 ? 'info' : (this.props.data.status == 2 ? 'warning' : (this.props.data.status == 3 ? 'success' : 'danger')))}
                                    pill
                                  >
                                    {this.props.data.status_name}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Transaction Number</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.id} </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Date</label>
                                <div className="text-dark font-weight-bold"> {formatDate(this.props.data.created_partner_at)} WIB</div>
                              </div>
                            </div>
                          </div>
                          <label className="small text-primary font-weight-bold mt-2">Detail Payment</label>
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-6">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Total Point</label>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label className="text-dark mb-0">: {addCommas(this.props.data.total_point)}</label>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Total Price</label>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label className="text-dark mb-0">: Rp. {addCommas(this.props.data.total_point_price)}</label>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">Admin Fee</label>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label className="text-dark mb-0">: Rp. {addCommas(this.props.data.admin_fee)}</label>
                              </div>
                            </div>
                            <Alert color="primary">
                              <div className='d-flex flex-column'>
                                <div className='small'>Total Withdrawal</div>
                                <div className='font-weight-bold text-xl'>Rp. {addCommas(this.props.data?.total_payment)}</div>
                              </div>
                            </Alert>
                          </div>
                          <label className="small text-primary font-weight-bold mt-2">Payment Method</label>
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-md-5">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">From</label>
                                <div className="small">
                                  {this.props.data.evidence?.account_from_bank}<br />
                                  {this.props.data.evidence?.account_from_number}<br />
                                  a/n {this.props.data.evidence?.account_from_name}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="small text-secondary mb-0">To</label>
                                <div className="small">
                                  {this.props.data.evidence?.account_to_bank}<br />
                                  {this.props.data.evidence?.account_to_number}<br />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="small text-secondary">Evidence</label>
                                <div className="text-dark font-weight-bold d-flex gap-2">
                                  <img
                                    src={this.props.data.evidence?.payment_proof}
                                    width={135}
                                    onClick={() => {
                                      this.setState({
                                        previewImage: this.props.data.evidence?.payment_proof,
                                      });
                                      this.props.close()
                                    }}
                                    alt=""
                                    className="img-fluid pe-auto"
                                    role="button"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="small text-secondary mb-2">Notes</label>
                                <div className="text-dark font-weight-bold pr-3">
                                  <Input
                                    name="note"
                                    type="textarea"
                                    placeholder="Enter Note"
                                    rows="2"
                                    value={this.state.body.note ?? this.props.data.note}
                                    onChange={(e) => this.setState({ body: { ...this.state.body, note: e.target.value } })}
                                    invalid={this.state.error['note']}
                                  />
                                  <FormFeedback>{this.state.error.note}</FormFeedback>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
            }
          </ModalBody>
          {
            this.props.data.status == 2 && (
              this.props.access.includes('update') && (
                <ModalFooter>
                  <Button color="success" onClick={() => {
                    this.setState({
                      confirmModal: true,
                      confirmText: {
                        title: 'Are you sure you have received this payment ?',
                        subtitle: 'This process will add partner points',
                        confirmText: 'Yes, it has been accepted!'
                      },
                      body: {
                        ...this.state.body,
                        status: 3
                      }
                    })
                    this.props.close();
                  }}>Receive Payment</Button>
                  <Button color="danger" onClick={() => {
                    if (this.state.body.note == '') {
                      this.setState({
                        error: {
                          note: 'Reason is required'
                        },
                        buttonLoading: false,
                      })
                      return false;
                    }

                    this.setState({
                      confirmModal: true,
                      confirmText: {
                        title: 'Are you sure you want to reject this payment ?',
                        subtitle: 'This process will reject the partner points',
                        confirmText: 'Yes, Reject Payment!'
                      },
                      body: {
                        ...this.state.body,
                        status: 4
                      }
                    })
                    this.props.close();
                  }}>Reject Payment</Button>
                </ModalFooter>
              )
            )
          }
        </Modal>
        <Modal isOpen={this.state.previewImage} toggle={() => { this.setState({ previewImage: false }); this.props.reOpen(); }} size="lg">
          <i className="w-full h3  fa fa-times-circle position-absolute p-2 top-0 end-0" role="button" onClick={() => { this.setState({ previewImage: false }); this.props.reOpen(); }}></i>
          <img src={this.state.previewImage} alt="" className="img-fluid" />
        </Modal>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
          status={this.state.notif.status}
        />
        <ConfirmModalSweet
          show={this.state.confirmModal}
          title={this.state.confirmText.title}
          subtitle={this.state.confirmText.subtitle}
          confirmText={this.state.confirmText.confirmText}
          onConfirm={() => { this.setState({ confirmModal: false }); this.onSubmit(); }}
          onCancel={() => { this.setState({ confirmModal: false }); this.props.reOpen(); }}
        />
      </>

    )
  }
}