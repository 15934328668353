import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from 'src/api'
import {
  Spinner,
  Input,
  Col,
  Row,
  Label,
  Button,
  FormGroup,
  FormFeedback,
  Badge,
} from 'reactstrap';
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import NotifToast from 'src/components/notificationModal/NotifToast';
import { addCommas } from 'src/lib/helpers';
import ViewSurat from './_component/ViewSurat';

class add extends Component {

  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      id: this.props.match.params.id,
      menuCode: constants.MENU_LEADS_TRACKING_OFFERING_LETTER,
      scope: [],
      buttonLoading: false,
      previewLoading: false,
      disabled: true,
      body: {
        id_leads: this.props.match.params.id,
        prefix_number: 'KGB/SP/',
        hal: 'Penawaran Hologram Shieldtag',
        kepada: null,
        alamat: null,
        tgl_surat: new Date().toISOString().substr(0, 10),
        lampiran: '1 (satu) Lembar',
        user_id_ttd: null,
        status: 2,
      },
      required: ['hal', 'kepada', 'alamat', 'tgl_surat', 'lampiran', 'user_id_ttd'],
      error: {},
      data: {},
      editData: {},
      showTable: false,
      showModal: false,
      showModalSurat: false,
      pdf: '',
      search: '',
      notif: {
        show: false,
        status: true,
        message: '',
        title: 'Success'
      },
      optionSource: [],
      optionChannel: [],
      optionProductType: [],
      optionSignature: [],
      selectedOption: [
        {
          product_id: null,
          id_produk_lead_config: null,
          size: null,
          qty: null,
          price: null,
          history_price: []
        }
      ],
      errorSelectedOption: [],
      total: {
        qty: 0,
        price: 0
      },
      showModalSource: false,
      showModalChannel: false,
      status: [
        { value: '0', label: 'Reject' },
        { value: '1', label: 'Approved' },
        { value: '2', label: 'Pending' },
      ],
      statusLeads: [
        { value: '0', label: 'Deleted', color: 'secondary' },
        { value: '1', label: 'Cold', color: 'info' },
        { value: '2', label: 'Hot', color: 'warning' },
        { value: '3', label: 'Super Hot', color: 'primary' },
        { value: '4', label: 'Accepted', color: 'success' },
        { value: '5', label: 'Losses', color: 'danger' },
      ]
    };
  }

  async componentDidMount() {
    await this.checkAccess();
    this.onLoad();
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  onLoad = async () => {
    try {
      const data = await API.get('leads/' + this.state.id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      const signature = await API.get('user/signature?option=true', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      const productType = await API.get('product/leads/config?option=true', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      const source = await API.get('product/leads/source-type', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

      this.setState({
        data: data.data.data,
        optionSignature: signature.data.data,
        optionProductType: productType.data.data,
        optionSource: source.data.data,
        showTable: true,
        body: {
          ...this.state.body,
          kepada: data.data.data?.pic_name,
          alamat: data.data.data?.company_name,
        }
      })

    } catch (error) {
      console.log(error);
    }
  }

  onSubmit = () => {
    const body = { ...this.state.body };

    this.setState({
      buttonLoading: true,
    });

    // alert(JSON.stringify(body)); return;
    API.post('offering-letters', body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          buttonLoading: false,
          notif: {
            show: true,
            title: 'Success',
            message: result.message,
            status: result.success
          }
        });

        setTimeout(() => {
          this.setState({
            notif: {
              show: false
            }
          })
          this.props.history.push('/leads-tracking/offering-letter/' + result.data.offeringLetter.id);
        }, 3000);
      })
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is Required'
    }
    else {
      delete error[name]
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => (value === '' || value === null) && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  render() {
    const { selectedOption } = this.state;

    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-md-5">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title align-middle">Data Lead</strong>
                  </div>
                  <div className="card-body">
                    <Row className="row-cols-auto border-bottom align-items-center mb-4">
                      <Col md={6}>
                        <FormGroup>
                          <Label className='text-secondary'>Nama Perusahaan</Label>
                          <div className="text-dark font-weight-bold">{this.state.data.company_name}</div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label className='text-secondary'>Nama Brand</Label>
                          <div className="text-dark font-weight-bold">{this.state.data.brand_name}</div>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label className='text-secondary'>Nama PIC</Label>
                          <div className="text-dark font-weight-bold">{this.state.data.pic_name}</div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label className='text-secondary'>Sumber</Label>
                          <div className="text-dark font-weight-bold">{this.state.data.md_leads_source_type?.source_type}</div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label className='text-secondary'>Sumber Detail</Label>
                          <div className="text-dark font-weight-bold">{this.state.data.md_leads_channel?.channel}</div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label className='text-secondary'>Catatan</Label>
                          <div className="text-dark font-weight-bold">{this.state.data.note}</div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label className='text-secondary'>Status</Label>
                          <div className="text-dark font-weight-bold">
                          <label>
                              <Badge
                                color={this.state.statusLeads.filter(item => item.value == this.state.data.status)[0].color}
                                pill
                              >
                                {this.state.statusLeads.filter(item => item.value == this.state.data.status)[0].label}
                              </Badge>
                            </label>
                          </div>
                          </FormGroup>
                      </Col>
                    </Row>
                    {
                      selectedOption.map((item, index) => {
                        return (
                          <Row className=" align-items-center mb-4">
                            <Label className='text-secondary'>Daftar Produk</Label>
                            <DataTable
                              noHeader
                              columns={[
                                {
                                  name: 'Produk',
                                  width: '200px',
                                  cell: data => data.md_produk_lead_config?.md_tipe_produk?.tipe_produk
                                },
                                {
                                  name: 'Ukuran',
                                  width: '95px',
                                  selector: 'size',
                                },
                                {
                                  name: 'Kuantitas',
                                  cell: data => addCommas(data.qty)
                                },
                                {
                                  name: 'Harga Normal/pcs',
                                  width: '135px',
                                  cell: data => addCommas(data.normal_price)
                                },
                                {
                                  name: 'Harga Setelah Diskon/pcs',
                                  width: '175px',
                                  cell: data => addCommas(data.price)
                                },
                              ]}
                              data={this.state.data.lead_products}
                            />
                          </Row>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title align-middle">Offering Letter</strong>
                  </div>
                  <div className="card-body">
                    <Row className="align-items-center mb-4">
                      <Col md={12}>
                        <Label>Nomor Surat</Label>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label className='text-secondary text-sm'>Awalan</Label>
                          <Input
                            placeholder='Masukkan awalan nomor'
                            name='prefix_number'
                            type="text"
                            value={this.state.body.prefix_number}
                            onChange={this.onChange}
                            invalid={this.state.error['prefix_number'] ? true : false}
                          />
                          <FormFeedback >{this.state.error['prefix_number']}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label className='text-secondary text-sm'>Nomor</Label>
                          <Input
                            placeholder='Dibuat Otomatis'
                            type="text"
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={4}>
                        <FormGroup>
                          <Label>Tanggal Surat</Label>
                          <Input
                            name='tgl_surat'
                            type="date"
                            value={this.state.body.tgl_surat}
                            onChange={this.onChange}
                            invalid={this.state.error['tgl_surat'] ? true : false}
                          />
                          <FormFeedback >{this.state.error['tgl_surat']}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={8}>
                        <FormGroup>
                          <Label>Perihal</Label>
                          <Input
                            placeholder='Masukkan Perihal'
                            name='hal'
                            type="text"
                            value={this.state.body.hal}
                            onChange={this.onChange}
                            invalid={this.state.error['hal'] ? true : false}
                          />
                          <FormFeedback >{this.state.error['hal']}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <Label>Penerima</Label>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label className='text-secondary text-sm'>Kepada</Label>
                          <Input
                            placeholder='Masukkan Nama Penerima'
                            name='kepada'
                            type="text"
                            value={this.state.body.kepada}
                            onChange={this.onChange}
                            invalid={this.state.error['kepada'] ? true : false}
                          />
                          <FormFeedback >{this.state.error['kepada']}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label className='text-secondary text-sm'>Alamat</Label>
                          <Input
                            placeholder='Masukkan Alamat Penerima'
                            name='alamat'
                            type="text"
                            value={this.state.body.alamat}
                            onChange={this.onChange}
                            invalid={this.state.error['alamat'] ? true : false}
                          />
                          <FormFeedback >{this.state.error['alamat']}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Lampiran</Label>
                          <Input
                            placeholder='Masukkan Teks Lampiran'
                            name='lampiran'
                            type="text"
                            value={this.state.body.lampiran}
                            onChange={this.onChange}
                            invalid={this.state.error['lampiran'] ? true : false}
                          />
                          <FormFeedback >{this.state.error['lampiran']}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <Label className='w-100'>Penandatangan</Label>
                        <Input
                          name="user_id_ttd"
                          type="select"
                          onChange={this.onChange}
                          required
                          invalid={this.state.error['user_id_ttd'] ? true : false}
                        >
                          {
                            <>
                              <option value={''}>Pilih</option>
                              {
                                this.state.optionSignature.map(item => {
                                  return <option key={item.value} value={item.value}>{item.label}</option>
                                })
                              }
                            </>
                          }
                        </Input>
                        <FormFeedback >{this.state.error['id_source_type']}</FormFeedback>
                      </Col>
                    </Row>
                    <Button className='w-100' color="primary" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                      {!this.state.buttonLoading ? 'Generate' : <Spinner size="sm">Loading...</Spinner>}
                    </Button>
                    <Button className='w-100 mt-2' color="info" type='button'
                      onClick={async () => {
                        const pdf = await API.post('print-offering-letters-preview', { ...this.state.body, nomor_surat: '-' }, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
                        this.setState({
                          pdf: pdf.data?.path,
                          showModalSurat: true
                        })
                      }}
                      disabled={this.state.disabled}
                    >
                      {!this.state.previewLoading ? 'Pratinjau Surat' : <Spinner size="sm">Loading...</Spinner>}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NotifToast
            show={this.state.notif.show}
            close={() => this.setState({ notif: { show: false } })}
            title={this.state.notif.title}
            status={this.state.notif.status}
            message={this.state.notif.message}
          />
          <ViewSurat
            open={this.state.showModalSurat}
            close={() => this.setState({ showModalSurat: false })}
            path={this.state.pdf}
            filename={'Konsep Surat Penawaran'}
          />
        </div>
    )
  }
}

export default add;