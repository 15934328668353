import React, { Component } from 'react';
import API from '../../api'

export default class GenerateQRCode extends Component{
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            value: ""
        }
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event){
        let paramQRCode = {
            value: this.state.value,
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        API.post('qrcode', paramQRCode)
                .then(response => {
                    console.log("Sukses generate qrcode");
                    this.props.history.push("/");
                })
                .catch(error => {
                    console.log("Error generate qrcode",error);
                });
    }

    render() {
        return(
            <div className="content">
                <div className="animated fadeIn">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="card-title">Generate QR Code</strong>
                                </div>
                                <div className="card-body">
                                    <div className="form-group has-success">
                                        <label for="value" className="control-label mb-1">QR Code Value</label>
                                        <input 
                                            id="value" name="value" type="text" 
                                            className="form-control value valid" 
                                            data-val="true" 
                                            data-val-required="Please enter the the value of QR Code" 
                                            autocomplete="value" aria-required="true" 
                                            aria-invalid="false" aria-describedby="value"
                                            value={this.state.value}
                                            onChange={this.handleChange}
                                        />
                                        <span className="help-block field-validation-valid" data-valmsg-for="value" data-valmsg-replace="true"></span>
                                    </div>
                                    <button type="button" className="btn btn-info" onClick={this.handleSubmit}>Generate QR Code</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}