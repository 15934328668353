import React, { Component } from 'react';
import API from '../../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NotifToast from '../../../components/notificationModal/NotifToast';

const init_body = {
  point: '',
  max_point: '',
  status: 1,
}

export default class edit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      type: this.props.match.params.type,
      body: init_body,
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
    }
  }

  componentDidMount() {
    this.getData()
    // this.getOptionGroup()
  }
  getData() {
    let config = 'scn/config/point/referral';
    if (this.state.type === 'referrer') {
      config = 'scn/config/point/referrer';
    }
    API.get(config+`/${this.state.id}`,{headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          body: {
            point: result.data.point,
            max_count: result.data.max_count,
            status: result.data.status,
          },
        });
      })
      .catch(err => {
        alert(err)
      })
  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '');
    const result = filtered.length ? true : false

    this.setState({
      disabled: result,
    });

    return result
  }

  onSubmit() {
    this.setState({
      disabled: true,
      notif: { show: true, title: 'Processing ...' }
    });
    let config = 'scn/config/point/referral';
    if (this.state.type === 'referrer') {
      config = 'scn/config/point/referrer';
    }
    this.state.body.point = parseInt(this.state.body.point)
    this.state.body.max_count = parseInt(this.state.body.max_count)
    this.state.body.status = parseInt(this.state.body.status)

    API.put(config+`/${this.state.id}`, this.state.body, { headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` }})
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          body: {},
          notif: {
            status: true,
            title: 'Success',
            message: result.message,
          }
        });

        setTimeout(() => {
          this.setState({
            disabled: false,
            notif: { show: false }
          });
          this.props.history.push(`/scn/config/referral`);
        }, 2500);
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            status: false,
            title: 'Failed',
            message: err,
          }
        });
      })
  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Edit Config</strong>
                  {/* <NavLink href="/user/add" > */}
                  <div className="float-right">
                    <Link to="/scn/config/referral">
                      <button
                        color="info"
                        size="sm"
                        className="btn btn-outline-link"
                      >
                        <i className='fa fa-arrow-left'></i>
                      </button>
                    </Link>

                  </div>
                  {/* </NavLink> */}
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Bonus Point
                      </Label>
                      <Input
                        id="point"
                        name="point"
                        placeholder="Enter Name"
                        type="number"
                        onChange={this.onChange}
                        value={this.state.body.point}
                        invalid={this.state.error['point'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['point']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Max Mission Work
                      </Label>
                      <Input
                        id="max_count"
                        name="max_count"
                        placeholder="Enter Name"
                        type="number"
                        onChange={this.onChange}
                        value={this.state.body.max_count}
                        invalid={this.state.error['max_count'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['max_count']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label >
                        Status
                      </Label>
                      <Input
                        id="status"
                        name="status"
                        type="select"
                        onChange={this.onChange}
                        value={this.state.body.status}
                      >
                        <option value={''}>Choose</option>
                        <option value={'1'}>ACTIVE</option>
                        <option value={'0'}>NON-ACTIVE</option>
                      </Input>
                      <FormFeedback >{this.state.error['status']}</FormFeedback>
                    </FormGroup>
                    <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                      {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotifToast
          show={this.state.notif.show}
          status={this.state.notif.status}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
        />
      </div>
    )
  }
}