import React, { Component } from 'react';
import API from '../../api'
import axios from 'axios';
import UserDetailToken from '../../components/UserDetailToken';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default class SendNotif extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            deviceList:[],
            disabled: true,
            manufacturers:[],
            manufacturerId: "",
            manufacturerName: "",
            manufacturerLogo: "",
            giveAwayState:false,
            changeSenderState:false,
            notifTitle:'',
            notifBody:'',
            message:'',
            messagePreview:'',
            // platformSelected:'ios',
            // platformSelected:'android',
            expoTokens:[]
        }
        this.handleChangeManufacturer = this.handleChangeManufacturer.bind(this);
        this.handleChangeSender = this.handleChangeSender.bind(this);
    }

    async getManufacturerData(){
        let manufacturerOptions = await API.get('manufacturer/options', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
        this.setState({
            manufacturers: manufacturerOptions.data,
            manufacturerId: manufacturerOptions.data[0].manufacturer_id,
            manufacturerName: manufacturerOptions.data[0].name,
        })
        // setTimeout(()=>{
        //     alert(JSON.stringify(this.state.manufacturers))
        // }, 200)
    }

    getManufacturerOption(){
        let items = [];
        let manufacturer = this.state.manufacturers;
        // console.log("List manufacturer", manufacturer)
        for(let i=0; i < manufacturer.length; i++){
            items.push(<option key={manufacturer[i].manufacturer_id} value={manufacturer[i].manufacturer_id} data-name={manufacturer[i].name}>{manufacturer[i].name}</option>);
        }
        return items;
    }

    componentDidMount(){
        this.getManufacturerData();
        // API.get('user/getDeviceToken')
        // .then(response=>{
        //     if(response){
        //         for(var x = 0; x < response.data.length; x++){
        //             response.data[x].checked = false;
        //         }
        //         // alert(JSON.stringify(response.data))
        //         this.setState({
        //             deviceList: response.data
        //         })
        //     }
        // })
    }

    getDeviceTokenOpton(){
        let items = [];
        let deviceTokens = this.state.deviceList
        for(let i = 0; i < deviceTokens.length; i++){
            items.push(<option key={deviceTokens[i].id} value={deviceTokens[i].token}>{deviceTokens[i].token}</option>)
        }
        return items;
    }

    handleToggleCheckedAll(e){
        e.preventDefault();
        let deviceList = this.state.deviceList
        for(var i = 0; i < deviceList.length; i++){
            deviceList[i].checked = !deviceList[i].checked
        }
        this.setState({
            deviceList: deviceList
        })
    }

    handleCheckedToken(index){
        let deviceList = this.state.deviceList
        for(var i = 0; i < deviceList.length; i++){
            if(index === i){
                deviceList[i].checked = !deviceList[i].checked
                if(!deviceList[i].checked && deviceList[i].expo_token){
                    var index = this.state.expoTokens.indexOf(deviceList[i].expo_token);
                    if (index !== -1) {
                        this.state.expoTokens.splice(index, 1);
                    }
                }
                if(deviceList[i].expo_token && deviceList[i].checked){
                    this.state.expoTokens.push(deviceList[i].expo_token)
                }
                // alert(this.state.deviceList[i].checked)
            }
        }
        // if(this.state.expoTokens.length > 0){
        //     setTimeout(()=>{
        //         alert(this.state.expoTokens)
        //     }, 1000)
        // }
        this.setState({
            deviceList: deviceList
        })
        // setTimeout(()=>{
        //     alert(JSON.stringify(this.state.deviceList))
        // }, 100)
    }

    handleSendExpoNotif(e){
        // alert(this.state.expoTokens.length)
        if(this.state.expoTokens.length > 0 && this.state.notifTitle && this.state.notifBody){
            for(var i = 0; i < this.state.expoTokens.length; i++){
                API.get('/user/testExpoNotif/'+encodeURIComponent(this.state.notifTitle)+'/'+encodeURIComponent(this.state.notifBody)+'/'+this.state.expoTokens[i], { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
                .then(response=>{
                    if(response){
                        console.log(JSON.stringify(response))
                    }
                })
            }
            alert('Done')
        } else {
            alert('Please complete details')
        }
    }

    handleSendMessageandNotif(e){
        if(this.state.message && this.state.messagePreview && this.state.manufacturerId && this.state.manufacturerName && this.state.manufacturerLogo){
            if(!this.state.giveAwayState){
                API.get('user/sendMessageFromManufacturer/'+this.state.manufacturerId+'/'+this.state.manufacturerName+'/'+encodeURIComponent(this.state.manufacturerLogo)+'/'+encodeURIComponent(this.state.message)+'/'+this.state.messagePreview+'/0', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
                .then((response)=>{
                    if(response){
                        console.log(response)
                        API.get('getIncomingNewMessage/'+this.state.manufacturerId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
                        .then((response2)=>{
                            console.log(response2)
                            alert('Done sending message')
                        })
                        .catch((err)=>{
                            console.log(err)
                            alert('Done sending message')
                        })
                    }
                })
            } else {
                var userIdList = [];
                for(var i = 0; i < this.state.deviceList.length; i++){
                    if(this.state.deviceList[i].checked){
                        userIdList.push(this.state.deviceList[i].users_id)
                    }
                }
                const distinctUserId = [...new Set(userIdList)]
                alert(JSON.stringify(distinctUserId))
                if(distinctUserId.length > 0){
                    for(var j = 0; j < distinctUserId.length; j++){
                        API.get('user/sendMessageFromManufacturer/'+this.state.manufacturerId+'/'+this.state.manufacturerName+'/'+encodeURIComponent(this.state.manufacturerLogo)+'/'+encodeURIComponent(this.state.message)+'/'+this.state.messagePreview+'/'+distinctUserId[j], { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
                        .then((response)=>{
                            if(response){
                                console.log(response)
                                API.get('getIncomingNewMessage/'+this.state.manufacturerId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
                                .then((response2)=>{
                                    console.log(response2)
                                    alert('Done sending message')
                                })
                                .catch((err)=>{
                                    console.log(err)
                                    alert('Done sending message')
                                })
                            }
                        })
                    }
                }
            }
            if(!this.state.giveAwayState){
                for(var i = 0; i < this.state.deviceList.length; i++){
                    // alert(this.state.deviceList[i].token)
                    if(this.state.deviceList[i].platform === 'android'){
                        if(!this.state.deviceList[i].expo_token){
                            fetch('https://fcm.googleapis.com/fcm/send',{
                                method:'post',
                                headers: {
                                    "Authorization":"key=AAAAWz4Mh0I:APA91bF5WKVV2ykePK9Y455l6jUTocF5JZplkabdoOCNLyHcS-vCC9VoFovhCjvP0nIIAPQdY9bbtE2YECxbJdo2vW0kAhqIHstHOUMt9eLO_If6cgxzJzLMqTEqMO4xUiIB87CktPx8",
                                    "Content-Type":"application/json"
                                },
                                body: JSON.stringify({
                                    "to":this.state.deviceList[i].token,
                                    "priority": "high",
                                    "data" : {
                                        "experienceId": "@stanley.shieldtag/shieldtag",
                                        "scopeKey": "@stanley.shieldtag/shieldtag",
                                        "title": this.state.manufacturerName+' mengirimi Anda pesan',
                                        "message": 'Buka appnya sekarang'
                                    }
                                })
                            })
                            .then(response=> response.json())
                            .then(response=>{
                                if(response){
                                    // alert(JSON.stringify(response))
                                    console.log(JSON.stringify(response))
                                }
                            })
                            .catch((error)=>{
                                console.log(error)
                            })
                        } else {
                            API.get('/user/testExpoNotif/'+encodeURIComponent(this.state.manufacturerName+' mengirimi Anda pesan')+'/'+encodeURIComponent('Buka appnya sekarang')+'/'+this.state.deviceList[i].expo_token, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
                            .then(response=>{
                                if(response){
                                    console.log(JSON.stringify(response))
                                }
                            })
                        }
                    } else {
                        if(this.state.deviceList[i].expo_token){
                            API.get('/user/testExpoNotif/'+encodeURIComponent(this.state.manufacturerName+' mengirimi Anda pesan')+'/'+encodeURIComponent('Buka appnya sekarang')+'/'+this.state.deviceList[i].expo_token, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
                            .then(response=>{
                                if(response){
                                    console.log(JSON.stringify(response))
                                }
                            })
                        }
                    }
                }
            } else {
                for(var i = 0; i < this.state.deviceList.length; i++){
                    if(this.state.deviceList[i].checked){
                        if(this.state.deviceList[i].platform === 'android'){
                            if(!this.state.deviceList[i].expo_token){
                                fetch('https://fcm.googleapis.com/fcm/send',{
                                    method:'post',
                                    headers: {
                                        "Authorization":"key=AAAAWz4Mh0I:APA91bF5WKVV2ykePK9Y455l6jUTocF5JZplkabdoOCNLyHcS-vCC9VoFovhCjvP0nIIAPQdY9bbtE2YECxbJdo2vW0kAhqIHstHOUMt9eLO_If6cgxzJzLMqTEqMO4xUiIB87CktPx8",
                                        "Content-Type":"application/json"
                                    },
                                    body: JSON.stringify({
                                        "to":this.state.deviceList[i].token,
                                        "priority": "high",
                                        "data" : {
                                            "experienceId": "@stanley.shieldtag/shieldtag",
                                            "scopeKey": "@stanley.shieldtag/shieldtag",
                                            "title": this.state.manufacturerName+' Giveaway mengirimi Anda pesan',
                                            "message": 'Buka appnya sekarang'
                                        }
                                    })
                                })
                                .then(response=> response.json())
                                .then(response=>{
                                    if(response){
                                        // alert(JSON.stringify(response))
                                        console.log(JSON.stringify(response))
                                    }
                                })
                                .catch((error)=>{
                                    console.log(error)
                                })
                            } else {
                                API.get('/user/testExpoNotif/'+encodeURIComponent(this.state.manufacturerName+' Giveaway mengirimi Anda pesan')+'/'+encodeURIComponent('Buka appnya sekarang')+'/'+this.state.deviceList[i].expo_token, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
                                .then(response=>{
                                    if(response){
                                        console.log(JSON.stringify(response))
                                    }
                                })
                            }
                        } else {
                            if(this.state.deviceList[i].expo_token){
                                API.get('/user/testExpoNotif/'+encodeURIComponent(this.state.manufacturerName+' Giveaway mengirimi Anda pesan')+'/'+encodeURIComponent('Buka appnya sekarang')+'/'+this.state.deviceList[i].expo_token, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
                                .then(response=>{
                                    if(response){
                                        console.log(JSON.stringify(response))
                                    }
                                })
                            }
                        }
                    }
                    // alert(this.state.deviceList[i].token)
                }
            }
        }
    }

    async handleChangeSender(e){
        const dataManufacturer = await API.get('manufacturer/'+e.target.value, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
        // alert(JSON.stringify(dataManufacturer))
        this.setState({
            manufacturerName: dataManufacturer.data[0].name,
            manufacturerLogo: dataManufacturer.data[0].logo,
        })
    }

    async handleChangeManufacturer(e){
        this.setState({ deviceList:[] })
        this.setState({ manufacturerId:e.target.value })
        var userIdList = [];
        // setTimeout(()=>{
        //     alert(this.state.manufacturerName)
        //     alert(this.state.manufacturerLogo)
        // }, 200)
        if(e.target.value){
            API.get('user/getUserIdsWhoScannedManufacturerIdFromHistory/'+e.target.value, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
            .then(response=>{
                if(response){
                    // alert(JSON.stringify(response.data))
                    for(var i = 0; i < response.data.length; i++){
                        userIdList.push(response.data[i].user_app_id)
                    }
                    API.get('user/getDeviceTokenWhereUserIdIn/'+JSON.stringify(userIdList), { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
                    .then(response=>{
                        // alert(JSON.stringify(response.data))
                        this.setState({
                            deviceList: response.data
                        })
                        // setTimeout(()=>{
                        //     alert(JSON.stringify(this.state.deviceList))
                        // }, 500)
                    })
                    // alert(JSON.stringify(userIdList))
                }
            })
            const dataManufacturer = await API.get('manufacturer/'+e.target.value, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
            // alert(JSON.stringify(dataManufacturer))
            this.setState({
                manufacturerName: dataManufacturer.data[0].name,
                manufacturerLogo: dataManufacturer.data[0].logo,
            })
            // setTimeout(()=>{
            //     alert(this.state.manufacturerName)
            //     alert(this.state.manufacturerLogo)
            // }, 500)
        }
        // setTimeout(()=>{
        //     alert(this.state.manufacturerId)
        // }, 500)
    }

    testDistinctArray(e){
        var userIdList = [];
        for(var i = 0; i < this.state.deviceList.length; i++){
            userIdList.push(this.state.deviceList[i].users_id)
        }
        const distinctUserId = [...new Set(userIdList)]
        alert(JSON.stringify(distinctUserId))
    }

    handleSendNotif(e){
        e.preventDefault();
        for(var i = 0; i < this.state.deviceList.length; i++){
            // alert(this.state.deviceList[i].token)
            if(this.state.deviceList[i].checked){
                if(this.state.deviceList[i].platform === 'android'){
                    if(!this.state.deviceList[i].expo_token){
                        fetch('https://fcm.googleapis.com/fcm/send',{
                            method:'post',
                            headers: {
                                "Authorization":"key=AAAAWz4Mh0I:APA91bF5WKVV2ykePK9Y455l6jUTocF5JZplkabdoOCNLyHcS-vCC9VoFovhCjvP0nIIAPQdY9bbtE2YECxbJdo2vW0kAhqIHstHOUMt9eLO_If6cgxzJzLMqTEqMO4xUiIB87CktPx8",
                                "Content-Type":"application/json"
                            },
                            // body: JSON.stringify({
                            //     "to": this.state.deviceList[i].token,
                            //     "notification":{
                            //         "experienceId": "@stanley.shieldtag/shieldtag",
                            //         "scopeKey": "@stanley.shieldtag/shieldtag",
                            //         "title":this.state.notifTitle,
                            //         "body":this.state.notifBody,
                            //         "android_channel_id":"$default", // For Android >= 8
                            //         "channel_id":"$default", // For Android Version < 8
                            //         // "image": "https://xxxxx.com/xxxxx.jpeg"
                            //     },
                            //     "priority": "high",
                            //     // "data" : {
                            //     //     "experienceId": "@stanley.shieldtag/shieldtag",
                            //     //     "scopeKey": "@stanley.shieldtag/shieldtag",
                            //     //     "title": this.state.notifTitle,
                            //     //     "message": this.state.notifBody,
                            //     //     "android_channel_id":"default", // For Android >= 8
                            //     // },
                            //     "android": {
                            //         "notification": {
                            //             "android_channel_id":"$default",
                            //             "channel_id": "$default"
                            //         }
                            //     },
                            // })
                            body: JSON.stringify({
                                "to":this.state.deviceList[i].token,
                                "priority": "high",
                                "data" : {
                                    "experienceId": "@stanley.shieldtag/shieldtag",
                                    "scopeKey": "@stanley.shieldtag/shieldtag",
                                    "title": this.state.notifTitle,
                                    "message": this.state.notifBody
                                }
                            })
                        })
                        .then(response=> response.json())
                        .then(response=>{
                            if(response){
                                // alert(JSON.stringify(response))
                                console.log(JSON.stringify(response))
                            }
                        })
                        .catch((error)=>{
                            console.log(error)
                        })
                    } else {
                        API.get('/user/testExpoNotif/'+encodeURIComponent(this.state.notifTitle)+'/'+encodeURIComponent(this.state.notifBody)+'/'+this.state.deviceList[i].expo_token, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
                        .then(response=>{
                            if(response){
                                console.log(JSON.stringify(response))
                            }
                        })
                    }
    
                    // API.get('test/notif/'+this.state.notifTitle+'/'+this.state.notifBody+'/'+this.state.deviceList[i].token)
                    // .then(response=>{
                    //     if(response){
                    //         console.log(response)
                    //     }
                    // })
    
                    // fetch('https://exp.host/--/api/v2/push/send', { 
                    //     mode: 'no-cors',
                    //     method: 'post',
                    //     headers: {
                    //         'Host':'exp.host',
                    //         'Accept':'application/json',
                    //         'Accept-Encoding':'gzip, deflate',
                    //         'Content-Type':'application/json',
                    //         'Connection':'keep-alive'
                    //     },
                    //     body: {
                    //         "to": "ExponentPushToken[vtyrfMJgYufo2KMaL9CEHv]",
                    //         "sound": "default",
                    //         "title":"Halo kak",
                    //         "body": "selamat malam"
                    //     }
                    // })
                    // .then(response=> response.json())
                    // .then(response=> {
                    //     if(response){
                    //         console.log(response)
                    //     }
                    // })
                    // .catch((error)=>{
                    //     console.log(error)
                    // })
                } else {
                    if(this.state.deviceList[i].expo_token){
                        API.get('/user/testExpoNotif/'+encodeURIComponent(this.state.notifTitle)+'/'+encodeURIComponent(this.state.notifBody)+'/'+this.state.deviceList[i].expo_token, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
                        .then(response=>{
                            if(response){
                                console.log(JSON.stringify(response))
                            }
                        })
                    }
                }
            }
        }
        alert('Done')
    }
    
    render(){
        return(
            <div className='container-fluid'>
                <div className='content'>
                    <div className='card'>
                        <div className='card-header'>
                            <strong className='card-header'>Compose Notification</strong>
                        </div>
                        <div className='card-body'>
                            {/* <select name="deviceToken" id="deviceToken" class="form-control">
                                {this.getDeviceTokenOpton()}
                            </select> */}
                            <div className="form-group has-success">
                                <label for="notifTitle">Notification Title</label>
                                <input className="form-control" disabled value={this.state.notifTitle} onChange={(e)=>this.setState({ notifTitle: e.target.value })}/>
                            </div>
                            <div className="form-group has-success">
                                <label for="notifTitle">Notification Body</label>
                                <textarea className="form-control" disabled rows={5} value={this.state.notifBody} onChange={(e)=>this.setState({ notifBody: e.target.value })}/>
                            </div>
                            <div className="form-group has-success">
                                <label for="notifTitle">Select Manufacturer</label>
                                <select name="manufacturerId" id="manufacturerId" class="form-control" onChange={this.handleChangeManufacturer}>
                                    {this.getManufacturerOption()}
                                </select>    
                            </div>
                            <div className="form-group has-success">
                                <label for="notifTitle">Select Manufacturer (Sender)</label>
                                <select disabled={!this.state.changeSenderState} name="manufacturerId" id="manufacturerId" class="form-control" onChange={this.handleChangeSender}>
                                    {this.getManufacturerOption()}
                                </select>    
                            </div>
                            <label>
                                <input
                                    type='checkbox'
                                    id='sender'
                                    checked={this.state.changeSenderState}
                                    // value={item.token}
                                    onChange={()=>this.setState({ changeSenderState:!this.state.changeSenderState })}
                                    style={{ marginRight:10 }}
                                />
                                Change Message Sender?  
                            </label>
                            <div style={{ height:290 }}>
                                <label>Compose Message</label>
                                <ReactQuill style={{ height:200 }} theme="snow" value={this.state.message} onChange={(message) => this.setState({ message:message, messagePreview:message.replace(/<[^>]+>/g, ' ') })}/>
                            </div>
                            <label>
                                <input
                                    type='checkbox'
                                    id='giveAway'
                                    checked={this.state.giveAwayState}
                                    // value={item.token}
                                    onChange={()=>this.setState({ giveAwayState:!this.state.giveAwayState })}
                                    style={{ marginRight:10 }}
                                />
                                Send Giveaway Message?     
                            </label>
                            <div>
                                <p>Message Preview:</p>
                                <p>{this.state.message}</p>
                            </div>
                            <div>
                                <p>Message Preview Plain:</p>
                                <p>{this.state.messagePreview}</p>
                            </div>
                            <p>Select Device Token</p>
                            {
                                this.state.deviceList.length > 0
                                ?
                                <div style={{ maxHeight:250, overflowY:'auto', marginBottom:'1em' }}>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Username/Email</th>
                                                <th scope="col">Fullname</th>
                                                <th scope="col">Device Token</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.deviceList.map((item, index)=>{
                                                    // const usernameLabel = this.getUserDetailById(item.users_id);
                                                    return(
                                                        <tr>
                                                            <td>
                                                                {
                                                                    item.platform === this.state.platformSelected || !this.state.platformSelected
                                                                    ?
                                                                    <label>
                                                                        <input
                                                                            type='checkbox'
                                                                            id={`checkbox-token-${index}`}
                                                                            checked={item.checked}
                                                                            value={item.token}
                                                                            disabled={!item.enabled || !this.state.giveAwayState}
                                                                            onChange={()=>this.handleCheckedToken(index)}
                                                                            style={{ marginRight:10 }}
                                                                        />
                                                                        {index+1}
                                                                    </label>
                                                                    :
                                                                    null
                                                                }
                                                            </td>
                                                            <td>
                                                                {item.users_id ? <UserDetailToken userId={item.users_id} showUsername={true}/> : 'Unknown user'}
                                                            </td>
                                                            <td>
                                                                {item.users_id ? <UserDetailToken userId={item.users_id} showFullname={true}/> : 'Unknown user'}
                                                            </td>
                                                            <td>
                                                                {item.expo_token ? item.expo_token : null}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                null
                            }
                            <div className='row'>
                                <div className='col-md-2'>
                                    <button type="button" className="btn btn-info" onClick={(e)=>this.handleToggleCheckedAll(e)}>Toggle Select All</button>
                                </div>
                                <div className='col-md-2'>
                                    <button type="button" className="btn btn-info" onClick={(e)=>this.handleSendNotif(e)}><i class="fa fa-paper-plane" aria-hidden="true"></i> Send Notification</button>
                                </div>
                                <div className='col-md-2 d-none'>
                                    <button type="button" className="btn btn-info" onClick={(e)=>this.handleSendExpoNotif(e)}><i class="fa fa-paper-plane" aria-hidden="true" disabled={this.state.disabled}></i> Send Test Expo Notification</button>
                                </div>
                                <div className='col-md-2'>
                                    <button type="button" className="btn btn-info" onClick={(e)=>this.handleSendMessageandNotif(e)}><i class="fa fa-paper-plane" aria-hidden="true"></i> Send Notification & Message</button>
                                    {/* <button type="button" className="btn btn-info" onClick={(e)=>this.testDistinctArray(e)}><i class="fa fa-paper-plane" aria-hidden="true"></i> Send Notification & Message</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}