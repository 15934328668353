import React, { Component } from 'react';
import API from '../../api'
import {
  Form,
  FormGroup,
  Label,
  Spinner,
  Input,
  FormText,
  Badge
} from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

class detail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      body: {},
      loader: false,
      bodyPath: '',
      accessMenus: [],
      accessAction: [],
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({
      loader: true
    });

    API.get(`forum/${this.state.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;

        this.setState({
          body: result.data,
          loader: false
        });
        console.log(this.state.body.users_created.username)
      })
      .catch(err => {
        this.setState({
          loader: false
        });
      })
  }

  onChange = (e) => {

  }

  render() {
    return (
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <Link to="/forum">
                    <button
                      color="info"
                      size="sm"
                      className="btn btn-outline-link"
                    >
                      <i className='fa fa-arrow-left'></i>
                    </button>
                  </Link>
                  <strong className="card-title align-middle">Detail Forum</strong>
                  <div className="float-right">
                    {this.state.body?.is_fake_product ? <Badge color="danger">Laporan Produk Palsu</Badge> : null}
                  </div>
                </div>
                <div className="card-body">
                  {
                    this.state.body?.id ?
                      <Form>
                        <FormGroup>
                          <Label>Email</Label>
                          <Input
                            id="description"
                            name="description"
                            placeholder="Enter Username"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.body.email}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Fullname</Label>
                          <Input
                            id="description"
                            name="description"
                            placeholder="Enter Fullname"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.body.fullname}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Phone Number</Label>
                          <Input
                            id="phone"
                            name="phone"
                            placeholder="Enter Phone Number"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.body.users_created?.phone}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label >QR Code ID</Label>
                          <Input
                            id="qr_code_id"
                            onChange={this.onChange}
                            value={this.state.body.qrcode_id_conv}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label >Product Name</Label>
                          <Input
                            id="product_name"
                            onChange={this.onChange}
                            value={this.state.body.product_name}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Place to Buy</Label>
                          <Input
                            id="forum_place_to_buy_id"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.body.place_to_buy?.name}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Store Name</Label>
                          <Input
                            id="store_name"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.body.store_name}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label >Sensor Store Name</Label>
                          <Input
                            id="is_sensor_store_name"
                            onChange={this.onChange}
                            value={this.state.body.is_sensor_store_name ? 'Yes' : 'No'}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label >Purchase Date</Label>
                          <Input
                            id="purchase_date"
                            onChange={this.onChange}
                            value={moment(this.state.body.purchase_date).format("dddd, D MMM YYYY")}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label >Description</Label>
                          <Input
                            id="purchase_date"
                            type='textarea'
                            onChange={this.onChange}
                            value={this.state.body.description}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label >Created At</Label>
                          <Input
                            id="purchase_date"
                            onChange={this.onChange}
                            value={moment(this.state.body.created_at).format("dddd, D MMM YYYY HH:mm:ss ") + 'WIB'}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label >Images</Label>
                          <div className='align-center w-100'>
                            {
                              this.state.body.images.map((item, index) => {
                                return <img src={item.image} style={{ maxWidth: '250px', maxHeight: '250px' }} class="rounded p-2" alt="image"></img>
                              })
                            }
                          </div>
                        </FormGroup>
                      </Form>
                      :
                      (this.state.loader ? <Spinner style={{ marginLeft: '50%' }}>Loading</Spinner>
                        : "Forum tidak ditemukan"
                      )
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}

export default detail;