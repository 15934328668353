import React, { Component } from 'react';
import API from 'src/api'
import {
  Spinner,
  Input,
  Col,
  Row,
  Label,
  Button,
  FormGroup,
  FormFeedback,
  Alert,
  Badge,
} from 'reactstrap';
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import ConfirmModal from 'src/components/notificationModal/ConfirmModal';
import NotifToast from 'src/components/notificationModal/NotifToast';
import Select from 'react-select';
import { addCommas } from 'src/lib/helpers';
import SourceType from '../sourceType/Index';
import Channel from '../channel/Index';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class add extends Component {

  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      id: this.props.match.params.id,
      menuCode: constants.MENU_LEADS_TRACKING_TYPE_PRODUCT,
      scope: [],
      buttonLoading: false,
      disabled: false,
      body: {
        id_source_type: null,
        id_leads_channel: null,
        company_name: null,
        brand_name: null,
        pic_name: null,
        phone: null,
        note: null,
        status: null,
        lead_products: [],
      },
      required: ['company_name', 'brand_name', 'pic_name', 'phone', 'id_source_type', 'id_leads_channel', 'status'],
      error: {},
      data: [],
      editData: {},
      showTable: false,
      showModal: false,
      search: '',
      notif: {
        show: false,
        status: true,
        message: '',
        title: 'Success'
      },
      optionSource: [],
      optionChannel: [],
      optionProductType: [],
      selectedOption: [
        {
          product_id: null,
          id_produk_lead_config: null,
          size: null,
          qty: null,
          price: null,
          history_price: []
        }
      ],
      errorSelectedOption: [],
      total: {
        qty: 0,
        price: 0
      },
      showModalSource: false,
      showModalChannel: false,
      status: [
        // { value: '0', label: 'Deleted' },
        { value: '1', label: 'Cold' },
        { value: '2', label: 'Hot' },
        { value: '3', label: 'Super Hot' },
        { value: '4', label: 'Accepted' },
        { value: '5', label: 'Losses' },
      ],
    };
  }

  async componentDidMount() {
    await this.checkAccess();
    this.onLoad();
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  onLoad = async () => {
    try {
      const source = await API.get('product/leads/source-type', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      const detail = await API.get('leads/' + this.state.id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      const detailData = detail.data.data;

      const selected_id = detailData.lead_products.map(item => item.md_produk_lead_config?.id);
      const productType = await API.get('product/leads/config?option=true&selected_id='+selected_id.join(','), { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

      const channel = await API.get('product/leads/channel?id_source_type=' + detailData.id_source_type, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

      this.setState({
        optionProductType: productType.data.data,
        optionSource: source.data.data,
        optionChannel: channel.data.data,
        showTable: true,
        body: {
          ...this.state.body,
          id_source_type: detailData.id_source_type,
          id_leads_channel: detailData.id_leads_channel,
          company_name: detailData.company_name,
          brand_name: detailData.brand_name,
          pic_name: detailData.pic_name,
          phone: detailData.phone,
          note: detailData.note,
          status: detailData.status,
        },
        selectedOption: detailData.lead_products.map(item => {
          return {
            product_id: item.id_produk_lead_config,
            id_produk_lead_config: item.md_produk_lead_config?.id,
            size: item.size,
            qty: item.qty,
            normal_price: item.normal_price,
            price: item.price,
            subtotal: item.price * item.qty,
            history_price: [],
            label: item.md_produk_lead_config.md_tipe_produk.tipe_produk + ' - ' + item.size,
          }
        })
      })

      this.calculateTotal();

    } catch (error) {

    }
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onSearch = (e) => {
    this.setState({
      search: e.target.value
    }, () => {
      if (this.state.search.length > 2 || this.state.search.length === 0) {
        this.getList();
      }
    })
  }

  onSubmit = () => {
    const lead_products = [...this.state.selectedOption].filter(item => item.product_id !== null);
    lead_products.map(item => {
      item.qty = parseInt(item.qty);
      item.price = parseInt(item.price ? item.price : item.normal_price);
      item.description = item.label ?? item.description
      delete item.sugestion_product;
    })
    // console.log('lead_products', lead_products)

    const body = { ...this.state.body };
    body.lead_products = lead_products;
    body.status = parseInt(body.status);

    this.setState({
      buttonLoading: true,
      disabled: true,
    });

    // alert(JSON.stringify(body)); return;
    API.put('leads/' + this.state.id, body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          buttonLoading: false,
          notif: {
            show: true,
            title: 'Success',
            message: result.message,
            status: result.status
          }
        });

        setTimeout(() => {
          this.setState({
            notif: {
              show: false
            },
            disabled: false,
          })
          this.props.history.push('/leads-tracking/data-leads');
        }, 3000);
      })
  }

  CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} className='py-1 option-custom'>
      <div className='px-2'>
        <div>{label}</div>
        {data.deskripsi && <small>{data.deskripsi}</small>}
      </div>
    </div>
  );

  onChangeProduct = async (e, index) => {
    const name = e.target.name;
    const value = e.target.value;

    const selectedOptionNew = this.state.selectedOption;
    selectedOptionNew[index] = { ...selectedOptionNew[index], [name]: value };
    // console.log('selectedOptionNew onChangeProduct', selectedOptionNew)

    if (name === 'qty') {
      const qty = selectedOptionNew[index].qty != "" ? parseInt(selectedOptionNew[index].qty) : 0;

      const calculate = await API.get('calculate-leads?'
        + 'tipe_produk_id=' + selectedOptionNew[index].product_id
        + '&size=' + selectedOptionNew[index].size
        + '&qty=' + qty
        , {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
      const calculateData = calculate.data.data;
      const price = selectedOptionNew[index].price ? selectedOptionNew[index].price : calculateData.calculate_product.price;

      selectedOptionNew[index] = {
        ...selectedOptionNew[index],
        normal_price: calculateData.calculate_product.price,
        price: selectedOptionNew[index].price,
        subtotal: price * selectedOptionNew[index].qty,
        id_produk_lead_config: calculateData.calculate_product.id,
        sugestion_product: calculateData.sugestion_product
      };
      // console.log('selectedOptionNew after calculate', selectedOptionNew)
    } else if(name === 'price') {
      const price = value ? value : selectedOptionNew[index].normal_price;

      selectedOptionNew[index] = {
        ...selectedOptionNew[index],
        price: value,
        subtotal: selectedOptionNew[index].qty * value
      };
      // console.log('selectedOptionNew after price', selectedOptionNew)
    }
    // console.log('selectedOptionNew final', selectedOptionNew)

    this.setState({ selectedOption: selectedOptionNew });

    this.onValidation()
    this.calculateTotal();
  }

  onDeleteProduct = (index) => {
    const selectedOptionNew = this.state.selectedOption;
    selectedOptionNew.splice(index, 1);

    this.setState({ selectedOption: selectedOptionNew });
    this.onValidation();
    this.calculateTotal();
  }

  onSelect = (selectedOption, index) => {
    const selectedOptionNew = this.state.selectedOption;
    selectedOptionNew[index] = {
      ...selectedOptionNew[index],
      product_id: selectedOption.value,
      size: selectedOption.size,
      qty: 0,
      normal_price: 0,
      price: 0,
      subtotal: 0,
      description: selectedOption.label,
    };
    // console.log('selectedOptionNew ', selectedOptionNew)


    this.setState({ selectedOption: selectedOptionNew });
    this.calculateTotal();
    this.onValidation();
  };

  onValidation() {
    const asArray = Object.entries(this.state.body);

    let filteredProduct = [];
    this.state.selectedOption.map(item => {
      const asArray = Object.entries(item);
      const filtered = asArray.filter(([key, value]) => (value === '' || value === null) && key !== 'id_produk_lead_config' && key !== 'price');
      if (filtered.length) {
        filteredProduct.push(filtered);
      }
    })

    this.setState({
      disabled: filteredProduct.length ? true : false,
    });
  }

  calculateTotal = () => {
    let totalQty = 0;
    let totalPrice = 0;
    this.state.selectedOption.map(item => {
      const price = item.price ? item.price : item.normal_price;

      totalQty = totalQty + parseInt(item.qty);
      totalPrice = totalPrice + (parseInt(price) * parseInt(item.qty));
    })

    this.setState({
      total: {
        qty: isNaN(totalQty) ? 0 : totalQty,
        price: isNaN(totalPrice) ? 0 : totalPrice
      }
    });
  }

  render() {
    const { selectedOption } = this.state;

    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-md-5">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title align-middle"> Detail Lead</strong>
                  </div>
                  <div className="card-body">
                    <Row className="row-cols-auto align-items-center mb-4">
                      <Col className='w-100'>
                        <FormGroup>
                          <Label>Company Name</Label>
                          <Input
                            type="text"
                            value={this.state.body.company_name}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <FormGroup>
                          <Label>Brand Name</Label>
                          <Input
                            type="text"
                            value={this.state.body.brand_name}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <FormGroup>
                          <Label>Whatsapp Number</Label>
                          <Input
                            type="number"
                            value={this.state.body.phone}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <FormGroup>
                          <Label>PIC Name</Label>
                          <Input
                            type="text"
                            value={this.state.body.pic_name}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col className='w-100'>
                        <Label className='w-100'>
                          Source
                        </Label>
                        <Input
                          type="select"
                          value={this.state.body.id_source_type}
                          disabled={true}
                        >
                          {
                            <>
                              <option value={''}>Choose</option>
                              {
                                this.state.optionSource.map(item => {
                                  return <option key={item.id} value={item.id}>{item.source_type}</option>
                                })
                              }
                            </>
                          }
                        </Input>
                        <FormFeedback >{this.state.error['id_source_type']}</FormFeedback>
                      </Col>
                      <Col className='w-100 mt-3'>
                        <Label className='w-100'>
                          Channel
                        </Label>
                        <Input
                          type="select"
                          value={this.state.body.id_leads_channel}
                          disabled={true}
                        >
                          {
                            this.state.body.id_source_type ?
                              <>
                                <option value={''}>{this.state.optionChannel?.length ? 'Choose' : 'Empty'}</option>
                                {
                                  this.state.optionChannel.map(item => {
                                    return <option key={item.id} value={item.id}>{item.channel}</option>
                                  })
                                }
                              </>
                              : <option value={''}>Please select source first</option>
                          }
                        </Input>
                        <FormFeedback >{this.state.error['id_leads_channel']}</FormFeedback>
                      </Col>
                      <Col className='w-100 mt-2'>
                        <Label>Note</Label>
                        <Input
                          type="textarea"
                          value={this.state.body.note}
                          rows="2"
                          disabled={true}
                        />
                        <FormFeedback >{this.state.error['note']}</FormFeedback>
                      </Col>
                      <Col className='w-100'>
                        <Label className='w-100'>Status</Label>
                        <Input
                          type="select"
                          value={this.state.body.status}
                          disabled={true}
                        >
                          {
                            <>
                              <option value={''}>Choose</option>
                              {
                                this.state.status.map(item => {
                                  return <option key={item.value} value={item.value}>{item.label}</option>
                                })
                              }
                            </>
                          }
                        </Input>
                        <FormFeedback >{this.state.error['status']}</FormFeedback>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title align-middle">Product</strong>
                    <div className="float-right">
                      <button className='btn btn-sm btn-primary' onClick={() => {
                        const selectedOptionNew = this.state.selectedOption;
                        selectedOptionNew.push({ product_id: null, size: null, qty: null, price: null });
                        this.setState({ selectedOption: selectedOptionNew });

                        this.onValidation();
                      }}>
                        <i className='fa fa-plus-square mr-1'></i> Add Product
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    {
                      selectedOption.map((item, index) => {
                        return (
                          <Row className=" align-items-center mb-4">
                            <Col md={8}>
                              <FormGroup>
                                <Label>Product {index + 1}</Label>
                                <Select
                                  name="product_id"
                                  value={this.state.optionProductType.find((option) => option.value === item.product_id)}
                                  onChange={(e) => this.onSelect(e, index)}
                                  options={this.state.optionProductType}
                                  components={{
                                    Option: this.CustomOption,
                                  }}
                                />
                                <FormFeedback >{this.state.error['product_id']}</FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col md='4'>
                              <FormGroup>
                                <Label>Size</Label>
                                <Input
                                  placeholder='Enter Size'
                                  name='size'
                                  type="text"
                                  value={item.size}
                                  onChange={(e) => this.onChangeProduct(e, index)}
                                  readOnly={item.product_id ? false : true}
                                />
                              </FormGroup>
                            </Col>
                            <Col md='3'>
                              <FormGroup>
                                <Label>Qty</Label>
                                <Input
                                  placeholder='Enter Quantity'
                                  name='qty'
                                  type="text"
                                  value={item.qty}
                                  onChange={(e) => this.onChangeProduct(e, index)}
                                  readOnly={item.product_id ? false : true}
                                />
                              </FormGroup>
                            </Col>
                            <Col md='3'>
                              <FormGroup>
                                <Label>Normal Price</Label>
                                <Input
                                  placeholder='Auto Calculate'
                                  name='normal_price'
                                  type="text"
                                  value={item.normal_price}
                                  onChange={(e) => this.onChangeProduct(e, index)}
                                  readOnly={true}
                                />
                              </FormGroup>
                            </Col>
                            <Col md='3'>
                              <FormGroup>
                                <Label>Price after Discount</Label>
                                <Input
                                  placeholder='Enter Price'
                                  name='price'
                                  type="text"
                                  value={item.price}
                                  onChange={(e) => this.onChangeProduct(e, index)}
                                  readOnly={item.product_id ? false : true}
                                />
                              </FormGroup>
                            </Col>
                            <Col md='3'>
                              <FormGroup>
                                <Label>Sub Total</Label>
                                <Input
                                  placeholder='Auto Calculate'
                                  name='price_total'
                                  type="text"
                                  value={item.subtotal > 0 ? addCommas(item.subtotal) : 0}
                                  onChange={(e) => this.onChangeProduct(e, index)}
                                  readOnly={true}
                                />
                              </FormGroup>
                            </Col>
                            {
                              item.sugestion_product?.price &&
                              <Col md={12} className='w-100 text-sm mb-2'>
                                <div>
                                  <div>History Price:</div>
                                    <Badge color="primary" className='mr-1'>
                                      {item.sugestion_product?.leads?.company_name}: {item.sugestion_product?.qty} pcs Rp. {addCommas(item.sugestion_product?.price)}
                                    </Badge>
                                </div>
                              </Col>
                            }

                            {
                              index > 0 &&
                              <Col className='w-100'>
                                <Button className='w-100 text-primary' onClick={() => this.onDeleteProduct(index)} outline><i className='fa fa-trash mr-2'></i>Hapus</Button>
                              </Col>
                            }
                          </Row>
                        )
                      })
                    }
                    <Alert color="primary" className='mt-3'>
                      <div className='d-flex'>
                        <Label className='w-25'>Total Qty</Label>
                        <div className="text-dark">:  <span className='font-weight-bold'>{this.state.total.qty}</span> </div>
                      </div>
                      <div className='d-flex'>
                        <Label className='w-25'>Total Price</Label>
                        <div className="text-dark">:  <span className='font-weight-bold'>Rp. {this.state.total.price ? addCommas(this.state.total.price) : 0}</span> </div>
                      </div>
                    </Alert>
                    <Button className='w-100' color="primary" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                      {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                    </Button>
                    <Button className='w-100 mt-2' color="info" onClick={() => this.props.history.push(`/leads-tracking/offering-letter/add/${this.state.id}`)} disabled={selectedOption.length ? false : true}>
                      Buat Surat Penawaran
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NotifToast
            show={this.state.notif.show}
            close={() => this.setState({ notif: { show: false } })}
            title={this.state.notif.title}
            status={this.state.notif.status}
            message={this.state.notif.message}
          />
          <SourceType
            open={this.state.showModalSource}
            close={() => this.setState({ showModalSource: !this.state.showModalSource })}
            data={this.state.optionSource}
            afterSubmit={() => this.onLoad()}
          />
          <Channel
            open={this.state.showModalChannel}
            close={() => this.setState({ showModalChannel: !this.state.showModalChannel })}
            data={this.state.optionChannel}
            id_source_type={this.state.body.id_source_type}
            afterSubmit={async () => {
              const channel = await API.get('product/leads/channel?id_source_type=' + this.state.body.id_source_type, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
              this.setState({
                optionChannel: channel.data.data,
              })
            }}
          />
        </div>
    )
  }
}

export default add;